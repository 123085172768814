import { round } from "@/Utils/index";
import { ADS_FILTERS } from "@/Constants/filters";

export const getRoundedAdsFilterValues = filterValues => {
  const arrayCriteriaKeys = [ADS_FILTERS.inclVatPrice.name];

  for (const key of arrayCriteriaKeys) {
    if (filterValues[key].length) {
      let min = filterValues[key][0];
      let max = filterValues[key][1];

      if (key === ADS_FILTERS.inclVatPrice.name) {
        min = round(filterValues[key][0], "min", key);
        max = round(filterValues[key][1], "max", key);
      }

      min = min === 0 ? ADS_FILTERS[key].min : min;
      max = max === 0 ? ADS_FILTERS[key].max : max;

      filterValues[key] = [min, max];
    }
  }

  return filterValues;
};
