/**
 * @namespace Constants
 * @module Constantes Codes
 * @description Fichier de constantes de codes d'informations neutres
 * @author Sébastien NOBOUR
 */

/**
 * @constant
 * @type {string}
 */
export const IS_NOT_SUBSCRIBER = "IS_NOT_SUBSCRIBER";
