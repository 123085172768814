import { ENTRYPOINT } from "@/Constants/restApiRoutes";

/**
 * @namespace Constants
 * @module Constantes Fallback URLs
 * @author Sébastien NOBOUR
 * @description Fichier de constantes d'url fallback
 */

/**
 * @constant
 * @type {string}
 */
export const dealershipLogoFallbackUrl = `${ENTRYPOINT}/images/dealers/no-logo-concession.jpg`;

/**
 * @constant
 * @type {string}
 */
export const dealershipCoverImageFallbackUrl = `${ENTRYPOINT}/images/dealers/no-img-concession.jpg`;
