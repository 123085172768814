import { Serializer } from "miragejs";

// examples  single resource brand response api/brands/14
// const respRes = {
//   '@context': '/public/api/contexts/Brand',
//   '@id': '/public/api/brands/14',
//   '@type': 'Brand',
//   id: 14,
//   name: '3CCARTIER',
//   slug: '3ccartier',
//   models: [
//     {
//       '@id': '/public/api/models/124',
//       '@type': 'Model',
//       id: 124,
//       name: 'BT-5',
//       slug: 'bt-5',
//     },
//     {
//       '@id': '/public/api/models/177',
//       '@type': 'Model',
//       id: 177,
//       name: 'Carav1',
//       slug: 'carav1',
//     },
//   ],
//   family: [
//     {
//       '@id': '/public/api/families/1',
//       '@type': 'Family',
//       nameFr: 'Camping-car',
//     },
//   ],
// }

// examples  single resource brand response api/brands
// const respCollectionRes = {
//   '@context': '/public/api/contexts/Brand',
//   '@id': '/public/api/brands',
//   '@type': 'hydra:Collection',
//   'hydra:member': [
//     {
//       '@id': '/public/api/brands/14',
//       '@type': 'Brand',
//       id: 14,
//       name: '3CCARTIER',
//       slug: '3ccartier',
//       models: [
//         {
//           '@id': '/public/api/models/124',
//           '@type': 'Model',
//           id: 124,
//           name: 'BT-5',
//           slug: 'bt-5',
//         },
//         {
//           '@id': '/public/api/models/177',
//           '@type': 'Model',
//           id: 177,
//           name: 'Carav1',
//           slug: 'carav1',
//         },
//       ],
//       family: [
//         {
//           '@id': '/public/api/families/1',
//           '@type': 'Family',
//           nameFr: 'Camping-car',
//         },
//       ],
//     },
//   ],
//   'hydra:totalItems': 157,
// }

export const HydraSerializer = Serializer.extend({
  // schema
  // attr
  // embed
  root: true,
  // serializeIds
  // include
  // keyForAttribute
  // keyForCollection
  // keyForEmbeddedRelationship
  // keyForForeignKey
  // keyForModel
  // keyForPolymorphicForeignKeyId
  // keyForPolymorphicForeignKeyType
  // keyForRelationship
  // keyForRelationshipIds
  // normalize
  // serialize
});
