import { useContext } from "react";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import UseMediaQueries from "@/Shared/Hooks/UseMediaQueries";

export const defaultEtatsMenu = [
  { id: "global", toggled: false, refDom: null, timeout: null },
  { id: "blog", toggled: false, refDom: null, timeout: null },
  { id: "compte", toggled: false, refDom: null, timeout: null },
  { id: "notifications", toggled: false, refDom: null, timeout: null },
];

export default function UseMenus(id, refMenu) {
  const {
    state: { etatsMenu },
    setEtatsMenu,
  } = useContext(GlobalContext);
  const menuRef = etatsMenu?.find(e => e.id === id);
  const key = etatsMenu?.findIndex(el => el.id === id);

  /* -- */
  const mq = UseMediaQueries();
  const mqmin60 = mq.min60;
  const mqmax60 = mq.max60;
  /* -- */

  const toggleMenu = (id, etat) => {
    // MAJ du state récupéré de GlobalContext lorsque les boutons sont (!)survolés
    ////////////////////////////////////////////////////////////////////
    const newEtatsMenu = etatsMenu?.map((el, i) => {
      // console.log('toggleMenu',id,' :: ', etat,' ][ map[',el.id,   '] : ',el.toggled,']')
      return el.id === id
        ? { ...el, toggled: etat }
        : { ...el, toggled: false };
    });
    setEtatsMenu(newEtatsMenu);
    ////////////////////////////////////////////////////////////////////
  };

  // actions sur les boutons
  ////////////////////////////////////////////////////////////////////
  const containerOnMouseEnter = () => {
    // console.log('containerOnMouseEnter', id)
    if (mqmin60) {
      toggleMenu(id, true);
    }
  };
  const containerOnMouseLeave = refDomMenu => {
    // console.log('containerOnMouseLeave', id)
    if (mqmin60) {
      toggleMenu(id, false);
    }
  };
  // pour MOBILE
  ////////////////////////////////////////////////////////////////////
  const btnOnClick = refDomMenu => {
    /* pour mobile */
    // console.log('btnOnClick', id)
    if (mqmax60) {
      toggleMenu(id, !menuRef.toggled);
    }
  };

  return {
    toggleMenu,
    menuRef,
    containerOnMouseEnter,
    containerOnMouseLeave,
    btnOnClick,
  };
}
