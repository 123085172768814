import React, { useContext, useEffect, useState } from "react";
import cssHeader from "@/Shared/Header/Header.module.css";
import Link from "next/link";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { PAGE_PARTICULIER_RECHERCHES_SAUVEGARDEES } from "@/Constants/pageRoutes";

// TODO since it is a children component to header > SousMenuProfile, should not these components be called SubHeaders
const SousMenuParticulier = ({ onClick }) => {
  const {
    state: { my },
  } = useContext(GlobalContext);

  const [greetings, setGreetings] = useState("Bonjour");

  useEffect(() => {
    if (my?.firstname) {
      const message = `Bonjour ${my?.firstname}`;
      setGreetings(message);
    }
  }, [my]);

  useEffect(() => {
    return () => {
      setGreetings("");
    };
  }, []);

  return (
    <div className={`${cssHeader["c-nav-main__sous-menu__split"]}`}>
      <div className={`${cssHeader["c-nav-main__sous-menu__colonne"]}`}>
        <div
          className={`${cssHeader["c-nav-main__sous-menu__titre"]} f-primary f-primary--700 c-primary`}
        >
          My Wago
        </div>
        <Link href='/espace-client/favoris'>
          <a onClick={onClick}>
            Favoris{" "}
            <strong className={"f-primary"}> ({my?.nbFavorites || 0})</strong>
          </a>
        </Link>

        <Link href={PAGE_PARTICULIER_RECHERCHES_SAUVEGARDEES}>
          <a>
            Recherches sauvegardées{" "}
            <strong className={"f-primary"}>
              {" "}
              ({my?.searches?.length ?? "0"})
            </strong>
          </a>
        </Link>
        {/* #SLEEPING <Link href="/espace-client/abonnements"><a>Abonnements &amp; Notifications</a></Link>*/}
        {/* <Link href="/espace-client/abonnements"><a onClick={onClick}>Notifications</a></Link>*/}
      </div>

      <div className={`${cssHeader["c-nav-main__sous-menu__colonne"]}`}>
        <div
          className={`${cssHeader["c-nav-main__sous-menu__titre"]} f-primary f-primary--700 c-primary`}
        >
          {greetings}
        </div>
        <Link href='/espace-client/mon-compte'>
          <a onClick={onClick}>Mon compte</a>
        </Link>
        <Link href='/espace-client/mes-vehicules'>
          <a onClick={onClick}>
            <span>
              {my?.nbVehicles < 2 ? "Mon véhicule" : "Mes véhicules"}{" "}
              <strong className={"c-primary"}>({my?.nbVehicles})</strong> /
              Annonce{my?.nbOffers > 1 ? "s" : ""}{" "}
              <strong className={"c-primary"}>({my?.nbOffers || 0})</strong>
            </span>
          </a>
        </Link>
        {/* #SLEEPING <Link href="/espace-client/factures"><a>Paiements &amp; factures</a></Link>*/}
      </div>
    </div>
  );
};

SousMenuParticulier.propTypes = {
  //
};

export default SousMenuParticulier;
