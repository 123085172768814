import * as types from "@/Types/ads";
import { filtersToQueryString, objectToQueryString } from "@/Utils/index";
import { restApiGetOffers } from "@/Utils/http/businessApiRequests";
import {
  ACTION_FILTER_CHANGED_FROM_SEARCH_FORM,
  ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS,
  ACTION_FROM_INITIAL_FILTER_GENERATION,
} from "@/Constants/actions";
import { ORDER } from "@/Constants/InputsValues";
import { removeValuesAdsFilter } from "@/Utils/adsFilter/removeValuesAdsFilter";

/**
 * @file store/actions.js
 * @description contient des fonctions permettant de mettre à jour la state redux liée au filtrage d'annonce.
 * Utiliser redux dans cette circonstance est nécessaire car un changement dans le store entraine d'autres mécanismes
 * difficile à traiter via react context (qui est aussi davantage à utiliser pour stocker de façon simple un objet
 * complet et moins mettre à jour finement l'un de ses champs)
 * @todo plusieurs fonctions devraient être regroupées en prenant jusqu'à 3 paramètres dont un objet d'options
 * (pr éviter d'avoir plein de code et en avoir moins à tester) + créer une fonction qui ne met à jour qu'un critère
 * et non le filtre complet (pour les perf et l'UX).
 * @module redux
 * @namespace Redux Ads
 */

export const changeAdsActionFrom = actionFrom => (dispatch, getState) => {
  dispatch({
    type: types.ACTION_CHANGED,
    payload: {
      actionFrom,
    },
  });
};

export const changeAdsOrder = selectedOption => dispatch => {
  if (!selectedOption) {
    selectedOption = ORDER.find(
      order => order.value === "publish-date-decrease"
    );
  }

  dispatch({
    type: types.ORDER_CHANGED,
    payload: { order: { ...selectedOption } },
  });
};

/**
 * @function met a jour le filtre d'annonces dans le store
 * @todo remove name param as a criteria or full filter can be handled by ads reducer at event type FILTER_VALUES_LOADED,
 * remove param actionFrom and add a an options object with fields actionFrom,
 * peut-être que cette fonction ne devrait pas appeller loadAds (voir commentaire sur changeOrder)
 * @returns {void}
 * @param filters
 */
export const changeAdsFilter = filters => (dispatch, getState) => {
  dispatch({
    type: types.FILTER_VALUES_LOADED,
    payload: {
      filters: { ...filters },
    },
  });
};

/**
 *
 * @param filters
 * @returns {(function(*): void)|*}
 */
export const changeInitialAdsFilter = filters => dispatch => {
  const initialFilter = removeValuesAdsFilter({ ...filters });

  dispatch({
    type: types.INITIAL_ADS_FILTER_VALUES_LOADED,
    payload: {
      initialFilter: { ...initialFilter },
    },
  });
};

/**
 * @function
 * @description change l'ordre de tri des annonces en transformant l'ordre contenu dans le store redux en un queryParams
 * et soumet une requête à l'API métier
 * @todo peut-être que cette fonction ne devrait pas appeler directement loadAds si l'on change aussi le filtre (et vice-vers)
 * @param selectedOption
 * @returns {void}
 */
export const changeOrder = selectedOption => dispatch => {
  dispatch({
    type: types.ACTION_CHANGED,
    payload: {
      actionFrom: ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS,
    },
  });

  if (!selectedOption) {
    selectedOption = {
      order: {},
    };
  }

  dispatch({
    type: types.ORDER_CHANGED,
    payload: { order: selectedOption },
  });

  dispatch(loadAds());
};

/**
 * @function met a jour le filtre d'annonces dans le store
 * @deprecated utiliser changeAdsFilter
 * @param name
 * @param filter
 * @param actionFrom
 * @returns {void}
 */
export const changeFilters =
  (name, filter, actionFrom = ACTION_FILTER_CHANGED_FROM_SEARCH_FORM) =>
  (dispatch, getState) => {
    try {
      let filters = { ...getState().ads.filters };

      if (name?.length && filter) {
        filters = { ...filters, [name]: filter };
      }

      if (actionFrom !== ACTION_FROM_INITIAL_FILTER_GENERATION)
        dispatch({
          type: types.ACTION_CHANGED,
          payload: {
            actionFrom,
          },
        });

      dispatch({
        type: types.FILTER_VALUES_LOADED,
        payload: { filters },
      });

      dispatch(loadAds());
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
    }
  };

/**
 * @description utiliser changeFilters et lui ajouter un objet options en 2e param avec le champ actionFrom
 * @param name
 * @param filter
 * @returns {void}
 */
export const updateAdsFiltersAndAds = adsFilter => (dispatch, getState) => {
  dispatch({
    type: types.FILTER_VALUES_LOADED,
    payload: { ...adsFilter },
  });

  dispatch(loadAds());
};

export const changeAdsListFromFormSubmit = () => dispatch => {
  dispatch({
    type: types.ACTION_CHANGED,
    payload: {
      actionFrom: ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS,
    },
  });
};

/**
 * @deprecated
 * @description utiliser changeAdsFilter
 * @param name
 * @param filter
 * @returns {void}
 */
export const loadFilterValues = (name, filter) => (dispatch, getState) => {
  const filters = { ...getState().ads.filters, [name]: filter };

  dispatch({
    type: types.FILTER_VALUES_LOADED,
    payload: { filters },
  });
};

/**
 * @function
 * @description va récupérer auprès de l'API métier les annonces correspondant au filtre contenu dans le store redux
 * en transformant le filtre en une querystring passée en requête
 * @returns {void}
 */
export const loadAds = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.LOAD_STARTED });
    const { order, filters, page } = getState().ads;

    const queryParams = {};

    const isFiltersTruthy = Object.keys(filters)?.length > 0;

    if (order) {
      queryParams.order = order.order;
    }

    if (!order) {
      if (page) queryParams.page = page;

      if (!(isFiltersTruthy && page)) queryParams.page = 1;
    }

    const queryString =
      objectToQueryString(queryParams) + filtersToQueryString(filters);
    const response = await restApiGetOffers(queryString);

    const offers = response?.offers || [];

    dispatch({
      type: types.LOAD_SUCCESS,
      payload: {
        list: offers,
        count: response?.totalItems,
        page: response?.currentPageId,
        nextPage: response?.nextPageId,
        previousPage: response?.previousPageId,
        firstPage: response?.firstPageId,
        lastPage: response?.lastPageId,
      },
    });
  } catch (error) {
    if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
    dispatch({ type: types.LOAD_FAILURE, payload: { error: error.message } });
  }
};

/**
 * @deprecated
 * @function
 * @description devrait être traitée dans loadAds en lui donnant un paramètre options contenant le champ actionFrom
 * @returns {void}
 */
export const loadMoreAds = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.ACTION_CHANGED,
      payload: {
        actionFrom: ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS,
      },
    });

    dispatch({ type: types.LOAD_STARTED });

    const { nextPage, order: orderValue, filters, list } = getState().ads;

    const order =
      ORDER.find(reference => reference.value === orderValue)?.order || null;

    if (nextPage > 1) {
      const queryString =
        objectToQueryString({ page: nextPage, order }) +
        filtersToQueryString(filters);
      const response = await restApiGetOffers(queryString);

      const offers = response?.offers || [];

      dispatch({
        type: types.LOAD_SUCCESS,
        payload: {
          list: [...list, ...offers],
          count: response?.totalItems,
          page: response?.currentPageId,
          nextPage: response?.nextPageId,
          previousPage: response?.previousPageId,
          firstPage: response?.firstPageId,
          lastPage: response?.lastPageId,
        },
      });
    }
  } catch (error) {
    if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);

    dispatch({ type: types.LOAD_FAILURE, payload: { error: error.message } });
  }
};

/**
 * @function
 * @deprecated
 * @description utiliser changeOrder ou changeFilters
 * @param filters
 * @todo if offers order by default is publish-date-decrease, it should not be added here. Providing a null string for the value in the reducer should actually mean that the default order value should be loaded from the constant Order
 * @returns {void}
 */
export const reinitializeAdsFiltersAndOrder = filters => dispatch => {
  dispatch({
    type: types.FILTER_VALUES_LOADED,
    payload: {
      filters: { ...filters },
    },
  });

  const order = ORDER.find(order => order.value === "publish-date-decrease");

  dispatch({
    type: types.ORDER_CHANGED,
    payload: {
      order,
    },
  });

  dispatch(loadAds());

  dispatch({
    type: types.ACTION_CHANGED,
    payload: {
      actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
    },
  });
};

/**
 * @function Est en charge de mettre à jour le filtre d'annonces avec un des données initiales.
 * Devrait être fusionnée avec changeFilter et prenant en 2e parametre l'objet options contenant le champs actionFrom, isToFetchNewAds
 * @param filters
 * @param options
 * @deprecated
 * @returns {void}
 */
export const reinitializeAdsFilters = filters => dispatch => {
  dispatch({
    type: types.FILTER_VALUES_LOADED,
    payload: {
      filters,
    },
    actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
  });

  dispatch(loadAds());

  dispatch({
    type: types.ACTION_CHANGED,
    payload: {
      actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
    },
  });
};

/**
 * @deprecated
 * @description utiliser changeOrder et rajouter en options l'action. Inutile de rajouter un param isToFetchNewAds
 * puisque cette action doit déclencher la récupération de nouvelles annonces
 * @param order
 * @returns {void}
 */
export const reinitializeAdsOrder = order => dispatch => {
  dispatch({
    type: types.ORDER_CHANGED,
    payload: { order },
    actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
  });

  dispatch(loadAds());

  dispatch({
    type: types.ACTION_CHANGED,
    payload: {
      actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
    },
  });
};

/**
 * @function
 * @deprecated
 * @description utiliser reinitializeAdsFilters et donner en option l'action utilisée
 * @param filters
 * @returns {void}
 */
export const reinitializeAdsFiltersFromForm = filters => dispatch => {
  dispatch({
    type: types.FILTER_VALUES_LOADED,
    payload: {
      filters,
    },
    actionFrom: ACTION_FILTER_CHANGED_FROM_SEARCH_FORM,
  });

  dispatch(loadAds());
};
