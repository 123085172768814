import React, { useContext, useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import css from "./Footer.module.css";
import Logo from "@/Shared/Logo";
import Svg from "@/Shared/Svg";
import FormNewsletter from "@/Forms/FormNewsletter";
import Link from "next/link";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import Btn from "@/Shared/Ui/Btn";
import { useRouter } from "next/router";
import { AUTHENTICATED, UNAUTHENTICATED } from "@/Constants/global";
import {
  PAGE_BLOG,
  PAGE_CONCEPT_WAG,
  PAGE_CONNEXION_INSCRIPTION,
  PAGE_CREATION_COMPTE_PARTICULIER,
  PAGE_CREATION_COMPTE_PRO,
  PAGE_PARTICULIER_COMPTE,
  PAGE_PRO_HOME,
  PAGE_PRO_MON_COMPTE,
  PAGE_RECHERCHE_VEHICULE,
  PAGE_VENDEURS,
} from "@/Constants/pageRoutes";
import { testLocators } from "@/Constants/testLocators";

const Footer = () => {
  const {
    state: { categoryAnnexes },
  } = useContext(GlobalContext);
  const router = useRouter();

  const { data, status } = useSession();
  const [profilFooter, setProfil] = useState();
  const [titreCompte, setTitreCompte] = useState("Votre compte");

  // https://developers.axeptio.eu/cookies/axeptio-javascript-sdk#alternative-using-the-window.axeptiosdk-object
  const openPopupCookies = () => {
    if (typeof window !== "undefined") window?.openAxeptioCookies();
  };

  useEffect(() => {
    if (status === UNAUTHENTICATED) {
      setProfil("invite");
      setTitreCompte("Votre compte");
    }
    if (data?.user?.userType && status === AUTHENTICATED) {
      setProfil(data.user.userType);
      setTitreCompte("My Wago");
    }
  }, [status, data?.user?.userType]);

  return (
    <>
      <footer
        data-testid={testLocators.components.Shared.Footer.title}
        className={`
        ${css["l-footer"]} 
        ${
          (router?.pathname === PAGE_CONNEXION_INSCRIPTION ||
            router?.pathname === PAGE_CREATION_COMPTE_PRO ||
            router?.pathname === PAGE_CREATION_COMPTE_PARTICULIER) &&
          css["l-footer--no-border"]
        }`}
      >
        <div className={"c-footer l-marges-fixes"}>
          <div className={`${css["c-footer__logos"]}`}>
            <Logo situation='header' />

            <div className={`${css["c-footer__social"]}`}>
              <a
                href='https://www.facebook.com/weekandgocom/'
                target='_blank'
                rel='noreferrer'
                aria-label='Facebook'
              >
                <Svg selection='ico-fb' />
              </a>
              <a
                href='https://www.youtube.com/channel/UCi5la4x4Jx16YyXi_FB8lOQ'
                target='_blank'
                rel='noreferrer'
                aria-label='Youtube'
              >
                <Svg selection='ico-yt' />
              </a>
              <a
                href='https://www.instagram.com/weekandgo_com/'
                target='_blank'
                rel='noreferrer'
                aria-label='Instagram'
              >
                <Svg selection='ico-ig' />
              </a>
            </div>
          </div>

          <FormNewsletter />

          <div className={`${css["c-footer__raccourcis"]} l-marges-fixes`}>
            <div className={`${css["c-footer__liens"]}`}>
              <div className={`${css["c-footer__raccourcis__block"]}`}>
                <h2
                  className={`${css["c-footer__raccourcis__titre"]} f-primary f-primary--700`}
                >
                  Je cherche
                </h2>
                <div className={`${css["c-footer__raccourcis__liens"]}`}>
                  <Link
                    href={{
                      pathname: PAGE_RECHERCHE_VEHICULE,
                      query: { reinitialiseAdsFilter: "true" },
                    }}
                  >
                    <a aria-label='rechercher un véhicule'>Un véhicule</a>
                  </Link>
                  <Link href={PAGE_VENDEURS}>
                    <a aria-label='rechercher un vendeur'>Un vendeur</a>
                  </Link>
                  <Link href={PAGE_BLOG}>
                    <a aria-label='blog'>Blog</a>
                  </Link>
                </div>
              </div>
              <div className={`${css["c-footer__raccourcis__block"]}`}>
                <h2
                  className={`${css["c-footer__raccourcis__titre"]} f-primary f-primary--700`}
                >
                  WeekandGO
                </h2>
                <div className={`${css["c-footer__raccourcis__liens"]}`}>
                  <Link href={PAGE_CONCEPT_WAG}>
                    <a aria-label='le concept Weekandgo'>Le concept</a>
                  </Link>
                  {/* #SLEEPING <LinkContactWeekandgo /> */}
                </div>
              </div>
              <div className={`${css["c-footer__raccourcis__block"]}`}>
                <h2
                  className={`${css["c-footer__raccourcis__titre"]} f-primary f-primary--700`}
                >
                  {titreCompte}
                </h2>
                <div className={`${css["c-footer__raccourcis__liens"]}`}>
                  {profilFooter === "invite" ? (
                    <>
                      <Link href={PAGE_CONNEXION_INSCRIPTION}>
                        <a aria-label='inscription'>{"Je m'inscris"}</a>
                      </Link>
                      <Link href={PAGE_CONNEXION_INSCRIPTION}>
                        <a aria-label='connexion'>Je me connecte</a>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                  {profilFooter === "particulier" ? (
                    <>
                      <Link href={PAGE_PARTICULIER_COMPTE}>
                        <a aria-label='mon compte'>Mon compte</a>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                  {profilFooter === "professionnel" ? (
                    <>
                      <Link href={PAGE_PRO_HOME}>
                        <a aria-label='tableau de bord pro'>Tableau de bord</a>
                      </Link>
                      <Link href={PAGE_PRO_MON_COMPTE}>
                        <a aria-label='mon compte pro'>Mon compte</a>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${css["c-footer__raccourcis__block"]} ${css["c-footer__raccourcis__block--dte"]}`}
            >
              <div className={`${css["c-footer__raccourcis__liens"]}`}>
                <Btn type={"externalLink"} href='https://www.yescapa.fr/'>
                  Location entre particuliers{" "}
                </Btn>
                {categoryAnnexes?.articles?.map(article => (
                  <Link
                    href={`/${categoryAnnexes?.slug}/${article.slug}`}
                    key={article.slug}
                  >
                    <a aria-label={`article : ${article.title}`}>
                      {article.title}
                    </a>
                  </Link>
                ))}
                <Btn type={"text"} onClick={openPopupCookies}>
                  Gestion des cookies
                </Btn>
                {/*<Link href='/annexes/plan'><a>Plan du site</a></Link>*/}
              </div>
            </div>
          </div>

          <div>
            <div className={css["mention-covoiturage"]}>
              <p>
                <span>
                  Pour les trajets courts, privilégiez la marche ou le vélo
                </span>
                <strong>#SeDéplacerMoinsPolluer</strong>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  //
};

export default Footer;
