import { FEATURES } from "@/Constants/features";
import { ENVS } from "@/Constants/global";

// featureFlagManager.js
/**
 * @file utils/featureFlagManager.js
 * @description contient des fonctions générales
 * @todo a besoin d'être redécoupé plus finement
 * @module Utils index
 * @namespace Utils
 */

export const featureManager = {
  isFeatureEnabled(featureName, env) {
    return this.features?.[featureName](env) || false;
  },

  features: {
    [FEATURES.googleTagManager]: env => ENVS.production === env,
  },
};
