export const themeStyle = {
  breakpoints: {
    values: {
      mobile: 0,
      b30: 480,
      b45: 720,
      b60: 960,
      b80: 1280,
      b100: 1600,
      big: 1920,
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#063865",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: "#ff8674",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    /*
    ############################################################################
    ############################################################################
    S L I D E R
     */
    MuiSlider: {
      styleOverrides: {
        thumb: {
          backgroundColor: "secondary",
          border: "4px #ddd solid",
          width: "22px",
          height: "22px",
          marginTop: "-2px",
          marginLeft: "-2px",
        },
        rail: {
          color: "#ddd",
          height: "5px",
          marginTop: "-2px",
        },
        track: {
          height: "5px",
          marginTop: "-2px",
        },
        mark: {
          width: "5px",
          height: "5px",
          marginTop: "-2px",
          color: "#ddd",
        },
        markLabel: {
          fontSize: "1.3rem",
          fontStyle: "italic",
          marginTop: "0.6rem",
        },
      },
    },
    /*
    ############################################################################
    ############################################################################
    BOX
     */
    MuiBox: {
      variants: [
        {
          props: { variant: "whiteShadow" },
          style: {
            background: "#fff",
            boxShadow: "0 0 5px rgba(0,0,0,0.3)",
            borderRadius: "10px",
          },
        },
      ],
    },
    /*
    ############################################################################
    ############################################################################
    I C O N E S
     */
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transform: "scale(1.4)",
        },
      },
    },
    /*
    ############################################################################
    ############################################################################
    GLOBAL I N P U T
     */
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          border: "3px #f2f2f2 solid",
        },
        input: {
          padding: "15px 12px 10px",
        },
        underline: {
          "&::before": {
            display: "none",
          },
          "&::after": {
            display: "none",
          },
        },
      },
    },
    /*
    ############################################################################
    ############################################################################
    S E L E C T
     */
    MuiSelect: {
      styleOverrides: {
        icon: {
          top: "calc(50% - 5px)",
          right: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "1.2rem 1.2rem 1.1rem",
        },
      },
    },
    /*MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: "translate(14px, 15px) scale(1)"
        }
      }
    },*/
    /*
    ############################################################################
    ############################################################################
    C H E C K B O X
     */
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#e0e0e0",
          transform: "translateY(-1px) scale(1.1)",
          marginRight: "3px",
          "&.Mui-checked": {
            color: "#ff8674",
          },
        },
      },
    },
    /*
    ############################################################################
    ############################################################################
        C H E C K B O X
    */
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#e0e0e0",
          transform: "translateY(-1px) scale(1.5)",
          marginRight: "3px",
          "&.Mui-checked": {
            color: "#ff8674",
          },
        },
      },
    },
  },
  /*
  ############################################################################
  ############################################################################
  F O N T     G L O B A L
   */
  typography: {
    root: {
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: "1.5rem",
    },
    h1: {
      fontSize: "2.4rem",
    },
    h2: {
      fontSize: "2.0rem",
    },
    h3: {
      fontSize: "1.7rem",
    },
    a: {
      color: "#0a4b77",
    },
  },
  boxMenu: {
    whiteShadow: {
      background: "#fff",
      boxShadow: "0 0 5px rgba(0,0,0,0.3)",
      borderRadius: "10px",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontSize: "1.5rem",
      },
    },
  },
};
