import css from "@/Shared/HeaderUser/HeaderUser.module.css";
import cssHeader from "@/Shared/Header/Header.module.css";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import Btn from "@/Shared/Ui/Btn";
import SousMenuProfil from "@/Widgets/Header/SousMenuProfil";
import { HEADERS_ATTRIBUTE } from "@/Constants/dataAttributes";

const HeaderParticulier = ({ toggleoffMenuMobile }) => {
  return (
    <div
      className={`${css["c-header-user"]} ${cssHeader["c-nav-main__ref"]} layout__header layout__header--particulier`}
      data-header={HEADERS_ATTRIBUTE.individual}
    >
      {/* SOUS MENU PROFIL -- VARIE SUIVANT PRO / PARTICULIER */}
      <SousMenuProfil context='particulier' />

      {/* SOUS MENU NOTIFICATIONS */}
      {/* #SLEEPING <SousMenuNotifications context="particulier" />*/}

      {/* #SLEEPING <div className={`${css['c-header-user__new']} ${css['c-header-mail__btn']}`}>*/}
      {/*  <BtnFilter type="routeLink" path="/espace-client/messagerie" svg="ico-leadMail" className={`js__trigrpusr ${uiBtn['u-btn-icousr--logue']} `}/>*/}
      {/*</div>*/}

      <Btn
        type='routeLink'
        className={`${css["c-header-user__publier"]} f-primary ${uiBtn["u-btn-primary"]} ${uiBtn["u-btn-hoverSlide--secondary"]} ${uiBtn["u-btn-hoverSlide"]}`}
        label='Publier une annonce'
        path='/espace-client/mes-vehicules/ajout'
      />
    </div>
  );
};

HeaderParticulier.propTypes = {
  //
};

export default HeaderParticulier;
