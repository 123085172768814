import React, { useState } from "react";
import PropTypes from "prop-types";
import css from "./Critere.module.css";
import blockCss from "../BlockCritere/BlockCritere.module.css";

import Svg from "@/Shared/Svg";
import wagoTheme from "@/Theme/themeWagoMui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import { Controller, useFormContext } from "react-hook-form";

const Critere = ({
  svgSelection,
  label,
  type,
  tPlaceholder,
  isSvg,
  min,
  max,
  step,
  register,
  ...props
}) => {
  const selectionSvg = svgSelection ?? "";
  const labelSvg = label ?? "";

  const formContext = useFormContext();

  const [value, setValue] = useState([min, max]);
  const marks = [
    { value: min, label: min },
    { value: max, label: max },
  ];
  const [isActiveTxt, setActiveTxt] = useState(false);
  const [isActiveIco, setActiveIco] = useState(false);

  const handleChange = (event, newValue) => setValue(newValue);

  const toggleClassText = e => {
    setActiveTxt(!isActiveTxt);
    e.preventDefault();
  };

  const toggleClassIco = e => {
    setActiveIco(!isActiveIco);
    e.preventDefault();
  };

  const getElementBlock = type => {
    let output;

    switch (type) {
      case "text":
      case "textfield":
        output = register ? (
          <Controller
            name={register?.name}
            control={formContext?.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value ?? ""}
                label={tPlaceholder}
                variant='outlined'
                className={`${isSvg ? "u-var-ico" : ""}`}
              />
            )}
          />
        ) : (
          <TextField
            id='outlined-basic'
            label={tPlaceholder}
            variant='outlined'
            className={`${isSvg ? "u-var-ico" : ""}`}
          />
        );

        output = (
          <div key={props?.key}>
            <ThemeProvider theme={wagoTheme}>{output}</ThemeProvider>
          </div>
        );
        break;

      case "checkbox":
        output = (
          <div key={props?.key}>
            <ThemeProvider theme={wagoTheme}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='checkedC'
                    classes={{ root: "custom-checkbox-root" }}
                  />
                }
                label={label}
              />
            </ThemeProvider>
          </div>
        );
        break;

      case "icoHasNiv2":
        output = (
          <div key={props?.key}>
            <a href='#' className={`${css["c-critere-toggleNiv2-link"]}`}>
              <Svg
                selection='ico-niv2-moins'
                classNames={"c-critere-toggleMoins"}
              />
              <Svg
                selection='ico-niv2-plus'
                classNames={"c-critere-togglePlus"}
              />
              {label}
            </a>
          </div>
        );
        break;

      case "bouton-texte":
      case "button":
        output = (
          <a
            onClick={toggleClassText}
            key={props?.key}
            href=''
            className={`${css["c-critere-txtSelect"]} f-primary ${
              isActiveTxt && css["c-critere-txtSelect--selected"]
            }`}
          >
            <span>{labelSvg}</span>
          </a>
        );
        break;

      case "slider":
        output = (
          <div key={props?.key}>
            <ThemeProvider theme={wagoTheme}>
              <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay='auto'
                step={step}
                min={min}
                max={max}
                // getAriaValueText={label}
                aria-labelledby={label}
                marks={marks}
              />
            </ThemeProvider>
          </div>
        );
        break;

      case "multi":
        output = (
          <div
            key={props?.key}
            className={`${blockCss["c-search__critere"]} ${blockCss["c-search__groupe__space"]}`}
          >
            <ThemeProvider theme={wagoTheme}>
              {label && (
                <h3
                  className={`${blockCss["c-search__critere__titre"]} f-primary f-primary--700`}
                >
                  {label}
                </h3>
              )}
              <div className={`${blockCss["c-search__form__slider"]}`}>
                <Slider
                  value={value || false}
                  onChange={handleChange}
                  valueLabelDisplay='auto'
                  step={step}
                  min={min}
                  max={max}
                  // getAriaValueText={label}
                  aria-labelledby={label}
                  marks={marks}
                />
              </div>
            </ThemeProvider>
          </div>
        );
        break;

      default:
        output = (
          <a
            onClick={toggleClassIco}
            key={props?.key}
            href=''
            className={`${css["c-critere-imgsvg"]} ${
              isActiveIco && css["c-critere-imgsvg--selected"]
            }`}
          >
            {selectionSvg && <Svg selection={selectionSvg} />}
            {labelSvg && <span>{labelSvg}</span>}
          </a>
        );
    }

    return output;
  };

  return getElementBlock(type);
};

Critere.propTypes = {
  type: PropTypes.string,
  selectionSvg: PropTypes.string,
  labelSvg: PropTypes.string,
  isSvg: PropTypes.bool,
};

export default Critere;
