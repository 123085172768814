import css from "@/Shared/HeaderUser/HeaderUser.module.css";
import Svg from "@/Shared/Svg";
import Link from "next/link";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { useContext, useEffect } from "react";
import { PAGE_PRO_HOME } from "@/Constants/pageRoutes";
import { IS_NOT_SUBSCRIBER } from "@/Constants/codes";

const TableauDeBord = () => {
  const {
    state: { my, globalModalProps },
    setGlobalModalProps,
  } = useContext(GlobalContext);

  const data = {
    nbOffers: my?.nbOffers ?? "0",
    nbNeufs: my?.nbNeufs ?? "0",
    nbOccasions: my?.nbOccasions ?? "0",
  };

  if (!my?.subscribed) {
    data.nbOffers = "0";
    data.nbNeufs = "0";
    data.nbOccasions = "0";
  }

  const showIsNotSubscriberInfo = () =>
    setGlobalModalProps({ code: IS_NOT_SUBSCRIBER });

  const children = (
    <a className={`${css["c-header__proTDB__link"]}`}>
      <div className={`${css["c-header__proTDB"]} f-primary`}>
        <div className={`${css["c-header__proTDB__titre"]}`}>
          <strong className={"f-primary--700"}>{data?.nbOffers}</strong>
          <span>annonces</span>
        </div>

        <div className={`${css["c-header__proTDB__linkk"]}`}>
          <Svg selection='ico-tdb' />
          <span>
            Tableau
            <br />
            de bord
          </span>
        </div>

        <div className={`${css["c-header__proTDB__nb"]}`}>
          <strong className={"c-primary"}>VN</strong>
          <span>({data?.nbNeufs})</span>
        </div>

        <div
          className={`${css["c-header__proTDB__nb"]} ${css["c-header__proTDB__nb--last"]}`}
        >
          <strong className={"c-primary"}>VO</strong>
          <span>({data?.nbOccasions})</span>
        </div>
      </div>
    </a>
  );

  useEffect(() => {
    if (globalModalProps?.isClosed) {
      setGlobalModalProps();
    }
  }, [globalModalProps?.isClosed]);

  return (
    <>
      {my.subscribed ? (
        <Link href={PAGE_PRO_HOME}>{children}</Link>
      ) : (
        <div onClick={showIsNotSubscriberInfo}>{children}</div>
      )}
    </>
  );
};

TableauDeBord.propTypes = {
  //
};

export default TableauDeBord;
