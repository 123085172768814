import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import {
  ALREADY_SUBSCRIBED_NEWSLETTER,
  CODE_403,
  DEALERSHIP_SUPPRESSION_ALREADY_REQUESTED,
  EMPTY_DATA,
  ERROR_DISTANT_SERVER,
  ERROR_MISSING_OFFER_FROM_COMPARATOR,
  ERROR_NO_OFFER_FOR_BRAND,
  ERROR_OFF_SUBSCRIPTION,
  ERROR_SAVED_RESEARCH_VALUES_FETCH,
  ERROR_SERVER,
  FAILED_TO_FETCH,
  GENERIC_ERROR,
  INCOMPLETE_RECAPTCHA,
  INVALID_MESSAGE,
  IS_NOT_CONNECTED,
  IS_UNDER_LEGAL_AGE_ACCOUNT_CREATE,
  MAX_50_IMAGES_FOR_OFFER,
  MISSING_TOKEN_PASSWORD_REINIT,
  NOT_FOUND,
  NOT_FOUND_NO_CAPITAL_F,
  NOT_FOUND_SNAKE_CASE,
  NO_ACCESSTOKEN_RECEIVED,
  NO_ACCESS_TOKEN_PROVIDED,
  NO_CREDENTIALS_RETURN,
  NO_REFRESH_TOKEN_RECEIVED,
  NO_TOKENS_RECEIVED,
  NO_URN,
  OFFER_ALREADY_REPORTED_BY_USER,
  OFFER_NOT_FOUND,
  OFFER_NOT_TO_USER,
  ON_GOING_BANK_DATA_UPDATE,
  RECIPIENT_NOT_FOUND,
  REFRESH_TOKEN_ERROR,
  RELEASE_DATE_ERROR,
  SENDER_IS_RECIPIENT,
  SESSION_EXPIRED,
  TOKEN_DECODE_ERROR,
  VEHICLE_WRONG_HEIGHT,
  VEHICLE_WRONG_INCL_VAT_PRICE,
  VEHICLE_WRONG_LENGTH,
  VEHICLE_WRONG_WIDTH,
  WRONG_CREDENTIALS,
  WRONG_PASSWORD,
} from "@/Constants/errors";
import DialogInfo from "@/Shared/Ui/DialogInfo";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import {
  DIALOG_TYPE_CONFIRM,
  DIALOG_TYPE_INFO,
  ENVS,
} from "@/Constants/global";
import DialogConfirmation from "@/Shared/Ui/DialogConfirmation";
import { millesimes } from "@/Constants/InputsValues";
import moment from "moment";
import { PAGE_CONNEXION_INSCRIPTION } from "@/Constants/pageRoutes";
import { restApiPostLogout } from "@/Utils/http/businessApiRequests";
import { HEADERS_ATTRIBUTE } from "@/Constants/dataAttributes";

/**
 * @file components/Shared/GlobalErrorHandler/GlobalErrorHandler.js
 * @see constants/errors.js
 * @module Global Error Handler
 * @namespace Code Central
 */

/**
 * @function
 * @description Gère les erreurs remontées par setGlobalError (venant de GlobalContextProvider) ,
 * prenant en paramètre soit un string ou objet. Toute erreur ne devant pas être montrée dans une modale doivent être
 * fournies ainsi `setGlobalError({isToShow: false, error})`.
 * Seules les erreurs remontées à la soumission d'un formulaire peuvent être directement retournées avec
 * setGlobalError(error.message)`. Sinon, des messages sensibles d'erreurs peuvent être retournés. Prend aussi un prop
 * error en paramètre pour traiter les erreurs retournées par le serveur Next.js.
 * @param error
 * @returns {JSX.Element}
 */
const GlobalErrorHandler = ({ error }) => {
  const router = useRouter();
  const session = useSession();
  const {
    state: { globalError },
    setGlobalError,
  } = useContext(GlobalContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorDialog, setErrorDialog] = useState(null);

  const getDialogFromStringError = async (stringError = "") => {
    let element = null;
    if (stringError?.length) {
      const { props, dialogType } = await getDialogParamsFromStringError(
        stringError
      );

      if (dialogType === DIALOG_TYPE_INFO) element = <DialogInfo {...props} />;

      if (dialogType === DIALOG_TYPE_CONFIRM)
        element = <DialogConfirmation {...props} />;
    }

    return element;
  };

  const getDialogParamsFromStringError = async errorMessage => {
    let output = null;

    let dialogType = DIALOG_TYPE_INFO;
    let props = {
      open: true,
      objet: "Erreur",
      message: errorMessage,
      submit: "Fermer",
      handleClose: () => setIsDialogOpen(false),
    };

    errorMessage = errorMessage.replace("Error: ", "");

    const GENERAL_ERROR_MESSAGE = (
      <p>
        Une erreur est survenue. Merci de réessayer ultérieurement. Si le
        problème persiste, merci de contacter le support par email à&nbsp;
        <a href='mailto:support@leb-communication.fr'>
          support@leb-communication.fr
        </a>
        .
      </p>
    );

    switch (errorMessage) {
      case GENERIC_ERROR:
      case ERROR_DISTANT_SERVER:
        props.children = GENERAL_ERROR_MESSAGE;
        break;

      case SESSION_EXPIRED:
        dialogType = DIALOG_TYPE_CONFIRM;

        props = { ...props, ...(await getDialogPropsErrorSessionExpired()) };
        break;

      case REFRESH_TOKEN_ERROR:
        dialogType = DIALOG_TYPE_CONFIRM;
        props = { ...props, ...(await getDialogPropsErrorSessionWillExpire()) };
        break;

      case WRONG_CREDENTIALS:
        props.message = "Identifiants incorrects.";
        break;

      case IS_NOT_CONNECTED:
        dialogType = DIALOG_TYPE_CONFIRM;

        props = { ...props, ...getDialogPropsErrorIsNotConntected() };
        break;

      case NO_ACCESS_TOKEN_PROVIDED:
        dialogType = DIALOG_TYPE_CONFIRM;
        props = { ...props, ...(await getDialogPropsErrorNoAccessToken()) };
        break;

      case INVALID_MESSAGE:
        props.message =
          "Le contenu du message est invalide. Celui-ci doit faire plus de 5 caractères et ne " +
          "peut pas contenir les termes http, ftp, mailto, https, www ou ftps.";
        break;

      case RECIPIENT_NOT_FOUND:
        props.message =
          "Le vendeur destinataire n'a pas été retrouvé dans notre base de données.";
        break;

      case NOT_FOUND:
      case NOT_FOUND_NO_CAPITAL_F:
      case NOT_FOUND_SNAKE_CASE:
        props.message = "La ressource demandée n'a pas été trouvée.";
        break;

      case OFFER_NOT_FOUND:
        props.message =
          "L'offre à propos de laquelle vous écrivez au vendeur n'a pas été retrouvée dans notre base de données.";
        break;

      case SENDER_IS_RECIPIENT:
        props.message =
          "Vous ne pouvez pas être à la fois émetteur et récepteur d'un message de prise de contact pour une annonce.";
        break;

      case OFFER_NOT_TO_USER:
        props.message = "Cette annonce n'appartient pas au bon vendeur.";
        break;

      case ON_GOING_BANK_DATA_UPDATE:
        props.message =
          "Une demande de modification de vos coordonnées bancaires est déjà en cours de traitement.";
        break;

      case WRONG_PASSWORD:
        props.message = "Ce n'est pas le mot de passe actuel.";
        break;

      case CODE_403:
        props.message =
          "Vous ne disposez pas des droits nécessaires pour réaliser cette action.";
        break;

      case FAILED_TO_FETCH:
        props.message =
          "Le serveur n'a pas pu être contacté, merci de réessayer ultérieurement.";
        break;

      case INCOMPLETE_RECAPTCHA:
        props.message = "Veuillez compléter la vérification Google Recaptcha.";
        break;

      case RELEASE_DATE_ERROR:
        props.message = `La date de première mise en circulation peut débuter au plus tôt un an avant son 
      année de millesime et être comprise entre le 01/01/${
        millesimes[millesimes.length - 1]?.value
      } 
      et le ${moment(Date.now()).format("DD/MM/YYYY")}.`;
        break;

      case OFFER_ALREADY_REPORTED_BY_USER:
        props.message = "Vous avez déjà signalé cette annonce.";
        break;

      case IS_UNDER_LEGAL_AGE_ACCOUNT_CREATE:
        props.message = "Vous devez être majeur pour créer un compte.";
        break;

      case ALREADY_SUBSCRIBED_NEWSLETTER:
        props.message = "Vous êtes déjà inscrit à la newsletter.";
        break;

      case MISSING_TOKEN_PASSWORD_REINIT:
        props.message =
          "Le jeton de réinitialisation de mot de passe est manquant.";
        break;

      case MAX_50_IMAGES_FOR_OFFER:
        props.message = "50 images maximum peuvent être ajoutées.";
        break;

      case VEHICLE_WRONG_HEIGHT:
        props.message = "La hauteur du véhicule ne peut pas dépasser 5m.";
        break;

      case VEHICLE_WRONG_LENGTH:
        props.message = "La longueur du véhicule ne peut pas dépasser 20m.";
        break;

      case VEHICLE_WRONG_WIDTH:
        props.message = "La largeur du véhicule ne peut pas dépasser 5m.";
        break;

      case VEHICLE_WRONG_INCL_VAT_PRICE:
        props.message = "Le prix de vente de votre offre est incorrect.";
        break;

      case DEALERSHIP_SUPPRESSION_ALREADY_REQUESTED:
        props.message =
          "Une demande de suppression pour cette concession a déjà été soumise.";
        break;

      case ERROR_OFF_SUBSCRIPTION:
        props.children = (
          <p>
            Votre abonnement au service Week And Go est désactivé. Pour utiliser
            cette fonctionnalité, veuillez le réactiver en contactant le support
            par email à&nbsp;
            <a href='mailto:hello@weekandgo.com'>
              support@leb-communication.fr
            </a>
          </p>
        );
        break;

      case EMPTY_DATA:
        props.children = (
          <p>
            Erreur technique: Aucun élément fourni à envoyer au serveur.
            Veuillez informer le par email à&nbsp;
            <a href='mailto:hello@weekandgo.com'>
              support@leb-communication.fr
            </a>
          </p>
        );
        break;

      case ERROR_NO_OFFER_FOR_BRAND:
        props.objet = "Aucun résultat";
        props.message =
          "Il n'y a actuellement aucune offre disponible de cette marque.";
        break;

      case ERROR_SAVED_RESEARCH_VALUES_FETCH:
        props.objet = "Erreur lors de le génération du filtre";
        props.children = GENERAL_ERROR_MESSAGE;
        break;

      case ERROR_MISSING_OFFER_FROM_COMPARATOR:
        props.objet = "Erreur dans le comparateur d'annonces";
        props.message =
          "Au moins une annonce sauvegardée dans le comparateur n'est plus disponible depuis sa dernière utilisation.";
        break;

      case NO_ACCESSTOKEN_RECEIVED:
      case NO_TOKENS_RECEIVED:
      case NO_REFRESH_TOKEN_RECEIVED:
      case NO_CREDENTIALS_RETURN:
      case TOKEN_DECODE_ERROR:
      case NO_URN:
      case "Unexpected token u in JSON at position 0":
      default:
        if (!process.env.NEXT_PUBLIC_IS_PROD_ENV)
          console.error("Unknown error. ", errorMessage);

        // props.message = GENERAL_ERROR_MESSAGE
        props.message = errorMessage;
    }

    output = { props, dialogType };
    return output;
  };

  // TODO: think about redirection at account creation ?
  const getDialogPropsErrorIsNotConntected = () => ({
    handleClose: () => setIsDialogOpen(false),
    handleSubmit: async () => {
      if (session?.data?.user?.token) {
        await restApiPostLogout(session?.data.user.token);
      }

      await signOut({ redirect: false });

      const redirect = router.asPath.replace(/reinitialiseAdsFilter=true/, "");

      router.push({
        pathname: PAGE_CONNEXION_INSCRIPTION,
        query: { redirect },
      });

      setIsDialogOpen(false);
    },
    objet: "Mince... vous n'êtes pas connecté.",
    message:
      "Veuillez vous connecter à votre compte utilisateur pour utiliser cette fonctionnalité.",
    submit: "Me connecter ou créer un compte",
  });

  const getDialogPropsErrorSessionExpired = async () => {
    await signOut({ redirect: false });

    return {
      handleSubmit: async () => {
        await router.replace({
          pathname: PAGE_CONNEXION_INSCRIPTION,
          query: { redirect: router.asPath },
        });

        setIsDialogOpen(false);
      },
      handleClose: () => setIsDialogOpen(false),
      message:
        "Votre session a expiré et vous avez été automatiquement déconnecté. Veuillez vous reconnecter.",
      submit: "Me reconnecter",
    };
  };

  const getDialogPropsErrorSessionWillExpire = async () => {
    return {
      handleSubmit: async () => {
        await signOut({ redirect: false });
        await router.replace({
          pathname: PAGE_CONNEXION_INSCRIPTION,
          query: { redirect: router.asPath },
        });

        setIsDialogOpen(false);
      },
      handleClose: () => setIsDialogOpen(false),
      message:
        "Votre session utilisateur est toujours valide mais va bientôt expirer. Nous vous recommandons de vous reconnecter pour la renouveller.",
      deny: "Plus tard",
      submit: "Me reconnecter",
    };
  };

  const getDialogPropsErrorNoAccessToken = async () => {
    return {
      handleSubmit: async () => {
        await signOut({ redirect: false });
        await router.replace({
          pathname: PAGE_CONNEXION_INSCRIPTION,
          query: { redirect: router.asPath },
        });

        setIsDialogOpen(false);
      },
      handleClose: () => setIsDialogOpen(false),
      children: (
        <p>
          Une erreur de session est survenue. Veuillez vous vous déconnecter et
          vous reconnecter. Si cette erreur persiste, merci de contacter le
          support par email à
          <a href='mailto:support@leb-communication.fr'>
            support@leb-communication.fr
          </a>
          .
        </p>
      ),
      deny: "Annuler",
      submit: "Me reconnecter",
    };
  };

  const manageGlobalError = async globalError => {
    if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) {
      console.error(
        `manageGlobalError : ${
          globalError?.message
            ? globalError.message
            : globalError?.error?.message
        }`
      );
    }

    if (typeof globalError === "string") {
      const element = await getDialogFromStringError(globalError);

      if (element) {
        setErrorDialog(element);
        setIsDialogOpen(true);
      }
    }

    // TODO SPOILER: in error field should be placed... AN ERROR Seb !!!!
    // if (typeof globalError === 'object' && !(globalError instanceof Error)) {
    if (typeof globalError === "object") {
      const errorObject = {
        isToShow: !!globalError.isToShow,
        error: globalError.error,
      };

      let errorMessage = String(
        errorObject.error.message ?? errorObject.message
      );

      // parse error
      switch (errorMessage) {
        case WRONG_CREDENTIALS:
        case SESSION_EXPIRED:
          const headerVisitorEl = document.querySelector(
            `[data-header=${HEADERS_ATTRIBUTE.visitor}]`
          );
          if (headerVisitorEl) {
            // disconnect user because he'll not be able to find the disconnection endpoint
            if (session?.data?.user?.token) {
              await restApiPostLogout(session?.data.user.token);
            }

            await signOut({ redirect: false });

            await router.push({
              pathname: PAGE_CONNEXION_INSCRIPTION,
              query: { redirect: router.asPath },
            });
            errorObject.isToShow = true;
            errorMessage = GENERIC_ERROR;
          }
          break;
        default:
          return;
      }

      if (errorObject.isToShow) {
        const element = await getDialogFromStringError(errorMessage);

        if (element) {
          setErrorDialog(element);
          setIsDialogOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    if (error) {
      if (process?.env.NEXT_PUBLIC_APP_ENV === ENVS.development)
        console.error("error from server : ", error);

      console.error(ERROR_SERVER);

      //  refactor why updating the state for that?? useless... Ha no. This error comes from the server. Still a bad design...
      const message = error?.message
        ? setGlobalError(error.message)
        : String(error);
      setGlobalError(message);
    }
  }, [error]);

  useEffect(async () => {
    if (globalError) {
      await manageGlobalError(globalError);
    }
  }, [globalError]);

  useEffect(() => {
    if (!isDialogOpen && globalError?.length) {
      setGlobalError("");
      setErrorDialog();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    return () => {
      setIsDialogOpen(false);
      setGlobalError("");
      setErrorDialog();
    };
  }, []);

  return <>{isDialogOpen && errorDialog}</>;
};

export default GlobalErrorHandler;
