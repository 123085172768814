import { isObjectTruthy } from "@/Utils/index";
import {
  EMPTY_HTTP_OPTIONS,
  ERROR_HTTP_REQUEST_ABORT,
  NO_RESPONSE_FROM_REQUEST,
} from "@/Constants/errors";
import { MAX_HTTP_REQUEST_TIMEOUT } from "@/Constants/limits";

const defaultOptions = {
  method: "GET", // *GET, POST, PUT, DELETE, etc.
  mode: "cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "same-origin", // include, *same-origin, omit
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const GET_OPTIONS = { ...defaultOptions };

const POST_OPTIONS = Object.assign(
  { ...defaultOptions },
  {
    method: "POST",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: {},
  }
);

const PUT_OPTIONS = Object.assign(
  { ...defaultOptions },
  {
    method: "PUT",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: {},
  }
);

const DELETE_OPTIONS = Object.assign(
  { ...defaultOptions },
  {
    method: "DELETE",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: {},
  }
);

export const httpGet = async (uri, options = {}) => {
  const fullOptions = { ...GET_OPTIONS, ...options };

  const response = await executeHttpRequest(uri, fullOptions);
  return response?.json();
};

export const httpPost = async (uri, options = { body: {} }) => {
  let body;
  [options, body] = prepareBody(options);

  const fullOptions = {
    ...POST_OPTIONS,
    body,
    ...options,
  };

  const response = await executeHttpRequest(uri, fullOptions);
  return response.json();
};

export const httpPut = async (uri, options = { body: {} }) => {
  let body;
  [options, body] = prepareBody(options);

  const fullOptions = {
    ...PUT_OPTIONS,
    body,
    ...options,
  };

  const response = await executeHttpRequest(uri, fullOptions);
  return response.json();
};

export const httpDelete = async (uri, options = { body: {} }) => {
  const fullOptions = { ...DELETE_OPTIONS, ...options };

  return await executeHttpRequest(uri, fullOptions);
};

export const executeHttpRequest = async (url, options = {}) => {
  let output = null;
  let timeout;
  let signal;

  try {
    if (!isObjectTruthy(options)) throw new Error(EMPTY_HTTP_OPTIONS);

    if (options.headers["Content-Type"] === "multipart/form-data")
      delete options.headers["Content-Type"];

    const controller = new AbortController();
    signal = controller.signal;

    const timeAbort = options?.timeAbort;
    delete options.timeAbort;

    timeout = setTimeout(() => {
      controller.abort();
    }, timeAbort ?? MAX_HTTP_REQUEST_TIMEOUT);

    const response = await fetch(url, { ...options, signal });

    if (!response) {
      throw new Error(NO_RESPONSE_FROM_REQUEST);
    }

    output = response;
    clearTimeout(timeout);
  } catch (error) {
    if (error?.name === ERROR_HTTP_REQUEST_ABORT) {
      clearTimeout(timeout);
      // eslint-disable-next-line no-console
      console.debug("aborted request");
    } else {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
      throw error;
    }
  }
  return output;
};

const prepareBody = options => {
  let body = {};
  if (isObjectTruthy(options.body)) {
    body = options.body;
    delete options.body;
  }
  return [options, body];
};

export const normalize = data => JSON.parse(JSON.stringify(data));
export const deserialize = data => JSON.parse(data);

export const serialize = object => JSON.stringify(object);
