import React, { useContext, useEffect } from "react";
import cssHeader from "@/Shared/Header/Header.module.css";
import Link from "next/link";
import cssThemeEspace from "@/Theme/EspaceTheme.module.css";
import Svg from "@/Shared/Svg";
import {
  PAGE_PRO_FLUX,
  PAGE_PRO_HOME,
  PAGE_PRO_MA_CONCESSION,
  PAGE_PRO_MES_CONCESSIONS,
  PAGE_PRO_MON_COMPTE,
} from "@/Constants/pageRoutes";
import {
  PROFESSIONAL_PROFILE_SIEGE,
  PROFESSIONAL_PROFILE_SITE,
} from "@/Constants/global";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { useRouter } from "next/router";

// TODO since it is a children component to header > SousMenuProfile, should not these components be called SubHeaders
const SousMenuPro = ({ onClick }) => {
  const router = useRouter();
  const {
    state: { my, globalModalProps },
    setGlobalModalProps,
  } = useContext(GlobalContext);

  const data = {
    nbOffers: my?.nbOffers ?? "0",
    nbNeufs: my?.nbNeufs ?? "0",
    nbOccasions: my?.nbOccasions ?? "0",
    nbConcessions: my?.nbConcessions ?? "0",
  };

  if (!my?.subscribed) {
    data.nbOffers = "0";
    data.nbNeufs = "0";
    data.nbOccasions = "0";
    data.nbConcessions = "0";
  }

  useEffect(() => {
    if (globalModalProps?.isClosed) {
      setGlobalModalProps();
    }
  }, [globalModalProps?.isClosed]);

  return (
    <div className={`${cssHeader["c-nav-main__sous-menu__split"]}`}>
      {my?.subscribed && (
        <div className={`${cssHeader["c-nav-main__sous-menu__colonne"]}`}>
          <div
            className={`${cssHeader["c-nav-main__sous-menu__titre"]} f-primary f-primary--700 c-primary`}
          >
            Mes Véhicules
          </div>
          <Link href={PAGE_PRO_FLUX}>
            <a
              className={`${cssThemeEspace["btn-flux-pro"]}`}
              onClick={onClick}
            >
              <Svg selection='ico-sync-pro' />
              <span className={"btn-flux-pro__label"}>
                <strong className={"f-primary"}>Mon FLUX</strong>
              </span>
            </a>
          </Link>
        </div>
      )}

      <div className={`${cssHeader["c-nav-main__sous-menu__colonne"]}`}>
        <div
          className={`${cssHeader["c-nav-main__sous-menu__titre"]} f-primary f-primary--700 c-primary`}
        >
          My Wago
        </div>
        {my?.subscribed && (
          <Link href={PAGE_PRO_HOME}>
            <a
              className={`u-f-center ${cssHeader["link-tdb-menu"]}`}
              onClick={onClick}
            >
              <Svg selection='ico-tdb' />
              <span>
                <strong>{data?.nbOffers}</strong> annonces
              </span>
            </a>
          </Link>
        )}
        <Link href={PAGE_PRO_MON_COMPTE}>
          <a onClick={onClick}>Mon compte</a>
        </Link>

        {my?.profil === PROFESSIONAL_PROFILE_SIEGE && (
          <Link href={PAGE_PRO_MES_CONCESSIONS}>
            <a onClick={onClick}>
              Mes concessions{" "}
              <strong className={"f-primary"}>
                {" "}
                ({data?.nbConcessions ?? "0"})
              </strong>
            </a>
          </Link>
        )}

        {my?.profil === PROFESSIONAL_PROFILE_SITE && my?.subscribed && (
          <Link href={PAGE_PRO_MA_CONCESSION}>
            <a onClick={onClick}>
              <strong className={"f-primary"}>Ma concession</strong>
            </a>
          </Link>
        )}

        {/* #SLEEPING <Link href="/espace-pro/factures"><a>Paiements &amp; factures</a></Link>*/}
        {/* #SLEEPING <Link href="/espace-pro/factures">*/}
        {/*  <a onClick={onClick}>Abonnements &amp; factures</a>*/}
        {/*</Link>*/}
      </div>
    </div>
  );
};

SousMenuPro.propTypes = {
  //
};

export default SousMenuPro;
