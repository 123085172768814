import React, { useContext, useRef } from "react";
import { useSession } from "next-auth/react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
//import PropTypes from 'prop-types';
import css from "./FormNewsletter.module.css";
import { SuscribeToNewsletterSchema } from "@/Utils/formValidation/formSchemas";
import Message from "@/Shared/Ui/Message";
import { restApiPostNewsletterSubscribe } from "@/Utils/http/businessApiRequests";
import ReCaptcha from "@/Shared/ReCaptcha";
import { UNAUTHENTICATED } from "@/Constants/global";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { emitGoogleTagEvent } from "@/Utils/windowScripts";
import {
  ALREADY_SUBSCRIBED_NEWSLETTER,
  INCOMPLETE_RECAPTCHA,
} from "@/Constants/errors";
import { featureManager } from "@/Utils/featureFlagManager";
import { FEATURES } from "@/Constants/features";
import { GTAG_EVENTS } from "@/Constants/googleEvents";
const FormNewsletter = () => {
  const { setGlobalError, setGlobalSuccess } = useContext(GlobalContext);
  const { status } = useSession();

  const ref = useRef({ reCaptchaToken: null });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors: formErrors },
  } = useForm({
    resolver: joiResolver(SuscribeToNewsletterSchema()),
  });

  const onSubmit = async data => {
    try {
      if (!ref.current.reCaptchaToken) {
        throw new Error(INCOMPLETE_RECAPTCHA);
      }

      const response = await restApiPostNewsletterSubscribe(
        data,
        {
          reCaptchaToken: ref.current.reCaptchaToken,
        },
        {
          checkErrors: false,
        }
      );

      if (response.message === "Already subscribed") {
        throw new Error(ALREADY_SUBSCRIBED_NEWSLETTER);
      }

      reset({ email: "" });

      if (
        featureManager.isFeatureEnabled(
          FEATURES.googleTagManager,
          process.env.NEXT_PUBLIC_APP_ENV
        )
      )
        emitGoogleTagEvent(GTAG_EVENTS.inscription_newsletter.eventLabel);

      setGlobalSuccess("Votre inscription à la newsletter a bien été ajoutée.");
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);

      setGlobalError(error.message.replace("Error: ", ""));
    }
  };

  return (
    <>
      {status === UNAUTHENTICATED ? (
        <div className={`${css["c-newsletter-inscription"]}`}>
          <p className={"f-primary"}>
            <strong>{"L'actualité de WeekandGO"}</strong> directement dans votre
            boîte mail&nbsp;!
          </p>

          {formErrors?.email && (
            <Message context='error'>
              Veuillez renseigner une adresse email valide.
            </Message>
          )}

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`${css["c-newsletter-inscription__form"]}`}
          >
            <label
              className={`${css["c-newsletter-inscription__label"]} f-primary f-primary--700`}
              htmlFor='email'
            >
              Votre email
            </label>
            <div className={`${css["c-newsletter-inscription__form__champs"]}`}>
              <input
                className={`${css["c-newsletter-inscription__input"]}`}
                type='text'
                placeholder='nom.prenom@gmail.com'
                id='nl_mail'
                {...register("email")}
              />
              <input
                className={`${css["c-newsletter-inscription__submit"]} f-primary`}
                type='submit'
                value="Je m'inscris"
              />
            </div>
          </form>

          <ReCaptcha refReCaptchaToken={ref} />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

FormNewsletter.propTypes = {
  //
};

export default FormNewsletter;
