import { createContext, useEffect, useMemo, useState } from "react";
import { useSession } from "next-auth/react";
import {
  businessApiRequest,
  restApiApiGetMy,
  restApiApiGetMyUserDashboard,
  restApiApiGetMyUserDetails,
  restApiGetBrands,
  restApiGetCarriers,
  // restApiGetFamilies,
  restApiGetFiltersBrands,
  restApiGetMyOffers,
  restApiGetMyOffersTemp,
  restApiGetRanges,
} from "@/Utils/http/businessApiRequests";
import { defaultEtatsMenu } from "@/Shared/Hooks/UseMenus/UseMenus";
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  USER_TYPE_PARTICULIER,
  USER_TYPE_PROFESSIONNEL,
} from "@/Constants/global";
import {
  normalizeMyOffers,
  normalizeMyOffersTemp,
} from "@/Utils/normalizers/businessApiNormalizers";
import { orderBy } from "lodash";
import { getArrayActiveConcessions } from "@/Utils/index";
import {
  COUNTRIES_ROUTE,
  FILTERS_BRANDS_ROUTE,
  FILTERS_MODELS_ROUTE,
  FILTERS_RANGES_ROUTE,
  FILTERS_ROUTE,
  MY_FAVORITES_ROUTE,
} from "@/Constants/restApiRoutes";

// it has to ever stay filled with primitive types
const GlobalContext = createContext({
  state: {
    brands: [],
    brandPasserelleMarque: null,
    carriers: [],
    categoriesInfos: [],
    categoryAnnexes: [],
    countries: [],
    etatsMenu: [],
    families: [],
    adsFilterData: null,
    filteredBrands: null,
    // filteredFamilies: [],
    filteredModels: null,
    filteredRanges: null,
    globalBlogSeo: {},
    globalError: "",
    globalModalProps: null,
    globalSavedAdsResearch: null,
    globalSuccess: "",
    globalSuccessProps: null,
    history: [],
    my: {},
    myOffers: {},
    myFavorites: [],
    myUserDetails: {},
    professionalUser: {
      seat: {
        arrayOptionsDealerships: [],
      },
      myOfferTemp: {},
      myUserDashboard: {},
    },
    ranges: [],
  },
  setBrandPasserelleMarque: () => {},
  setCategoriesInfos: () => {},
  setCategoryAnnexes: () => {},
  setCountries: () => {},
  setEtatsMenu: () => {},
  setAdsFilterData: () => {},
  setFilteredBrands: () => {},
  // setFilteredFamilies: () => {},
  setFilteredModels: () => {},
  setFilteredRanges: () => {},
  setGlobalBlogSeo: () => {},
  setGlobalError: () => {},
  setGlobalModalProps: () => {},
  setGlobalSavedAdsResearch: () => {},
  setGlobalSuccess: () => {},
  setGlobalSuccessProps: () => {},
  setHistory: () => {},
  updateData: () => {},
  setMy: () => {},
  setMyOffers: () => {},
  setMyOffersTemp: () => {},
  setMyUserDashboard: () => {},
  setMyUserDetails: () => {},
  setMyFavorites: () => {},
  updateFilteredBrands: () => {},
  updateFilters: () => {},
  updateFormSelectValues: () => {},
  updateMy: () => {},
  updateMyOffers: () => {},
  updateMyOffersTemp: () => {},
  updateMyUserDashboard: () => {},
  updateMyUserDetails: () => {},
});

const GlobalContextProvider = ({ children, value }) => {
  const session = useSession();

  const [arrayOptionsDealerships, setArrayOptionsDealerships] = useState([]);
  const [brandPasserelleMarque, setBrandPasserelleMarque] = useState(null);
  const [brands, setBrands] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [categoriesInfos, setCategoriesInfos] = useState([]);
  const [categoryAnnexes, setCategoryAnnexes] = useState({});
  const [countries, setCountries] = useState([]);
  const [etatsMenu, setEtatsMenu] = useState(defaultEtatsMenu);
  const [adsFilterData, setAdsFilterData] = useState();
  const [filteredBrands, setFilteredBrands] = useState();
  // const [filteredFamilies, setFilteredFamilies] = useState([])
  const [filteredModels, setFilteredModels] = useState();
  const [filteredRanges, setFilteredRanges] = useState();
  const [families, setFamilies] = useState([]);
  const [globalBlogSeo, setGlobalBlogSeo] = useState({});
  const [globalError, setGlobalError] = useState("");
  const [globalModalProps, setGlobalModalProps] = useState();
  const [globalSavedAdsResearch, setGlobalSavedAdsResearch] = useState();
  const [globalSuccess, setGlobalSuccess] = useState("");
  const [globalSuccessProps, setGlobalSuccessProps] = useState();
  const [history, setHistory] = useState([]);
  const [my, setMy] = useState({});
  const [myOffers, setMyOffers] = useState({});
  const [myOffersTemp, setMyOffersTemp] = useState({});
  const [myFavorites, setMyFavorites] = useState([]);
  const [myUserDashboard, setMyUserDashboard] = useState({});
  const [myUserDetails, setMyUserDetails] = useState({});
  const [ranges, setRanges] = useState([]);

  const updateData = async session => {
    try {
      if (session?.status === UNAUTHENTICATED) {
        await updateFilters();
        setMy({});
        setMyUserDetails({});
        setMyFavorites([]);
      }

      if (session?.status === AUTHENTICATED) {
        const my = await updateMy();

        let arrayRequests = [
          updateFilters(),
          updateFormSelectValues(),
          updateMyUserDetails(),
        ];

        if (session?.data?.user?.userType === USER_TYPE_PARTICULIER) {
          arrayRequests = [
            ...arrayRequests,
            updateMyOffers(),
            updateMyFavorites(my),
          ];
        }

        if (session?.data?.user?.userType === USER_TYPE_PROFESSIONNEL) {
          arrayRequests = [
            ...arrayRequests,
            updateMyOffers({ isWithCategories: false }),
            updateMyOffersTemp(),
            updateMyUserDashboard(),
          ];
        }

        await Promise.all(arrayRequests);
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }
  };

  const updateFilteredBrands = async () => {
    const output = [];
    try {
      const output = await restApiGetFiltersBrands();
      setFilteredBrands([...output]);
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }
    return output;
  };

  const updateFilters = async () => {
    try {
      let [
        countries,
        filteredBrands,
        filteredModels,
        filteredRanges,
        adsFilterData,
      ] = await Promise.all([
        businessApiRequest(COUNTRIES_ROUTE, {}, { isProtected: false }),
        businessApiRequest(FILTERS_BRANDS_ROUTE, {}, { isProtected: false }),
        businessApiRequest(FILTERS_MODELS_ROUTE, {}, { isProtected: false }),
        businessApiRequest(FILTERS_RANGES_ROUTE, {}, { isProtected: false }),
        businessApiRequest(FILTERS_ROUTE, {}, { isProtected: false }),
      ]);

      countries = countries ?? [];
      filteredBrands = filteredBrands ?? [];
      filteredModels = filteredModels ?? [];
      filteredRanges = filteredRanges ?? [];
      adsFilterData = adsFilterData ?? null;

      setCountries(countries);
      setFilteredBrands(filteredBrands);
      setFilteredModels(filteredModels);
      setFilteredRanges(filteredRanges);
      setAdsFilterData(adsFilterData);
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }
  };

  const updateFormSelectValues = async () => {
    try {
      let [brands, carriers, countries, ranges] = await Promise.all([
        restApiGetBrands(),
        restApiGetCarriers(),
        businessApiRequest(COUNTRIES_ROUTE, {}, { isProtected: false }),
        restApiGetRanges(),
      ]);

      brands = brands ?? [];
      carriers = carriers ?? [];
      countries = countries ?? [];
      ranges = ranges ?? [];

      setBrands(brands);
      setCarriers(carriers);
      setCountries(countries);
      setRanges(ranges);
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }
  };

  const updateMy = async (accessToken = null) => {
    let output = null;
    try {
      accessToken = accessToken || session?.data?.user?.accessToken;
      if (accessToken?.length) {
        const my = await restApiApiGetMy({ accessToken });
        setMy({ ...my });
        output = my;
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }

    return output;
  };

  const updateMyOffers = async (options = {}) => {
    let output = null;

    options = {
      accessToken: options?.accessToken ?? null,
      isWithCategories: options?.isWithCategories ?? true,
    };

    const { isWithCategories } = options;

    try {
      const accessToken =
        options.accessToken ?? session?.data?.user?.accessToken;

      if (accessToken?.length) {
        const myOffersResponse = await restApiGetMyOffers(
          { accessToken },
          {
            checkErrors: true,
            normalize: false,
          }
        );

        output = normalizeMyOffers(myOffersResponse, { isWithCategories });

        setMyOffers({ ...output });
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }
    return output;
  };

  const updateMyOffersTemp = async (
    options = {
      normalize: true,
      accessToken: null,
    }
  ) => {
    let output = null;

    try {
      const accessToken =
        options?.accessToken ?? session?.data?.user?.accessToken;
      if (accessToken?.length) {
        const myOffersResponse = await restApiGetMyOffersTemp(
          { accessToken },
          {
            checkErrors: true,
            normalize: options.normalize,
          }
        );

        output = myOffersResponse;

        if (!options.normalize) {
          output = normalizeMyOffersTemp(myOffersResponse, {
            isWithCategories: false,
          });
        }
        setMyOffersTemp({ ...output });
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }

    return output;
  };

  const updateMyFavorites = async (accessToken = null) => {
    let output = [];

    try {
      accessToken = accessToken || session?.data?.user?.accessToken;
      if (accessToken?.length) {
        const myFavorites = await businessApiRequest(MY_FAVORITES_ROUTE, {
          accessToken,
        });

        setMyFavorites(myFavorites);
        output = myFavorites;
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }

    return output;
  };

  const updateMyUserDetails = async (accessToken = null) => {
    // TODO myUserDetails should only be called at mon-compte. Hence only leaving my data to be fetched at connection
    let output = null;

    try {
      accessToken = accessToken || session?.data?.user?.accessToken;

      if (accessToken?.length) {
        const [responseUserDetails] = await Promise.all([
          restApiApiGetMyUserDetails({ accessToken }),
        ]);

        setMyUserDetails({ ...responseUserDetails });
        output = { ...responseUserDetails };
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }

    return output;
  };

  const updateOptionsDealership = concessions => {
    let options = getArrayActiveConcessions(concessions);

    options = orderBy(options, ["label"], ["asc"]);

    if (options) setArrayOptionsDealerships(options);
    return options;
  };

  const updateMyUserDashboard = async (accessToken = null) => {
    let output = null;

    try {
      accessToken = accessToken ?? session?.data?.user?.accessToken;

      if (accessToken?.length) {
        const myUserDashboard = await restApiApiGetMyUserDashboard({
          accessToken,
        });
        setMyUserDashboard(myUserDashboard);
        output = myUserDashboard;
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
    }

    return output;
  };

  const memoValues = useMemo(
    () => ({
      brands,
      brandPasserelleMarque,
      carriers,
      categoriesInfos,
      categoryAnnexes,
      countries,
      etatsMenu,
      families,
      adsFilterData,
      filteredBrands,
      // filteredFamilies,
      filteredModels,
      filteredRanges,
      globalBlogSeo,
      globalError,
      globalModalProps,
      globalSavedAdsResearch,
      globalSuccess,
      globalSuccessProps,
      history,
      my,
      myOffers,
      myFavorites,
      myUserDetails,
      professionalUser: {
        seat: {
          arrayOptionsDealerships,
        },
        myUserDashboard,
        myOffersTemp,
      },
      ranges,
    }),
    [
      arrayOptionsDealerships,
      brandPasserelleMarque,
      brands,
      carriers,
      categoriesInfos,
      categoryAnnexes,
      countries,
      etatsMenu,
      families,
      adsFilterData,
      filteredBrands,
      // filteredFamilies,
      filteredModels,
      filteredRanges,
      globalBlogSeo,
      globalError,
      globalModalProps,
      globalSavedAdsResearch,
      globalSuccess,
      globalSuccessProps,
      history,
      my,
      myOffers,
      myOffersTemp,
      myFavorites,
      myUserDashboard,
      myUserDetails,
      ranges,
    ]
  );

  const innerValue = {
    state: {
      ...memoValues,
    },
    setBrandPasserelleMarque,
    setCategoriesInfos,
    setCategoryAnnexes,
    setEtatsMenu,
    setAdsFilterData,
    setCountries,
    setFilteredBrands,
    // setFilteredFamilies,
    setFilteredModels,
    setFilteredRanges,
    setGlobalBlogSeo,
    setGlobalError,
    setGlobalModalProps,
    setGlobalSavedAdsResearch,
    setGlobalSuccess,
    setGlobalSuccessProps,
    setHistory,
    setMy,
    setMyOffers,
    setMyOffersTemp,
    setMyUserDashboard,
    setMyUserDetails,
    setMyFavorites,
    updateData,
    updateFilteredBrands,
    updateFilters,
    updateFormSelectValues,
    updateMy,
    updateMyOffers,
    updateMyOffersTemp,
    updateMyFavorites,
    updateMyUserDashboard,
    updateMyUserDetails,
    updateOptionsDealership,
  };

  useEffect(() => {
    return () => {
      setArrayOptionsDealerships([]);
      setBrands([]);
      setBrandPasserelleMarque(null);
      setCarriers([]);
      setCountries([]);
      setFamilies([]);
      setFilteredBrands([]);
      // setFilteredFamilies([])
      setFilteredModels([]);
      setFilteredRanges([]);
      setGlobalError("");
      setGlobalModalProps();
      setGlobalSuccess("");
      setGlobalSuccessProps();
      setHistory([]);
      setMy({});
      setMyOffers({});
      setMyOffersTemp({});
      setMyFavorites([]);
      setMyUserDashboard({});
      setMyUserDetails({});
      setRanges([]);
    };
  }, []);

  return (
    <GlobalContext.Provider value={innerValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
export { GlobalContext };
