/**
 * @namespace Constants
 * @module Constantes FormContexts
 * @author Sébastien NOBOUR
 * @description Fichier de constantes de noms de contextes de formulaires.
 * Permet de définir un contexte de formulaire pour adapter les champs à cacher et montrer
 */

/**
 * @constant
 * @type {{edition: string, creation: string}}
 */
export const FORM_CONTEXT_OFFER = {
  creation: "offer-creation",
  edition: "offer-edition",
};

/**
 * @constant
 * @type {{edition: string, creation: string}}
 */
export const FORM_CONTEXT_VEHICLE = {
  creation: "vehicle-creation",
  edition: "vehicle-edition",
};

/**
 * @constant
 * @type {{edition: string, creation: string}}
 */
export const FORM_CONTEXT_PROFESSIONAL_ACCOUNT = {
  creation: "professional-account-creation",
  edition: "professional-account-edition",
};

/**
 * @constant
 * @type {string}
 */
export const FORM_CONTEXT_DEALERSHIP_DETAILS = "dealership-details-edition";

/**
 * @constant
 * @type {string}
 */
export const FORM_CONTEXT_DEALERSHIP_INFO = "dealership-info-creation";

/**
 * @constant
 * @type {string}
 */
export const FORM_CONTEXT_DEALERSHIP_IMAGES = "dealership-images";

/**
 * @constant
 * @type {string}
 */
export const FORM_CONTEXT_DEALERSHIP_SUPPRESSION = "dealership-suppression";

/**
 * @constant
 * @type {string}
 */
export const FORM_CONTEXT_DEALERSHIPS_FILTER =
  "FORM_CONTEXT_DEALERSHIPS_FILTER";

/**
 * @constant
 * @type {string}
 */
export const FORM_CONTEXT_OFFERS_FILTER = "FORM_CONTEXT_OFFERS_FILTER";
