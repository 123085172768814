import React from "react";
//import PropTypes from 'prop-types';
import css from "./HeaderUser.module.css";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import Btn from "@/Shared/Ui/Btn";
import { HEADERS_ATTRIBUTE } from "@/Constants/dataAttributes";

const HeaderUser = ({ toggleoffMenuMobile }) => {
  return (
    <>
      <div
        className={`${css["c-header-user"]} layout__header layout__header--user`}
        data-header={HEADERS_ATTRIBUTE.visitor}
      >
        <Btn
          type='routeLink'
          path='/connexion-inscription'
          svg='ico-user'
          className={`js__trigrpusr ${uiBtn["u-btn-icousr"]} ${uiBtn["u-btn-ico"]} ${uiBtn["u-btn-gris"]} ${uiBtn["u-btn-ico-min"]} ${uiBtn["u-btn-hoverSlide"]} ${uiBtn["u-btn-hoverSlide--secondary"]} u-btn-hoverSvg--primary`}
          onClick={toggleoffMenuMobile}
        />
        <Btn
          type='routeLink'
          path='/connexion-inscription'
          className={`${css["c-header-user__publier"]} f-primary ${uiBtn["u-btn-primary"]} ${uiBtn["u-btn-hoverSlide--secondary"]} ${uiBtn["u-btn-hoverSlide"]}`}
          label='Publier une annonce'
          onClick={toggleoffMenuMobile}
        />
      </div>
    </>
  );
};

HeaderUser.propTypes = {
  //
};

export default HeaderUser;
