import React from "react";
import css from "@/Shared/HeaderUser/HeaderUser.module.css";
import SousMenuProfil from "@/Widgets/Header/SousMenuProfil";
import TableauDeBord from "@/Widgets/EspacePro/TableauDeBord";
import { HEADERS_ATTRIBUTE } from "@/Constants/dataAttributes";

const HeaderPro = ({ toggleoffMenuMobile }) => {
  return (
    <div
      className={`${css["c-header-user"]} layout__header layout__header--pro`}
      data-header={HEADERS_ATTRIBUTE.pro}
    >
      {/* SOUS MENU PROFIL -- VARIE SUIVANT PRO / PARTICULIER */}
      <SousMenuProfil context='pro' />

      {/* SOUS MENU NOTIFICATIONS */}
      {/* #SLEEPING <SousMenuNotifications context="pro" />*/}

      {/* LIEN VERS MESSAGERIE */}
      {/* #SLEEPING <div className={`${css['c-header-user__new']} ${css['c-header-mail__btn']}`}>*/}
      {/*  <BtnFilter type="routeLink" path="/espace-pro/messagerie" svg="ico-leadMail" className={`js__trigrpusr ${uiBtn['u-btn-icousr--logue']}`} />*/}
      {/*</div>*/}

      {/* TABLEAU DE BORD PRO */}
      <TableauDeBord />
    </div>
  );
};

HeaderPro.propTypes = {
  //
};

export default HeaderPro;
