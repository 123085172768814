import React from "react";
import css from "./MenuMobile.module.css";

const MenuMobile = ({ menuRef, htmlDOM, toggleMenu }) => {
  return (
    <>
      <a
        className={`js__toggle-menu ${css["c-hb"]}`}
        onClick={() => toggleMenu("global", !menuRef.toggled)}
      >
        <div className={`${css["c-hb-gfx"]}`}>
          <div className={`${css["c-hb-1"]}`}></div>
          <div className={`${css["c-hb-2"]}`}></div>
          <div className={`${css["c-hb-3"]}`}></div>
        </div>
      </a>
    </>
  );
};

MenuMobile.propTypes = {
  //
};

export default MenuMobile;
