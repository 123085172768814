import "@/Utils/wdyr";
import "@/Public/index.css";
import GlobalLayout from "@/Shared/GlobalLayout";
import { wrapper } from "@/Store/index";
import { useEffect } from "react";

import { getInitialPropsWithRedux } from "@/Utils/getInitialPropsWithRedux/getInitialPropsWithRedux";
import { createMirageApis } from "@/Mirage/index";
import { GlobalWrapper } from "@/Shared/GlobalWrapper/GlobalWrapper";

/**
 * @function Fichier qui reprend la structure de base de toutes les pages tous les éléments de layout répétés doivent être positionnés ici Header, Footer, ...
 *
 * Equivalent de app.js en react
 * @todo Todo Supprimer le script API Google Map d'ici et l'intégrer au cas par cas dans les fichiers qui en ont besoin (David).
 * @namespace _app
 * @description
 * @param Component
 * @param fallback
 * @param session
 * @param error
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const App = ({
  Component,
  pageProps: { fallback, session, error, ...props },
}) => {
  useEffect(() => {
    //https://www.miragejs.com/quickstarts/nextjs/develop-an-app/#step-3-start-your-server-in-development only runs in browser
    try {
      if (process.env.NEXT_PUBLIC_IS_MOCKING_SERVERS) {
        const mirage = createMirageApis({ environment: "development" });
        mirage.logging = true;
        mirage.loadFixtures();

        // eslint-disable-next-line no-console
        console.info("Mirage server started on browser");
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <GlobalWrapper session={session}>
      <GlobalWrapper.GoogleTagLoadScript />
      <GlobalWrapper.GoogleTagConfigScript />
      <GlobalWrapper.ReCaptchaProvider>
        <GlobalLayout error={error}>
          <Component {...props} />
        </GlobalLayout>
      </GlobalWrapper.ReCaptchaProvider>
      <GlobalWrapper.NoScriptGoogleTagManager />
    </GlobalWrapper>
  );
};

// Monitoring with Axiom https://www.axiom.co/docs/integrations/vercel
export function reportWebVitals(metric) {
  const url = process.env.NEXT_PUBLIC_AXIOM_INGEST_ENDPOINT;

  if (url) {
    let callback = () => fetch(url, { body, method: "POST", keepalive: true });

    const body = JSON.stringify({
      route: window.__NEXT_DATA__.page,
      ...metric,
    });

    if (navigator.sendBeacon) {
      callback = () => navigator.sendBeacon(url, body);
    }

    callback();
  }
}

App.getInitialProps = wrapper.getInitialAppProps(getInitialPropsWithRedux);

export default wrapper.withRedux(App);
