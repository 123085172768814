import { useEffect, useState } from "react";

function UseMediaQueries() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [mediaQueries, setmediaQueries] = useState({
    /* max-width */
    max1: undefined,
    max30: undefined,
    max45: undefined,
    max60: undefined,
    max80: undefined,
    max90: undefined,
    max100: undefined,
    max110: undefined,
    max120: undefined,
    max130: undefined,
    /* min-width */
    min5: undefined,
    min30: undefined,
    min45: undefined,
    min60: undefined,
    min80: undefined,
    min90: undefined,
    min100: undefined,
    min110: undefined,
    min120: undefined,
    min130: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    // Call handler right away so state gets updated with initial window size
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setmediaQueries({
          /* max-width */
          max1: window.matchMedia("(max-width: 1em)").matches,
          max30: window.matchMedia("(max-width: 30em)").matches,
          max45: window.matchMedia("(max-width: 45em)").matches,
          max60: window.matchMedia("(max-width: 60em)").matches,
          max80: window.matchMedia("(max-width: 80em)").matches,
          max90: window.matchMedia("(max-width: 90em)").matches,
          max100: window.matchMedia("(max-width: 100em)").matches,
          max110: window.matchMedia("(max-width: 110em)").matches,
          max120: window.matchMedia("(max-width: 120em)").matches,
          max130: window.matchMedia("(max-width: 130em)").matches,
          /* min-width */
          min1: window.matchMedia("(min-width: 1em)").matches,
          min30: window.matchMedia("(min-width: 30em)").matches,
          min45: window.matchMedia("(min-width: 45em)").matches,
          min60: window.matchMedia("(min-width: 60em)").matches,
          min80: window.matchMedia("(min-width: 80em)").matches,
          min90: window.matchMedia("(min-width: 90em)").matches,
          min100: window.matchMedia("(min-width: 100em)").matches,
          min110: window.matchMedia("(min-width: 110em)").matches,
          min120: window.matchMedia("(min-width: 120em)").matches,
          min130: window.matchMedia("(min-width: 130em)").matches,
        });
      };

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
    return null;
  }, []);
  return mediaQueries;
}

export default UseMediaQueries;
