import { Model, createServer } from "miragejs";
import { routeGetMy, routeGetMyOffers } from "@/Mirage/business/routes/my";
import { HydraSerializer } from "@/Mirage/business/serializers/HydraSerializer";
import { authenticate } from "@/Mirage/business/utils";
import { inflections } from "inflected";
import { routeGetArticles } from "@/Mirage/cms/routes/articles";
import { routeGetBrands } from "@/Mirage/business/routes/brands";
import { routeGetCategories } from "@/Mirage/cms/routes/categories";
import { routeGetCountries } from "@/Mirage/business/routes/countries";
import { routeGetGlobalSeo } from "@/Mirage/cms/routes/global";
import { routeGetMyUserDetails } from "@/Mirage/business/routes/myUserDetails";
import { routeGetOffers } from "@/Mirage/business/routes/offers";
import { routePostLogin } from "@/Mirage/business/routes/login";
import { routePostLogout } from "@/Mirage/business/routes/logout";
import { routePostNewsletterSubscribe } from "@/Mirage/business/routes/newsletter";
import { routePostTokenRefresh } from "@/Mirage/business/routes/tokenRefresh";
import seeds from "@/Mirage/utils/seeds";

inflections("en", function (inflect) {
  inflect.irregular("my", "my");
});

/**
 * @description create mirage apis for business Strapi CMS and next BFF APIs for testing and development purposes in
 * order to not depend on the real APIs and accelerate the tests.
 * @param params
 * @returns {Server<MirageRegistry<{country: ModelDefinition, my: ModelDefinition, user: ModelDefinition}, AnyFactories>>}
 */
export const createMirageApis = params => {
  params = { environment: params?.environment ?? "test" };

  const { environment } = params;

  return createServer({
    environment,

    // https://miragejs.com/docs/testing/assertions/
    trackRequests: true,

    serializers: {
      application: HydraSerializer,
    },

    // custom pluralization if needed: https://miragejs.com/docs/advanced/customizing-inflections/#gatsby-focus-wrapper
    models: {
      country: Model,
      user: Model,
      my: Model,
      myDetail: Model,
      brand: Model,
      offer: Model,
      blogArticle: Model,
      blogGlobal: Model,
      blogCategory: Model,
    },

    // not executed in test environment https://stackoverflow.com/questions/62720508/miragejs-seeds-function-not-called
    // so instead make fixtures in tests https://miragejs.com/tutorial/part-8/
    // https://miragejs.com/docs/main-concepts/factories/#using-factories
    seeds(server) {
      seeds.seedCommonBusinessData(server);
      seeds.seedBlogData(server);
    },

    // https://www.smashingmagazine.com/2020/06/mirage-javascript-timing-response-passthrough/#passthrough
    // https://miragejs.com/docs/getting-started/overview/#passthrough
    // https://miragejs.com/api/classes/server/#passthrough
    routes() {
      //  --------------- Business API  --------------- //
      // curl https: entrypoint | pbcopy (on mac)
      this.urlPrefix = process.env.NEXT_PUBLIC_ENTRYPOINT;
      this.namespace = "api";

      this.get("/brands", routeGetBrands);

      this.get("/countries", routeGetCountries);

      this.post("/login", routePostLogin);
      this.post("/logout", (schema, request) =>
        authenticate(schema, request, routePostLogout)
      );

      this.post("/newsletter/subscribe", routePostNewsletterSubscribe);

      this.post("/token/refresh", routePostTokenRefresh);

      this.get("/my", (schema, request) =>
        authenticate(schema, request, routeGetMy)
      );
      this.get("/my/offerstemp", (schema, request) =>
        authenticate(schema, request, routeGetMyOffers)
      );
      this.get("/my/offers", (schema, request) =>
        authenticate(schema, request, routeGetMyOffers)
      );

      this.get("/my/user/details", (schema, request) =>
        authenticate(schema, request, routeGetMyUserDetails)
      );
      this.get("my/user/dashboard", () => {});

      this.get("/offers/:id", routeGetOffers);

      this.passthrough();

      //  --------------- Next Backend For Frontend API  --------------- //
      this.urlPrefix = process.env.NEXT_PUBLIC_SITE_URL;
      this.namespace = "";
      this.passthrough("*");

      //  --------------- Strapi CMS API  --------------- //
      this.urlPrefix = process.env.STRAPI_API_URI;
      this.namespace = "";
      this.passthrough("");
      this.get("articles/:slug", routeGetArticles);
      this.get("articles", () => routeGetArticles);
      this.get("categories/:category", routeGetCategories);
      this.get("categories", routeGetCategories);
      this.get("**global**", routeGetGlobalSeo);

      //  --------------- All other 3rd party APIs  --------------- //
      this.urlPrefix = "";
      this.namespace = "";

      this.passthrough("https://analytics.google.com**");
      this.passthrough("https://client.axept.io/**");
      this.passthrough("https://api.axept.io/**");
      this.passthrough("https://www.recaptcha.net**");
    },
  });
};

// export const createMirageCypressProxy = () => {
//   // If your app makes requests to domains other than / (the current domain), add them
//   // here so that they are also proxied from your app to the handleFromCypress function.
//   // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
//   const otherDomains = [
//     'res.cloudinary.com',
//     process.env.NEXT_PUBLIC_ENTRYPOINT,
//     process.env.STRAPI_API_URI,
//   ]
//   const methods = ['get', 'put', 'patch', 'post', 'delete']
//
//   return createServer({
//     environment: 'test',
//
//     routes() {
//       for (const domain of ['/', ...otherDomains]) {
//         for (const method of methods) {
//           this[method](`${domain}`, async (schema, request) => {
//             const [status, headers, body] = await window.handleFromCypress(
//               request
//             )
//             return new Response(status, headers, body)
//           })
//         }
//       }
//
//       // https://miragejs.com/docs/getting-started/overview/#passthrough
//       // If your central server has any calls to passthrough(), you'll need to duplicate them here
//       this.passthrough('https://analytics.google.com/**')
//       this.passthrough('https://client.axept.io/**')
//       this.passthrough('https://www.recaptcha.net/**')
//
//       this.passthrough(`${process.env.NEXT_PUBLIC_ENTRYPOINT}/api/**`)
//       this.passthrough(`${process.env.STRAPI_API_URI}/**`)
//     },
//   })
// }
