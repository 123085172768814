// ADS ACTION TYPES
export const LOAD = "ADS/LOAD";
export const LOAD_SUCCESS = "ADS/LOAD_SUCCESS";
export const LOAD_FAILURE = "ADS/LOAD_FAILURE";
export const LOAD_STARTED = "ADS/LOAD_STARTED";
export const ACTION_CHANGED = "ADS/ACTION_CHANGED";
export const ORDER_CHANGED = "ADS/ORDER_CHANGED";
export const FILTER_VALUES_LOADED = "ADS/FILTER_VALUES_LOADED";
export const INITIAL_ADS_FILTER_VALUES_LOADED =
  "ADS/INITIAL_FILTER_VALUES_LOADED";
