import { ADS_FILTERS } from "@/Constants/filters";
import { sortBy } from "lodash";
import { businessApiRequest } from "@/Utils/http/businessApiRequests";
import {
  BRANDS_ROUTE,
  DEALERS_ROUTE,
  MODELS_ROUTE,
} from "@/Constants/restApiRoutes";
import {
  getCriteriaDealerValue,
  getFilterModelValue,
} from "@/Utils/filtersFunctions";
import { deserialize } from "@/Utils/http/httpRequests";

export const updateAdsFilterFromResearch = async ({
  accessToken = null,
  adsFilter,
  research,
}) => {
  const search = deserialize(research.search);

  // console.log('search : ', search)
  // {
  //     "type": [
  //         "occasion"
  //     ],
  //     "user.userType": [
  //         "professionnel"
  //     ],
  //     "model.rangeVehicle": [
  //         4,
  //         3
  //     ],
  //     "bedType": [
  //         "lit-a-la-francaise",
  //         "lit-central",
  //         "lit-toit-relevable",
  //         "lit-transversal",
  //         "lits-jumeaux"
  //     ],
  //     "length": {
  //         "gte": 9.5
  //     },
  //     "height": {
  //         "lte": 0.4
  //     },
  //     "width": {
  //         "lte": 0.6,
  //         "gte": 6.4
  //     },
  //     "inclVatPrice": {
  //         "gte": 325000
  //     },
  //     "postcode": "35000",
  //     "radius": "1000",
  //     "registeredSeats": {
  //         "lte": 3
  //     },
  //     "mileage": {
  //         "gte": 110000
  //     },
  //     "millesime": {
  //         "lte": 346
  //     },
  //     "model.brand": [
  //         20,
  //         23,
  //         107,
  //         25,
  //         43,
  //         2,
  //         54,
  //         13
  //     ],
  //     "model": [
  //         138,
  //         539
  //     ],
  //     "gearbox": [
  //         "automatique",
  //         "manuelle",
  //         "sequentielle"
  //     ],
  //     "grossWeight": {
  //         "lte": "3500"
  //     },
  //     "heating": [
  //         "Carburant"
  //     ]
  // }

  // eslint-disable-next-line prefer-const
  for (let [key, values] of Object.entries(search)) {
    switch (key) {
      case ADS_FILTERS.type.name:
      case ADS_FILTERS["user.userType"].name:
        adsFilter[key].values = adsFilter[key].values.map(refValue => ({
          ...refValue,
          checked: Boolean(values.find(name => name === refValue.name)),
        }));
        break;

      case ADS_FILTERS.gearbox.name:
      case ADS_FILTERS.heating.name:
      case ADS_FILTERS.bedType.name:
        adsFilter[key].values = adsFilter[key].values.map(refValue => ({
          ...refValue,
          checked: Boolean(values.find(value => value === refValue.value)),
        }));
        break;

      case ADS_FILTERS["model.brand"].name:
      case ADS_FILTERS.model.name:
      case ADS_FILTERS.dealer.name:
      case ADS_FILTERS["model.rangeVehicle"].name:
        values?.forEach((id, indexId) => {
          const indexFoundIdInCriteriaValues = adsFilter[
            key
          ]?.values?.findIndex(value => value.id === id);

          if (indexFoundIdInCriteriaValues > -1) {
            adsFilter[key].values[indexFoundIdInCriteriaValues] = {
              ...adsFilter[key]?.values[indexFoundIdInCriteriaValues],
              checked: true,
              disabled: false,
            };

            // using values.splice(indexId, 1) breaks the forEach loop (one out of 2 index gets ignored)
            values = values?.slice(indexId, 1);
          }
        });

        if (values?.length) {
          const routes = {
            "model.brand": BRANDS_ROUTE,
            model: MODELS_ROUTE,
            dealer: DEALERS_ROUTE,
          };

          let arrayData = null;

          const route = routes[key];
          if (route) {
            const arrayBusinessApiRequests = [];
            values.forEach(id =>
              arrayBusinessApiRequests.push(
                businessApiRequest(route, { accessToken, id })
              )
            );

            arrayData = await Promise.all(arrayBusinessApiRequests);
          }

          const convertToCheckboxes = {
            "model.brand": arrayData =>
              arrayData.map(brand => ({
                label: brand.name,
                name: brand.name,
                id: brand.id,
                key: brand.id,
                checked: true,
                disabled: false,
              })),
            model: arrayData =>
              arrayData.map(model => getFilterModelValue(model, true)),
            dealer: arrayData =>
              arrayData.map(data => getCriteriaDealerValue(data, true)),
          };

          let arrayMissingCriteriaValues = null;

          if (convertToCheckboxes[key] && arrayData)
            arrayMissingCriteriaValues = convertToCheckboxes[key](
              arrayData,
              true
            );

          if (key === ADS_FILTERS["model.rangeVehicle"].name) {
            arrayMissingCriteriaValues = values.map(id => {
              const indexFound = ADS_FILTERS[key].values.findIndex(
                value => value.id === id
              );

              let value = adsFilter?.[key]?.values[indexFound];

              if (value) {
                value.checked = true;
                value.disabled = false;
              }

              if (!value) {
                value = ADS_FILTERS[key].values[indexFound];
              }

              return value;
            });
          }

          if (arrayMissingCriteriaValues) {
            adsFilter[key].values = [
              ...adsFilter[key].values,
              ...arrayMissingCriteriaValues,
            ];
          }

          adsFilter[key].values = sortBy(adsFilter[key].values, ["label"]);
        }
        break;

      case ADS_FILTERS.size.values[0].name:
      case ADS_FILTERS.size.values[1].name:
      case ADS_FILTERS.size.values[2].name:
      case ADS_FILTERS.inclVatPrice.name:
      case ADS_FILTERS.registeredSeats.name:
      case ADS_FILTERS.sleepingSeats.name:
      case ADS_FILTERS.mileage.name:
      case ADS_FILTERS.millesime.name:
        const getValues = () => {
          const arrayValues = [];

          if (values.lte) {
            arrayValues[0] = values.lte;
          }

          if (values.gte) {
            arrayValues[1] = values.gte;
          }

          return arrayValues;
        };

        let updateCriteria = () => {
          adsFilter[key].values = getValues(key);
          if (adsFilter[key].min > adsFilter[key].values[0]) {
            adsFilter[key].min = adsFilter[key].values[0];
          }

          if (adsFilter[key].max < adsFilter[key].values[1]) {
            adsFilter[key].max = adsFilter[key].values[1];
          }
        };

        if (
          [
            adsFilter.size.values[0].name,
            adsFilter.size.values[1].name,
            adsFilter.size.values[2].name,
          ].includes(key)
        ) {
          updateCriteria = () => {
            const indexSubCriteria = adsFilter.size.values.findIndex(
              value => value.name === key
            );

            adsFilter.size.values[indexSubCriteria] = {
              ...adsFilter.size.values[indexSubCriteria],
              values: getValues(key),
            };
            if (
              adsFilter.size.values[indexSubCriteria].min >
              adsFilter.size.values[indexSubCriteria][0]
            ) {
              adsFilter.size.values[indexSubCriteria].min =
                adsFilter.size.values[indexSubCriteria][0];
            }

            if (
              adsFilter.size.values[indexSubCriteria].max <
              adsFilter.size.values[indexSubCriteria][1]
            ) {
              adsFilter.size.values[indexSubCriteria].max =
                adsFilter.size.values[indexSubCriteria][1];
            }
          };
        }

        updateCriteria();
        break;

      case ADS_FILTERS.grossWeight.name:
        adsFilter.grossWeight.values[0].checked = Boolean(values.lte);
        adsFilter.grossWeight.values[1].checked = Boolean(values.gte);

        break;

      case ADS_FILTERS.localisation.values[0].name:
        if (values) {
          adsFilter.localisation.values[0].value = values;
        }
        break;

      case ADS_FILTERS.localisation.values[1].name:
        if (values) {
          adsFilter.localisation.values[1].value = values;
        }
        break;
    }
  }

  return adsFilter;
};
