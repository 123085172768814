/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes Time
 * @description Fichier de constantes de millisecondes. Devrait être fusionné avec constants/dates.js
 */

/**
 * @constant
 * @type {number}
 */
export const ONE_SECOND = 1000;

/**
 * @constant
 * @type {number}
 */
export const FIVE_SECONDS = ONE_SECOND * 5;

/**
 * @constant
 * @type {number}
 */
export const ONE_MINUTE = 60000;
/**
 * @constant
 * @type {number}
 */
export const ONE_HOUR = 3600000;
