import css from "@/Shared/Header/Header.module.css";
import SousMenuBlog from "@/Widgets/Header/SousMenuBlog";
import { useRouter } from "next/router";
import Link from "next/link";
import UseMediaQueries from "@/Shared/Hooks/UseMediaQueries";
import {
  PAGE_CONCEPT_WAG,
  PAGE_JEU_CONCOURS_1_AN_CARBURANT_GRATUIT,
  PAGE_RECHERCHE_VEHICULE,
  PAGE_VENDEURS,
} from "@/Constants/pageRoutes";
import { isGameFreeFuel2022MarchActive } from "@/Utils/index";

const isGameOnGoing = isGameFreeFuel2022MarchActive(Date.now());

const MenuCommun = ({ menuRef, htmlDOM, toggleMenu }) => {
  const router = useRouter();
  const mq = UseMediaQueries();
  const mqmax60 = mq.max60;

  const toggleoffMenuMobile = () => {
    if (mqmax60) toggleMenu("global", false);
  };

  return (
    <nav className={`${css["c-nav-main"]} js__mainMenu`}>
      <div className={`${css["c-nav-main__grappe"]}`}>
        <span className={"f-primary f-primary--700"}>Je cherche</span>

        <div className={`${css["c-nav-main__grappe"]}`}>
          {/* -- RECHERCHE VEHICULE -- */}
          <Link
            href={{
              pathname: PAGE_RECHERCHE_VEHICULE,
              query: { reinitialiseAdsFilter: "true" },
            }}
          >
            <a
              className={`f-primary ${css["c-nav-main__grappe__lien"]} 
              ${
                router.pathname === "/recherche-vehicule-loisirs"
                  ? css["c-nav-main__grappe__lien--actif"]
                  : ""
              }
            `}
              onClick={toggleoffMenuMobile}
            >
              Un véhicule
            </a>
          </Link>

          {/* -- RECHERCHE VENDEUR -- */}
          <Link href={PAGE_VENDEURS}>
            <a
              className={`
              f-primary ${css["c-nav-main__grappe__lien"]} ${
                router.pathname === PAGE_VENDEURS &&
                css["c-nav-main__grappe__lien--actif"]
              }
            `}
              onClick={toggleoffMenuMobile}
            >
              Un vendeur
            </a>
          </Link>

          {/*<Link href="/blog/static__blog">*/}
          {/*  <a className={`${css['c-nav-main__grappe__lien']}`}>Blog <em>static</em></a>*/}
          {/*</Link>*/}
        </div>
      </div>
      {/* -- LIEN BLOG + SOUS MENU BLOG  -- */}
      <SousMenuBlog toggleoffMenuMobile={toggleoffMenuMobile} />
      <Link href={PAGE_CONCEPT_WAG}>
        <a
          className={`
          f-primary ${css["c-nav-main__lien-single"]} 
          ${
            router.pathname === PAGE_CONCEPT_WAG &&
            css["c-nav-main__lien-single--actif"]
          }
        `}
          onClick={toggleoffMenuMobile}
        >
          Le concept
        </a>
      </Link>
      {isGameOnGoing && (
        <Link href={PAGE_JEU_CONCOURS_1_AN_CARBURANT_GRATUIT}>
          <a
            className={`f-primary ${css["c-nav-main__lien-single"]} ${
              css["c-nav-main__lien-jeu"]
            } 
          ${
            router.pathname === "/un-an-de-carburant-a-gagner" &&
            css["c-nav-main__lien-single--actif"]
          }
          `}
            onClick={toggleoffMenuMobile}
          >
            Jeu-concours
          </a>
        </Link>
      )}
    </nav>
  );
};

export default MenuCommun;
