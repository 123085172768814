//import PropTypes from 'prop-types';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export const appearFromY = (elem, delay, origine) => {
  gsap.from(elem, {
    y: origine !== null ? origine : 40,
    autoAlpha: 0,
    opacity: 0,
    duration: 1.4,
    ease: "back",
    delay: delay !== null ? delay : "",
  });
};

export const appearSousMenu = (elem, delay, origine) => {
  // console.log('appear',elem,delay,origine)
  gsap.from(elem, {
    y: origine !== null ? origine : -40,
    autoAlpha: 0,
    opacity: 0,
    duration: 0.6,
    ease: "back",
    delay: delay !== null ? delay : "",
  });
};

export const disappearSousMenu = (elem, delay, origine) => {
  // console.log('disappear',elem,delay,origine)
  gsap.to(elem, {
    y: origine !== null ? origine : 40,
    autoAlpha: 0,
    opacity: 0,
    duration: 0.6,
    ease: "back",
    delay: delay !== null ? delay : "",
  });
};

export const appearSousMenuRaw = (elem, delay, origine) => {
  // console.log('appear',elem.classList.value)
  elem.style.visibility = "visible";
  setTimeout(() => {
    elem.style.opacity = 1;
    elem.style.transform = "translateY(0)";
  }, 10);
};

export const disappearSousMenuRaw = (elem, delay, origine) => {
  // console.log('disappear',elem.classList.value)
  elem.style.visibility = "visible";
  elem.style.opacity = 1;
  setTimeout(() => {
    elem.style.opacity = 0;
    elem.style.transform = "translateY(20px)";
    setTimeout(() => {
      elem.style.visibility = "hidden";
    }, 20);
  }, 10);
};

export const scrollTriggerFade = (elem, markers) => {
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ //
  gsap.registerPlugin(ScrollTrigger);
  // console.log('scrolltrigger ok pour > ',elem, elem.getBoundingClientRect.height)
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ //
  const timelineSTFade = gsap.timeline({
    scrollTrigger: {
      trigger: elem,
      start: "top 72%",
      end: "top 20%",
      markers: markers,
      toggleActions: "play none none none",
    },
    defaults: { autoAlpha: 0, opacity: 0, ease: "Expo.easeOut" },
  });
  timelineSTFade.fromTo(
    elem,
    { opacity: 0, y: 100, autoAlpha: 0 },
    { opacity: 1, y: 0, autoAlpha: 1, duration: 2.0 }
  );
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ //
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ //
};
