/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes Regex
 * @description Fichier de constantes d'expressions régulières à utiliser pour de la validation de champs,
 * recherches de valeurs dans propriétés, le but est de ne pas avoir à les ré-écrire.
 */

// https://stackoverflow.com/questions/20690499/concrete-javascript-regex-for-accented-characters-diacritics
import { radiosCivility } from "@/Constants/InputsValues";

/**
 * @constant
 * @type {RegExp}
 */
export const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s-']*$/;

/**
 * @constant
 * @type {RegExp}
 */
export const addressRegex = /^(?=.*[A-Za-zÀ-ÖØ-öø-ÿ])(?=.*[0-9\s-'])/;

/**
 * @constant
 * @type {RegExp}
 */
export const addressComplementRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s-',.]*$/;

/**
 * @constant
 * @type {RegExp}
 */
export const userNameRegex = /^[a-zA-Z0-9-_.]{3,}$/i;

/**
 * @constant
 * @type {RegExp}
 */
export const zipCodeRegex = /^[a-zA-Z0-9\s-]*$/;

/**
 * @constant
 * @type {RegExp}
 */
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W)/;

/**
 * @constant
 * @type {RegExp}
 */
export const youtubeUrlRegex = /^https:\/\/www.youtube.com/;

/**
 * @constant
 * @type {RegExp}
 */
export const notDigitRegex = /\D/g;

/**
 * @constant
 * @type {RegExp}
 */
export const whiteSpaceRegex = /\s/g;

/**
 * @constant
 * @type {RegExp}
 */
export const chassisNumberRegex = /^[a-zA-Z-0-9]{17}$/i;

/**
 * @constant
 * @type {RegExp}
 */
export const frenchDimensionsRegex = /^(\d{1,2})$|^(\d{1,2})+(,)+(\d{1,2})$/;

/**
 * @constant
 * @type {RegExp}
 */
export const frenchPriceRegex = /^[\d\s]*$|^([\d\s]*)+(,)+(\d{1,2})$/;

/**
 * @constant
 */

/**
 * @constant
 * @type {string}
 */
const radiosCivilityString = radiosCivility.map(radio => radio.value).join("|");

/**
 * @constant
 * @type {RegExp}
 */
export const radiosCivilityRegex = new RegExp(`^[${radiosCivilityString}]+$`);

/**
 * @constant
 * @type {RegExp}
 */
export const imageDealershipExtensionRegex = /\.jpeg|\.jpg|\.png/g;

/**
 * @constant
 * @type {RegExp}
 */
export const errorServerRegex = /^5/;
