import Joi from "joi";
import {
  arrayValuesValidate,
  chassisNumberValidate,
  civilityValidate,
  dimensionsValidate,
  emailAddressValidate,
  firstAddressValidate,
  nameValidate,
  passwordRepeatValidate,
  passwordValidate,
  phoneNumberValidate,
  priceValidate,
  secondAddressValidate,
  userNameValidate,
  zipCodeValidate,
} from "@/Utils/formValidation/inputValidators";
import {
  CARROSSERIES,
  CAUSESIGNALEMENT,
  GEARBOXES,
  METIERSPARTICULIERS,
  bedTypes,
  energies,
  firstHand,
  heaterEnergies,
  heaterTypes,
  millesimes,
  yesOrNo,
} from "@/Constants/InputsValues";

/**
 * @file utils/formValidation/formSchemas.
 * @description contient des fonctions validant les données soumises dans les formulaires avant de les fournir
 * aux fonctions en charge d'envoyer ces données aux différentes APIs distantes.
 * @module Utils Form Validation
 * @namespace Code Central
 */
import { MAX_NB_PEOPLE_HOUSEHOLD } from "@/Constants/limits";

export const extendedJoi = Joi.extend(require("joi-phone-number")).extend(
  require("@joi/date")
);

// TODO date formate might not be the good one
export const CreationCompteParticulierFormSchema = () =>
  extendedJoi.object({
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
    phoneNumber: phoneNumberValidate(),
    emailAddress: emailAddressValidate(),
    firstAddress: firstAddressValidate(),
    secondAddress: secondAddressValidate(),
    zipCode: zipCodeValidate(),
    city: nameValidate(),
    country: extendedJoi.number().integer().required(),
    birthday: extendedJoi.date().format("YYYY-MM-DD").required(),
    userName: userNameValidate(),
    password: passwordValidate(),
    passwordRepeat: passwordRepeatValidate("password"),
    termsOfUse: extendedJoi.boolean().valid(true).required(),
  });

export const CreationCompteProFormSchema = () =>
  extendedJoi.object({
    companyName: extendedJoi.string().max(150).required(),
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
    phoneNumber: phoneNumberValidate(),
    phoneHidden: arrayValuesValidate(yesOrNo)?.single().optional(),
    emailAddress: emailAddressValidate(),
    firstAddress: firstAddressValidate(),
    secondAddress: secondAddressValidate(),
    zipCode: zipCodeValidate(),
    city: nameValidate(),
    country: extendedJoi.number().integer().required(),
    language: extendedJoi.string().max(10).required(),
    vatNumber: extendedJoi.string().max(150).required(),
    triNumber: extendedJoi.string().allow("").max(150),
    dealershipTotalNumber: extendedJoi
      .number()
      .integer()
      .min(1)
      .max(999999)
      .required(),
    password: passwordValidate(),
    passwordRepeat: passwordRepeatValidate("password"),
    termsOfUse: extendedJoi.boolean().valid(true).required(),
  });

export const ConnectionFormSchema = () =>
  extendedJoi.object({
    emailAddress: emailAddressValidate(),
    password: extendedJoi.string().max(150).required(),
  });

export const ForgottenPasswordFormSchema = () =>
  extendedJoi.object({
    emailAddress: emailAddressValidate(),
  });

// TODO date formate might not be the good one
export const EditDonneesPersonnellesSchema = () =>
  extendedJoi.object({
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
    phoneNumber: phoneNumberValidate(),
    firstAddress: firstAddressValidate(),
    secondAddress: secondAddressValidate(),
    zipCode: zipCodeValidate(),
    city: nameValidate(),
    country: extendedJoi.number().integer().required(),
    birthday: extendedJoi.date().format("YYYY-MM-DD").required(),
    companyName: extendedJoi.any().valid(""),
    vatNumber: extendedJoi.any().valid(""),
    triNumber: extendedJoi.any().valid(""),
  });

export const EditPreferencesSchema = () =>
  extendedJoi.object({
    language: extendedJoi.string().max(10).required(),
    newsletter: arrayValuesValidate(yesOrNo)?.single().required(),
  });

/**
 * @function
 * @description Valide le formulaire d'édition des données du formulaire FormDonneesProfessionelles.js
 * @returns {*|Error}
 * @see components/Forms/FormDonneesProfessionnelles/FormDonneesProfessionnelles.js
 * @todo enlever `optional()` du champ phoneHidden une fois que l'endpoint my/user/details renvoie le champ phoneHidden
 */
export const EditDonneesPersonnellesSchemaPro = () =>
  extendedJoi.object({
    companyName: extendedJoi.string().max(150).required(),
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
    phoneNumber: phoneNumberValidate(),
    phoneHidden: arrayValuesValidate(yesOrNo)?.single().optional(),
    firstAddress: firstAddressValidate(),
    secondAddress: secondAddressValidate(),
    zipCode: zipCodeValidate(),
    city: nameValidate(),
    country: extendedJoi.number().integer().required(),
    vatNumber: extendedJoi.string().max(150).required(),
    triNumber: extendedJoi.string().allow("").max(150),
  });

export const EditPasswordSchema = () =>
  extendedJoi.object({
    emailAddress: emailAddressValidate(),
    userName: userNameValidate(),
    password: extendedJoi.string().max(150).required(),
    newPassword: passwordValidate(),
    repeatNewPassword: passwordRepeatValidate("newPassword"),
  });

export const ResetPasswordSchema = () =>
  extendedJoi.object({
    password: passwordValidate(),
    repeatPassword: passwordRepeatValidate("password"),
  });

export const ModificationRibFormSchema = () =>
  extendedJoi.object({
    bankName: extendedJoi.string().max(150).required(),
    bankCode: extendedJoi.string().max(150).required(),
    accountNumber: extendedJoi.string().max(150).required(),
    key: extendedJoi.string().max(150).required(),
    iban: extendedJoi.string().max(150).required(),
    swift: extendedJoi.string().max(150).required(),
  });

// https://joi.dev/api/?v=17.6.0#anywhencondition-options
export const AjoutEditionDonneesVehicule = ({ ranges = [] }) =>
  ranges?.length &&
  extendedJoi
    .object({
      id: extendedJoi.any(),
      range: arrayValuesValidate(ranges)?.single().required(),
      brand: extendedJoi.number().min(1).max(10000).required(),
      millesime: arrayValuesValidate(millesimes)?.single().required(),
      name: extendedJoi.string().max(150).required(),
      firstHand: arrayValuesValidate(firstHand)?.single().required(),
      bedType: arrayValuesValidate(bedTypes)?.single().required(),
      registeredSeats: extendedJoi
        .number()
        .integer()
        .min(0)
        .max(1000)
        .required(),
      sleepingSeats: extendedJoi.number().integer().min(0).max(1000).required(),
      length: dimensionsValidate(),
      width: dimensionsValidate(),
      height: dimensionsValidate(),
      popUpRoof: arrayValuesValidate(yesOrNo)?.single().required(),
      carrier: extendedJoi.number().min(1).max(10000).required(),
      chassisNumber: chassisNumberValidate(),
      grossWeight: extendedJoi.number().min(100).required(),
      unladenWeight: extendedJoi.number().min(100).required(),
      releaseDate: extendedJoi.date().format("DD/MM/YYYY").required(),
      game: extendedJoi.any(),
    })
    .when(
      extendedJoi
        .object({ range: extendedJoi.valid(CARROSSERIES[2].key) })
        .unknown(),
      {
        then: extendedJoi.object({
          mileage: extendedJoi.valid(""),
          energy: extendedJoi.valid(""),
          gearbox: extendedJoi.valid(""),
          dinPower: extendedJoi.valid(""),
          fiscalPower: extendedJoi.valid(""),
        }),
        otherwise: extendedJoi.object({
          mileage: extendedJoi.number().integer().min(1).max(999999),
          energy: arrayValuesValidate(energies)?.single(),
          gearbox: arrayValuesValidate(GEARBOXES)?.single(),
          dinPower: extendedJoi.number().min(0).max(2000),
          fiscalPower: extendedJoi.number().min(0).max(2000),
        }),
      }
    );

// todo: should remove the range and readapt form output
export const AjoutEditionAnnonce = ({
  ranges = [],
  isGameSignUpShowing = false,
}) =>
  ranges?.length &&
  extendedJoi
    .object({
      inclVatPrice: priceValidate(),
      zipCode: zipCodeValidate(),
      city: nameValidate(),
      images: extendedJoi.any(),
      id: extendedJoi.any(),
      firstHand: arrayValuesValidate(firstHand)?.single().required(),
      country: extendedJoi.number().integer().required(),
      heaterEnergy: arrayValuesValidate(heaterEnergies)?.single().required(),
      heaterType: arrayValuesValidate(heaterTypes)?.single().required(),
      introduction: extendedJoi.string().max(5000).required(),
      shownName: extendedJoi.string().max(150).required(),
      phoneHidden: arrayValuesValidate(yesOrNo)?.single().required(),
      range: arrayValuesValidate(ranges)?.single().required(),
      brand: extendedJoi.number().min(1).max(10000).required(),
      millesime: arrayValuesValidate(millesimes)?.single().required(),
      name: extendedJoi.string().max(150).required(),
      bedType: arrayValuesValidate(bedTypes)?.single().required(),
      registeredSeats: extendedJoi
        .number()
        .integer()
        .min(0)
        .max(100)
        .required(),
      sleepingSeats: extendedJoi.number().integer().min(0).max(100).required(),
      length: dimensionsValidate(),
      width: dimensionsValidate(),
      height: dimensionsValidate(),
      popUpRoof: arrayValuesValidate(yesOrNo)?.single().required(),
      carrier: extendedJoi.number().min(1).max(10000).required(),
      chassisNumber: chassisNumberValidate(),
      grossWeight: extendedJoi.number().min(100).required(),
      unladenWeight: extendedJoi.number().min(100).required(),
      releaseDate: extendedJoi.date().format("DD/MM/YYYY").required(),
      game: isGameSignUpShowing ? extendedJoi.boolean() : extendedJoi.any(),
    })
    .when(
      extendedJoi
        .object({ range: extendedJoi.valid(CARROSSERIES[2].key) })
        .unknown(),
      {
        then: extendedJoi.object({
          mileage: extendedJoi.valid(""),
          energy: extendedJoi.valid(""),
          gearbox: extendedJoi.valid(""),
          dinPower: extendedJoi.valid(""),
          fiscalPower: extendedJoi.valid(""),
        }),
        otherwise: extendedJoi.object({
          mileage: extendedJoi.number().integer().min(1).max(999999),
          energy: arrayValuesValidate(energies)?.single(),
          gearbox: arrayValuesValidate(GEARBOXES)?.single(),
          dinPower: extendedJoi.number().min(0).max(2000),
          fiscalPower: extendedJoi.number().min(0).max(2000),
        }),
      }
    );

export const SuscribeToNewsletterSchema = () =>
  extendedJoi.object({
    email: emailAddressValidate(),
  });

export const DeleteOfferSchema = soldFrom =>
  extendedJoi.object({
    soldFrom: arrayValuesValidate(soldFrom)?.single().required(),
    way: extendedJoi.string().allow("").max(150),
  });

export const ContactDealerSchema = () =>
  extendedJoi.object({
    message: extendedJoi.string().min(3).max(500).required(),
  });

export const ReportOfferSchema = () =>
  extendedJoi.object({
    reason: arrayValuesValidate(CAUSESIGNALEMENT)?.single().required(),
    message: extendedJoi.string().min(3).max(500).required(),
  });

export const UserMoreSchema = () =>
  extendedJoi.object({
    occupation: extendedJoi
      .string()
      .valid(...METIERSPARTICULIERS.map(object => object.value))
      ?.required(),
    nbPeopleHousehold: extendedJoi
      .number()
      .integer()
      .min(1)
      .max(MAX_NB_PEOPLE_HOUSEHOLD)
      .required(),
    userMorePeople: extendedJoi
      .array()
      .length(extendedJoi.ref("nbPeopleHousehold"))
      .items(
        extendedJoi.object({
          birthdate: extendedJoi.date().format("YYYY-MM-DD").max("now"),
        })
      )
      .required(),
    haveRv: extendedJoi.boolean().required(),
    haveRvType: extendedJoi.any().when("haveRv", {
      is: true,
      then: extendedJoi.string().min(1).required(),
      otherwise: extendedJoi.valid(""),
    }),
    purchaseRv: extendedJoi.boolean().required(),
    purchaseRvNew: extendedJoi.boolean().when("purchaseRv", {
      is: true,
      then: extendedJoi.boolean().required(),
      otherwise: extendedJoi.valid(false),
    }),
    purchaseRvOld: extendedJoi.boolean().when("purchaseRv", {
      is: true,
      then: extendedJoi.boolean().required(),
      otherwise: extendedJoi.valid(false),
    }),
    purchaseRvType: extendedJoi.any().when("purchaseRv", {
      is: true,
      then: extendedJoi.string().min(1).required(),
      otherwise: extendedJoi.valid(""),
    }),
    rvLocation: extendedJoi.boolean().required(),
    rvLocationType: extendedJoi.any().when("rvLocation", {
      is: true,
      then: extendedJoi.string().min(1).required(),
      otherwise: extendedJoi.valid(""),
    }),
    nbPeopleRv: extendedJoi
      .number()
      .integer()
      .min(1)
      .max(MAX_NB_PEOPLE_HOUSEHOLD),
    nbAdultsRv: extendedJoi
      .number()
      .integer()
      .min(1)
      .max(MAX_NB_PEOPLE_HOUSEHOLD),
    nbTeenagersRv: extendedJoi
      .number()
      .integer()
      .min(0)
      .max(MAX_NB_PEOPLE_HOUSEHOLD),
    nbChildrenRv: extendedJoi
      .number()
      .integer()
      .min(0)
      .max(MAX_NB_PEOPLE_HOUSEHOLD),
  });

// TODO many fields are not required in edition and others are missing
export const AjoutInfoConcession = () =>
  extendedJoi.object({
    companyName: extendedJoi.string().max(150).required(),
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
    phoneNumber: phoneNumberValidate(),
    // phoneHidden: extendedJoi
    //   .string()
    //   .valid(...yesOrNo.map(option => option.value))
    //   .required(),
    phoneHidden: extendedJoi.string().allow(null, ""),
    firstAddress: firstAddressValidate(),
    secondAddress: secondAddressValidate(),
    zipCode: zipCodeValidate(),
    city: nameValidate(),
    country: extendedJoi.number().integer().required(),
    vatNumber: extendedJoi.string().max(150).required(),
    triNumber: extendedJoi.string().allow("").max(150),
    emailAddress: emailAddressValidate(),
    language: extendedJoi.string().max(10).required(),
    termsOfUse: extendedJoi.boolean().valid(true).required(),
  });

export const EditionDetailsConcession = () =>
  extendedJoi.object({
    description: extendedJoi.string().min(0).max(5000),
    mondaySchedule: extendedJoi.string().max(150).required(),
    tuesdaySchedule: extendedJoi.string().max(150).required(),
    wednesdaySchedule: extendedJoi.string().max(150).required(),
    thursdaySchedule: extendedJoi.string().max(150).required(),
    fridaySchedule: extendedJoi.string().max(150).required(),
    saturdaySchedule: extendedJoi.string().max(150).required(),
    sundaySchedule: extendedJoi.string().max(150).required(),
    arrayDistributedBrandsIds: extendedJoi
      .array()
      .items(extendedJoi.number().integer())
      .min(1),
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
  });

export const ImagesConcession = () =>
  extendedJoi.object({
    civility: civilityValidate(),
    lastname: nameValidate(),
    firstname: nameValidate(),
    logo: extendedJoi.object().allow(null, ""),
    image: extendedJoi.object().allow(null, ""),
  });

export const SuppressionConcession = () =>
  extendedJoi.object({
    dealershipId: extendedJoi.number().integer(),
    reason: extendedJoi.string().min(0).max(5000),
  });

export const saveAdsResearch = () =>
  extendedJoi.object({
    name: extendedJoi.string().max(150).required(),
    search: extendedJoi.string().min(1).max(500000).required(),
  });

export const updateAdsResearch = () =>
  extendedJoi.object({
    name: extendedJoi.string().max(150).required(),
    search: extendedJoi.string().min(1).max(500000).required(),
    id: extendedJoi.number().integer(),
  });
