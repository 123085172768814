/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes StrapiApi
 * @description Fichier de constantes de routes pour l'API blog de Strapi.
 * Les routes sont à apeller dans la fonction strapiApi. En fonction de la route,
 * la fonction est capable de déterminer sous quelle forme d'objet on veut recevoir et envoyer les données.
 */

/**
 * @constant
 * @type {string}
 */
export const STRAPI_API_URI = process.env.STRAPI_API_URI;

/**
 * @constant
 * @type {string}
 */
export const BLOG_GLOBAL_SEO_ROUTE = `${STRAPI_API_URI}/global`;

/**
 * @constant
 * @type {string}
 */
export const BLOG_CATEGORIES_ROUTE = `${STRAPI_API_URI}/categories`;

/**
 * @constant
 * @type {string}
 */
export const BLOG_ARTICLES_ROUTE = `${STRAPI_API_URI}/seo`;

/**
 * @constant
 * @type {string}
 */
export const CLOUDINARY_API_URI = "res.cloudinary.com";

/**
 * @constant
 * @type {string}
 */
export const IMAGE_FALLBACK_URL = "/images/404__notfound.jpg";
