import { SWRConfig } from "swr";
import { SessionProvider } from "next-auth/react";
import { Provider as JotaiProvider } from "jotai";
import Head from "next/head";
import Script from "next/script";
import { GOOGLE_TAG_KEY } from "@/Constants/global";
import GlobalContextProvider from "@/Shared/GlobalContextProvider";
import TokensRefresher from "@/Shared/TokensRefresher";
import ReCaptchaProvider from "@/Shared/ReCaptchaProvider";
import React, { useState } from "react";
import { ONE_MINUTE } from "@/Constants/time";

/**
 * @function
 * @namespace Global
 * @description Wraps all providers most of the app components depend on. Created to simplify _app.js where all of
 * this was written but RecaptchaProvider added before in GlobalLayout (which should only handle the visual placing
 * of components), and bundling everything here to make it reusable in storybooks. It is a decorator (also called
 * wrapper when the code is for visual things): it extends the components behavior.
 * https://refactoring.guru/design-patterns/decorator
 * @todo Missing Seo component on many pages Maybe only the props related to blog and brands should be loaded right
 * away for SEO purposes. The rest might move out.
 * @param children
 * @param session
 * @return {JSX.Element}
 * @constructor
 */
export const GlobalWrapper = ({ children, session }) => {
  const swrRefreshInterval = ONE_MINUTE * 5;
  // https://next-auth.js.org/getting-started/client#refetch-interval
  const [intervalRefreshTokens, setIntervalRefreshTokens] = useState(0);

  return (
    <SWRConfig
      value={{
        refreshInterval: swrRefreshInterval,
      }}
    >
      <SessionProvider
        session={session}
        refetchInterval={intervalRefreshTokens}
      >
        <JotaiProvider>
          <Head>
            <meta charSet='UTF-8' />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1, shrink-to-fit=no'
            />
          </Head>
          <GlobalContextProvider>
            <TokensRefresher
              setInterval={setIntervalRefreshTokens}
              interval={intervalRefreshTokens}
            />
            {children}
          </GlobalContextProvider>
        </JotaiProvider>
      </SessionProvider>
    </SWRConfig>
  );
};

GlobalWrapper.GoogleTagScripts = function GlobalWrapperGoogleTagScripts() {
  return (
    <Script id={"googleTagLoadScript"} strategy='afterInteractive'>
      {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({
              'gtm.start': new Date().getTime(), 
              event:'gtm.js'
            });
            
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),
            dl=l!='dataLayer'?'&l='+l:'';
            
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_KEY}');
          
          window.dataLayer = window.dataLayer || []
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date())
          gtag('config', '${GOOGLE_TAG_KEY}', {
            page_path: window.location.pathname,
          });
        `}
    </Script>
  );
};

GlobalWrapper.GoogleTagLoadScript =
  function GlobalWrapperGoogleTagLoadScript() {
    return (
      <Script id={"googleTagLoadScript"} strategy='afterInteractive'>
        {`(function(w,d,s,l,i){
          w[l]=w[l]||[];w[l].push({
            'gtm.start': new Date().getTime(), 
            event:'gtm.js'
          });
          
          var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),
          dl=l!='dataLayer'?'&l='+l:'';
          
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GOOGLE_TAG_KEY}');`}
      </Script>
    );
  };

GlobalWrapper.GoogleTagConfigScript =
  function GlobalWrapperGoogleTagConfigScript() {
    return (
      <Script id={"googleTagConfigScript"} strategy='afterInteractive'>
        {`window.dataLayer = window.dataLayer || []
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date())
          gtag('config', '${GOOGLE_TAG_KEY}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    );
  };

GlobalWrapper.ReCaptchaProvider = function GlobalWrapperReCaptchaProvider({
  children,
}) {
  return <ReCaptchaProvider>{children}</ReCaptchaProvider>;
};

GlobalWrapper.NoScriptGoogleTagManager =
  function GlobalWrapperNoScriptGoogleTagManager() {
    return (
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe 
            src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_KEY}" 
            height="0" 
            width="0" 
            style="display: none; visibility: hidden;"
            ></iframe>`,
        }}
      ></noscript>
    );
  };
