import React from "react";

if (
  process?.env?.NEXT_PUBLIC_APP_ENV === "development" &&
  process.env.NEXT_PUBLIC_DEVELOPER !== "SEBASTIEN"
) {
  if (typeof window !== "undefined") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: false,
    });
  }
}
