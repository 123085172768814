import {
  serialize,
  serializePayloadPostDealershipNew,
} from "@/Utils/serializers/businessApiSerializers";
import { MY_CONCESSIONS_NEW_ROUTE } from "@/Constants/restApiRoutes";

/**
 * @function
 * @description formate les données à envoyer à l'API métier avec un POST, PUT ou PATCH et les stringify. Appelé par
 * la fonction businessApiRequest. Cette fonction doit être suffisamment intelligente pour sélectionner et executer la
 * fonction de serialization correspondant à l'urn et paramètres donnés à la fonction businessApiRequest.
 * @param urn
 * @param data
 * @returns {Promise<string>|string}
 */
export const serializeBusinessData = async (urn, data) => {
  let output;

  switch (urn) {
    case MY_CONCESSIONS_NEW_ROUTE:
      output = serializePayloadPostDealershipNew(data);
      break;
    default:
      output = serialize(data);
  }

  return output;
};
