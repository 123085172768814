import { useContext, useEffect, useState } from "react";
import DialogInfo from "@/Shared/Ui/DialogInfo";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { DIALOG_TYPE_CONFIRM, DIALOG_TYPE_INFO } from "@/Constants/global";
import DialogConfirmation from "@/Shared/Ui/DialogConfirmation";

const GlobalSuccessHandler = () => {
  const {
    state: { globalSuccess, globalSuccessProps },
    setGlobalSuccess,
    setGlobalSuccessProps,
  } = useContext(GlobalContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successDialog, setSuccessDialog] = useState(null);

  const getDialogAccordingToSuccess = (successMessage = "") => {
    const dialogType = DIALOG_TYPE_INFO;
    let element;

    // TODO successMessage is not used to define the fitting dialog type. I forgot using it

    const props = {
      open: true,
      objet: "Succès",
      message: successMessage,
      submit: "Fermer",
      handleClose: () => setIsDialogOpen(false),
    };

    if (dialogType === DIALOG_TYPE_INFO) element = <DialogInfo {...props} />;

    if (dialogType === DIALOG_TYPE_CONFIRM)
      element = <DialogConfirmation {...props} />;

    return element;
  };

  const getDialogAccordingToGlobalSuccessProps = props => {
    const dialogType = DIALOG_TYPE_INFO;
    let element;

    // TODO submit and close and deny props in Dialog need to be removed as they're replaced by textButtonClose and
    //  textButtonSubmit.
    props = {
      dialogType: props?.dialogType ?? DIALOG_TYPE_INFO,
      objet: props?.objet ?? "Succès",
      message: props?.message ?? "",
      textButtonSubmit: props.textButtonSubmit ?? props?.submit ?? null,
      textButtonClose: props.textButtonClose ?? props?.deny ?? null,
      isClosed: false,
      isSubmitted: false,
    };

    // TODO successMessage is not used to define the fitting dialog type. I forgot using it

    const handleClose = () => {
      setGlobalSuccessProps({ ...props, isClosed: true });
      setIsDialogOpen(false);
    };

    const handleSubmit = () => {
      setGlobalSuccessProps({ ...props, isSubmitted: true });
      setIsDialogOpen(false);
    };

    const dialogProps = {
      open: true,
      objet: props.objet,
      message: props.message,
      submit: props.submit,
      handleClose,
      handleSubmit,
      textButtonClose: props.textButtonClose,
      textButtonSubmit: props.textButtonSubmit,
    };

    if (props?.dialogType === DIALOG_TYPE_INFO)
      element = <DialogInfo {...dialogProps} />;

    if (props?.dialogType === DIALOG_TYPE_CONFIRM)
      element = <DialogConfirmation {...dialogProps} />;

    return element;
  };

  useEffect(() => {
    if (globalSuccess?.length) {
      const successMessage = String(globalSuccess).trim();

      const element = getDialogAccordingToSuccess(successMessage);

      if (element) {
        setSuccessDialog(element);
        setIsDialogOpen(true);
      }
    }
  }, [globalSuccess]);

  useEffect(() => {
    if (globalSuccessProps) {
      const element =
        getDialogAccordingToGlobalSuccessProps(globalSuccessProps);

      if (element) {
        setSuccessDialog(element);
        setIsDialogOpen(true);
      }
    }
  }, [globalSuccessProps]);

  useEffect(() => {
    if (!isDialogOpen && (globalSuccess?.length || globalSuccessProps)) {
      setGlobalSuccess("");
      setGlobalSuccessProps();
      setSuccessDialog();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    return () => {
      setIsDialogOpen(false);
      setSuccessDialog();
    };
  }, []);

  return <>{isDialogOpen && successDialog}</>;
};

export default GlobalSuccessHandler;
