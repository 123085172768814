import React from "react";
import PropTypes from "prop-types";
import css from "./Logo.module.css";

import Svg from "@/Shared/Svg";
import Link from "next/link";

const Logo = ({ situation, onClick }) => {
  let classSize = "",
    classLogoSituation;

  switch (situation) {
    case "header":
    default:
      classLogoSituation = "c-logo--header";
      classSize = "c-logo--sizeHeader";
      break;
    case "footer":
      classLogoSituation = "c-logo--footer";
      classSize = "c-logo--sizeFooter";
      break;
    case "inline-titre":
      classLogoSituation = "c-logo--inline-titre";
      classSize = "c-logo--inlinetitre";
      break;
  }

  return (
    <div
      className={`${css["c-logo"]} ${css[classLogoSituation]}`}
      data-print='flogo'
      onClick={onClick}
    >
      <Link href='/'>
        <a>
          <Svg selection='logo-inline' classNames={`${css[classSize]}`} />
        </a>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  situation: PropTypes.string,
};

export default Logo;
