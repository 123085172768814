import { notDigitRegex } from "@/Constants/regex";
import moment from "moment";
import { isObjectTruthy } from "@/Utils/index";
import { EMPTY_DATA } from "@/Constants/errors";

/**
 * @file utils/serializers/businessApiSerializers.
 * @description contient des fonctions mettant en forme de string JSON les données qui leurs sont fournies dans
 * le format attendu par l'API Métier.
 * @module Utils Serializers Business API
 * @namespace Code Central
 */

/**
 * @description Applique juste JSON.stringify. Pas sûr que ce soit utile.
 * @param object
 * @returns {string}
 */
export const serialize = object => JSON.stringify(object);

export const formatDateForRequest = date => moment(date).format("YYYY-MM-DD");

const parsePhoneNumber = phone => phone.replace(notDigitRegex, "");

export const serializePayloadPostUsersNew = data => {
  const {
    userType,
    civility: civ,
    lastname,
    firstname,
    phoneNumber: phone,
    emailAddress: email,
    userName,
    password: pwd,
    firstAddress: address1,
    secondAddress: address2,
    zipCode: postcode,
    city,
    birthday: birthdate,
    newsletter,
    language,
    phoneHidden,
    country,
    termsOfUse,
  } = data;

  const payload = {
    userType,
    lastname,
    firstname,
    phone: parsePhoneNumber(phone),
    email,
    phoneHidden,
    userName,
    pwd,
    address1,
    address2,
    postcode,
    city,
    birthdate: moment(Date.parse(birthdate)).format("YYYY-MM-DD"),
    newsletter,
    language,
    civ,
    country: `/public/api/countries/${country}`,
    rgpd: termsOfUse,
  };

  return serialize(payload);
};

export const serializePayloadPostLogin = data => {
  return serialize(data);
};

export const serializePayloadPostUserDmsNew = data => {
  const {
    companyName: denomination,
    civility: civ,
    lastname,
    firstname,
    phoneNumber: phone,
    phoneHidden,
    dealershipTotalNumber: nbConcess,
    emailAddress: email,
    // userName,
    password: pwd,
    firstAddress: address1,
    secondAddress: address2,
    zipCode: postcode,
    city,
    language,
    vatNumber,
    triNumber: tradeRegisterNumber,
    bankName,
    bankCode,
    accountNumber,
    key,
    iban,
    swift,
    country,
    termsOfUse,
  } = data;

  return serialize({
    denomination,
    civ,
    lastname,
    firstname,
    phone: parsePhoneNumber(phone),
    phoneHidden,
    nbConcess,
    email,
    // userName: '',
    pwd,
    address1,
    address2,
    postcode,
    city,
    language,
    vatNumber,
    tradeRegisterNumber,
    bankName,
    bankCode,
    accountNumber,
    key,
    iban,
    swift,
    country: `/public/api/countries/${country}`,
    rgpd: termsOfUse,
  });
};

export const serializePayloadMyOffer = data => {
  const {
    bedType,
    brand,
    carrier,
    chassisNumber,
    city,
    country,
    dinPower,
    energy,
    firstHand,
    fiscalPower,
    gearbox,
    grossWeight,
    heaterEnergy,
    heaterType,
    height,
    introduction,
    phoneHidden,
    length,
    mileage,
    millesime,
    name,
    popUpRoof,
    inclVatPrice,
    range,
    registeredSeats,
    shownName,
    sleepingSeats,
    width,
    zipCode,
    unladenWeight,
    releaseDate,
    game,
  } = data;

  const payload = {
    bedType: bedType || "",
    carrier: carrier ? `/public/api/carriers/${carrier}` : null,
    chassisNumber: chassisNumber || "",
    city: city || "",
    concours: game,
    country: country ? `/public/api/countries/${country}` : null,
    dinPower: dinPower || 0,
    energy: energy || "",
    firstHand,
    fiscalPower: fiscalPower || 0,
    gearbox: gearbox || "",
    grossWeight: grossWeight || 0,
    heating: heaterEnergy || "",
    heatingType: heaterType || "",
    inclVatPrice: inclVatPrice ?? null,
    height: height || 0,
    dealerWord: introduction || "",
    phoneHidden,
    length: length || 0,
    mileage: mileage || 0,
    millesime: Number(millesime) || 1980,
    model: {
      name: name || "",
      brand: brand ? `/public/api/brands/${brand}` : null,
      rangeVehicle: range ? `/public/api/ranges/${range}` : null,
    },
    popUpRoof,
    postcode: zipCode || "",
    range: range || "",
    registeredSeats: String(registeredSeats) || 0,
    shownName: shownName || "",
    sleepingSeats: String(sleepingSeats) || 0,
    width: width || 0,
    unladenWeight: unladenWeight || 0,
    releaseDate: formatDateForRequest(releaseDate) || null,
  };

  return serialize(payload);
};

export const serializePayloadNewVehicle = data => {
  const {
    bedType,
    brand,
    carrier,
    chassisNumber,
    dinPower,
    energy,
    firstHand,
    fiscalPower,
    gearbox,
    grossWeight,
    heaterEnergy,
    heaterType,
    height,
    length,
    mileage,
    millesime,
    name,
    popUpRoof,
    range,
    registeredSeats,
    releaseDate,
    sleepingSeats,
    unladenWeight,
    width,
  } = data;

  return serialize({
    bedType: bedType || "",
    carrier: carrier ? `/public/api/carriers/${carrier}` : null,
    chassisNumber: chassisNumber || "",
    dinPower: dinPower || 0,
    energy: energy || "",
    firstHand: firstHand === "yes",
    fiscalPower: fiscalPower || 0,
    gearbox: gearbox || "",
    grossWeight: grossWeight || 0,
    heating: heaterEnergy || "",
    heatingType: heaterType || "",
    height: height || 0,
    length: length || 0,
    mileage: mileage || 0,
    millesime: Number(millesime) || 1980,
    model: {
      name: name || "",
      brand: brand ? `/public/api/brands/${brand}` : null,
      rangeVehicle: range ? `/public/api/ranges/${range}` : null,
    },
    popUpRoof: popUpRoof === "yes",
    range: range || "",
    registeredSeats: String(registeredSeats) || 0,
    releaseDate: formatDateForRequest(releaseDate) || null,
    sleepingSeats: String(sleepingSeats) || 0,
    unladenWeight: unladenWeight || 0,
    width: width || 0,
  });
};

/**
 * @function
 * @description met en forme les données à envoyer à l'api métier sur l'endpoint PUT my/user/details
 * @param data
 * @param context
 * @returns {string}
 * @todo dé-commenter le champ phoneHidden du payload une fois que l'endpoint my/user/details
 * renvoie le champ phoneHidden
 */
export const serializePayloadPutMyDetailsUpdate = (data, context) => {
  if (!isObjectTruthy(data)) {
    throw new Error(EMPTY_DATA);
  }

  const {
    // companyName: denomination,
    civility: civ,
    lastname,
    firstname,
    phoneNumber: phone,
    phoneHidden,
    emailAddress: email,
    firstAddress: address1,
    secondAddress: address2,
    zipCode: postcode,
    city,
    language,
    birthday: birthdate,
    // vatNumber,
    // triNumber: tradeRegisterNumber,
    country,
  } = data;

  let payload = {
    civ,
    lastname,
    firstname,
    phone: parsePhoneNumber(phone),
    // phoneHidden,
    address1,
    address2,
    postcode,
    city,
    birthdate: moment(Date.parse(birthdate)).format("YYYY-MM-DD"),
    language,
    email,
    country: `/public/api/countries/${country}`,
  };

  if (context === "professionnel") {
    // payload = {
    //   ...payload,
    //   denomination : denomination.trim(''),
    //   vatnumber : vatNumber.replace(whiteSpaceRegex, '').trim(''),
    //   tradeRegisterNumber : tradeRegisterNumber.trim('')
    // }
    const payloadPro = {
      civ: payload.civ,
      lastname: payload.lastname,
      firstname: payload.firstname,
      country: payload.country,
      phone: payload.phone,
      language,
      phoneHidden,
    };

    payload = { ...payloadPro };
  }

  return serialize(payload);
};

export const serializePayloadPutMyPasswordUpdate = data => {
  if (!isObjectTruthy(data)) {
    throw new Error(EMPTY_DATA);
  }
  return serialize({
    email: data.emailAddress,
    username: data.userName,
    pwd: data.password,
    new_pwd: data.newPassword,
  });
};

export const serializePayloadMyPreferencesUpdate = data =>
  serialize({
    language: data.language,
    newsletter: data.newsletter === "yes",
  });

export const serializePayloadMyContact = data =>
  serialize({
    message: data?.message || null,
    userTo: data?.userTo ? `/api/dealers/${data?.userTo}` : null,
    offer: data?.offer ? `/api/offers/${data.offer}` : null,
  });

export const serializePayloadPostDealershipNew = data =>
  serialize({
    ...data,
    phone: parsePhoneNumber(data.phone),
    country: `/public/api/countries/${data.country}`,
    userName: data.denomination,
  });
