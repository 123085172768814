import {
  BRANDS_ROUTE,
  CARRIERS_ROUTE,
  COUNTRIES_ROUTE,
  FILTERS_BRANDS_ROUTE,
  FILTERS_MODELS_ROUTE,
  FILTERS_RANGES_ROUTE,
  FILTERS_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  MY_BANK_DATA_REQUEST_CURRENT_ROUTE,
  MY_BANK_DATA_UPDATE_ROUTE,
  MY_CONCESSIONS_ROUTE,
  MY_CONTACT_ROUTE,
  MY_DETAILS_UPDATE_ROUTE,
  MY_FAVORITES_ROUTE,
  MY_MORE_ROUTE,
  MY_OFFERS_ROUTE,
  MY_OFFERS_TEMP_ROUTE,
  MY_OFFER_IMAGES_ROUTE,
  MY_PASSWORD_UPDATE_ROUTE,
  MY_PREFERENCES_ROUTE,
  MY_ROUTE,
  MY_USER_DASHBOARD_ROUTE,
  MY_USER_DETAILS_ROUTE,
  MY_VEHICLES_NEW_ROUTE,
  MY_VEHICLES_ROUTE,
  NEWSLETTER_SUBSCRIBE_ROUTE,
  OFFERS_ROUTE,
  RANGES_ROUTE,
  TOKEN_REFRESH_ROUTE,
  USERS_ROUTE,
  USER_DMS_NEW_ROUTE,
  USER_PASSWORD_RESET_ROUTE,
  USER_PASSWORD_TOKEN_ROUTE,
} from "@/Constants/restApiRoutes";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "@/Utils/http/httpRequests";
import { isObjectTruthy, isTokenTruthy } from "@/Utils/index";
import {
  CODE_403,
  EMPTY_DATA,
  ERROR_DISTANT_SERVER,
  EXPIRED_JWT_TOKEN,
  MISSING_DATA,
  MISSING_ID,
  NO_ACCESSTOKEN_RECEIVED,
  NO_ACCESS_TOKEN_PROVIDED,
  NO_CREDENTIALS_RETURN,
  NO_RECAPTCHA_TOKEN,
  NO_REFRESH_TOKEN_PROVIDED,
  NO_REFRESH_TOKEN_RECEIVED,
  NO_TOKENS_RECEIVED,
  NO_URN,
  NO_USERTO,
  SESSION_EXPIRED,
  WRONG_CREDENTIALS,
} from "@/Constants/errors";
import {
  serialize,
  serializePayloadMyContact,
  serializePayloadMyOffer,
  serializePayloadMyPreferencesUpdate,
  serializePayloadNewVehicle,
  serializePayloadPostLogin,
  serializePayloadPostUserDmsNew,
  serializePayloadPostUsersNew,
  serializePayloadPutMyDetailsUpdate,
  serializePayloadPutMyPasswordUpdate,
} from "@/Utils/serializers/businessApiSerializers";
import {
  getArrayData,
  normalize,
  normalizeBrands,
  normalizeCarriers,
  normalizeCountries,
  normalizeModels,
  normalizeMy,
  normalizeMyBankDataRequestCurrent,
  normalizeMyDealerships,
  normalizeMyFavorites,
  normalizeMyOfferById,
  normalizeMyOffers,
  normalizeMyOffersTemp,
  normalizeMyPasswordUpdate,
  normalizeMyUserDashboard,
  normalizeMyUserDetails,
  normalizeMyVehiclesNew,
  normalizeOfferById,
  normalizeOffers,
  normalizeRanges,
  normalizeTokens,
} from "@/Utils/normalizers/businessApiNormalizers";
import { errorServerRegex } from "@/Constants/regex";
import { normalizeBusinessData } from "@/Utils/normalizers/normalizeBusinessData";
import { serializeBusinessData } from "@/Utils/serializers/serializeBusinessData";
import { isNumber } from "lodash";
import { MAX_HTTP_REQUEST_TIMEOUT } from "@/Constants/limits";

/**
 * @file businessApiRequests.js
 * @description contient les fonctions permettant de faire appel à l'API REST métier.
 * @module BusinessApiRequest
 */

/**
 * @function
 * @description Analyse les réponses retournées par l'API métier et personnalise ou non l'erreur à traiter sur
 * l'application frontend. Appelée dans la fonction businessApiRequest
 * @param response
 * @throws SESSION_EXPIRED|WRONG_CREDENTIALS|CODE_403|unknown
 */
export const checkErrorMessage = response => {
  if (
    response?.status === false ||
    (response?.code !== 200 && response?.message) ||
    response?.errors
  ) {
    let errorMessage = response?.message;

    if (response?.errors) {
      const errors = Object.values(response.errors)
        ?.map(error => error)
        ?.join("; ");

      if (errorMessage && errors) {
        errorMessage = `${errorMessage} : ${errors}`;
      }
    }

    let genericError = "";
    if (response?.code) {
      if (response.code === 401) {
        genericError = WRONG_CREDENTIALS;

        if (response.message === EXPIRED_JWT_TOKEN) {
          console.error(response.message);
          genericError = SESSION_EXPIRED;
        }
      }

      if (response.code === 403) {
        genericError = CODE_403;
      }

      if (errorServerRegex.test(String(response.code))) {
        genericError = ERROR_DISTANT_SERVER;
      }

      errorMessage = genericError || errorMessage;
    }

    if (errorMessage) {
      console.error(
        `Error found at checkErrorMessage. Code ${response.code}; ${errorMessage}`
      );
      throw new Error(errorMessage);
    }
  }
};

/**
 * @function
 * @description Retourne un string concaténant la route de la ressource, son identifiant et les paramètres de query.
 * Utilisée par la fonction businessApiRequest
 * @param urn
 * @param id
 * @param query
 * @returns {string}
 */
export const concatUri = (urn, { id, query }) => {
  let output = urn;

  if (id) output = `${output}/${id}`;
  if (query) output = `${output}${query}`;

  return output;
};

/**
 * @function
 * @description lance une erreur si aucun accessToken n'est fourni. Appelé dans la fonction businessApiRequest lorsqu'un
 * route est restreinte à un client possédant un JWT
 * @param accessToken
 * @throws NO_ACCESS_TOKEN_PROVIDED
 */
export const checkAccessToken = accessToken => {
  if (!isTokenTruthy(accessToken)) {
    throw new Error(NO_ACCESS_TOKEN_PROVIDED);
  }
};

/**
 * @function
 * @description lance une erreur si aucun token n'est fourni par un service captcha externe.
 * Appelé dans la fonction businessApiRequest lorsqu'une route est accessible à n'importe qui et qu'on veut la protéger
 * des attaques DDoS.
 * @param token
 * @throws NO_RECAPTCHA_TOKEN
 */
export const checkRecaptchaToken = token => {
  if (!token) {
    throw new Error(NO_RECAPTCHA_TOKEN);
  }
};

/**
 * @function
 * @description selectionne et retourne une callback exécutant une requête http en fonction des paramètres donnés.
 * Apellé par businessApiRequest
 * @param uri
 * @param httpMethod
 * @param options
 * @returns {Promise<*>}
 */
export const execHttpFunction = async (uri, httpMethod, options) => {
  const objectFunctions = {
    POST: async () => httpPost(uri, options),
    PUT: async () => httpPut(uri, options),
    DELETE: async () => httpDelete(uri, options),
    GET: async () => httpGet(uri, options),
  };

  return await objectFunctions[httpMethod]();
};

// ------------- Initial REST API requests (POST, GET, PATCH, PUT, DELETE) -------------

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param path
 * @param params
 * @param checkErrors
 * @returns {Promise<*>}
 */
export const restApiGet = async (path, params = {}, checkErrors = true) => {
  let { headers } = params;
  headers = { "Content-Type": "application/ld+json", ...headers };
  const response = await httpGet(path, { ...params, headers });
  checkErrors && checkErrorMessage(response);
  return response;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param path
 * @param params
 * @param checkErrors
 * @returns {Promise<*>}
 */
export const restApiPost = async (path, params = {}, checkErrors = true) => {
  let { headers } = params;
  headers = { "Content-Type": "application/ld+json", ...headers };
  const response = await httpPost(path, { ...params, headers });
  checkErrors && checkErrorMessage(response);
  return response;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param path
 * @param params
 * @param checkErrors
 * @returns {Promise<*>}
 */
export const restApiPut = async (path, params = {}, checkErrors = true) => {
  let { headers } = params;
  headers = { "Content-Type": "application/ld+json", ...headers };
  const response = await httpPut(path, { ...params, headers });
  checkErrors && checkErrorMessage(response);
  return response;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param path
 * @param params
 * @param checkErrors
 * @returns {Promise<*>}
 */
export const restApiDelete = async (path, params = {}, checkErrors = true) => {
  let { headers } = params;
  headers = { "Content-Type": "application/ld+json", ...headers };

  const response = await httpDelete(path, { ...params, headers });
  checkErrors && checkErrorMessage(response);
  return response;
};

/**
 * @function
 * @description Cette fonction éxecute une requête http auprès de l'API métier. Sur demande elle délègue sur demande la
 * normalisation des réponses, serialisation des données à envoyer, traitetement des erreurs retournées en réponse,
 * vérifie les JWT ou token recaptache
 * @param urn
 * @param params
 * @param options
 * @returns {Promise<Promise<object>|object|*>}
 * @todo remove all children functions of these ones to simplify the code and make it maintainable
 */
export const businessApiRequest = async (
  urn = null,
  params = {},
  options = {}
) => {
  if (!urn) {
    throw new Error(NO_URN);
  }

  params = {
    accessToken: params?.accessToken ?? null,
    body: params?.body ?? null,
    id: params?.id ?? null,
    httpMethod: params?.httpMethod ?? "GET",
    query: params?.query ?? null,
    contentType: params?.contentType ?? "application/ld+json",
    timeAbort: isNumber(options?.timeAbort)
      ? options.timeAbort
      : MAX_HTTP_REQUEST_TIMEOUT,
    ...params,
  };

  options = {
    isCheckingErrors: options?.isCheckingErrors ?? true,
    isToNormalize: options?.isToNormalize ?? true,
    isToSerialize:
      params?.body && params?.contentType !== "multipart/form-data"
        ? options?.isToSerialize ?? true
        : false,
  };

  const { accessToken, body, id, httpMethod, query, timeAbort, ...rest } =
    params;
  const { isCheckingErrors, isToNormalize, isToSerialize } = options;

  let headers = {};

  if (accessToken) {
    isCheckingErrors && checkAccessToken(accessToken);
    headers = { ...headers, authorization: `Bearer ${accessToken}` };
  }

  if (params?.contentType) {
    headers = { ...headers, "Content-Type": params.contentType };
  }

  const requestOptions = {
    headers,
    body: isToSerialize ? await serializeBusinessData(urn, body) : body,
    timeAbort,
    ...rest,
  };

  const uri = concatUri(urn, { id, query });

  const response = await execHttpFunction(uri, httpMethod, requestOptions);

  isCheckingErrors && checkErrorMessage(response);

  return isToNormalize && isCheckingErrors
    ? await normalizeBusinessData(urn, response, params)
    : response;
};

// ------------- GET requests -------------

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetFilters = async (
  query = "",
  options = { checkErrors: true, normalize: true }
) => {
  let output;
  const path = concatUri(FILTERS_ROUTE, { query });

  const response = await restApiGet(path, {}, options?.checkErrors);

  if (options?.normalize && options?.checkErrors) {
    if (response?.code === 200 && response?.content) {
      output = response.content;
    }
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetFiltersBrands = async (
  query = "",
  options = { checkErrors: true, normalize: true }
) => {
  let output;
  const path = concatUri(FILTERS_BRANDS_ROUTE, { query });

  output = await restApiGet(path, {}, options?.checkErrors);

  if (options?.normalize && options?.checkErrors) {
    output = normalizeBrands(getArrayData(output));
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetFiltersModels = async (
  query = "",
  options = { checkErrors: true, normalize: true }
) => {
  let output;
  const path = concatUri(FILTERS_MODELS_ROUTE, { query });

  output = await restApiGet(path, {}, options?.checkErrors);

  if (options?.normalize && options?.checkErrors) {
    output = normalizeModels(output);
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetFiltersRanges = async (
  query = "",
  options = { checkErrors: true, normalize: true }
) => {
  let output;
  const path = concatUri(FILTERS_RANGES_ROUTE, { query });

  output = await restApiGet(path, {}, options?.checkErrors);

  if (options?.normalize && options?.checkErrors) {
    output = getArrayData(output);
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @returns {Promise<any>}
 */
export const restApiGetBrands = async (query = "") => {
  const path = concatUri(BRANDS_ROUTE, { query });
  const response = await restApiGet(path);
  // if(!response['hydra:member']) throw new Error(NO_HYDRA_MEMBER)
  return normalizeBrands(getArrayData(response));
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param params
 * @param options
 * @returns {Promise<null>}
 */
export const restApiApiGetMy = async (
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  let output;
  checkAccessToken(params?.accessToken);

  output = await restApiGet(MY_ROUTE, {
    headers: {
      Authorization: `Bearer ${params?.accessToken}`,
    },
  });

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMy(output, params?.accessToken);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param accessToken
 * @returns {Promise<{zipCode: *, country: * | undefined, newsletter: string, firstname: *, civility: *, city: *, secondAddress: *, language: *, userName: *, lastname: *, emailAddress: *, phoneNumber: *, userMore: *, id: *|undefined, firstAddress: *, userType: *}>}
 */
export const restApiApiGetMyUserDetails = async ({ accessToken = "" }) => {
  checkAccessToken(accessToken);

  const response = await restApiGet(MY_USER_DETAILS_ROUTE, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return normalizeMyUserDetails(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiApiGetMyUserDashboard = async (
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  checkAccessToken(params?.accessToken);

  const response = await restApiGet(MY_USER_DASHBOARD_ROUTE, {
    headers: {
      Authorization: `Bearer ${params?.accessToken}`,
    },
  });

  let output = response;
  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyUserDashboard(response);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @returns {Promise<*>}
 */
export const restApiGetCountries = async (query = "") => {
  const path = concatUri(COUNTRIES_ROUTE, { query });
  const response = await restApiGet(path);

  return normalizeCountries(getArrayData(response));
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param accessToken
 * @param query
 * @returns {Promise<*>}
 */
export const restApiGetMyBankDataRequestCurrent = async (
  { accessToken = "" },
  query = ""
) => {
  const path = concatUri(MY_BANK_DATA_REQUEST_CURRENT_ROUTE, { query });

  checkAccessToken(accessToken);

  const response = await restApiGet(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return normalizeMyBankDataRequestCurrent(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @returns {Promise<any>}
 */
export const restApiGetRanges = async (query = "") => {
  const path = concatUri(RANGES_ROUTE, { query });
  const response = await restApiGet(path);
  // if(!response['hydra:member']) throw new Error(NO_HYDRA_MEMBER)
  return normalizeRanges(getArrayData(response));
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @returns {Promise<any>}
 */
export const restApiGetCarriers = async (query = "") => {
  const path = concatUri(CARRIERS_ROUTE, { query });
  const response = await restApiGet(path);
  return normalizeCarriers(getArrayData(response));
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetOfferById = async (
  id = "",
  options = { checkErrors: true, normalize: true }
) => {
  let output;

  if (!id) throw new Error(MISSING_ID);

  const path = concatUri(OFFERS_ROUTE, { id });
  output = await restApiGet(path, {}, options?.checkErrors);

  if (options?.checkErrors && options?.normalize) {
    output = normalizeOfferById(output);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetOffers = async (
  query = null,
  options = { checkErrors: true, normalize: true }
) => {
  let output;

  const path = concatUri(OFFERS_ROUTE, { query });
  output = await restApiGet(path, {}, options?.checkErrors);
  if (options?.checkErrors && options?.normalize) {
    output = normalizeOffers(output);
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetMyOffers = async (
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  options = {
    checkErrors: options?.checkErrors ?? true,
    normalize: options?.normalize ?? true,
  };

  checkAccessToken(params?.accessToken);

  let output = await restApiGet(
    MY_OFFERS_ROUTE,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyOffers(output);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param query
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetMyFavorites = async (
  query = "",
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  checkAccessToken(params?.accessToken);

  let output;

  const path = concatUri(MY_FAVORITES_ROUTE, { query });
  output = await restApiGet(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyFavorites(output);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetMyOffersById = async (
  id = "",
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  if (!id) throw new Error(MISSING_ID);
  checkAccessToken(params?.accessToken);

  let output;

  const path = concatUri(MY_OFFERS_ROUTE, { id });
  output = await restApiGet(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyOfferById(output);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetMyOffersTemp = async (
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  checkAccessToken(params?.accessToken);

  let output = await restApiGet(
    MY_OFFERS_TEMP_ROUTE,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyOffersTemp(output, { isWithCategories: false });
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiApiGetMyDealerships = async (
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  let output = null;
  checkAccessToken(params?.accessToken);

  output = await restApiGet(MY_CONCESSIONS_ROUTE, {
    headers: {
      Authorization: `Bearer ${params?.accessToken}`,
    },
  });

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyDealerships(output);
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetMyDealershipsByIdOffers = async (
  id = "",
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  let output;

  if (!id) throw new Error(MISSING_ID);

  checkAccessToken(params?.accessToken);

  let path = concatUri(MY_CONCESSIONS_ROUTE, { id });
  path = `${path}/offers`;

  output = await restApiGet(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyOffers(output, { isWithCategories: false });
  }

  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiGetMyDealershipsByIdOfferstemp = async (
  id = "",
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  let output;

  if (!id) throw new Error(MISSING_ID);
  checkAccessToken(params?.accessToken);

  let path = concatUri(MY_CONCESSIONS_ROUTE, { id });
  path = `${path}/offerstemp`;

  output = await restApiGet(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalizeMyOffersTemp(output, { isWithCategories: false });
  }

  return output;
};

// ------------- POST requests -------------

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param data
 * @returns {Promise<*>}
 */
export const restApiPostUsersNew = async data => {
  if (!isObjectTruthy(data)) {
    throw new Error(EMPTY_DATA);
  }
  const payload = serializePayloadPostUsersNew(data);
  return await restApiPost(`${USERS_ROUTE}/new`, { body: payload });
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param data
 * @returns {Promise<{accessToken: *, refreshToken: *}>}
 */
export const restApiPostLogin = async data => {
  let error;

  if (!isObjectTruthy(data)) {
    throw new Error(EMPTY_DATA);
  }

  const payload = serializePayloadPostLogin(data);
  const response = await restApiPost(`${LOGIN_ROUTE}`, { body: payload });

  if (!response.token) {
    error = NO_ACCESSTOKEN_RECEIVED;
  }

  if (!response["refresh_token"]) {
    error = error?.length ? NO_TOKENS_RECEIVED : NO_REFRESH_TOKEN_RECEIVED;
  }

  if (error) {
    throw new Error(error);
  }

  return normalizeTokens(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param accessToken
 * @returns {Promise<*>}
 */
export const restApiPostLogout = async accessToken => {
  checkAccessToken(accessToken);

  return await restApiPost(LOGOUT_ROUTE, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// TODO use this function as an example for all the other ones
/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param data
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPostOfferByIdReport = async (
  data,
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  if (typeof data?.id !== "number") {
    throw new Error(MISSING_ID);
  }

  checkAccessToken(params?.accessToken);
  const path = `${concatUri(OFFERS_ROUTE, { id: data?.id })}/report`;

  const payload = serialize({
    reason: data?.reason,
    message: data?.message,
  });

  let output = await restApiPost(
    path,
    {
      headers: { Authorization: `Bearer ${params?.accessToken}` },
      body: payload,
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalize(output);
  }

  return output;
};

export const restApiPostUsersDmsNew = async data => {
  if (!isObjectTruthy(data)) {
    throw new Error(EMPTY_DATA);
  }

  const payload = serializePayloadPostUserDmsNew(data);
  return await restApiPost(USER_DMS_NEW_ROUTE, { body: payload });
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param params
 * @returns {Promise<{accessToken: *, refreshToken: *}>}
 */
export const restApiPostTokenRefresh = async (
  params = { accessToken: "", refreshToken: "" }
) => {
  if (!params?.accessToken?.length) {
    throw new Error(NO_ACCESS_TOKEN_PROVIDED);
  }

  if (!params?.refreshToken?.length) {
    throw new Error(NO_REFRESH_TOKEN_PROVIDED);
  }

  let payload = {
    refresh_token: params.refreshToken,
  };

  payload = serialize(payload);

  const response = await restApiPost(TOKEN_REFRESH_ROUTE, {
    headers: {
      Authorization: `Bearer ${params.accessToken}`,
    },
    body: payload,
  });

  if (!(response.token && response["refresh_token"])) {
    throw new Error(NO_CREDENTIALS_RETURN);
  }

  return normalizeTokens(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param email
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPostUserPasswordToken = async (
  email = "",
  options = { checkErrors: true }
) => {
  if (!email.length) {
    throw new Error(EMPTY_DATA);
  }

  let payload = { email };

  payload = serialize(payload);

  return await restApiPost(
    USER_PASSWORD_TOKEN_ROUTE,
    {
      body: payload,
    },
    options?.checkErrors
  );
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param data
 * @param params
 * @returns {Promise<*>}
 */
export const restApiPostMyBankDataUpdate = async (
  data = {},
  params = { accessToken: "" }
) => {
  checkAccessToken(params?.accessToken);

  let payload = {
    bankName: data.bankName,
    bankCode: data.bankCode,
    accountNumber: data.accountNumber,
    key: data.key,
    iban: data.iban,
    swift: data.swift,
  };

  payload = serialize(payload);

  return await restApiPost(MY_BANK_DATA_UPDATE_ROUTE, {
    headers: {
      Authorization: `Bearer ${params.accessToken}`,
    },
    body: payload,
  });
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @returns {Promise<*>}
 */
export const restApiPostMyVehiclesNew = async (
  payload = {},
  params = { accessToken: "" }
) => {
  checkAccessToken(params?.accessToken);

  if (!isObjectTruthy(payload)) {
    throw new Error(EMPTY_DATA);
  }

  const response = await restApiPost(MY_VEHICLES_NEW_ROUTE, {
    headers: { Authorization: `Bearer ${params.accessToken}` },
    body: serializePayloadNewVehicle(payload),
  });

  return normalizeMyVehiclesNew(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @returns {Promise<any>}
 */
export const restApiPostMyOffersImagesById = async (
  payload,
  params = { accessToken: "", id: "" }
) => {
  if (typeof params.id !== "number") {
    throw new Error(MISSING_ID);
  }

  checkAccessToken(params?.accessToken);

  if (!isObjectTruthy(payload)) {
    throw new Error(EMPTY_DATA);
  }

  const query = `/${params.id}/images`;

  const path = concatUri(MY_OFFERS_ROUTE, { query });

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${params.accessToken}`,
  };

  const response = await restApiPost(path, {
    headers,
    body: payload,
  });

  return normalizeMyOfferById(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPostNewsletterSubscribe = async (
  payload = {},
  params = { reCaptchaToken: "" },
  options = {
    checkErrors: true,
  }
) => {
  checkRecaptchaToken(params?.reCaptchaToken);

  if (!isObjectTruthy(payload)) {
    throw new Error(EMPTY_DATA);
  }

  return await restApiPost(
    NEWSLETTER_SUBSCRIBE_ROUTE,
    {
      body: serialize(payload),
    },
    options?.checkErrors
  );
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param data
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPostOfferPhoneLeadWithId = async (
  data,
  options = { checkErrors: true, normalize: false }
) => {
  if (typeof data?.id !== "number") {
    throw new Error(MISSING_ID);
  }

  const path = `${concatUri(OFFERS_ROUTE, { id: data?.id })}/phone`;

  const payload = serialize({ id: data?.id });

  let output = await restApiPost(
    path,
    {
      body: payload,
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalize(output);
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPostMyContact = async (
  payload = {},
  params = { accessToken: "" },
  options = {
    checkErrors: true,
    normalize: true,
  }
) => {
  if (!isObjectTruthy(payload)) {
    throw new Error(EMPTY_DATA);
  }

  if (!payload?.userTo) {
    throw new Error(NO_USERTO);
  }

  checkAccessToken(params?.accessToken);

  const response = await restApiPost(
    MY_CONTACT_ROUTE,
    {
      headers: {
        Authorization: `Bearer ${params?.accessToken}`,
      },
      body: serializePayloadMyContact(payload),
    },
    options?.checkErrors
  );

  let output = response;
  if (options?.normalize && options?.checkErrors) {
    output = response;
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPostMyMoreUpdate = async (
  payload = {},
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  let output = null;
  checkAccessToken(params?.accessToken);

  if (!isObjectTruthy(payload)) {
    throw new Error(MISSING_DATA);
  }

  output = await restApiPost(
    `${MY_MORE_ROUTE}/update`,
    {
      headers: {
        Authorization: `Bearer ${params?.accessToken}`,
      },
      body: serialize(payload),
    },
    options?.checkErrors
  );

  if (options?.checkErrors && options?.normalize) {
    output = normalize(output);
  }

  return output;
};

// ------------- PUT requests -------------

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @returns {Promise<*>}
 */
export const restApiPutMyPasswordUpdate = async (
  payload,
  params = { accessToken: "" }
) => {
  checkAccessToken(params?.accessToken);

  payload = serializePayloadPutMyPasswordUpdate(payload);

  const response = await restApiPut(MY_PASSWORD_UPDATE_ROUTE, {
    body: payload,
    headers: {
      Authorization: `Bearer ${params?.accessToken}`,
    },
  });

  return normalizeMyPasswordUpdate(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @returns {Promise<{zipCode: *, country: * | undefined, newsletter: string, firstname: *, civility: *, city: *, secondAddress: *, language: *, userName: *, lastname: *, emailAddress: *, phoneNumber: *, userMore: *, id: *|undefined, firstAddress: *, userType: *}>}
 */
export const restApiPutMyDetailsUpdate = async (
  payload,
  params = { accessToken: "", userType: "" }
) => {
  checkAccessToken(params?.accessToken);

  payload = serializePayloadPutMyDetailsUpdate(payload, params?.userType);

  const response = await restApiPut(MY_DETAILS_UPDATE_ROUTE, {
    mode: "cors",
    headers: {
      Authorization: `Bearer ${params?.accessToken}`,
    },
    body: payload,
  });

  return normalizeMyUserDetails(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPutUserPasswordReset = async (
  payload = { pwd: "", token: "" },
  options = { checkErrors: true }
) => {
  checkAccessToken(payload.token);

  if (!payload.pwd.length) {
    throw new Error(`${EMPTY_DATA} for password`);
  }

  payload = serialize(payload);

  return await restApiPut(
    USER_PASSWORD_RESET_ROUTE,
    {
      body: payload,
    },
    options?.checkErrors
  );
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param payload
 * @param params
 * @returns {Promise<*>}
 */
export const restApiPutMyVehiclesNew = async (
  id = null,
  payload = {},
  params = { accessToken: "" }
) => {
  if (typeof id !== "number") {
    throw new Error(MISSING_ID);
  }

  if (!isObjectTruthy(payload)) {
    throw new Error(EMPTY_DATA);
  }

  checkAccessToken(params?.accessToken);

  const path = concatUri(MY_VEHICLES_ROUTE, { id });

  return await restApiPut(path, {
    headers: {
      Authorization: `Bearer ${params.accessToken}`,
    },
    body: serializePayloadMyOffer(payload),
  });
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @returns {Promise<any>}
 */
export const restApiPutMyOfferById = async (
  payload = {},
  params = { accessToken: "", id: "" }
) => {
  const { id, accessToken } = params;
  if (typeof id !== "number") {
    throw new Error(MISSING_ID);
  }

  checkAccessToken(accessToken);

  if (!isObjectTruthy(payload)) {
    throw new Error(MISSING_DATA);
  }

  payload = serializePayloadMyOffer(payload);
  const path = concatUri(MY_OFFERS_ROUTE, { id });

  const response = await restApiPut(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: payload,
  });

  return normalizeMyOfferById(response);
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPutMyOfferByIdFavoritesAdd = async (
  id = null,
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  if (typeof id !== "number") {
    throw new Error(MISSING_ID);
  }

  checkAccessToken(params?.accessToken);

  const path = `${concatUri(MY_OFFERS_ROUTE, { id })}/favorites/add`;

  const response = await restApiPut(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
      body: "{}",
    },
    options?.checkErrors
  );

  let output = response;
  if (options?.normalize && options?.checkErrors) {
    output = response;
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPutMyOfferByIdFavoritesRemove = async (
  id = null,
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  if (typeof id !== "number") {
    throw new Error(MISSING_ID);
  }

  checkAccessToken(params?.accessToken);

  const path = `${concatUri(MY_OFFERS_ROUTE, { id })}/favorites/remove`;

  const response = await restApiPut(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
      body: "{}",
    },
    options?.checkErrors
  );

  let output = response;
  if (options?.normalize && options?.checkErrors) {
    output = response;
  }
  return output;
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param payload
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiPutMyPreferencesUpdate = async (
  payload = {},
  params = { accessToken: "" },
  options = { checkErrors: true, normalize: true }
) => {
  let output = null;
  checkAccessToken(params?.accessToken);

  if (!isObjectTruthy(payload)) {
    throw new Error(MISSING_DATA);
  }

  output = await restApiPut(
    `${MY_PREFERENCES_ROUTE}/update`,
    {
      headers: {
        Authorization: `Bearer ${params?.accessToken}`,
      },
      body: serializePayloadMyPreferencesUpdate(payload),
    },
    options?.checkErrors
  );

  if (options?.normalize) {
    output = normalizeMyUserDetails(output);
  }

  return output;
};

// ------------- DELETE requests -------------

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param data
 * @param params
 * @returns {Promise<*>}
 */
export const restApiDeleteMyOfferById = async (
  data,
  params = { accessToken: "" }
) => {
  checkAccessToken(params?.accessToken);

  if (typeof data.id !== "number") {
    throw new Error(MISSING_ID);
  }

  const path = concatUri(MY_OFFERS_ROUTE, { id: data.id });

  return await restApiDelete(path, {
    headers: {
      Authorization: `Bearer ${params.accessToken}`,
    },
    body: serialize({
      soldFrom: data.soldFrom,
      way: data.way,
    }),
  });
};

/**
 * @function
 * @deprecated utiliser businessApiRequest et l'étendre au lieu d'utiliser cette fonction
 * @param id
 * @param params
 * @param options
 * @returns {Promise<*>}
 */
export const restApiDeleteMyOfferImageById = async (
  id,
  params = { accessToken: "" },
  options = { checkErrors: true }
) => {
  checkAccessToken(params?.accessToken);

  if (typeof id !== "number") {
    throw new Error(MISSING_ID);
  }

  const path = concatUri(MY_OFFER_IMAGES_ROUTE, { id });

  return await restApiDelete(
    path,
    {
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
      },
    },
    options?.checkErrors
  );
};
