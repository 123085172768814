import { IMAGE_FALLBACK_URL } from "@/Constants/strapiApi";
import { normalize } from "@/Utils/http/httpRequests";
import { DateTime } from "luxon";
import { orderBy } from "lodash";

// Used in _app.js Accueil
export const normalizeAppProps = categories => {
  const [categoriesInfos, categoryAnnexes] = normalizeCategoriesApp(categories);
  return { categoriesInfos, categoryAnnexes };
};

// Used in index.js Accueil
export const normalizeAccueilProps = articles => {
  const LIMIT = 6;
  if (articles && articles.length) {
    articles = normalizeArticlesNoAnnexes(articles).splice(0, LIMIT);
  }
  return normalize(articles);
};

// Used in Blog/index.js to normalize its data
export const normalizeBlogProps = categories => {
  if (categories && categories.length) {
    [categories] = normalizeCategories(categories);
  }
  return normalize(categories);
};

// Used in Blog/[category]/index.js to normalize data
export const normalizeCategoryProps = (categories, categorySlug) => {
  let category;
  [categories] = normalizeCategories(categories);

  for (const data of categories) {
    if (data.slug === categorySlug) {
      category = data;
      break;
    }
  }

  return [
    normalize(category),
    normalize(category.articles),
    normalize(categories),
  ];
};

// Used in Blog/[category]/[article].js to normalize data
export const normalizeArticleProps = (
  articles,
  articleSlug,
  categories,
  categorySlug
) => {
  const LIMIT = 3;

  articles = normalizeArticles(articles);

  categories = categories.map(category => {
    category.articles = normalizeArticles(category.articles).slice(0, LIMIT);
    return category;
  });

  const [article, relatedArticles] = parseArticles(articles, articleSlug);

  const category = filterCategory(categories, categorySlug);

  if (article.image) article.image.src = getImageSrc(article.image.url);

  return [normalize(article), normalize(category), normalize(relatedArticles)];
};

// returns categories in a given format and cast out category with slug annexes
const normalizeCategories = categories => {
  let sortedCategories;
  let sortedCategoryAnnexes;

  if (categories) {
    categories = categories.map(category => {
      category.image = {
        ...category.image?.formats.medium,
        alt: category.image?.alternativeText,
        src: getImageSrc(category.image?.url),
      };

      category.articles = normalizeArticles(category.articles);

      return category;
    });

    const [freshSortedCategories, freshSortedCategoryAnnexes] =
      sortCategories(categories);
    sortedCategories = freshSortedCategories;
    sortedCategoryAnnexes = freshSortedCategoryAnnexes;
  }
  return [sortedCategories, sortedCategoryAnnexes];
};

const normalizeCategoriesApp = categories => {
  let normalizedCategories = [];
  let normalizedCategoryAnnexes = {};

  if (categories?.length) {
    [normalizedCategories, normalizedCategoryAnnexes] =
      normalizeCategories(categories);

    normalizedCategories = normalizedCategories?.map(category => {
      const articles = category.articles.map(article => {
        return { title: article.title, slug: article.slug };
      });
      return { name: category.name, slug: category.slug, articles };
    });

    if (normalizedCategoryAnnexes)
      normalizedCategoryAnnexes.articles =
        normalizedCategoryAnnexes?.articles.map(article => {
          return { title: article.title, slug: article.slug };
        });

    normalizedCategoryAnnexes = {
      slug: normalizedCategoryAnnexes?.slug ?? "",
      articles: normalizedCategoryAnnexes?.articles ?? [],
    };
  }

  return [normalizedCategories, normalizedCategoryAnnexes];
};

const sortCategories = categories => {
  let categoryAnnexes = null;

  categories = categories.filter(category => {
    if (category.slug !== "annexes") {
      return true;
    }
    categoryAnnexes = category;
    return false;
  });
  return [categories, categoryAnnexes];
};

// To normalize array of articles
const normalizeArticles = articles => {
  if (articles && articles.length) {
    articles = getArticlesToPublish(articles);
    articles = articles?.map(article => {
      return normalizeArticle(article);
    });
    articles = sortArticlesByDate(articles);
  }
  return articles;
};

// To normalize array of articles
const normalizeArticlesNoAnnexes = articles => {
  if (articles && articles.length) {
    articles = getArticlesToPublish(articles);
    articles = articles
      ?.filter(article => article.category?.slug !== "annexes")
      .map(article => {
        return normalizeArticle(article);
      });
    articles = sortArticlesByDate(articles);
  }
  return articles;
};

// To normalize a single article
const normalizeArticle = article => {
  try {
    if (article.body[0] === undefined) {
      new Error(
        `Article of id ${article.id} and slug ${article.slug} has empty body`
      );
    }
  } catch (error) {
    if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);

    article.type = "text";
    article.body[0] = {
      __component: "sections.content",
      content: "This article has no content",
    };
  }

  const videoRegex = /\bvideo\b/;

  article.date = new Date(article.updated_at);

  article.body = parseArticleBody(article);

  if (!videoRegex.test(article.body[0]?.__component)) {
    article.type = "text";
    return article;
  }

  if (videoRegex.test(article.body[0]?.__component)) {
    article.type = "video";
  }

  return article;
};

// Return the category whom a single article belongs
const filterCategory = (categories, slug) => {
  categories = categories.filter(category => slug === category.slug);
  return categories[0];
};

// Return the article matching the given slug and an array of related articles
const parseArticles = (articles, slug) => {
  let article = null;
  const relatedArticles = [];

  articles = getArticlesToPublish(articles);
  for (const data of articles) {
    if (slug === data.slug) {
      article = data;
    }
    relatedArticles.push(data);
  }

  return [article, relatedArticles];
};

// Return array of articles that can be published
const getArticlesToPublish = articles => {
  articles = articles.filter(article => getIsArticleToPublish(article));
  articles = articles.filter(article => !!article);
  return articles;
};

export const getIsArticleToPublish = article => {
  let isArticleToPublish = false;
  const currentDate = DateTime.now().toUnixInteger();

  let isMatchingStartDatePublication = true;
  let isMatchingEndDatePublication = true;

  if (article?.startPublication) {
    const dateStartPublication = DateTime.fromFormat(
      article.startPublication,
      "yyyy-MM-dd"
    ).toUnixInteger();
    isMatchingStartDatePublication = dateStartPublication <= currentDate;
  }

  if (article?.endPublication) {
    const dateEndPublication = DateTime.fromISO(
      article.endPublication
    ).toUnixInteger();
    isMatchingEndDatePublication = dateEndPublication >= currentDate;
  }

  isArticleToPublish =
    isMatchingStartDatePublication &&
    isMatchingEndDatePublication &&
    article.public;

  // if(!isArticleToPublish) {
  //   console.table([
  //     ['slug', article.slug],
  //     ['startPublication', article.startPublication],
  //     ['endPublication', article.endPublication],
  //     ['currentDate', currentDate]
  //   ])
  //   console.info('The above article is not published. See concerned articles above')
  // }

  return isArticleToPublish;
};

// sort articles by date, starting from most recent
export const sortArticlesByDate = articles =>
  orderBy(articles, ["created_at"], ["desc"]);

// return a complete link to fetch for an image or fallback image
const getImageSrc = (url, fallbackUrl = IMAGE_FALLBACK_URL) => {
  if (!url) return fallbackUrl;

  return url;
};

// Transform the structure of the sections in an article body
const parseArticleBody = article => {
  const imageFormats = [".jpg", ".jpeg", ".png", ".webp"];

  return article.body?.map(section => {
    if (
      section.__component === "sections.diaporama" ||
      section.__component === "sections.mosaic"
    ) {
      section.images = section.images.map(image => {
        if (imageFormats.includes(image.formats?.medium?.ext)) {
          image = {
            ...image?.formats.medium,
            alt: image.alternativeText || image?.name,
            src: getImageSrc(image.url),
          };
        }

        if (
          !imageFormats.includes(image.formats?.medium?.ext) &&
          imageFormats.includes(image.formats?.thumbnail?.ext)
        ) {
          image = {
            ...image?.formats.thumbnail,
            alt: image.alternativeText || image?.name,
            src: getImageSrc(image.url),
          };
        }

        return image;
      });
      return section;
    }

    if (section.__component === "sections.image") {
      try {
        if (imageFormats.includes(section.image?.formats?.medium.ext)) {
          section.image = {
            ...section.image?.formats.medium,
            alt: section.image?.alternativeText || section?.image?.name,
            src: getImageSrc(section.image?.url),
          };
          return section;
        }
      } catch (error) {
        console.error(`Error: images of article id ${article.id} of slug ${article.slug} 
        in section ${section.__component} of id ${section.id} should be in formats .jpg, .jpeg, .png or .webp`);
      }
    }

    if (section.__component === "sections.self-hosted-video") {
      section.__component = "sections.video";
      if (section.video) {
        section.video.src = section.video.url;
        return section;
      }

      // TODO something weird with the above condition
      section = {
        ...section,
        video: { src: null },
      };
      return section;
    }

    if (section.__component === "sections.external-video") {
      section.__component = "sections.video";
      section.video = {};
      section.video.src = `${section.link}`;
      return section;
    }

    if (section.__component === "sections.text-image") {
      try {
        if (imageFormats.includes(section.image?.formats?.medium.ext)) {
          section.image = {
            ...section.image?.formats.medium,
            alt: section.image?.alternativeText || section.image?.name,
            src: getImageSrc(section.image?.url),
          };
          return section;
        }
      } catch (error) {
        console.error(`Error: images of article id ${article.id} of slug ${article.slug} 
        in section ${section.__component} of id ${section.id} should be in formats .jpg, .jpeg, .png or .webp`);
      }
    }

    return section;
  });
};
