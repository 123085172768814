/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes Limits
 * @description Fichier de constantes de nombres de limites en nombre.
 */

/**
 * @constant
 * @type {number}
 */
export const MAX_NB_100 = 100;

/**
 * @constant
 * @type {number}
 */
export const MIN_NB_0 = 0;

/**
 * @constant
 * @type {number}
 */
export const MAX_NB_PEOPLE_HOUSEHOLD = MAX_NB_100;

/**
 * @constant
 * @type {number}
 */
export const MAX_NB_PEOPLE_RV = MAX_NB_100;

/**
 * @constant
 * @type {number}
 */
export const MAX_NB_ADULTS_RV = MAX_NB_100;

/**
 * @constant
 * @type {number}
 */
export const MAX_NB_TEENS_RV = MAX_NB_100;

/**
 * @constant
 * @type {number}
 */
export const MAX_NB_CHILDREN_RV = MAX_NB_100;

/**
 * @constant
 * @type {number}
 */
export const MAX_WIDTH_LOGO_DEALERHIP = 400;

/**
 * @constant
 * @type {number}
 */
export const MAX_WIDTH_COVER_DEALERHIP = 500;

/**
 * @constant
 * @type {{min: (function(*): number), max: (function(*): number)}}
 */
export const ROUND_LIMITS_FUNCTIONS = {
  min: value => Math.floor(value),
  max: value => Math.ceil(value),
};

/**
 * @constant
 * @type {number}
 */
export const MAX_HTTP_REQUEST_TIMEOUT = 60000;
