import { GTAG_EVENTS } from "@/Constants/googleEvents";
import { ERROR_UNKNOWN_GTAG_EVENT } from "@/Constants/errors";

/**
 * @function
 * @description envoie les évènements google tag sur les serveurs google lorsque
 * NEXT_PUBLIC_IS_GOOGLE_TAG_ACTIVE (donc en env de production)
 * @param eventLabel
 * @returns {event}
 */
export const emitGoogleTagEvent = eventLabel => {
  const event = GTAG_EVENTS[eventLabel];

  if (!event) {
    throw new Error(
      `${ERROR_UNKNOWN_GTAG_EVENT} for event name "${eventLabel}"`
    );
  }

  try {
    const { action, eventCategory, eventLabel, value } = event;

    window.gtag("event", action, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: value,
    });
  } catch (error) {
    console.error(error);
  }

  return event;
};
