import {
  dealershipCoverImageFallbackUrl,
  dealershipLogoFallbackUrl,
} from "@/Constants/fallbackUrls";

/**
 * @description Fichier de constantes de codes d'informations neutres. Devrait être fusionné avec constants/codes.js
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes Global
 */

/**
 * @constant
 * @type {string}
 */
export const USER_TYPE_PARTICULIER = "particulier";

/**
 * @constant
 * @type {string}
 */
export const USER_TYPE_PROFESSIONNEL = "professionnel";

/**
 * @constant
 * @type {string}
 */
export const USER_TYPE_INVITE = "invite";

/**
 * @constant
 * @type {string}
 */
export const PROFESSIONAL_PROFILE_SITE = "Site";

/**
 * @constant
 * @type {string}
 */
export const PROFESSIONAL_PROFILE_SIEGE = "Siège";

/**
 * @constant
 * @type {string}
 */
export const RECAPTCHA_V3_SITE_KEY = "6LdV45UUAAAAAG4Q4uzCsWDNIWV7AtBeHUWSJSwu";

/**
 * @constant
 * @type {string}
 */
export const GOOGLE_MAP_KEY = "AIzaSyDlFUxjQuEI2kXM6OsbzVas8Fp0U-CHlz8";

/**
 * @constant
 * @type {string}
 */
export const GOOGLE_TAG_KEY = "GTM-KBDZ9H4";

/**
 * @constant
 * @type {string}
 */
export const UNIVERSAL_ANALYTICS_KEY = "UA-198735038-1";

/**
 * @constant
 * @type {object}
 */
export const onlyForSaleFilter = {
  // TODO isAd does not return any result
  // isAd: 1,
  // isPublished: 1,
  // archive: 0
};

/**
 * @constant
 * @type {String}
 */
export const AUTHENTICATED = "authenticated";

/**
 * @constant
 * @type {String}
 */
export const UNAUTHENTICATED = "unauthenticated";

/**
 * @constant
 * @type {String}
 */
export const LOADING = "loading";

/**
 * @constant
 * @type {string}
 */
export const TYPE_LEAD_TEL = "tel";

/**
 * @constant
 * @type {string}
 */
export const TYPE_LEAD_WRITE = "write";

/**
 * @constant
 * @type {string}
 */
export const DIALOG_TYPE_INFO = "info";

/**
 * @constant
 * @type {string}
 */
export const DIALOG_TYPE_CONFIRM = "confirm";

/**
 * @constant
 * @type {string[]}
 */
export const REST_API_IMAGE_FORMATS = [
  "thumbnails",
  "small",
  "medium",
  "large",
];

/**
 * @constant
 * @type {{"fr-FR": string}}
 */
export const LOCALES = {
  "fr-FR": "fr-FR",
};

/**
 * @constant
 * @type {{development: string, production: string}}
 */
export const ENVS = {
  production: "production",
  development: "development",
  preview: "preview",
  test: "test",
};

/**
 * @constant
 * @description https://github.com/vercel/next.js/discussions/22036
 * @type {{build: string}}
 */
export const NEXT_PHASES = {
  build: "phase-production-build",
};

/**
 * @constant
 * @type {string}
 */
export const OFFER_TYPE_OCCASION = "occasion";

/**
 * @constant
 * @type {string}
 */
export const OFFER_TYPE_NEUF = "neuf";

/**
 * @constant
 * @type {number}
 */
export const NOT_FOUND_IN_ARRAY = -1;

/**
 * @constant
 * @type {string}
 */
export const DEALERSHIP_LOGO_IMAGE_TYPE = "LOGO";

/**
 * @constant
 * @type {string}
 */
export const DEALERSHIP_COVER_IMAGE_TYPE = "COVER";

/**
 * @constant
 * @type {{width: number, height: number}}
 */
export const DEALERSHIP_LOGO_IMAGE_DEFAULT_DIMENSIONS = {
  height: 200,
  width: 200,
};

/**
 * @constant
 * @type {{width: number, height: number}}
 */
export const DEALERSHIP_COVER_IMAGE_DEFAULT_DIMENSIONS = {
  height: 300,
  width: 500,
};

/**
 * @constant
 * @type {{parent: string, individual: string}}
 */
export const ORIGINAL_SUBSCRIBER = {
  parent: "Parent",
  individual: "Individuel",
};

/**
 * @constant
 * @type {{src: string, alt: string, width: number, height: number}}
 */
export const missingLogoImageData = {
  src: dealershipLogoFallbackUrl,
  alt: "Sans image de logo",
  width: 80,
  height: 50,
};

/**
 * @constant
 * @type {{src: string, alt: string, width: number, height: number}}
 */
export const missingCoverImageData = {
  src: dealershipCoverImageFallbackUrl,
  alt: "Sans image de couverture",
  width: 500,
  height: 375,
};
