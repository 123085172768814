import Joi from "joi";
import {
  addressComplementRegex,
  addressRegex,
  chassisNumberRegex,
  frenchDimensionsRegex,
  frenchPriceRegex,
  nameRegex,
  passwordRegex,
  radiosCivilityRegex,
  userNameRegex,
  zipCodeRegex,
} from "@/Constants/regex";

import { radiosAgreements } from "@/Constants/InputsValues";

const extendedJoi = Joi.extend(require("joi-phone-number")).extend(
  require("@joi/date")
);

export const civilityValidate = () =>
  extendedJoi.string().pattern(radiosCivilityRegex).required();

export const nameValidate = () =>
  extendedJoi.string().pattern(nameRegex).max(150).required();

export const phoneNumberValidate = () =>
  extendedJoi
    .string()
    .min(10)
    .max(50)
    .phoneNumber({ defaultCountry: "FR", format: "national" })
    .required();

export const emailAddressValidate = () =>
  extendedJoi
    .string()
    .max(150)
    .email({ tlds: { allow: false } })
    .required();

export const firstAddressValidate = () =>
  extendedJoi.string().pattern(addressRegex).max(150).required();

export const chassisNumberValidate = () =>
  extendedJoi.string().pattern(chassisNumberRegex).max(150).required();

export const secondAddressValidate = () =>
  extendedJoi
    .string()
    .pattern(addressComplementRegex)
    .max(150)
    .default("")
    .empty("")
    .optional();

export const zipCodeValidate = () =>
  extendedJoi.string().max(50).pattern(zipCodeRegex).required();

export const userNameValidate = () =>
  extendedJoi.string().pattern(userNameRegex).required();

export const passwordValidate = () =>
  extendedJoi.string().pattern(passwordRegex).required();

export const passwordRepeatValidate = ref => extendedJoi.ref(ref);

export const termsOfUseValidate = () =>
  extendedJoi.boolean().truthy(radiosAgreements[0].value).required();

export const arrayValuesValidate = arrayOptions => {
  const values = arrayOptions?.map(option => option.value ?? option);
  return values?.length
    ? extendedJoi?.array().items(extendedJoi.string().valid(...values))
    : null;
};

export const dimensionsValidate = () =>
  extendedJoi.string().pattern(frenchDimensionsRegex).required();
export const priceValidate = () =>
  extendedJoi.string().pattern(frenchPriceRegex).required();
