import sortBy from "lodash/sortBy";
import { ACTION_FILTER_CHANGED_FROM_SEARCH_FORM } from "@/Constants/actions";
import { turnStringIntoNumber } from "@/Utils/index";
import { ADS_FILTERS } from "@/Constants/filters";
import { OFFER_TYPE_NEUF, OFFER_TYPE_OCCASION } from "@/Constants/global";
import { FLUXPROTRI } from "@/Constants/InputsValues";
import { isArray } from "lodash";

/**
 * @file utils/filtersFunctions.js
 * @description contient des fonctions utilisées pour les filtres dans constants/filters.js
 * @see constants/filters.js
 * @todo a besoin d'être redécoupé plus finement
 * @module Utils Filters Functions
 * @namespace Code Central
 */

/**
 * @function
 * @description Formate l'objet marque (brand) donné en paramètre en un objet values contenu dans le champ values de
 * type array du critère brands d'un filtre d'annonces et le retourne.
 * @todo Redondant d'avec getInitialOffersFilterFromOffers
 * @param brands
 * @param keyStart
 * @param checked
 * @returns {object}
 */
export const convertArrayBrandsToFilterAdsBrandsCriteriaValues = (
  brands,
  keyStart = 0,
  checked = false
) =>
  brands?.map(brand => ({
    label: brand.name,
    name: brand.name,
    id: brand.id,
    key: keyStart++,
    checked: Boolean(checked),
    disabled: false,
  }));

/**
 * @function
 * @description retourne arrayFilterValuesModels un array de valeurs du critère model d'un filtre généré sur la base
 * d'ads un objet de state globale redux contenant le filtre d'annonces, filteredBrands un tableau
 * de marques séléctionné dans le filtre et filteredModels un tableau de models de véhicules actuellement
 * utilisés dans les annonces existantes. Le but de cette fonction est de ne retourner que les models de
 * véhicules appartenant aux marques sélectionnées.
 * @param ads
 * @param filteredBrands
 * @param filteredModels
 * @returns {*}
 */
export const getArrayFilterModelAvailableValues = (
  ads,
  filteredBrands,
  filteredModels
) => {
  const arrayFilterValuesBrand = ads?.filters["model.brand"]?.values;
  let arrayFilterValuesModels = ads?.filters?.["model"]?.values;

  const arrayCheckedValuesBrand = getFilterBrandCheckedValues(
    arrayFilterValuesBrand
  );
  const arrayActiveBrands = getFilterBrandActiveValues(
    filteredBrands,
    arrayCheckedValuesBrand
  );
  const arrayModelsInActiveBrands = getModelsInActiveBrands(arrayActiveBrands);
  const arrayAvailableModels = getAvailableModels(
    filteredModels,
    arrayModelsInActiveBrands
  );

  if (!arrayFilterValuesModels?.length) {
    arrayFilterValuesModels = arrayAvailableModels?.map(availableModel =>
      getFilterModelValue(availableModel)
    );
  }

  arrayFilterValuesModels = changeArrayFilterModelValues(
    arrayFilterValuesModels,
    arrayAvailableModels
  );

  return arrayFilterValuesModels;
};

/**
 * @function
 * @description Filtre un array d'objets checkboxes de marques contenues dans un filtre -> brands -> values et retourne
 * un tableau ne contenant que des objets objets checkboxes de marques où le champ checked vaut true.
 * @todo pourrait être plus généraliste et renommé getArrayCriteriaCheckedValues
 * @param valuesBrands
 * @returns {Array | null}
 */
export const getFilterBrandCheckedValues = valuesBrands =>
  valuesBrands?.filter(brandValue => brandValue.checked);

/**
 * @function
 * @description Retourne un array de brands parmi filteredBrands marques utilisées dans les offres en premier paramètre,
 * dont les id correspondent aux id des objets checkboxes de l'array checkedValuesBrand en second paramètre.
 * @param filteredBrands
 * @param checkedValuesBrand
 * @returns {Array}
 */
export const getFilterBrandActiveValues = (
  filteredBrands,
  checkedValuesBrand
) => {
  const arrayActiveBrands = [];

  checkedValuesBrand?.forEach(checkedValueBrand => {
    const activeBrand = filteredBrands?.find(
      filteredBrand => checkedValueBrand.id === filteredBrand.id
    );

    if (activeBrand) arrayActiveBrands.push(activeBrand);
  });

  return arrayActiveBrands;
};

/**
 * @function
 * @description Retourne un array de models de véhicules contenus dans brands array de marques fourni en paramètres.
 * @param brands
 * @returns {Array}
 */
export const getModelsInActiveBrands = brands => {
  const arrayModelsInActiveBrands = [];

  brands?.forEach(
    brand => brand.models && arrayModelsInActiveBrands.push(...brand.models)
  );
  return arrayModelsInActiveBrands;
};

/**
 * @function
 * @description Retourne un array de models de véhicules au sein de filteredModels un array de models de véhicules
 * utilisés dans les annonces lorsque l'id d'un model correspond à celui d'un model contenu dans
 * arrayModelsInActiveBrands array de models de véhicules dans des marques sélectionnées.
 * @param filteredModels
 * @param arrayModelsInActiveBrands
 * @returns {Array}
 */
export const getAvailableModels = (
  filteredModels,
  arrayModelsInActiveBrands
) => {
  let output = filteredModels;

  if (arrayModelsInActiveBrands?.length) {
    const arrayAvailableModels = [];

    filteredModels?.forEach(filteredModel => {
      const availableModel = arrayModelsInActiveBrands?.find(
        modelInActiveBrand => modelInActiveBrand.id === filteredModel.id
      );

      if (availableModel) {
        arrayAvailableModels.push(availableModel);
      }
    });

    if (arrayAvailableModels?.length) {
      output = arrayAvailableModels;
    }
  }

  return output;
};

/**
 * @function
 * @description Retourne un objet checkbox model sur la base d'un model de véhicule fourni. Utilisé pour générer les
 * objets checkboxes models contenu dans un filtre, champ model, champs values de type array. Cette fonction a pour but
 * de réinitialiser les valeurs du critère model dans un filtre lorsqu'on remet à 0 ce dernier.
 * @param model
 * @returns {object}
 */
export const getFilterModelValue = (model, checked = null) => ({
  id: model?.id,
  label: model?.name,
  name: model?.slug,
  checked: checked ?? Boolean(model?.checked),
  finitions: model?.finitions || [],
  key: model.id,
  disabled: false,
});

/**
 * @function
 * @description Réinitialise le champ disabled d'un des objets d'arrayFilterValuesModels fourni en paramètres,
 * étant un array d'objets checkboxes de models de véhicules lorsque le champ id d'un de ses objet correspond au champ
 * id d'un objet model contenu dans le paramètre arrayAvailableModels de type array. Cette fonction a pour but de
 * réinitialiser les valeurs du critère model.rangeVehicle dans un filtre lorsqu'on remet à 0 ce dernier.
 * @param arrayFilterValuesModels
 * @param arrayAvailableModels
 * @returns {object}
 */
export const changeArrayFilterModelValues = (
  arrayFilterValuesModels,
  arrayAvailableModels
) =>
  arrayFilterValuesModels?.map(modelValue => {
    let output = modelValue;
    output.disabled = false;

    const availableModel = arrayAvailableModels?.find(
      availableModel => availableModel?.id === modelValue?.id
    );

    if (!availableModel) {
      output = {
        ...modelValue,
        disabled: true,
        checked: false,
      };
    }

    return output;
  });

/**
 * @function
 * @description Retourne un array d'objets checkboxes de profils de véhicules sur la base de filteredRanges un array
 * de profils actuellement utilisés sur les annonces et ads un objet de state global redux gérant le filtre d'annonces.
 * Cette fonction sert à générer des objets checkboxes de profils dans le champ values de type array dans le critère
 * model.rangeVehicle d'un filtre.
 * @param filteredRanges
 * @param ads
 * @returns {*}
 */
export const getFilterRangeValues = (filteredRanges, ads) => {
  let arrayFilterRangeValues = getFilterRangeFilteredValues(
    ads?.filters["model.rangeVehicle"]?.values,
    filteredRanges
  );
  arrayFilterRangeValues = sortBy(arrayFilterRangeValues, ["label"]);
  return arrayFilterRangeValues;
};

/**
 * @function
 * @description Retourne un array d'objets checkboxes de profils de véhicules dont le champ disabled est réinitialisé
 * lorsqu'un objet checkbox de profil dans l'array arrayFilterRangeValues possède un name ayant la même valeur que le
 * champ slug d'un objet profil contenu dans l'array filteredRanges
 * @param arrayFilterRangeValues
 * @param filteredRanges
 * @returns {object | array}
 */
export const getFilterRangeFilteredValues = (
  arrayFilterRangeValues,
  filteredRanges
) => {
  if (isArray(arrayFilterRangeValues)) {
    arrayFilterRangeValues = arrayFilterRangeValues.map(filterRangeValue => {
      filterRangeValue.disabled = false;

      const availableFilterRange = filteredRanges?.find(
        filteredRange => filterRangeValue?.name === filteredRange?.slugFr
      );

      if (!availableFilterRange) {
        filterRangeValue.disabled = true;
      }
      return filterRangeValue;
    });
  }
  return arrayFilterRangeValues;
};

/**
 * @function
 * @description Met à jour les variables adsList et adsCount géréees par react context en exécutant des callbacks.
 * Prend en paramètres adsList une liste d'annonces, ads objet de state global redux stockant les
 * informations liées à la logique des annonces, setAdsList une fonction useState gérant adsList un array de state
 * globale react context stockant les annonces filtrées, setAdsCount une fonction useState gérant adsCount
 * un array de state globale react context stockant la somme des annonces affichées à l'écran.
 * Cette fonction est créée pour gérer les annonces filtrées et le compte total d'annonces affichées à l'écran. Ne se
 * sert pas de redux pour ne passer sur une solution plus simple que redux.
 * @todo devrait être déplacée dans helpers/offersListFunctions.js (les helpers sont des fonctions liées à l'affichage)
 * @param adsList
 * @param ads
 * @param setAdsList
 * @param setAdsCount
 */
export const onChangeStoreAdsListHandleStateUpdate = (
  adsList,
  ads,
  setAdsList,
  setAdsCount
) => {
  const isStoreAdsListAndStateAdsListDifferent = !!(
    (adsList?.length || ads?.list?.length) &&
    ads?.list !== adsList
  );

  if (
    ads.actionFrom !== ACTION_FILTER_CHANGED_FROM_SEARCH_FORM &&
    isStoreAdsListAndStateAdsListDifferent
  ) {
    setAdsList(ads?.list);
    setAdsCount(ads?.count);
  }
};

/**
 * @function
 * @param arrayAllAvailableOffers
 * @param filters
 * @description Génère et retourne un objet filtre d'annonces avec des valeurs initiales basées sur
 * les annonces existantes.
 * Prend en paramètres arrayAllAvailableOffers un array d'annonces servant à récupérer les valeurs initiales à utiliser
 * dans le filtre et filters le filtre d'annonces à mettre à jour.
 * @todo Vaut la peine d'être executé sur le serveur de next, pour créer un filtre mis en cache sur le serveur et
 * l'invalider toutes les 5 mins. Permettrait d'économiser des ressources + de ne pas ralentir l'affichage du filtre
 * initial.
 * @returns {*}
 */
export const getInitialOffersFilterFromOffers = (
  arrayAllAvailableOffers,
  filters
) => {
  let newFilters = filters;

  if (filters && arrayAllAvailableOffers?.length) {
    newFilters = getNewOffersFilters(arrayAllAvailableOffers, filters);
  }

  return newFilters;
};

/**
 * @function
 * @description Génère et retourne un objet correspondant au filtre initial pour les annonces.
 * Prend en paramètrex arrayOffers, array des annonces existantes et filters correspondant au filtre des annonces dans
 * l'objet de state globale redux ads.filters
 * @param arrayOffers
 * @param filters
 * @returns {*}
 */
export const getNewOffersFilters = (arrayOffers, filters) => {
  let newFilters = { ...filters };
  const valuesForFilters = {
    type: { values: [] },
    "user.userType": { values: [] },
    "model.rangeVehicle": { values: [] },
    bedType: { values: [] },
    // 'size' : {
    //   'length' : [],
    //   'height' : [],
    //   'width' : [],
    // },
    // 'inclVatPrice' : {values: []},
    // 'localisation' : [],
    // 'registeredSeats' : {values: []},
    // 'sleepingSeats' : {values: []},
    // 'mileage' : {values: []},
    millesime: { values: [] },
    "model.brand": { values: [] },
    model: { values: [] },
    // 'gearbox' : {values: []},
    // 'grossWeight' : {values: []},
    // 'heating' : {values: []},
    // 'dealers': {values: []},
  };

  if (arrayOffers) {
    const newValuesForFilters = getValuesForOffersFiltersFromOffers(
      valuesForFilters,
      arrayOffers
    );

    newFilters = updateFiltersValues(filters, newValuesForFilters);
  }

  return newFilters;
};

/**
 * @function
 * @description Génère et retourne un objet pour les critères du filtre d'annonces avec des valeurs initiales basées sur
 * les annonces existantes.
 * Prend en paramètres valuesForFilters un objet contenant en champs les critères de filtres, eux même contenant values,
 * un array de valeurs, à completer, offers, un array d'annonces servant à récupérer les valeurs à pousser dans les
 * arrays values contenus dans valuesForFilters. S'il est nécessaire de générer des valeurs initiales grâce aux
 * annonces, il suffit d'ajouter `valuesForFilters[<nom-du-critère>].values.push(offer[<nom-du-critère>])
 * @param valuesForFilters
 * @param offers
 * @returns {*}
 */
export const getValuesForOffersFiltersFromOffers = (
  valuesForFilters,
  offers
) => {
  const arrayFiltersKeys = Object.keys(valuesForFilters);

  offers.forEach(offer => {
    if (offer) {
      valuesForFilters["model.rangeVehicle"].values.push(
        offer?.model?.rangeVehicle?.slugFr
      );
      valuesForFilters["model.brand"].values.push(offer.model?.brand?.id);
      valuesForFilters["millesime"].values.push(offer.millesime);
    }
  });

  for (const key of arrayFiltersKeys) {
    valuesForFilters[key].values = valuesForFilters[key].values.filter(
      value => !!value
    );
    valuesForFilters[key].values = [...new Set(valuesForFilters[key].values)];
  }

  offers.forEach(offer => {
    const isModelInKeptBrands = !!valuesForFilters["model.brand"].values.find(
      brandValue => offer.model?.brand?.id === brandValue
    );
    isModelInKeptBrands &&
      valuesForFilters["model"].values.push(offer.model?.id);
  });

  if (valuesForFilters["millesime"].values) {
    valuesForFilters["millesime"].values.sort();
    const lastIndex = valuesForFilters["millesime"].values.length - 1;

    const min = valuesForFilters["millesime"].values[0];
    const max = valuesForFilters["millesime"].values[lastIndex];

    valuesForFilters["millesime"].values = [min, max];
  }

  return valuesForFilters;
};

/**
 * @function
 * @description Met à jour les valeurs des critères du filtre d'annonces et retourne un filtre d'annonces. Prend en
 * paramètres filters le filtre d'annonces à mettre à jour et newValuesForFilters un objet contenant les valeurs à mettre
 * dans le filtre d'annonces. Pour mettre à jour les valeurs d'un critère, il suffit de déclarer un nouveau case dans
 * le switch avec pour valeur le nom du critère.
 * @param filters
 * @param newValuesForFilters
 * @returns {*}
 */
export const updateFiltersValues = (filters, newValuesForFilters) => {
  const arrayFiltersKeys = Object.keys(newValuesForFilters);

  let updatedFilters = { ...filters };

  if (filters && newValuesForFilters) {
    const arrayFiltersKeysKept = arrayFiltersKeys.filter(
      key => updatedFilters?.[key]?.values?.length
    );

    updatedFilters = changeFiltersInputsValues(
      updatedFilters,
      newValuesForFilters,
      arrayFiltersKeysKept
    );
  }

  return updatedFilters;
};

/**
 * @function
 * @description Réinialise les valeurs sélectionnés par input user du filtre d'annonces appartenant à des critères gardés
 * et le retourne.
 * @param updatedFilters
 * @param newValuesForFilters
 * @param arrayFiltersKeptKeys
 * @todo arrayFiltersKeptKeys ferait mieux d'être dégagé et newValuesForFilters devrait ne garder que des keys à
 * utiliser afin de ne fournir que deux paramètres à cette fonction. Pour l'instant, cette fonction ne gère
 * la réinitialiser les valeurs d'inputs que sur les clés de critères contenant des valeurs mises à jour. Cela permet
 * de ne pas looper sur des clés dont les valeurs ne changent pas... Mais est-ce que cela ne créé pas de bug ?
 * @returns {*}
 */
export const changeFiltersInputsValues = (
  updatedFilters,
  newValuesForFilters,
  arrayFiltersKeptKeys
) => {
  for (const key of arrayFiltersKeptKeys) {
    if (newValuesForFilters[key]?.values?.length) {
      switch (key) {
        // case 'type':
        // case 'user.userType':
        case "model.rangeVehicle":
          updatedFilters[key].values = updatedFilters[key].values.map(
            filterValue => ({
              ...filterValue,
              disabled: !newValuesForFilters[key].values.find(
                newValue => filterValue.name === newValue
              ),
            })
          );
          break;

        // case 'bedType':
        //   updatedFilters[key].values = updatedFilters[key].values.map(filterValue => ({
        //     ...filterValue,
        //     disabled: !(
        //       newValuesForFilters[key].values.find(newValue => filterValue.label === newValue)
        //         || newValuesForFilters[key].values.find(newValue => filterValue?.moreValues
        //           ?.find(value => value === newValue))
        //     )
        //   }))
        //   break

        case "model.brand":
          updatedFilters[key].values = updatedFilters[key].values.map(
            filterValue => ({
              ...filterValue,
              disabled: !newValuesForFilters[key].values.find(
                newValue => filterValue.id === newValue
              ),
            })
          );
          break;

        //  do nothing here on model since it depends on models belonging only to newValuesForFilters.model.brand.values kept
        case "model":
          updatedFilters[key].values = updatedFilters[key].values.map(
            filterValue => ({
              ...filterValue,
              disabled: !newValuesForFilters[key].values.find(
                newValue => filterValue.id === newValue
              ),
            })
          );
          break;

        case "millesime":
          updatedFilters[key] = {
            ...updatedFilters[key],
            min: newValuesForFilters[key].values[0],
            max: newValuesForFilters[key].values[1],
          };
          break;

        default:
      }
    }
  }
  return updatedFilters;
};

/**
 * @function
 * @description Retourne les critères de filtres que l'on souhaite afficher sur le premier bloc de critères de filtrage
 * d'annonces sur la page d'accueil. Prend en paramètre filters, le filtre d'annonces.
 * @param filters
 * @returns {unknown[]}
 */
export const getFiltersCol1 = filters => {
  let filtersCol1 =
    Object.entries(filters)?.filter(entry =>
      ["type", "model.rangeVehicle"].includes(entry[0])
    ) || null;

  if (!filtersCol1) {
    filtersCol1 = Object.entries({ ...ADS_FILTERS })?.filter(entry =>
      ["type", "model.rangeVehicle"].includes(entry[0])
    );
  }

  if (!filtersCol1) filtersCol1 = [];

  return filtersCol1;
};

/**
 * @function
 * @description Retourne les critères de filtres que l'on souhaite afficher sur le deuxième bloc de critères de filtrage
 * d'annonces sur la page d'accueil. Prend en paramètre filters, le filtre d'annonces.
 * @param filters
 * @returns {unknown[]}
 */
export const getFiltersCol2 = filters => {
  let filtersCol2 =
    Object.entries(filters)?.filter(entry => ["bedType"].includes(entry[0])) ||
    null;

  if (!filtersCol2) {
    filtersCol2 = Object.entries({ ...ADS_FILTERS })?.filter(entry =>
      ["bedType"].includes(entry[0])
    );
  }

  if (!filtersCol2) filtersCol2 = [];

  return filtersCol2;
};

/**
 * @function
 * @description Genère et retourne un critère 'model' avec des valeurs initiales pour le filtre d'annonces.
 * Prend en paramètres ads l'objet de state global gérant les annonces et son filtre, filteredModels un array de
 * models de véhicules actuellement utilisées sur les annonces et filteredBrands un array de
 * marques de véhicules actuellement utilisées sur les annonces. Fournir filteredBrands est nécessaire pour ne garder
 * que les models de véhicules utilisés sur des annonces actives.
 * @param ads
 * @param filteredModels
 * @param filteredBrands
 * @returns {objet}
 * @todo Redondant d'avec getInitialOffersFilterFromOffers
 * @deprecated
 * @see getInitialOffersFilterFromOffers
 */
export const getInitialFilterModel = (ads, filteredModels, filteredBrands) => {
  const newFilterValuesAvailableModels = getArrayFilterModelAvailableValues(
    ads,
    filteredBrands,
    filteredModels
  );
  let filter = ads.filters["model"];
  let arrayFilterModelValues = filter?.values;

  if (newFilterValuesAvailableModels)
    arrayFilterModelValues = newFilterValuesAvailableModels;

  arrayFilterModelValues = arrayFilterModelValues.map(value => ({
    ...value,
    checked: false,
  }));

  if (arrayFilterModelValues && filter) {
    filter = {
      ...filter,
      values: [...arrayFilterModelValues],
    };
  }

  return filter;
};

/**
 * @function
 * @description Genère et retourne un critère 'model.brand' avec des valeurs initiales pour le filtre d'annonces.
 * Prend en paramètres ads l'objet de state global gérant les annonces et son filtre, et filteredBrands un array de
 * marques de véhicules actuellement utilisées sur les annonces.
 * @param ads
 * @param filteredBrands
 * @returns {objet}
 * @todo Redondant d'avec getInitialOffersFilterFromOffers
 * @deprecated
 * @see getInitialOffersFilterFromOffers
 */
export const getInitialFilterModelBrand = (ads, filteredBrands) => {
  const arrayFilterValuesBrands =
    convertArrayBrandsToFilterAdsBrandsCriteriaValues(filteredBrands);
  let filter = ads.filters["model.brand"];
  if (arrayFilterValuesBrands && ads.filters["model.brand"]) {
    filter = {
      ...filter,
      values: arrayFilterValuesBrands,
    };
  }
  return filter;
};

/**
 * @function
 * @description Genère et retourne un critère 'model.rangeVehicle' avec des valeurs initiales pour le filtre d'annonces.
 * Prend en paramètres ads l'objet de state global gérant les annonces et son filtre, et filteredRanges un array de
 * profils de véhicules actuellement utilisés sur les annonces.
 * @param ads
 * @param filteredRanges
 * @returns {objet}
 * @todo Redondant d'avec getInitialOffersFilterFromOffers. En plus c'est pas un filtre mais un critère que l'on gère ici
 * @deprecated
 * @see getInitialOffersFilterFromOffers
 */
export const getInitialFilterRange = (ads, filteredRanges) => {
  let arrayFilterRangeValues = getFilterRangeValues(filteredRanges, ads);
  let filter = ads.filters["model.rangeVehicle"];

  if (arrayFilterRangeValues && filter) {
    arrayFilterRangeValues = arrayFilterRangeValues.map(filterRangeValue => ({
      ...filterRangeValue,
      checked: false,
    }));
    filter = {
      ...filter,
      values: arrayFilterRangeValues,
    };
  }
  return filter;
};

/**
 * @function
 * @description Retourne un filtre avec des données réinitialisées sur la bases des données des critères de modèles,
 * profils et marques de véhicules. Prend en paramètres filtersData objet basé sur ADS_FILTERS représentant le
 * filtre des annonces, un objet anonyme en second paramètre avec les champs newFilterModelBrand un objet représentant
 * le critère de filtrage de marques de véhicules, newFilterRange un objet représentant le critère de filtrage de
 * profils de véhicules, newFilterModel un objet représentant le critère de filtrage de modèles de véhicules.
 * @param filtersData
 * @param newFilterModelBrand
 * @param newFilterRange
 * @param newFilterModel
 * @returns {object}
 * @todo à mon avis c'est redondant d'avec getInitialOffersFilterFromOffers. Ou devrait s'apeller cleanOffersFilterData
 * @deprecated
 * @see getInitialOffersFilterFromOffers
 */
export const getInitialOffersFilterData = (
  filtersData,
  { newFilterModelBrand, newFilterRange, newFilterModel }
) => {
  const filters = { ...ADS_FILTERS };

  if (filtersData && newFilterModelBrand && newFilterRange && newFilterModel) {
    filtersData.gearbox = filtersData.gearbox.map(slug => slug?.toLowerCase());

    filters.type = {
      ...filters.type,
      values: filters.type.values.map(value => ({
        ...value,
        disabled: !filtersData.type?.find(slug => slug === value?.name),
      })),
    };

    filters["user.userType"] = {
      ...filters["user.userType"],
      values: filters["user.userType"].values.map(value => ({
        ...value,
        disabled: !filtersData.userType?.find(slug => slug === value?.name),
      })),
    };

    filters["model.rangeVehicle"] = { ...newFilterRange };

    filters.bedType = {
      ...filters.bedType,
      values: filters.bedType?.values.map(value => ({
        ...value,
        disabled: !filtersData?.bedType?.find(slug => slug === value?.key),
      })),
    };

    filters.size.values.forEach(value => {
      if (value.name === "length") {
        const { lengthMax, lengthMin } = filtersData;
        value.max = Number(lengthMax);
        value.min = Number(lengthMin);
        value.values = [null, null];
      }

      if (value.name === "height") {
        const { heightMax, heightMin } = filtersData;
        value.max = Number(heightMax);
        value.min = Number(heightMin);
        value.values = [null, null];
      }

      if (value.name === "width") {
        const { widthMax, widthMin } = filtersData;
        value.max = Number(widthMax);
        value.min = Number(widthMin);
        value.values = [null, null];
      }
    });

    filters.inclVatPrice.max = Number(filtersData?.inclVatPriceMax);
    filters.inclVatPrice.min = Number(filtersData?.inclVatPriceMin);
    filters.inclVatPrice.values = [null, null];

    filters.registeredSeats.max = Number(filtersData?.registeredSeatsMax);
    filters.registeredSeats.min = Number(filtersData?.registeredSeatsMin);
    filters.registeredSeats.values = [null, null];

    filters.localisation = {
      ...filters.localisation,
      values: [
        { ...ADS_FILTERS.localisation.values[0], value: "" },
        { ...ADS_FILTERS.localisation.values[1], value: "100" },
      ],
    };

    filters.sleepingSeats.max = Number(filtersData?.sleepingSeatsMax);
    filters.sleepingSeats.min = Number(filtersData?.sleepingSeatsMin);
    filters.sleepingSeats.values = [null, null];

    filters.mileage.max = Number(filtersData?.mileageMax);
    filters.mileage.min = Number(filtersData?.mileageMin);
    filters.mileage.values = [null, null];

    filters.millesime.max = Number(filtersData?.millesimeMax);
    filters.millesime.min = Number(filtersData?.millesimeMin);
    filters.millesime.values = [null, null];

    filters["model.brand"] = { ...newFilterModelBrand };

    filters["model"] = { ...newFilterModel };

    filters.gearbox = {
      ...filters.gearbox,
      values: filters.gearbox.values.map(value => ({
        ...value,
        disabled: !filtersData.gearbox?.find(slug => slug === value?.value),
      })),
    };

    filters.heating = {
      ...filters.heating,
      values: filters.heating?.values?.map(value => ({
        ...value,
        disabled: !filtersData.heating?.find(slug => slug === value?.value),
      })),
    };

    filters.dealer = {
      ...filters.dealer,
      values: filters.dealer?.values.map(value => ({
        ...value,
        checked: false,
        disabled: true,
      })),
    };
  }
  return filters;
};

/**
 * @function
 * @description Filtres et retourne un array d'annonces en fonction des valeurs des critères du filtre de flux d'offres.
 * Prend en paramètres offers un array d'annonces à trier,
 * filters le filtre de flux d'offres basé sur la constante FILTERS_PROFESSIONAL_PROFILE_SEAT et options.arePublished
 * un boolean pour informer si offers correspond à des annonces du flux archivées ou actives.
 * @param offers
 * @param filters
 * @param options
 * @returns {object[]}
 */
export const filterFluxOffers = (
  offers,
  filters,
  options = { isArrayUnpublished: false }
) => {
  // -- filters.isFilteringUnpublished is handled in components/Layout/EspacePro/Flux/Flux.js
  let arrayResults = [];

  const isAtLeastOneCheckboxTruthy =
    filters.isFilteringArchived ||
    filters.isFilteringFirstHand ||
    filters.isFilteringSecondHand ||
    filters.isFilteringUnpublished;

  filters = {
    isFilteringArchived: filters.isFilteringArchived,
    isFilteringFirstHand: filters.isFilteringFirstHand,
    isFilteringSecondHand: filters.isFilteringSecondHand,
    isFilteringUnpublished: filters.isFilteringUnpublished,
    year: filters.year,
  };

  if (offers?.length) {
    const isUnpublishedOffer = options?.isArrayUnpublished;

    if (isAtLeastOneCheckboxTruthy) {
      arrayResults = offers.filter(offer =>
        filterSingleOffer(offer, filters, isUnpublishedOffer)
      );
    } else {
      arrayResults = offers.filter(offer => getIsMatchingYear(offer, filters));
    }
  }

  return arrayResults;
};

/**
 * @function
 * @description Retourne un boolean informant si oui ou non une le millesime d'une annonce correspond à une année
 * fournie. Le paramètre offer correspond à une annonce et filters est le filtre d'annonce de flux sur lequel la fonction
 * lit la valeur de type string (?) de filters.year
 * @param offer
 * @param filters
 * @returns {boolean}
 */
const getIsMatchingYear = (offer, filters) => {
  let isMatching = true;

  if (filters?.year) {
    const millesime = offer.millesime ? String(offer.millesime) : null;

    isMatching = filters?.year === "N/A" && millesime === null;

    if (millesime) isMatching = millesime === filters?.year;
  }

  return isMatching;
};

/**
 * @function
 * @description Retourne un array d'offres triés par le critère indiqué. Prend en paramètres offers un array d'offres
 * à trier; sortOrder un string devant correspondre à un critère de tri; options un objet contenant le champ isPublished
 * ayant une valeur de type boolean indiquant si les offres données en premier paramètre sont archivées ou
 * actives/publiées.
 * @namespace Code Central
 * @param offers
 * @param sortOrder
 * @param options
 * @returns {*}
 */
export const getSortedFluxFilteredOffers = (
  offers,
  sortOrder,
  options = { isPublished: true }
) => {
  let arraySortedOffers = offers;

  if (offers?.length) {
    const sortingFunction = getFluxOfferSortingFunction(sortOrder, {
      isPublished: options?.isPublished,
    });
    arraySortedOffers = sortingFunction(offers);
  }

  return arraySortedOffers;
};

/**
 * @function
 * @description Retourne une fonction callback correspondant à la fonction de tri associé au critère de tri renseigné.
 * Prend en paramètres sortOrder, une valeur de type string correspondant à un critère de tri; options un objet
 * contenant le champ isPublished ayant une valeur de type boolean indiquant si les offres données en premier
 * paramètre sont archivées ou actives/publiées.
 * @param sortOrder
 * @param options
 * @returns {*}
 */
export const getFluxOfferSortingFunction = (
  sortOrder,
  options = { isPublished: true }
) => {
  const dateField = options?.isPublished === true ? "published" : "created";

  const sortingFunctions = {
    // view-increase
    [FLUXPROTRI[9].value]: offers =>
      offers.sort((a, b) => Number(a.nbViews) - Number(b.nbViews)),
    // view-decrease
    [FLUXPROTRI[8].value]: offers =>
      offers.sort((a, b) => Number(b.nbViews) - Number(a.nbViews)),
    // lead-increase
    [FLUXPROTRI[7].value]: offers =>
      offers.sort((a, b) => {
        const nbLeadsB = Number(b.nbCalls) + Number(b.nbMails);
        const nbLeadsA = Number(a.nbCalls) + Number(a.nbMails);
        return nbLeadsA - nbLeadsB;
      }),
    // lead-decrease
    [FLUXPROTRI[6].value]: offers =>
      offers.sort((a, b) => {
        const nbLeadsB = Number(b.nbCalls) + Number(b.nbMails);
        const nbLeadsA = Number(a.nbCalls) + Number(a.nbMails);

        return nbLeadsB - nbLeadsA;
      }),
    // price-increase
    [FLUXPROTRI[5].value]: offers =>
      offers.sort(
        (a, b) =>
          turnStringIntoNumber(a.inclVatPrice) -
          turnStringIntoNumber(b.inclVatPrice)
      ),
    // price-decrease
    [FLUXPROTRI[4].value]: offers =>
      offers.sort(
        (a, b) =>
          turnStringIntoNumber(b.inclVatPrice) -
          turnStringIntoNumber(a.inclVatPrice)
      ),
    // offer-name-increase
    [FLUXPROTRI[3].value]: offers =>
      offers.sort((a, b) =>
        String(a.model?.name).localeCompare(String(b.model?.name))
      ),
    // offer-name-decrease
    [FLUXPROTRI[2].value]: offers =>
      offers.sort((a, b) =>
        String(b.model?.name).localeCompare(String(a.model?.name))
      ),
    // date-increase
    [FLUXPROTRI[1].value]: offers =>
      offers.sort(
        (a, b) => new Date(a?.[dateField]) - new Date(b?.[dateField])
      ),
    // date-decrease
    [FLUXPROTRI[0].value]: offers =>
      offers.sort(
        (a, b) => new Date(b?.[dateField]) - new Date(a?.[dateField])
      ),
  };

  return sortingFunctions[sortOrder] ?? sortingFunctions[FLUXPROTRI[0].value];
};

/**
 * @function
 * @description Retourne un boolean permettant de vérifier si oui ou non une offre correspond aux critères aux critères
 * de filtrages. Prend en paramètres offer un objet représentant une offre, filters un objet représentant le filtre des
 * flux de concessions, isPublished un boolean informant si l'offre est publiée ou archivée.
 * @param offer
 * @param filters
 * @param isUnpublishedOffer
 * @returns {boolean}
 */
export const filterSingleOffer = (
  offer,
  filters,
  isUnpublishedOffer = false
) => {
  const criteria = {
    isMatchingArchived: true,
    isMatchingPublished: true,
    // #SLEEPING isBoosted: true,
    isMatchingFirstHand: true,
    isMatchingSecondHand: true,
    isMatchingYear: true,
  };

  const isAtLeastOneCheckboxCriteriaTruthy =
    filters.isFilteringArchived ||
    filters.isFilteringFirstHand ||
    filters.isFilteringSecondHand ||
    filters.isFilteringUnpublished;

  if (isAtLeastOneCheckboxCriteriaTruthy) {
    if (!(filters.isFilteringFirstHand && filters.isFilteringSecondHand)) {
      criteria.isMatchingFirstHand = filters.isFilteringFirstHand
        ? offer.type === OFFER_TYPE_NEUF
        : true;
      criteria.isMatchingSecondHand = filters.isFilteringSecondHand
        ? offer.type === OFFER_TYPE_OCCASION
        : true;
    }

    criteria.isMatchingArchived = getIsMatchingArchived(offer, filters);
    criteria.isMatchingPublished = getIsMatchingPublished(offer, filters);
  }

  criteria.isMatchingYear = getIsMatchingYear(offer, filters);

  if (criteria.isMatchingArchived && !isUnpublishedOffer) {
    criteria.isMatchingPublished = true;
  }

  return Object.values(criteria).every(value => value === true);
};

/**
 * @function
 * @description Retourne un boolean informant si une offre correspond ou pas au critère
 * isFilteringArchived de filtrage de flux de concession.
 * Prend en paramètres offer un objet représentant une offre, filters un objet représentant le filtre des
 * flux de concessions, isUnpublishedOffer un boolean informant si l'offre fait partie d'un array d'offres non publiées
 * ou non.
 * @param offer
 * @param filters
 * @returns {boolean}
 */
const getIsMatchingArchived = (offer, filters) =>
  filters.isFilteringArchived
    ? !!offer.archive === true
    : !!offer.archive === false;

/**
 * @function
 * @description Retourne un boolean informant si une offre correspond ou pas au critère isFilteringUnpublished de filtrage
 * de flux de concession. Prend en paramètres offer un objet représentant une offre, filters un objet représentant le
 * filtre des flux de concessions, isUnpublishedOffer un boolean permettant de ne pas filter une offre lorsqu'il vaut true
 * pour indiquer que l'on traite une offre venant déjà d'un array d'offres non publiées.
 * @param offer
 * @param filters
 * @returns {boolean}
 */
const getIsMatchingPublished = (offer, filters) =>
  filters.isFilteringUnpublished
    ? Boolean(offer.isPublished) === false
    : Boolean(offer.isPublished) === true;

/**
 * @function
 * @description Transforme un objet marque en un objet checkbox à contenir dans ADS_FILTERS.['model.brand'].values.
 * Prend en paramètres brand un objet marque retourné par l'API et params un objet contenant le champ
 * arrayBrandsCheckboxes un array d'objets checkboxes de marques contenus dans le filtre d'annonce.
 * @param brand
 * @param params
 * @returns {{name: string, checked: boolean, label: *}}
 */
export const getArrayFilterBrandsCheckboxProps = (
  brand,
  params = { arrayBrandsCheckboxes: [] }
) => ({
  label: brand?.name,
  name: String(brand?.id),
  checked: !!params.arrayBrandsCheckboxes.find(
    value => value === String(brand?.id)
  ),
});

/**
 * @function
 * @param dealer
 * @returns {{checked: boolean, disabled: boolean, id, label: (*|string)}}
 */
export const getCriteriaDealerValue = (dealer, checked = false) => ({
  id: dealer.id,
  checked: Boolean(checked),
  disabled: false,
  label: dealer?.denomination ?? "",
  key: dealer?.id || null,
});
