import { normalizeAppProps } from "@/Utils/normalizers/strapiApiNormalizers";
import { CLOUDINARY_API_URI, STRAPI_API_URI } from "@/Constants/strapiApi";
import { httpGet } from "@/Utils/http/httpRequests";

export const getStrapiURL = (path = "") => {
  return `${STRAPI_API_URI}${path}`;
};

export const getCloudinaryURL = (path = "") => {
  return `${CLOUDINARY_API_URI}${path}`;
};

// TODO: make cmsApiGetGlobal, cmsApiGetArticles, cmsApiGetCategories to handle data to return
//  at error (when strapi is down)

// Helper to make GET requests to Strapi
export const fetchStrapiApi = async route => {
  const url = getStrapiURL(route);
  const options = { headers: { Authorization: "" } };

  const response = await httpGet(url, options);

  if (response.statusCode === 500) {
    throw new Error(response?.message);
  }
  return response;
};

// Helper to get image for SEO
export const getStrapiMedia = media => {
  let imageUrl;
  if (media) {
    imageUrl = media.url.startsWith("/")
      ? getCloudinaryURL(media.url)
      : media.url;
  }
  return imageUrl;
};

/**
 * @deprecated
 * @returns {Array}
 */
export const strapiGetBlogCategories = async () => {
  let output = [];
  const categories = await fetchStrapiApi("/categories");
  if (categories?.length) {
    const { categoriesInfos, categoryAnnexes } = normalizeAppProps(categories);
    output = { categoriesInfos, categoryAnnexes };
  }
  return output;
};

/**
 * @deprecated
 * @returns {Promise<any | undefined>}
 */
export const strapiGetGlobalBlogSeo = () =>
  fetchStrapiApi("/global").then(data => (data ? data : {}));
