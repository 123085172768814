import { useRouter } from "next/router";
import css from "./Spinner.module.css";
import Svg from "@/Shared/Svg";
import { useEffect, useState } from "react";

const Spinner = ({ isLoading, isChangingOnRouterUpdate = true }) => {
  const router = useRouter();

  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    router.events.on("routeChangeStart", () => setIsShowing(true));
    router.events.on("routeChangeComplete", () => setIsShowing(false));
    router.events.on("routeChangeError", () => setIsShowing(false));

    return () => {
      router.events.off("routeChangeStart", () => setIsShowing(true));
      router.events.off("routeChangeComplete", setIsShowing(false));
      router.events.off("routeChangeError", setIsShowing(false));
    };
  }, [router]);

  useEffect(() => {
    try {
      if (
        isChangingOnRouterUpdate === false &&
        typeof isLoading === "boolean"
      ) {
        setIsShowing(isLoading);
      }
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
    }
  }, [isLoading]);

  return (
    <>
      {isShowing && (
        <div className={css.spinner}>
          <Svg selection='spinner' />
        </div>
      )}
    </>
  );
};

export default Spinner;
