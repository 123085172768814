import { ADS_FILTERS } from "@/Constants/filters";

export const removeValuesAdsFilter = adsFilter => {
  for (const [key, values] of Object.entries(adsFilter)) {
    switch (key) {
      case ADS_FILTERS.type.name:
      case ADS_FILTERS["user.userType"].name:
      case ADS_FILTERS.gearbox.name:
      case ADS_FILTERS.heating.name:
      case ADS_FILTERS.bedType.name:
      case ADS_FILTERS["model.brand"].name:
      case ADS_FILTERS.model.name:
      case ADS_FILTERS.dealer.name:
      case ADS_FILTERS["model.rangeVehicle"].name:
        adsFilter[key].values = adsFilter[key].values.map(value => ({
          ...value,
          checked: false,
        }));
        break;

      case ADS_FILTERS.inclVatPrice.name:
      case ADS_FILTERS.registeredSeats.name:
      case ADS_FILTERS.sleepingSeats.name:
      case ADS_FILTERS.mileage.name:
      case ADS_FILTERS.millesime.name:
        adsFilter[key].values = [];
        break;

      case ADS_FILTERS.size.name:
        adsFilter.size.values = adsFilter.size.values.map(value => ({
          ...value,
          values: [],
        }));
        break;

      case ADS_FILTERS.grossWeight.name:
        adsFilter.grossWeight.values = adsFilter.grossWeight.values.map(
          value => ({ ...value, checked: false })
        );

        break;

      case ADS_FILTERS.localisation.name:
        adsFilter.localisation.values[0].value = "";
        adsFilter.localisation.values[1].value = "100";
        break;
    }
  }

  return adsFilter;
};
