import {
  formatDateToFr,
  generateOfferSlug,
  getIdFromIri,
  getParamAfterLastChar,
  isObjectTruthy,
} from "@/Utils/index";
import moment from "moment";
import { EMPTY_DATA } from "@/Constants/errors";
import { isArray, sortBy } from "lodash";
import {
  CARROSSERIES,
  GEARBOXES,
  energies,
  heaterEnergies,
  heaterTypes,
} from "@/Constants/InputsValues";
import { ENTRYPOINT, MY_CONCESSION_ROUTE } from "@/Constants/restApiRoutes";
import {
  LOCALES,
  PROFESSIONAL_PROFILE_SIEGE,
  PROFESSIONAL_PROFILE_SITE,
  REST_API_IMAGE_FORMATS,
  USER_TYPE_PARTICULIER,
  USER_TYPE_PROFESSIONNEL,
} from "@/Constants/global";
import {
  businessApiRequest,
  restApiApiGetMyDealerships,
} from "@/Utils/http/businessApiRequests";
import { DateTime } from "luxon";
import { imageDealershipExtensionRegex } from "@/Constants/regex";

// tools
export const normalize = data => JSON.parse(JSON.stringify(data));

export const formatNumberToLocale = number =>
  new Intl.NumberFormat(LOCALES["fr-FR"]).format(number);

export const formatDateToLocale = (date, locale) =>
  DateTime.fromJSDate(date).toLocaleString({ locale });

const isDataValid = data => isObjectTruthy(data) && !data.error;

export const getArrayData = data => {
  let output = data;

  if (data["@type"] === "hydra:Collection" || data["hydra:member"])
    output = data["hydra:member"];

  if (isArray(data)) output = data;

  return output;
};

const getDealershipImageWithSizeUrls = image => {
  let output = image;
  let extension;

  const indexExtensionStart = image?.filePath?.search(
    imageDealershipExtensionRegex
  );

  if (indexExtensionStart > -1) {
    extension = image.filePath.substring(
      indexExtensionStart,
      image.filePath.length
    );
    output = generateImageWithSizeUrls(image, "name", {
      resourceName: "dealers",
      isImageString: false,
      extension,
    });
    output.fileUrl = !output?.fileUrl
      ? `${ENTRYPOINT}/images/dealers/${output.filePath}`
      : output.fileUrl;
  }

  return output;
};

const generateImageWithSizeUrls = (
  image = {},
  pathName = "name",
  options = {
    resourceName: "offers",
    isImageString: false,
    extension: ".webp",
  }
) => {
  const getObjectFromArrayOfObjects = arrayUrlObjects => {
    return arrayUrlObjects.reduce((accumulator, object) => {
      return {
        ...accumulator,
        [Object.keys(object)[0]]: Object.values(object)[0],
      };
    }, {});
  };

  const { extension, resourceName, isImageString } = options;
  if (isImageString === true) {
    pathName = null;
  }

  image = generateImageFileUrl(image, {
    extension,
    isImageString,
    resourceName,
  });

  let urls = REST_API_IMAGE_FORMATS.map(formatName =>
    generateFormatUrl(image, pathName, formatName, {
      extension,
      isImageString,
      resourceName,
    })
  );

  urls = getObjectFromArrayOfObjects(urls);

  image = Object.assign(image, urls);
  return image;
};

const generateImageFileUrl = (
  image,
  options = {
    isImageString: false,
    extension: ".jpg",
    resourceName: "offers_temp",
  }
) => {
  let output = image;

  if (options?.isImageString === false) {
    output = {
      ...image,
      fileUrl: image?.fileUrl ? `${ENTRYPOINT}${image.fileUrl}` : null,
    };
  }
  if (options?.isImageString === true) {
    output = {
      name: image,
      fileUrl: image
        ? `${ENTRYPOINT}/images/${options?.resourceName}/${image}${options?.extension}`
        : null,
    };
  }

  return output;
};

const generateFormatUrl = (
  image,
  pathName,
  formatName,
  options = {
    extension: ".webp",
    isImageString: false,
    resourceName: "offers",
  }
) => {
  const { extension, resourceName, isImageString } = options;

  let output = {};

  if (image?.[pathName]?.length && resourceName && isImageString === false) {
    output = {
      [formatName]: `${ENTRYPOINT}/images/${resourceName}/${formatName}/${image.name}${extension}`,
    };
  }

  if (isImageString === true) {
    output = {
      [formatName]: `${ENTRYPOINT}/images/${resourceName}/${formatName}/${image.name}${extension}`,
    };
  }
  return output;
};

const sortUnpublishedOffers = offers => {
  const arrayPublished = [];
  const arrayUnpublished = [];
  offers.forEach(offer => {
    offer.isPublished === false
      ? arrayUnpublished.push(offer)
      : arrayPublished.push(offer);
  });
  return { arrayPublished, arrayUnpublished };
};

const getUserField = userField => {
  const dealershipId = getIdFromIri(userField?.requestedFor);
  return {
    ...userField,
    dealershipId,
  };
};

// --------- normalizers ------------ //

export const normalizeBrands = (data, options) => {
  options = {
    isAlphabeticSort: options?.isAlphabeticSort ?? true,
  };

  let normalizedData = [];

  const normalizeOneBrand = datum => ({
    ...datum,
    slug: datum?.slug,
    id: datum?.id || getIdFromIri(datum?.["@id"]) || null,
    family: datum?.family,
    models: normalizeBrandsModels(datum?.models),
    value: datum?.id,
    name: datum?.name,
  });

  const normalizeBrandsModels = data => {
    let normalizedData = [];

    if (isDataValid(data)) {
      normalizedData = data?.map(datum => ({
        ...datum,
        id: datum?.id || getIdFromIri(datum?.["@id"]) || null,
        label: datum?.name,
        value: datum?.name,
        key: datum?.key,
      }));
      normalizedData = sortBy(normalizedData, ["label"]);
    }
    return normalizedData;
  };

  if (isDataValid(data)) {
    normalizedData = getArrayData(data);

    let normalizeCallback = () => normalizeOneBrand(normalizedData);

    if (isArray(normalizedData)) {
      normalizeCallback = () =>
        options?.isAlphabeticSort
          ? sortBy(
              normalizedData.map(datum => normalizeOneBrand(datum)),
              ["name"]
            )
          : normalizedData.map(datum => normalizeOneBrand(datum));
    }

    normalizedData = normalizeCallback();
  }

  return normalize(normalizedData);
};

export const normalizeModels = data => {
  let normalizedData = [];

  // [
  // @id: "/public/api/models/88"
  // @type: "Model"
  // finitions: []
  // id: 88
  // name: "696 F 60ème Anniversaire"
  // slug: "696-f-60eme-anniversaire"
  // ]
  const normalizeOneModel = datum => ({
    ...datum,
    id: datum?.id || getIdFromIri(datum?.["@id"]) || null,
  });

  if (isDataValid(data)) {
    normalizedData = getArrayData(data);
    let normalizeCallback = () => normalizeOneModel(normalizedData);

    if (isArray(normalizedData)) {
      normalizeCallback = () =>
        sortBy(normalizedData.map(normalizeOneModel), ["name"]);
    }

    normalizedData = normalizeCallback();
  }

  return normalize(normalizedData);
};

export const normalizeRanges = data => {
  let normalizedData = [];

  // [
  // @id: "/public/api/ranges/6"
  // @type: "Range"
  // family: {@id: '/public/api/families/1', @type: 'Family', nameFr: 'Camping-car'}
  // nameFr: "Capucine"
  // slugFr: "capucine"
  // ]
  const normalizeOneRange = datum => ({
    slug: datum?.slugFr,
    slugFr: datum?.slugFr,
    id: getIdFromIri(datum?.["@id"]),
    family: datum?.family,
    value: datum?.slugFr,
    name: datum?.nameFr,
  });

  if (isDataValid(data)) {
    normalizedData = getArrayData(data);
    let normalizeCallback = () => normalizeOneRange(normalizedData);

    if (isArray(normalizedData)) {
      normalizeCallback = () =>
        sortBy(normalizedData.map(normalizeOneRange), ["name"]);
    }

    normalizedData = normalizeCallback();
  }
  return normalize(normalizedData);
};

export const normalizeCarriers = data => {
  let normalizedData = [];

  if (isDataValid(data)) {
    const arrayData = getArrayData(data);

    normalizedData = arrayData.map(datum => ({
      id: getIdFromIri(datum?.["@id"]),
      name: datum?.name,
      slug: datum?.slug,
      value: getIdFromIri(datum?.["@id"]),
    }));
    normalizedData = sortBy(normalizedData, ["name"]);
  }
  return normalize(normalizedData);
};

export const normalizeFamilies = data => {
  let normalizedData = [];

  if (isDataValid(data)) {
    const arrayData = getArrayData(data);

    normalizedData = arrayData.map(datum => ({
      id: getIdFromIri(datum?.["@id"]),
      value: getIdFromIri(datum?.["@id"]),
      name: datum?.nameFr,
      slug: datum?.slugFr,
    }));
    normalizedData = sortBy(normalizedData, ["name"]);
  }
  return normalize(normalizedData);
};

export const normalizeCountries = data => {
  let normalizedData = [];

  function normalizeOneCountry(datum) {
    const id = getIdFromIri(datum?.["@id"]);

    return {
      id,
      isoCode: datum.isoCode,
      name: datum.name,
      placeholder: datum.name,
      value: id,
    };
  }

  if (data) {
    data = getArrayData(data);
    let normalizeCallback = () => normalizeOneCountry(data);

    if (isArray(data)) {
      normalizeCallback = () =>
        sortBy(data?.map(normalizeOneCountry), ["name"]).filter(
          country => country.isoCode === "FRA"
        );
    }

    normalizedData = normalizeCallback();
  }

  return normalizedData;
};

export const normalizeTokens = data => ({
  accessToken: data.token,
  refreshToken: data["refresh_token"],
});

export const normalizeUsers = data => {
  let output = null;
  if (data) {
    output = data?.map(datum => ({
      ...datum,
      id: getIdFromIri(datum?.["@id"]),
    }));
  }
  return output;
};

export const normalizeDealers = data => {
  let output = null;

  if (data) {
    data = getArrayData(data);
    let normalizeCallback = () => normalizeDealership(data);

    if (isArray(data)) {
      normalizeCallback = () =>
        sortBy(data?.map(normalizeDealership), ["denomination"]);
    }
    // output = data?.map(datum => ({
    //   ...datum,
    //   id: getIdFromIri(datum?.['@id']')
    // }))

    output = normalizeCallback();
  }

  return output;
};

export const normalizeMyUserDetails = data => {
  if (!isDataValid(data)) {
    throw new Error(EMPTY_DATA);
  }

  const myUserDetails = {
    id: getIdFromIri(data?.["@id"] || data?.id),
    phoneNumber: data.phone,
    emailAddress: data.email,
    civility: data.civ,
    zipCode: data.postcode,
    city: data.city,
    firstAddress: data.address1,
    secondAddress: data.address2,
    country: getIdFromIri(data.country),
    language: data.language,
    userName: data.userName,
    userType: data.userType,
    firstname: data.firstname,
    lastname: data.lastname,
    newsletter: data.newsletter === true ? "yes" : "no",
    userMore: data.userMore,
  };

  if (data.userType === USER_TYPE_PROFESSIONNEL) {
    myUserDetails.companyName = data.denomination;
    myUserDetails.vatNumber = data.vatNumber;
    myUserDetails.tradeRegisterNumber = data.tradeRegisterNumber;

    if (typeof data.userBankData !== "string" && !data?.userBankData?.length) {
      data.userBankData = null;
    }
    myUserDetails.userBankData = data.userBankData;
  }

  if (data.userType === USER_TYPE_PARTICULIER) {
    myUserDetails.birthday = moment(Date.parse(data.birthdate)).format(
      "YYYY-MM-DD"
    );
    data.userMore = {
      ...data.userMore,
      userMorePeople: data.userMore?.userMorePeople?.forEach(
        individual =>
          (individual.birthdate = moment(
            Date.parse(individual.birthdate)
          ).format("YYYY-MM-DD"))
      ),
    };
  }
  return myUserDetails;
};

export const normalizeMyPasswordUpdate = data => {
  return data;
};

export const normalizeMyVehiclesNew = data => {
  // @context: "/public/api/contexts/Offers"
  // @id: "/public/api/offers/388"
  // @type: "Offers"
  // archive: null
  // archived: null
  // city: null
  // family:
  // @id: "/public/api/families/2"
  // @type: "Family"
  // nameFr: "Caravane"
  // slugFr: "caravane"
  // [[Prototype]]: Object
  // finish: null
  // firstImage: null
  // heatPrice: null
  // id: 388
  // inclVatPrice: null
  // isAd: false
  // isBoosted: false
  // isPublished: false
  // isUserVehicule: true
  // latitude: null
  // length: 6
  // longitude: null
  // mainVehicle: null
  // mileage: 40000
  // millesime: 2011
  // model:
  // @id: "/public/api/models/20"
  // @type: "Model"
  // brand:
  // @id: "/public/api/brands/108"
  // @type: "Brand"
  // id: 108
  // name: "BAUER"
  // slug: "bauer"
  // [[Prototype]]: Object
  // id: 20
  // name: "Sky Lander"
  // rangeVehicle:
  // @id: "/public/api/ranges/5"
  // @type: "Range"
  // nameDe: "Caravane"
  // nameEn: "Caravane"
  // nameFr: "Caravane"
  // slugDe: "caravane"
  // slugEn: "caravane"
  // slugFr: "caravane"
  // [[Prototype]]: Object
  // slug: "sky-lander"
  // [[Prototype]]: Object
  // new: null
  // postcode: null
  // published: null
  // registeredSeats: "4"
  // sleepingSeats: "4"
  // type: "occasion"
  // user:
  // @id: "/public/api/users/5"
  // @type: "User"
  // civ: "M"
  // denomination: null
  // firstname: "Sébastien"
  // lastname: "NOBOUR"
  // ref: "P0000005"
  // userType: "particulier"

  const output = { ...data };

  delete output?.["@context"];
  delete output?.["@id"];
  delete output?.["@type"];

  return data;
};

export const normalizeOffers = data => {
  // "@context": "\/public\/api\/contexts\/Offers",
  // "@id": "\/public\/api\/offers",
  // "@type": "hydra:Collection",
  // "hydra:member": [offers...]
  // "hydra:totalItems": 113,
  // "hydra:view": {
  // 		"@id": "\/public\/api\/offers?page=2",
  // 		"@type": "hydra:PartialCollectionView",
  // 		"hydra:first": "\/public\/api\/offers?page=1",
  // 		"hydra:last": "\/public\/api\/offers?page=4",
  // 		"hydra:previous": "\/public\/api\/offers?page=1",
  // 		"hydra:next": "\/public\/api\/offers?page=3"
  // 	},

  const arrayData = getArrayData(data);
  const offers = arrayData.map(datum => {
    datum.slug = generateOfferSlug(datum);
    datum.inclVatPrice = formatNumberToLocale(datum?.inclVatPrice);

    if (datum?.firstImage) {
      datum.firstImage = generateImageWithSizeUrls(datum?.firstImage);
    }

    if (!datum.millesime || datum.millesime === 0)
      datum.millesime = Number(new Date().getFullYear());

    return datum;
  });

  return normalize({
    offers: offers || [],
    firstPageId:
      ~~getParamAfterLastChar(data?.["hydra:view"]?.["hydra:first"], "=") ||
      null,
    lastPageId:
      ~~getParamAfterLastChar(data?.["hydra:view"]?.["hydra:last"], "=") ||
      null,
    nextPageId:
      ~~getParamAfterLastChar(data?.["hydra:view"]?.["hydra:next"], "=") ||
      null,
    previousPageId:
      ~~getParamAfterLastChar(data?.["hydra:view"]?.["hydra:previous"], "=") ||
      null,
    currentPageId:
      ~~getParamAfterLastChar(data?.["hydra:view"]?.["@id"], "=") || null,
    totalItems: ~~data?.["hydra:totalItems"] || 0,
  });
};

export const normalizeMyOffers = (
  data,
  options = { isWithCategories: true }
) => {
  // single offer
  // {
  //       '@id': '/public/api/offers/24',
  //       '@type': 'Offers',
  //       id: 24,
  //       type: 'occasion',
  //       millesime: 2011,
  //       mileage: 60000,
  //       inclVatPrice: null,
  //       registeredSeats: '4',
  //       sleepingSeats: '4',
  //       length: 5,
  //       isAd: false,
  //       isUserVehicule: true,
  //       archive: null,
  //       archived: null,
  //       heatPrice: null,
  //       mainVehicle: null,
  //       new: null,
  //       isBoosted: false,
  //       family: [Object],
  //       model: [Object],
  //       user: [Object],
  //       images: []
  //     },
  // const total = data['hydra:totalItems']

  let offers = [];
  let unpublishedOffers = [];
  const vehicles = [];
  const archives = [];

  options = {
    isWithCategories: options?.isWithCategories ?? true,
  };

  const arrayData = getArrayData(data);

  arrayData.forEach(datum => {
    datum.id = getIdFromIri(datum["@id"]);

    datum.slug = generateOfferSlug(datum);
    datum.inclVatPrice = formatNumberToLocale(datum.inclVatPrice);

    if (datum.firstImage)
      datum.firstImage = generateImageWithSizeUrls(datum.firstImage);

    if (datum?.images) {
      datum.images = datum.images?.map(image =>
        generateImageWithSizeUrls(image)
      );

      if (!datum.firstImage) {
        const firstImage =
          datum.images.find(image => image.position === 0) ||
          datum.images.find(image => image.position === 1);
        if (firstImage) datum.firstImage = firstImage;
      }
    }

    if (options.isWithCategories === true) {
      datum?.isAd === true && !datum.archive && offers.push(datum);
      datum?.isAd === false && !datum.archive && vehicles.push(datum);
      datum?.archive === true && archives.push(datum);

      const { arrayPublished, arrayUnpublished } =
        sortUnpublishedOffers(offers);
      offers = arrayPublished;
      unpublishedOffers = arrayUnpublished;
    }

    options.isWithCategories === false && offers.push(datum);
  });

  return normalize({
    offers,
    vehicles,
    archives,
    unpublishedOffers,
  });
};

export const normalizeMyOffersTemp = (
  data,
  options = { isWithCategories: true }
) => {
  const offers = [];

  const arrayData = getArrayData(data);

  arrayData.forEach(datum => {
    datum.id = getIdFromIri(datum["@id"]);

    datum.slug = generateOfferSlug(datum);
    datum.inclVatPrice = formatNumberToLocale(datum.inclVatPrice);

    if (datum.image)
      datum.firstImage = generateImageWithSizeUrls(datum.image, null, {
        extension: ".jpg",
        isImageString: true,
        resourceName: "offers_temp",
      });

    options?.isWithCategories === false && offers.push(datum);
  });

  return normalize({ offers });
};

export const normalizeOfferById = data => {
  // {
  //   '@context': '/public/api/contexts/Offers',
  //   '@id': '/public/api/offers/43',
  //   '@type': 'Offers',
  //   id: 43,
  //   type: 'occasion',
  //   millesime: 2012,
  //   version: null,
  //   finish: null,
  //   mileage: 400000,
  //   inclVatPrice: null,
  //   registeredSeats: '4',
  //   sleepingSeats: '4',
  //   bedType: 'Couchage appoint',
  //   gearbox: 'Manuelle',
  //   grossWeight: 1250,
  //   chassis: null,
  //   length: 7,
  //   width: 4,
  //   height: 3,
  //   heating: null,
  //   heatingType: null,
  //   dealerWord: null,
  //   visit360: null,
  //   chassisNumber: '0000000MISS239774',
  //   diningSeats: null,
  //   interiorColor: null,
  //   cleanWaterTank: null,
  //   wasteWaterTank: null,
  //   doorsNumber: null,
  //   fiscalPower: 5,
  //   dinPower: 130,
  //   energy: 'Électrique',
  //   unladenWeight: null,
  //   firstHand: null,
  //   popUpRoof: false,
  //   postcode: null,
  //   city: 'Les Clayes sous bois',
  //   phoneHidden: true,
  //   shownName: 'SebastienPart',
  //   releaseDate: null,
  //   isAd: true,
  //   isUserVehicule: true,
  //   archive: null,
  //   archived: null,
  //   heatPrice: null,
  //   mainVehicle: null,
  //   new: null,
  //   isBoosted: false,
  //   country: '/public/api/countries/1',
  //   family: {
  //     '@id': '/public/api/families/2',
  //     '@type': 'Family',
  //     nameFr: 'Caravane',
  //     slugFr: 'caravane'
  //   },
  //   model: {
  //     '@id': '/public/api/models/24',
  //     '@type': 'Model',
  //     id: 24,
  //     name: 'Globe Trotter',
  //     slug: 'globe-trotter',
  //     brand: {
  //       '@id': '/public/api/brands/21',
  //       '@type': 'Brand',
  //       id: 21,
  //       name: 'AUTO ROLLER',
  //       slug: 'auto-roller'
  //     },
  //     rangeVehicle: {
  //       '@id': '/public/api/ranges/5',
  //       '@type': 'Range',
  //       nameFr: 'Caravane',
  //       slugFr: 'caravane',
  //       nameEn: 'Caravane',
  //       slugEn: 'caravane',
  //       nameDe: 'Caravane',
  //       slugDe: 'caravane'
  //     }
  //   },
  //   user: {
  //     '@id': '/public/api/users/5',
  //     '@type': 'User',
  //     userType: 'particulier',
  //     denomination: null,
  //     ref: 'P0000005',
  //     civ: 'M',
  //     lastname: 'NOBOUR',
  //     firstname: 'Sébastien'
  //   },
  //   equipments: [],
  //   images: [],
  //   options: [],
  //   warranties: [],
  //   engineBrand: null,
  //   carrier: '/public/api/carriers/9'
  // }

  const firstImage = generateImageWithSizeUrls(data?.firstImage);
  const images = data?.images?.map(image => generateImageWithSizeUrls(image));

  // TODO Missing username or better changing field shownName into 'username' || 'title'
  const output = {
    ...data,
    brand: data?.model?.brand,
    country: getIdFromIri(data?.country),
    carrier: data?.carrier,
    millesime: data?.millesime,
    name: data?.model?.name,
    bedType: data?.bedType,
    firstImage: firstImage || {},
    images: images || [],
    registeredSeats: data?.registeredSeats,
    sleepingSeats: data?.sleepingSeats,
    length: data?.length,
    width: data?.width,
    height: data?.height,
    popUpRoof: data?.popUpRoof,
    mileage: data?.mileage,
    gearbox:
      GEARBOXES?.filter(gearbox => gearbox.key === data?.gearbox)?.[0]?.value ||
      GEARBOXES?.filter(gearbox => gearbox?.value === data?.gearbox)?.[0]
        ?.value ||
      "",
    energy:
      energies?.filter(energy => energy?.key === data?.energy)?.[0]?.value ||
      "",
    dinPower: data?.dinPower,
    fiscalPower: data?.fiscalPower,
    chassisNumber: data?.chassisNumber,
    grossWeight: data?.grossWeight,
    unladenWeight: data?.unladenWeight,
    inclVatPrice: formatNumberToLocale(data?.inclVatPrice),
    zipCode: data?.postcode,
    city: data?.city,
    heaterEnergy:
      heaterEnergies.filter(energy => energy.key === data?.heating)?.[0]
        ?.value || "",
    heaterType:
      heaterTypes.filter(energy => energy.key === data?.heatingType)?.[0]
        ?.value || "",
    introduction: data?.dealerWord,
    shownName: data?.shownName,
    phoneHidden: data?.phoneHidden ?? false,
    range:
      CARROSSERIES?.filter(
        bodywork => bodywork.name === data?.model?.rangeVehicle?.nameFr
      )?.[0]?.value || "",
    releaseDate: formatDateToFr(data?.releaseDate),
    slug: generateOfferSlug(data),
    user: {
      ...data?.user,
      id: getIdFromIri(data?.user?.["@id"]),
    },
  };
  delete output["@id"];
  delete output["@context"];
  delete output["@type"];

  // range: offer.range,
  //         brand: offer.brand,
  //         country: offer.country,
  //         millesime: offer.millesime,
  //         name: offer.name,
  //         bedType: offer.bedType,
  //         registeredSeats: offer.registeredSeats,
  //         sleepingSeats: offer.sleepingSeats,
  //         length: offer.length,
  //         width: offer.width,
  //         height: offer.height,
  //         popUpRoof: offer.popUpRoof,
  //         mileage: offer.mileage,
  //         carrier: offer.carrier,
  //         gearbox: offer.gearbox,
  //         energy: offer.energy,
  //         dinPower: offer.dinPower,
  //         fiscalPower: offer.fiscalPower,
  //         chassisNumber: offer.chassisNumber,
  //         grossWeight: offer.grossWeight,
  //         inclVatPrice: offer.inclVatPrice,
  //         zipCode: offer.zipCode,
  //         city: offer.city,
  //         heaterEnergy: offer.heaterEnergy,
  //         heaterType: offer.heaterType,
  //         introduction: offer.introduction,
  //         shownName: offer.shownName,
  //         phoneHidden: offer.phoneHidden,
  // TODO add normalize on all normalizers
  return normalize(output);
};

export const normalizeMyOfferById = data => {
  const firstImage = generateImageWithSizeUrls(data?.firstImage);
  const images = data?.images?.map(image => generateImageWithSizeUrls(image));

  const output = {
    ...data,
    brand: data?.model?.brand,
    country: getIdFromIri(data?.country),
    carrier: data?.carrier,
    millesime: data?.millesime,
    name: data?.model?.name,
    bedType: data?.bedType,
    firstImage: firstImage || {},
    images: images || [],
    registeredSeats: data?.registeredSeats,
    sleepingSeats: data?.sleepingSeats,
    length: data?.length,
    width: data?.width,
    height: data?.height,
    popUpRoof: data?.popUpRoof,
    mileage: data?.mileage,
    gearbox:
      GEARBOXES?.filter(gearbox => gearbox.key === data?.gearbox)?.[0]?.value ||
      GEARBOXES?.filter(gearbox => gearbox?.value === data?.gearbox)?.[0]
        ?.value ||
      "",
    energy:
      energies?.filter(energy => energy?.key === data?.energy)?.[0]?.value ||
      "",
    dinPower: data?.dinPower,
    fiscalPower: data?.fiscalPower,
    chassisNumber: data?.chassisNumber,
    grossWeight: data?.grossWeight,
    unladenWeight: data?.unladenWeight,
    inclVatPrice: data?.inclVatPrice,
    zipCode: data?.postcode,
    city: data?.city,
    heaterEnergy:
      heaterEnergies.filter(energy => energy.key === data?.heating)?.[0]
        ?.value || "",
    heaterType:
      heaterTypes.filter(energy => energy.key === data?.heatingType)?.[0]
        ?.value || "",
    introduction: data?.dealerWord,
    shownName: data?.shownName,
    phoneHidden: data?.phoneHidden ?? false,
    range:
      CARROSSERIES?.filter(
        bodywork => bodywork.name === data?.model?.rangeVehicle?.nameFr
      )?.[0]?.value || "",
    releaseDate: formatDateToFr(data?.releaseDate),
    slug: generateOfferSlug(data),
    user: {
      ...data?.user,
      id: getIdFromIri(data?.["@id"]),
    },
    game: data?.concours || null,
  };
  delete output["@id"];
  delete output["@context"];
  delete output["@type"];

  // range: offer.range,
  //         brand: offer.brand,
  //         country: offer.country,
  //         millesime: offer.millesime,
  //         name: offer.name,
  //         bedType: offer.bedType,
  //         registeredSeats: offer.registeredSeats,
  //         sleepingSeats: offer.sleepingSeats,
  //         length: offer.length,
  //         width: offer.width,
  //         height: offer.height,
  //         popUpRoof: offer.popUpRoof,
  //         mileage: offer.mileage,
  //         carrier: offer.carrier,
  //         gearbox: offer.gearbox,
  //         energy: offer.energy,
  //         dinPower: offer.dinPower,
  //         fiscalPower: offer.fiscalPower,
  //         chassisNumber: offer.chassisNumber,
  //         grossWeight: offer.grossWeight,
  //         inclVatPrice: offer.inclVatPrice,
  //         zipCode: offer.zipCode,
  //         city: offer.city,
  //         heaterEnergy: offer.heaterEnergy,
  //         heaterType: offer.heaterType,
  //         introduction: offer.introduction,
  //         shownName: offer.shownName,
  //         phoneHidden: offer.phoneHidden,

  // TODO add normalize on all normalizers
  return normalize(output);
};

export const normalizeMyBankDataRequestCurrent = data =>
  data?.["hydra:member"]?.length ? data["hydra:member"] : data;

export const normalizeUserById = data => ({
  ...data,
  id: getIdFromIri(data?.["@id"]),
});

export const normalizeMyFavorites = data => {
  // {
  //   '@context': '/public/api/contexts/User',
  //   '@id': '/public/api/dealers',
  //   '@type': 'hydra:Collection',
  //   'hydra:member': [
  //     {
  //       '@type': 'UserFavorite',
  //       '@id': '_:5389',
  //       archive: false,
  //       archived: null,
  //       offer: [Object]
  //     },
  //     {
  //       '@type': 'UserFavorite',
  //       '@id': '_:5388',
  //       archive: false,
  //       archived: null,
  //       offer: [Object]
  //     }
  //   ],
  //   'hydra:totalItems': 2,
  //   'hydra:search': {
  //     '@type': 'hydra:IriTemplate',
  //     'hydra:template': '/public/api/my/favorites{?brand,brand[]}',
  //     'hydra:variableRepresentation': 'BasicRepresentation',
  //     'hydra:mapping': [ [Object], [Object] ]
  //   }
  // }
  data = data?.["hydra:member"] || data;

  data = data?.filter(
    favorite => favorite?.archive === false && favorite?.offer
  );

  return data?.map(favorite =>
    normalize({
      ...favorite,
      offer: {
        ...favorite.offer,
        id: getIdFromIri(favorite.offer?.["@id"]),
        slug: generateOfferSlug(favorite.offer),
        firstImage: {
          ...generateImageWithSizeUrls(favorite.offer?.firstImage),
        },
      },
    })
  );
};

export const normalizeMy = async (data, accessToken) => {
  let output = null;

  if (data) {
    output = data;
    output.id = getIdFromIri(output?.["@id"]);
    output.seat = null;
    output.site = null;

    let nbOffers = Number(output.nbOffers);
    let nbOccasions = Number(output.nbOccasions);
    let nbNeufs = Number(output.nbNeufs);

    nbOffers = isNaN(Number(output.nbOffers)) ? 0 : nbOffers;
    nbOccasions = isNaN(Number(output.nbOccasions)) ? 0 : nbOccasions;
    nbNeufs = isNaN(Number(output.nbNeufs)) ? 0 : nbNeufs;

    const sumNbNeufsOccasions = nbOccasions + nbNeufs;
    output.nbOffers =
      nbOffers < sumNbNeufsOccasions ? sumNbNeufsOccasions : nbOffers;

    try {
      if (output.profil === PROFESSIONAL_PROFILE_SIEGE) {
        output.seat = await restApiApiGetMyDealerships({ accessToken });
      }

      if (output.profil === PROFESSIONAL_PROFILE_SITE) {
        output.site = await businessApiRequest(MY_CONCESSION_ROUTE, {
          accessToken,
        });
      }

      if (output.userType === USER_TYPE_PROFESSIONNEL) {
        output.userRemoveIncurred = output.userRemoveIncurred.map(incurred =>
          getUserField(incurred)
        );
        output.userRemoveRequests = output.userRemoveRequests.map(request =>
          getUserField(request)
        );

        if (output?.site) {
          // output.site.isRemoved = !!output.userRemoveIncurred.find(incurred => incurred.dealershipId === output.site.id)
          output.site.isRemovalRequested = !!output.userRemoveRequests.find(
            request => request.dealershipId === output.site.id
          );
        }

        if (output?.seat?.concessions?.length) {
          output.seat.concessions = output.seat.concessions.map(dealership => ({
            ...dealership,
            // isRemoved: !!output.userRemoveIncurred.find(incurred => incurred.dealershipId === dealership.id),
            isRemovalRequested: !!output.userRemoveRequests.find(
              request => request.dealershipId === dealership.id
            ),
          }));
        }
      }

      if (output.userType === USER_TYPE_PARTICULIER) {
        output.searches = normalizeMySavedResearches(output.searches);
      }
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
    }
  }
  return output;
};

export const normalizeMyDealerships = async data => {
  let output = null;

  if (data) {
    output = data;
    output.id = getIdFromIri(output?.["@id"]);
    output.concessions.forEach(dealership => {
      dealership.id = getIdFromIri(dealership?.["@id"]);
      dealership.brands = dealership.brand?.map(brand => ({
        ...brand,
        id: getIdFromIri(brand["@id"]),
      }));
      dealership.images = dealership.images?.map(image =>
        getDealershipImageWithSizeUrls(image)
      );
    });
  }

  return output;
};

export const normalizeMyUserDashboard = async data => {
  let output = null;
  if (data) {
    output = data;
    output.id = getIdFromIri(output?.["@id"]);
    output.concessions = output.concessions?.map(dealership => ({
      ...dealership,
      id: getIdFromIri(dealership?.["@id"]),
      brands: dealership?.brand?.map(brand => ({
        ...brand,
        id: getIdFromIri(brand["@id"]),
      })),
    }));
  }
  return output;
};

export const normalizeMyDealership = data => {
  let output = null;

  if (data) {
    output = data;
    output.id = getIdFromIri(output?.["@id"]);
    // TODO error brand is an array and should be called brands on business API
    output.brands = output?.brand?.map(brand => ({
      ...brand,
      id: getIdFromIri(brand["@id"]),
    }));
    output.images = output.images?.map(image =>
      getDealershipImageWithSizeUrls(image)
    );
  }
  return output;
};

export const normalizeDealership = data => {
  let output = null;

  if (data) {
    output = data;
    output.id = getIdFromIri(output?.["@id"]);
    output.phoneHidden = data?.phoneHidden ?? false;
    output.brands = sortBy(output?.brand, ["slug"])?.map(brand => ({
      ...brand,
      id: getIdFromIri(brand["@id"]),
    }));
    output.images = output.images?.map(image =>
      getDealershipImageWithSizeUrls(image)
    );
  }

  return output;
};

export const normalizeMySavedResearches = data => {
  const normalizeOneResearch = research => ({
    ...research,
    id: getIdFromIri(research?.["@id"]),
    dateCreation: DateTime?.fromISO(research.created).toLocaleString(),
  });

  let callback = data => normalizeOneResearch(data);

  if (data?.["hydra:member"] || isArray(data)) {
    callback = data => {
      const arrayValues = data?.["hydra:member"] ?? data;
      return arrayValues.map(normalizeOneResearch);
    };
  }

  return callback(data);
};

export const normalizeFilters = response => {
  let output;

  if (response?.code === 200 && response?.content) {
    output = response.content;
  }

  return output;
};
