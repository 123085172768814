import React from "react";
import PropTypes from "prop-types";
import svgList from "@/Utils/svgList";
import css from "./Svg.module.css";

// Liste des composants SVG importés depuis public/images/svg/index.js
import { listClassesTostring } from "@/Utils/index";

/**
 *
 * @param classNames  - les classes associées à la classe SVG
 * @param classNamesGlobal - les classes passées par un composant parent
 * @param selection - l'appel du svg
 * @returns {JSX.Element|string}
 * @constructor
 */
const Svg = ({ classNames, classNamesGlobal, selection }) => {
  const SvgValue = svgList.find(el => el.key === selection)?.value;

  const svgClass = css[SvgValue?.Class];
  const otherClasses = classNames
    ? " " + listClassesTostring(classNames, css)
    : "";
  const globalClass = classNamesGlobal ? " " + classNamesGlobal : "";

  return SvgValue?.Component ? (
    <SvgValue.Component
      viewBox={SvgValue.ViewBox}
      className={`${svgClass}${globalClass}${otherClasses}`}
    />
  ) : (
    ""
  );
};

Svg.propTypes = {
  classNames: PropTypes.string,
  classNamesGlobal: PropTypes.string,
  selection: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default Svg;
