import { useCallback } from "react";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import { restApiPostLogout } from "@/Utils/http/businessApiRequests";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import Btn from "@/Shared/Ui/Btn";
import { PAGE_CONNEXION_INSCRIPTION } from "@/Constants/pageRoutes";

export const BtnDisconnectUser = ({ onClick }) => {
  const router = useRouter();
  const { data } = useSession();

  const disconnectUser = useCallback(async () => {
    onClick();
    if (data?.user?.token) {
      await restApiPostLogout(data.user.token);
    }

    await signOut({ redirect: false });
    router.push(PAGE_CONNEXION_INSCRIPTION);
  }, [data]);

  return (
    <Btn
      svg='ico-deconnexion'
      label='Déconnexion'
      order='icotext'
      className={`${uiBtn["u-btn-text"]} ${uiBtn["u-btn-rounded-shadow"]} 
      u-box-shadow ${uiBtn["u-btn-textico"]} ${uiBtn["u-btn-noborder"]} 
      ${uiBtn["u-btn-hoverSlide"]} ${uiBtn["u-btn-hoverSlide--primary"]}`}
      onClick={disconnectUser}
    />
  );
};

export default BtnDisconnectUser;
