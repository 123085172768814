import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_V3_SITE_KEY } from "@/Constants/global";

const ReCaptchaProvider = ({ children }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={RECAPTCHA_V3_SITE_KEY}
    language='fr'
    useRecaptchaNet='true'
  >
    {children}
  </GoogleReCaptchaProvider>
);

export default ReCaptchaProvider;
