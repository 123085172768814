/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes NextCacheSlugs
 * @description Fichier de constantes de paths du cache SWR de Next.
 * SWR a besoin de ces paths pour définir un emplacement de cache
 */

/**
 *
 * @type {string}
 */
export const NEXT_CACHE_SLUG = "cache";

/**
 * @constant
 * @type {string}
 */
export const NEXT_CACHE_SLUG_STRAPI = `${NEXT_CACHE_SLUG}-strapi`;

/**
 * @constant
 * @type {string}
 */
export const NEXT_CACHE_SLUG_BUSINESS_API = `${NEXT_CACHE_SLUG}-business`;

/**
 * @constant
 * @type {string}
 */
export const NEXT_CACHE_SLUG_CATEGORIES = `${NEXT_CACHE_SLUG_STRAPI}-categories`;

/**
 * @constant
 * @type {string}
 */
export const NEXT_CACHE_SLUG_GLOBAL_SEO = `${NEXT_CACHE_SLUG_STRAPI}-global-seo`;
