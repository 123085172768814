import { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import css from "@/Shared/Header/Header.module.css";
import Link from "next/link";
import { appearSousMenuRaw, disappearSousMenuRaw } from "@/Shared/Animate";
import UseMediaQueries from "@/Shared/Hooks/UseMediaQueries";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";

const SousMenuBlog = ({ toggleoffMenuMobile }) => {
  const router = useRouter();

  const {
    state: { categoriesInfos },
  } = useContext(GlobalContext);

  const [estDeplie, toggleMenu] = useState(false);

  let sousMenuUnivers = useRef(null);

  let timeoutMenu;

  const mq = UseMediaQueries();
  const mqmin60 = mq.min60;
  const mqmax60 = mq.max60;

  useEffect(() => {
    if (mqmin60)
      estDeplie
        ? appearSousMenuRaw(sousMenuUnivers, 0, 20)
        : disappearSousMenuRaw(sousMenuUnivers, 0, 40);
  }, [estDeplie]);

  return (
    <>
      <div
        className={`${css["c-nav-main__has-sous-menu"]}`}
        onMouseEnter={() => {
          if (mqmin60) {
            window.clearTimeout(timeoutMenu);
          }
        }}
        onMouseLeave={() => {
          if (mqmin60) {
            window.clearTimeout(timeoutMenu);
            timeoutMenu = setTimeout(() => {
              toggleMenu(false);
            }, 250);
          }
        }}
      >
        {/* -- RECHERCHE BLOG -- */}
        <Link href='/blog'>
          <a
            className={`f-primary 
              ${css["c-nav-main__lien-single"]}
              ${
                router.pathname === "/blog"
                  ? css["c-nav-main__lien-single--actif"]
                  : ""
              }
            `}
            onClick={toggleoffMenuMobile}
            onMouseEnter={() => {
              if (mqmin60) {
                toggleMenu(true);
              }
            }}
          >
            Blog
          </a>
        </Link>

        <div
          className={`${css["c-nav-main__sous-menu"]} u-box-white u-box-shadow`}
          ref={mqmin60 ? el => (sousMenuUnivers = el) : undefined}
        >
          {categoriesInfos?.map(category => (
            <Link href={`/blog/${category.slug}`} key={category.slug}>
              <a>{category.name}</a>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

SousMenuBlog.propTypes = {
  //
};

export default SousMenuBlog;
