import { updateAdsFilterFromResearch } from "@/Utils/adsFilter/updateAdsFilterFromResearch";

export const getAdsFilterFromResearch = async ({
  adsFilter,
  research = null,
  accessToken = null,
}) => {
  let output = null;

  if (accessToken) {
    output = await updateAdsFilterFromResearch({
      accessToken,
      adsFilter,
      research,
    });
  }

  return output;
};
