import { appearSousMenuRaw, disappearSousMenuRaw } from "@/Shared/Animate";

export const appearDisappearMenus = (
  etat,
  sousMenuCompte,
  opened,
  setOpened
) => {
  ////////////////////////////////////////////////////////////////////
  if (etat) {
    // APPEAR
    ////////////////////////////////////////////////////////////////////
    if (!opened) {
      appearSousMenuRaw(sousMenuCompte, 0, 20);
      setOpened(true);
    }
    ////////////////////////////////////////////////////////////////////
  } else {
    // DISAPPEAR
    if (opened) {
      disappearSousMenuRaw(sousMenuCompte, 0, 40);
      setOpened(false);
    }
  }
  ////////////////////////////////////////////////////////////////////
};
