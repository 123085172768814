import {
  handleSessionFetchingByPhase,
  prepareServerProps,
} from "@/Utils/index";
import { normalize } from "@/Utils/http/httpRequests";

export const getInitialPropsWithRedux =
  store =>
  async ({ Component, context }) => {
    const session = await handleSessionFetchingByPhase(
      process.env.NEXT_PHASE,
      context
    );

    // fetching of these data can not be handled with by GlobalContext and kept here to not lose data for SEO

    const { props, params } = await prepareServerProps(session, context);

    // should not return session
    const pageProps = normalize({
      error: props?.error,
      ...(Component.getInitialProps
        ? await Component.getInitialProps({ ...context, store })
        : {}),
      ...params,
    });

    return {
      pageProps,
    };
  };
