/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes PagesRoutes
 * @description Fichier de constantes de pages sur Next.js A utiliser afin de faire appel sans erreurs de frappes,
 * à des pages existantes lors de redirections via le routeur
 */

/**
 * @constant
 * @type {string}
 */
const ESPACE_CLIENT = "/espace-client/";

/**
 * @constant
 * @type {string}
 */
const ESPACE_PRO = "/espace-pro/";

/**
 * @constant
 * @type {string}
 */
export const PAGE_INDEX = "/";

/**
 * @constant
 * @type {string}
 */
export const PAGE_404 = "/404";

/**
 * @constant
 * @type {string}
 */
export const PAGE_RECHERCHE_VEHICULE = "/recherche-vehicule-loisirs";

/**
 * @constant
 * @type {string}
 */
export const PAGE_VENDEURS = "/vendeurs-vehicules-loisirs";

/**
 * @constant
 * @type {string}
 */
export const PAGE_BLOG = "/blog";

/**
 * @constant
 * @type {string}
 */
export const PAGE_CONCEPT_WAG = "/concept-weekandgo";

/**
 * @constant
 * @type {string}
 */
export const PAGE_JEU_CONCOURS_1_AN_CARBURANT_GRATUIT =
  "/un-an-de-carburant-a-gagner";

/**
 * @constant
 * @type {string}
 */
export const PAGE_ANNONCE = "/annonce/[slug]";

/**
 * @constant
 * @type {string}
 */
export const PAGE_CONNEXION_INSCRIPTION = "/connexion-inscription";

/**
 * @constant
 * @type {string}
 */
export const PAGE_CREATION_COMPTE_PRO = "/creation-compte-pro";

/**
 * @constant
 * @type {string}
 */
export const PAGE_CREATION_COMPTE_PARTICULIER = "/creation-compte";

/**
 * @constant
 * @type {string}
 */
export const PAGE_PARTICULIER_HOME = `${ESPACE_CLIENT}home`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PARTICULIER_COMPTE = `${ESPACE_CLIENT}mon-compte`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PARTICULIER_MES_VEHICULES = `${ESPACE_CLIENT}mes-vehicules`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PARTICULIER_RECHERCHES_SAUVEGARDEES = `${ESPACE_CLIENT}recherches-sauvegardees`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PRO_HOME = `${ESPACE_PRO}home`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PRO_MON_COMPTE = `${ESPACE_PRO}mon-compte`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PRO_FLUX = `${ESPACE_PRO}flux`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PRO_MES_CONCESSIONS = `${ESPACE_PRO}mes-concessions`;

/**
 * @constant
 * @type {string}
 */
export const PAGE_PRO_MA_CONCESSION = `${ESPACE_PRO}ma-concession`;

/**
 * @constant
 * @type {{PAGE_PRO_CONCESSION_DETAILS_EDITION: string, PAGE_PRO_CONCESSION_SUPPRESSION: string, PAGE_PRO_CONCESSION_INFO_ADD: string}}
 */
export const DYNAMIC_PAGE_ROUTES = {
  PAGE_PRO_CONCESSION_INFO_ADD: `${ESPACE_PRO}concession/infos/ajout`,
  PAGE_PRO_CONCESSION_DETAILS_EDITION: `${ESPACE_PRO}concession/details/edition`,
  PAGE_PRO_CONCESSION_SUPPRESSION: `${ESPACE_PRO}concession/suppression`,
};
