import PropTypes from "prop-types";
import { SpinnerCircular } from "spinners-react";
import Svg from "@/Shared/Svg";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { IS_NOT_CONNECTED } from "@/Constants/errors";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import React from "react";

const Btn = ({
  type = "button",
  query,
  svg,
  order,
  label = "",
  svgHover,
  children,
  path,
  isLoading = false,
  isRestrictedToConnectedUser = false,
  ...props
}) => {
  const { data: sessionData } = useSession();
  const { setGlobalError } = React.useContext(GlobalContext);

  const getWrapper = type => {
    let Wrapper;

    switch (type) {
      case "routeLink":
        // eslint-disable-next-line react/display-name
        Wrapper = ({ children, path, query, ...props }) => (
          <Link
            href={{
              pathname: path,
              query: query,
            }}
            for
          >
            <a {...props}>{children}</a>
          </Link>
        );
        break;
      case "link":
      case "text":
        // eslint-disable-next-line react/display-name
        Wrapper = ({ children, ...props }) => <a {...props}>{children}</a>;
        break;
      case "externalLink":
        // eslint-disable-next-line react/display-name
        Wrapper = ({ children, ...props }) => (
          <a target='_blank' rel='noreferrer noopener' {...props}>
            {children}
          </a>
        );
        break;
      case "button":
      case "submit":
      case "":
      default:
        // eslint-disable-next-line react/display-name
        Wrapper = ({ type, children, ...props }) => (
          <button
            type={props.type}
            {...props}
            className={`u-btn ${props.className ?? ""}`}
          >
            {children}
          </button>
        );
        break;
    }

    Wrapper.displayName = "BtnWrapper";

    return Wrapper;
  };

  label = !isLoading ? label : "";

  const Wrapper = getWrapper(type);

  const getOnClickCallback = () => {
    let callback = props.onClick ?? null;

    if (isRestrictedToConnectedUser && !sessionData?.user?.accessToken)
      callback = () => setGlobalError(IS_NOT_CONNECTED);

    return callback;
  };

  const onClickCallback = getOnClickCallback();

  return (
    <Wrapper
      type={type}
      query={query}
      path={path}
      className={props?.className}
      disabled={props.disabled}
      {...props}
      onClick={onClickCallback}
    >
      {order === "icotext" && svg && (
        <Svg selection={svg} classNames={svgHover ?? ""} />
      )}
      {label && <span className={"u-btn__content"}>{label}</span>}
      {isLoading ? (
        <span className={"u-btn__spinner"}>
          <SpinnerCircular
            size={24}
            thickness={100}
            speed={100}
            secondaryColor='rgb(242, 242, 242)'
            color='rgb(255, 134, 116)'
          />
        </span>
      ) : (
        children
      )}
      {order !== "icotext" && svg && (
        <Svg selection={svg} classNames={svgHover ?? ""} />
      )}
    </Wrapper>
  );
};

Btn.propTypes = {
  type: PropTypes.string,
  order: PropTypes.string,
  classNames: PropTypes.string,
  svgHover: PropTypes.string,
  svg: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  path: PropTypes.string,
  children: PropTypes.any,
};

export default Btn;
