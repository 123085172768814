import { Response } from "miragejs";

export const getAccessToken = authorization =>
  authorization.split("Bearer ")[1];

export const authenticate = async (schema, request, next) => {
  let output = false;

  try {
    let response = null;

    if (!request.requestHeaders.Authorization) {
      response = new Response(401, {}, { message: "No JWT Token" });
    }

    if (request.requestHeaders.Authorization.length < 5) {
      response = new Response(401, {}, { message: "Invalid JWT Token" });
    }

    const user = await schema.db.users.findBy({
      accessToken: getAccessToken(request.requestHeaders.Authorization),
    });

    if (!user) {
      response = new Response(404, {}, { message: "User not found." });
    }

    output = response || next(schema, request, user);
  } catch (error) {
    console.error(error);
  }

  return output;
};
