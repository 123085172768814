import { useContext, useEffect, useState } from "react";
import DialogInfo from "@/Shared/Ui/DialogInfo";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { DIALOG_TYPE_CONFIRM, DIALOG_TYPE_INFO } from "@/Constants/global";
import DialogConfirmation from "@/Shared/Ui/DialogConfirmation";
import { IS_NOT_SUBSCRIBER } from "@/Constants/codes";

const GlobalModalHandler = () => {
  const {
    state: { globalModalProps, globalError },
    setGlobalModalProps,
  } = useContext(GlobalContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalDialog, setModalDialog] = useState(null);

  const getDialogFromGlobalModalProps = props => {
    let element;

    const dialogType = props?.dialogType ?? DIALOG_TYPE_INFO;
    let onSubmit = () => {};
    let onClose = () => {};

    if (props.onSubmit) onSubmit = props.onSubmit;

    if (props.onClose) onClose = props.onClose;

    props = {
      objet: props?.objet ?? "Information",
      message: props?.message ?? "",
      textButtonSubmit: props.textButtonSubmit ?? props?.submit ?? null,
      textButtonClose: props.textButtonClose ?? props?.deny ?? null,
      isClosed: false,
      isSubmitted: false,
    };

    const dialogProps = {
      open: true,
      objet: props.objet,
      message: props.message,
      submit: props.submit,
      handleClose: async () => {
        setGlobalModalProps({ ...props, isClosed: true });
        await onClose();
        setIsDialogOpen(false);
      },
      handleSubmit: async () => {
        setGlobalModalProps({ ...props, isSubmitted: true });
        await onSubmit();
        setIsDialogOpen(false);
      },
      textButtonClose: props.textButtonClose,
      textButtonSubmit: props.textButtonSubmit,
    };

    if (dialogType === DIALOG_TYPE_INFO)
      element = <DialogInfo {...dialogProps} />;

    if (dialogType === DIALOG_TYPE_CONFIRM)
      element = <DialogConfirmation {...dialogProps} />;

    return element;
  };

  const getModalPropsFromCode = globalModalProps => {
    let output;

    switch (globalModalProps?.code) {
      case IS_NOT_SUBSCRIBER:
        output = {
          dialogType: DIALOG_TYPE_INFO,
          objet: "Votre compte est désactivé",
          message: (
            <>
              <span>
                Votre compte est désactivé. Les rubriques &lsquo;Tableau de
                bord&lsquo; et &lsquo;Mon Flux&lsquo; ne sont donc plus
                accessibles.
              </span>
              <br />
              <br />
              <span>
                Si vous souhaitez continuer à utiliser les fonctionnalités du
                site, merci de nous contacter sur
                <a href='mailto:hello@weekandgo.com'> hello@weekandgo.com</a>.
              </span>
            </>
          ),
          textButtonClose: "Fermer",
        };

        break;

      default:
        output = { globalModalProps, code: null };
    }

    return output;
  };

  useEffect(() => {
    if (!globalModalProps?.isSubmitted && !globalModalProps?.isClosed) {
      if (globalModalProps && !globalModalProps?.code) {
        const element = getDialogFromGlobalModalProps(globalModalProps);

        if (element) {
          setModalDialog(element);
          setIsDialogOpen(true);
        }
      }

      if (globalModalProps?.code) {
        const updatedGlobalModalProps = getModalPropsFromCode(globalModalProps);
        setGlobalModalProps(updatedGlobalModalProps);
      }
    }

    if (!globalModalProps) {
      setIsDialogOpen(false);
    }
  }, [globalModalProps]);

  useEffect(() => {
    if (!isDialogOpen && globalModalProps) {
      setGlobalModalProps();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (globalError) {
      setGlobalModalProps();
      setIsDialogOpen(false);
    }
  }, [globalError]);

  useEffect(() => {
    return () => {
      setIsDialogOpen(false);
      setGlobalModalProps();
    };
  }, []);

  return isDialogOpen ? modalDialog : "";
};

export default GlobalModalHandler;
