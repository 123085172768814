import { ADS_FILTERS } from "@/Constants/filters";
import { getIntegerFromString } from "@/Utils/index";

export const getAdsFilterValues = ({ filterData }) => {
  const filterValues = {
    type: [],
    "user.userType": [],
    "model.rangeVehicle": [],
    bedType: [],
    length: [],
    height: [],
    width: [],
    inclVatPrice: [],
    postcode: [],
    radius: [],
    registeredSeats: [],
    sleepingSeats: [],
    mileage: [],
    millesime: [],
    "model.brand": [],
    model: [],
    gearbox: [],
    grossWeight: [],
    heating: [],
    dealers: [],
  };

  const arrayKeys = [
    "length",
    "height",
    "width",
    "inclVatPrice",
    "registeredSeats",
    "sleepingSeats",
    "mileage",
    "millesime",
  ];

  for (const key of arrayKeys) {
    let min = getIntegerFromString(filterData[`${key}Min`], {
      key,
      limit: "min",
    });

    const max = getIntegerFromString(filterData[`${key}Max`], {
      key,
      limit: "max",
    });

    const refMin = ADS_FILTERS.size?.[key]?.min ?? ADS_FILTERS?.[key]?.min;

    if (min < refMin) min = ADS_FILTERS[key].min;

    filterValues[key] = [min, max];
  }

  filterValues.type = [...filterData.type];
  filterValues["user.userType"] = [...filterData.userType];
  filterValues.bedType = [...filterData.bedType];
  filterValues.gearbox = filterData.gearbox.map(slug => slug?.toLowerCase());
  filterValues.heating = [...filterData.heating];

  filterValues.postcode = [ADS_FILTERS.localisation.values[0].value];
  filterValues.radius = [ADS_FILTERS.localisation.values[1].value];
  filterValues.grossWeight = [
    ...ADS_FILTERS.grossWeight.values.map(value => value.label),
  ];
  return filterValues;
};
