import SVGS from "@/Images/svg";

/**
 * @constant svgList
 * @description liste de concordance des données svg existantes
 * @namespace Constants
 * @module Constantes Svg
 * @type {array<object>}
 */
const svgList = [
  {
    key: "logo-inline",
    value: {
      Component: SVGS.LogoInline,
      Class: "svg__logo-inline",
      ViewBox: "0 0 400 49",
    },
  },
  {
    key: "ico-user",
    value: {
      Component: SVGS.IcoUser,
      Class: "svg__ico-user",
      ViewBox: "0 0 37 37",
    },
  },
  {
    key: "ico-cloche",
    value: {
      Component: SVGS.IcoCloche,
      Class: "svg__ico-cloche",
      ViewBox: "0 0 31 37",
    },
  },
  {
    key: "ico-arobase",
    value: {
      Component: SVGS.IcoArobase,
      Class: "svg__ico-arobase",
      ViewBox: "0 0 37 37",
    },
  },
  {
    key: "ico-enveloppe",
    value: {
      Component: SVGS.IcoEnveloppe,
      Class: "svg__enveloppe",
      ViewBox: "0 0 22 16",
    },
  },
  {
    key: "ico-enveloppe--space",
    value: {
      Component: SVGS.IcoEnveloppeSpace,
      Class: "svg__enveloppe--space",
      ViewBox: "0 0 22 27",
    },
  },
  {
    key: "ico-cles",
    value: {
      Component: SVGS.IcoCles,
      Class: "svg__ico-cles",
      ViewBox: "0 0 65 68",
    },
  },
  {
    key: "ico-plus",
    value: {
      Component: SVGS.IcoPlus,
      Class: "svg__ico-plus",
      ViewBox: "0 0 32 32",
    },
  },
  {
    key: "ico-roueloupe",
    value: {
      Component: SVGS.IcoRoueLoupe,
      Class: "svg__roueloupe",
      ViewBox: "0 0 86 86",
    },
  },
  {
    key: "ico-close",
    value: {
      Component: SVGS.IcoClose,
      Class: "svg__close",
      ViewBox: "0 0 12 12",
    },
  },
  {
    key: "ico-close--min",
    value: {
      Component: SVGS.IcoClose,
      Class: "svg__close--min",
      ViewBox: "0 0 12 12",
    },
  },
  {
    key: "ico-affichage-grille",
    value: {
      Component: SVGS.IcoAffichageGrille,
      Class: "svg__affichage-resultats",
      ViewBox: "0 0 24 24",
    },
  },
  {
    key: "ico-affichage-lignes",
    value: {
      Component: SVGS.IcoAffichageLignes,
      Class: "svg__affichage-resultats",
      ViewBox: "0 0 24 24",
    },
  },
  {
    key: "ico-infos",
    value: {
      Component: SVGS.IcoInfos,
      Class: "svg__infos",
      ViewBox: "0 0 3 12",
    },
  },
  {
    key: "ico-arrow-down",
    value: {
      Component: SVGS.IcoArrowDown,
      Class: "svg__arrow-down",
      ViewBox: "0 0 21 18",
    },
  },
  {
    key: "ico-niv2-moins",
    value: {
      Component: SVGS.IcoNiv2Moins,
      Class: "svg__niv2-toggle",
      ViewBox: "0 0 19 15",
    },
  },
  {
    key: "ico-niv2-plus",
    value: {
      Component: SVGS.IcoNiv2Plus,
      Class: "svg__niv2-toggle",
      ViewBox: "0 0 19 15",
    },
  },
  {
    key: "ico-enveloppe2",
    value: {
      Component: SVGS.IcoEnveloppe2,
      Class: "svg__enveloppe2",
      ViewBox: "0 0 32 22",
    },
  },
  {
    key: "ico-baisse-prix",
    value: {
      Component: SVGS.IcoBaissePrix,
      Class: "svg__baisse-prix",
      ViewBox: "0 0 26 16",
    },
  },
  {
    key: "ico-plus-solo",
    value: {
      Component: SVGS.IcoPlusSolo,
      Class: "svg__plus-solo",
      ViewBox: "0 0 17 17",
    },
  },
  {
    key: "ico-moins-solo",
    value: {
      Component: SVGS.IcoMoins,
      Class: "svg__plus-solo",
      ViewBox: "0 0 17 17",
    },
  },
  {
    key: "ico-etoile",
    value: {
      Component: SVGS.IcoEtoile,
      Class: "svg__etoile",
      ViewBox: "0 0 20 20",
    },
  },
  {
    key: "ico-partager",
    value: {
      Component: SVGS.IcoPartager,
      Class: "svg__partager",
      ViewBox: "0 0 23 23",
    },
  },
  {
    key: "ico-imprimer",
    value: {
      Component: SVGS.IcoImprimer,
      Class: "svg__imprimer",
      ViewBox: "0 0 23 23",
    },
  },
  {
    key: "ico-signaler",
    value: {
      Component: SVGS.IcoSignaler,
      Class: "svg__signaler",
      ViewBox: "0 0 22 19",
    },
  },
  {
    key: "ico-kg",
    value: { Component: SVGS.IcoKg, Class: "svg__kg", ViewBox: "0 0 64 59" },
  },
  {
    key: "ico-comparateur-ajout",
    value: {
      Component: SVGS.IcoComparateurAjout,
      Class: "svg__comparateur-ajout",
      ViewBox: "0 0 34 31",
    },
  },
  {
    key: "ico-ajout-comparateur-v2",
    value: {
      Component: SVGS.IcoComparateurAjoutV2,
      Class: "svg__comparateur-ajout-v2",
      ViewBox: "0 0 32 22",
    },
  },
  {
    key: "trouvez",
    value: {
      Component: SVGS.Trouvez,
      Class: "svg__trouvez",
      ViewBox: "0 0 143 89",
    },
  },
  {
    key: "submit-go",
    value: {
      Component: SVGS.SubmitGo,
      Class: "svg__submit-go",
      ViewBox: "0 0 110 57",
    },
  },
  {
    key: "ico-tdb",
    value: { Component: SVGS.IcoTDB, Class: "svg__tdb", ViewBox: "0 0 18 18" },
  },
  {
    key: "ico-deconnexion",
    value: {
      Component: SVGS.IcoDeconnexion,
      Class: "svg__ico-deconnexion",
      ViewBox: "0 0 24 24",
    },
  },

  /* -- GFX -- */
  {
    key: "gfx-dome-centered-top",
    value: {
      Component: SVGS.GfxDomeCenteredTop,
      Class: "gfx__dome-centered-top",
      ViewBox: "0 0 380 85",
    },
  },
  {
    key: "gfx-wave-bottom",
    value: {
      Component: SVGS.GfxWaveBottom,
      Class: "gfx__wavebottom",
      ViewBox: "0 0 1380 75",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
-/-/-/-/-/-/-/ CARROSSERIES
/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "carrosserie-van",
    value: {
      Component: SVGS.CarrosserieVan,
      Class: "svg__carrosserie",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "carrosserie-fourgon",
    value: {
      Component: SVGS.CarrosserieFourgon,
      Class: "svg__carrosserie",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "carrosserie-caravane",
    value: {
      Component: SVGS.CarrosserieCaravane,
      Class: "svg__carrosserie",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "carrosserie-profile",
    value: {
      Component: SVGS.CarrosserieProfile,
      Class: "svg__carrosserie",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "carrosserie-integral",
    value: {
      Component: SVGS.CarrosserieIntegral,
      Class: "svg__carrosserie",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "carrosserie-capucine",
    value: {
      Component: SVGS.CarrosserieCapucine,
      Class: "svg__carrosserie",
      ViewBox: "0 0 100 70",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
-/-/-/-/-/-/-/ LITS
/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "lit-francaise",
    value: {
      Component: SVGS.LitFrancaise,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-a-la-francaise",
    value: {
      Component: SVGS.LitFrancaise,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-central",
    value: {
      Component: SVGS.LitCentral,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-pavillon",
    value: {
      Component: SVGS.LitPavillon,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-pavillon-cabine",
    value: {
      Component: SVGS.LitPavillon,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-toit-relevable",
    value: {
      Component: SVGS.LitToitRelevable,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-transversal",
    value: {
      Component: SVGS.LitTransversal,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-jumeaux",
    value: {
      Component: SVGS.LitJumeaux,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lits-jumeaux",
    value: {
      Component: SVGS.LitJumeaux,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-superposes",
    value: {
      Component: SVGS.LitSuperposes,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lits-superposes",
    value: {
      Component: SVGS.LitSuperposes,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },
  {
    key: "lit-appoint",
    value: {
      Component: SVGS.LitAppoint,
      Class: "svg__lit",
      ViewBox: "0 0 100 70",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
-/-/-/-/-/-/-/ DIFFERENTES ICONES
/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-comparateur",
    value: {
      Component: SVGS.IcoComparateur,
      Class: "svg__comparateur",
      ViewBox: "0 0 131 118",
    },
  },
  {
    key: "ico-comparateur-switch",
    value: {
      Component: SVGS.IcoCompateurSwitch,
      Class: "svg__comparateur-switch",
      ViewBox: "0 0 24 24",
    },
  },
  {
    key: "ico-checklist",
    value: {
      Component: SVGS.IcoChecklist,
      Class: "svg__checklist",
      ViewBox: "0 0 80 80",
    },
  },
  {
    key: "ico-video",
    value: {
      Component: SVGS.IcoVideo,
      Class: "svg__video",
      ViewBox: "0 0 55 55",
    },
  },
  {
    key: "ico-crayonmetre",
    value: {
      Component: SVGS.IcoCrayonMetre,
      Class: "svg__crayonmetre",
      ViewBox: "0 0 44 44",
    },
  },
  {
    key: "ico-arrow",
    value: {
      Component: SVGS.IcoArrow,
      Class: "svg__ico-arrow",
      ViewBox: "0 0 18 21",
    },
  },
  {
    key: "ico-bulle",
    value: {
      Component: SVGS.IcoBulle,
      Class: "svg__ico-bulle",
      ViewBox: "0 0 14 15",
    },
  },
  {
    key: "ico-play",
    value: {
      Component: SVGS.IcoPlay,
      Class: "svg__ico-play",
      ViewBox: "0 0 22 29",
    },
  },
  {
    key: "ico-distributeur",
    value: {
      Component: SVGS.IcoDistributeur,
      Class: "svg__ico-distributeur",
      ViewBox: "0 0 71 80",
    },
  },
  {
    key: "ico-fb",
    value: {
      Component: SVGS.IcoFb,
      Class: "svg__ico-facebook",
      ViewBox: "0 0 25 25",
    },
  },
  {
    key: "ico-yt",
    value: {
      Component: SVGS.IcoYt,
      Class: "svg__ico-youtube",
      ViewBox: "0 0 30 21",
    },
  },
  {
    key: "ico-ig",
    value: {
      Component: SVGS.IcoIg,
      Class: "svg__ico-instagram",
      ViewBox: "0 0 28 28",
    },
  },
  {
    key: "ico-toggle",
    value: {
      Component: SVGS.IcoToggle,
      Class: "svg__ico-toggle",
      ViewBox: "0 0 15 19",
    },
  },
  {
    key: "ico-toggle-sliced",
    value: {
      Component: SVGS.IcoToggleSliced,
      Class: "svg__ico-toggle-sliced",
      ViewBox: "0 0 15 11",
    },
  },
  {
    key: "ico-loupe",
    value: {
      Component: SVGS.IcoLoupe,
      Class: "svg__ico-loupe",
      ViewBox: "0 0 23 23",
    },
  },
  {
    key: "ico-loupe-recherche",
    value: {
      Component: SVGS.IcoLoupe,
      Class: "svg__ico-loupe-recherche",
      ViewBox: "0 0 23 23",
    },
  },
  {
    key: "ico-prixmarche",
    value: {
      Component: SVGS.IcoPrixMarche,
      Class: "svg__ico-prixmarche",
      ViewBox: "0 0 43 17",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  -/-/-/-/-/-/-/ ESPACE CLIENT
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  /*-/-/-/-/-/-/-/ PARTICULIER
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-nook",
    value: {
      Component: SVGS.IcoRemplissageProfil,
      Class: "svg__ico-tauxremplissageprofil",
      ViewBox: "0 0 52 52",
    },
  },
  {
    key: "ico-ok",
    value: {
      Component: SVGS.IcoRemplissageProfil100,
      Class: "svg__ico-tauxremplissageprofil100",
      ViewBox: "0 0 36 36",
    },
  },
  {
    key: "ico-leadTel",
    value: {
      Component: SVGS.IcoLeadTel,
      Class: "svg__ico-leadTel",
      ViewBox: "0 0 18 24",
    },
  },
  {
    key: "ico-leadMail",
    value: {
      Component: SVGS.IcoLeadMail,
      Class: "svg__ico-leadMail",
      ViewBox: "0 0 24 17",
    },
  },
  {
    key: "ico-fusee",
    value: {
      Component: SVGS.IcoFusee,
      Class: "svg__ico-fusee",
      ViewBox: "0 0 37 106",
    },
  },
  {
    key: "ico-fusee-small",
    value: {
      Component: SVGS.IcoFuseeSmall,
      Class: "svg__ico-fusee-small",
      ViewBox: "0 0 50 50",
    },
  },
  {
    key: "ico-ajout-comparateur",
    value: {
      Component: SVGS.IcoAjoutComparateur,
      Class: "svg__ico-ajout-comparateur",
      ViewBox: "0 0 35 32",
    },
  },
  {
    key: "ico-croix-supp",
    value: {
      Component: SVGS.IcoCroixSupp,
      Class: "svg__ico-croix-supp",
      ViewBox: "0 0 16 16",
    },
  },
  {
    key: "ico-ampoule",
    value: {
      Component: SVGS.IcoAmpoule,
      Class: "svg__ico-ampoule",
      ViewBox: "0 0 36 36",
    },
  },
  {
    key: "ico-oeil",
    value: {
      Component: SVGS.IcoOeil,
      Class: "svg__ico-oeil",
      ViewBox: "0 0 20 16",
    },
  },
  {
    key: "ico-oeil-input",
    value: {
      Component: SVGS.IcoOeilInput,
      Class: "svg__ico-oeil-input",
      ViewBox: "0 0 20 19",
    },
  },
  {
    key: "ico-oeil-input-barre",
    value: {
      Component: SVGS.IcoOeilInputBarre,
      Class: "svg__ico-oeil-input-barre",
      ViewBox: "0 0 20 19",
    },
  },
  {
    key: "ico-recadrage",
    value: {
      Component: SVGS.IcoRecadrage,
      Class: "svg__ico-recadrage",
      ViewBox: "0 0 23 23",
    },
  },
  {
    key: "ico-cal-abo",
    value: {
      Component: SVGS.IcoCalAbo,
      Class: "svg__ico-cal-abo",
      ViewBox: "0 0 50 50",
    },
  },

  /*-/-/-/-/-/-/-/ PRO
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-sync-pro",
    value: {
      Component: SVGS.IcoSyncPro,
      Class: "svg__ico-sync-pro",
      ViewBox: "0 0 31 30",
    },
  },
  {
    key: "ico-pointer",
    value: {
      Component: SVGS.IcoPointer,
      Class: "svg__ico-pointer",
      ViewBox: "0 0 14 20",
    },
  },
  {
    key: "ico-modification",
    value: {
      Component: SVGS.IcoModification,
      Class: "svg__ico-modification",
      ViewBox: "0 0 20 20",
    },
  },

  /*-/-/-/-/-/-/-/ DRAPEAUX
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-flag-fr",
    value: {
      Component: SVGS.FlagFR,
      Class: "svg__flag",
      ViewBox: "0 0 640 480",
    },
  },
  {
    key: "ico-flag-gb",
    value: {
      Component: SVGS.FlagGB,
      Class: "svg__flag",
      ViewBox: "0 0 640 480",
    },
  },
  {
    key: "ico-flag-de",
    value: {
      Component: SVGS.FlagDE,
      Class: "svg__flag",
      ViewBox: "0 0 640 480",
    },
  },
  {
    key: "ico-flag-es",
    value: {
      Component: SVGS.FlagES,
      Class: "svg__flag",
      ViewBox: "0 0 640 480",
    },
  },
  {
    key: "ico-flag-it",
    value: {
      Component: SVGS.FlagIT,
      Class: "svg__flag",
      ViewBox: "0 0 640 480",
    },
  },

  /*-/-/-/-/-/-/-/ MESSAGES FORM
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-circle-success",
    value: {
      Component: SVGS.IcoCircleSuccess,
      Class: "svg__ico-circle-message",
      ViewBox: "0 0 30 30",
    },
  },
  {
    key: "ico-circle-error",
    value: {
      Component: SVGS.IcoCircleError,
      Class: "svg__ico-circle-message",
      ViewBox: "0 0 30 30",
    },
  },
  {
    key: "ico-circle-care",
    value: {
      Component: SVGS.IcoCircleCare,
      Class: "svg__ico-circle-message",
      ViewBox: "0 0 30 30",
    },
  },
  {
    key: "ico-circle-info",
    value: {
      Component: SVGS.IcoCircleInfo,
      Class: "svg__ico-circle-message",
      ViewBox: "0 0 30 30",
    },
  },

  /*-/-/-/-/-/-/-/ FLUX
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-refresh",
    value: {
      Component: SVGS.IcoRefresh,
      Class: "svg__ico-refresh",
      ViewBox: "0 0 16 16",
    },
  },
  {
    key: "ico-protools",
    value: {
      Component: SVGS.IcoProTools,
      Class: "svg__ico-protools",
      ViewBox: "0 0 30 30",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  -/-/-/-/-/-/-/ MESSAGERIE
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-chat",
    value: {
      Component: SVGS.IcoChat,
      Class: "svg__ico-chat",
      ViewBox: "0 0 32 32",
    },
  },
  {
    key: "ico-file",
    value: {
      Component: SVGS.IcoFile,
      Class: "svg__ico-file",
      ViewBox: "0 0 31 31",
    },
  },
  {
    key: "ico-selection-multiple",
    value: {
      Component: SVGS.IcoSelectionMultiple,
      Class: "svg__ico-selection-multiple",
      ViewBox: "0 0 20 20",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  -/-/-/-/-/-/-/ CMS - ARTICLES
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-notif-comment",
    value: {
      Component: SVGS.IcoNotifComment,
      Class: "svg__ico-notif-comment",
      ViewBox: "0 0 20 20",
    },
  },
  {
    key: "ico-notif-recherche",
    value: {
      Component: SVGS.IcoNotifRecherche,
      Class: "svg__ico-notif-recherche",
      ViewBox: "0 0 20 20",
    },
  },
  {
    key: "ico-notif-fusee",
    value: {
      Component: SVGS.IcoNotifFusee,
      Class: "svg__ico-notif-fusee",
      ViewBox: "0 0 26 26",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  -/-/-/-/-/-/-/ ICO NOTIFICATION SOUS MENU
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "ico-article",
    value: {
      Component: SVGS.IcoArticle,
      Class: "svg__ico-article",
      ViewBox: "0 0 25 25",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  -/-/-/-/-/-/-/ DIVERS INTERFACE
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "spinner",
    value: {
      Component: SVGS.Spinner,
      Class: "svg__spinner",
      ViewBox: "0 0 57 57",
    },
  },

  /* /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-]
  -/-/-/-/-/-/-/ EVTS PROMOTIONNELS
  /-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/*/

  {
    key: "badge-carburant",
    value: {
      Component: SVGS.BadgeCarburant,
      Class: "svg__badge-carburant",
      ViewBox: "0 0 1240 980",
    },
  },
];

export default svgList;
