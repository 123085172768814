import * as types from "@/Types/ads";
import { ADS_FILTERS } from "@/Constants/filters";
import { ACTION_FROM_INITIAL_FILTER_GENERATION } from "@/Constants/actions";
import { ORDER } from "@/Constants/InputsValues";

const defaultState = {
  initialFilter: null,
  list: [],
  page: 1,
  order: ORDER.find(order => order.value === "publish-date-decrease"),
  filters: { ...ADS_FILTERS },
  count: 0,
  error: false,
  loading: false,
  nextPage: null,
  actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
  type: "",
};

const updateAdsFilterState = (payloadFilter, stateFilter) => ({
  type: {
    ...(payloadFilter?.type || stateFilter?.type),
  },
  "user.userType": {
    ...(payloadFilter?.["user.userType"] || stateFilter?.["user.userType"]),
  },
  "model.rangeVehicle": {
    ...(payloadFilter?.["model.rangeVehicle"] ||
      stateFilter?.["model.rangeVehicle"]),
  },
  bedType: {
    ...(payloadFilter?.bedType || stateFilter?.bedType),
  },
  size: {
    ...(payloadFilter?.size || stateFilter?.size),
  },
  inclVatPrice: {
    ...(payloadFilter?.inclVatPrice || stateFilter?.inclVatPrice),
  },
  registeredSeats: {
    ...(payloadFilter?.registeredSeats || stateFilter?.registeredSeats),
  },
  localisation: {
    ...(payloadFilter?.localisation || stateFilter?.localisation),
  },
  sleepingSeats: {
    ...(payloadFilter?.sleepingSeats || stateFilter?.sleepingSeats),
  },
  mileage: {
    ...(payloadFilter?.mileage || stateFilter?.mileage),
  },
  millesime: {
    ...(payloadFilter?.millesime || stateFilter?.millesime),
  },
  "model.brand": {
    ...(payloadFilter?.["model.brand"] || stateFilter?.["model.brand"]),
  },
  model: {
    ...(payloadFilter?.model || stateFilter?.model),
  },
  gearbox: {
    ...(payloadFilter?.gearbox || stateFilter?.gearbox),
  },
  // carrier
  grossWeight: {
    ...(payloadFilter?.grossWeight || stateFilter?.grossWeight),
  },
  // chassis
  heating: {
    ...(payloadFilter?.heating || stateFilter?.heating),
  },
  // heatingType
  dealer: {
    ...(payloadFilter?.dealer || stateFilter?.dealer),
  },
});

/**
 * @function
 * @param state
 * @param type
 * @param payload
 * @todo performances : needs a criteria loaded that only updates one given criteria instead of the whole filter
 * @returns {*}
 */
const adsReducer = (state = { ...defaultState }, { type, payload }) => {
  let output;

  switch (type) {
    case types.ACTION_CHANGED:
      output = { ...state, actionFrom: payload.actionFrom };
      break;

    case types.ORDER_CHANGED:
      output = { ...state, order: { ...payload.order } };
      break;

    case types.INITIAL_ADS_FILTER_VALUES_LOADED:
      output = {
        ...state,
        initialFilter: updateAdsFilterState(
          payload.initialFilter,
          state.initialFilter
        ),
        actionFrom: ACTION_FROM_INITIAL_FILTER_GENERATION,
      };
      break;

    case types.FILTER_VALUES_LOADED:
      output = {
        ...state,
        filters: updateAdsFilterState(payload.filters, state.filters),
      };
      break;

    case types.LOAD_STARTED:
      output = { ...state, loading: true, error: false };
      break;

    case types.LOAD_FAILURE:
      const error = payload.error;
      output = { ...state, loading: false, error };
      break;

    case types.LOAD_SUCCESS:
      output = {
        ...state,
        ...payload,
        loading: false,
        error: false,
      };
      break;

    default:
      output = state;
  }

  output.type = type;
  return { ...output };
};

export default adsReducer;
