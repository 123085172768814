import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cssHeader from "@/Shared/Header/Header.module.css";
import Btn from "@/Shared/Ui/Btn";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import SousMenuParticulier from "@/Widgets/Header/SousMenuParticulier";
import SousMenuPro from "@/Widgets/Header/SousMenuPro";
import UseMediaQueries from "@/Shared/Hooks/UseMediaQueries";
import UseMenus from "@/Shared/Hooks/UseMenus";
import BtnDisconnectUser from "@/Shared/Ui/BtnDisconnectUser";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { appearDisappearMenus } from "@/Utils/ux_menus";

// TODO since there is a menu on side and that very component belongs to header, should it not be named subHeaderProfile ? It's a bit confusing
const SousMenuProfil = ({ context }) => {
  const {
    state: { etatsMenu },
    setEtatsMenu,
  } = useContext(GlobalContext);
  let sousMenuCompte = useRef(null);

  let bodyHTML;
  const pathHome =
    context === "pro" ? "/espace-pro/home" : "/espace-client/home";
  /* -- */
  const mq = UseMediaQueries();
  const mqmin60 = mq.min60;
  const mqmax60 = mq.max60;
  /* -- */
  const {
    menuRef,
    containerOnMouseEnter,
    containerOnMouseLeave,
    btnOnMouseEnter,
    btnOnClick,
  } = UseMenus("compte");
  const [opened, setOpened] = useState(false);
  /* -- */

  useEffect(() => {
    // DESKTOP -- MENUS DEROULANTS <> sync avec useMenus dans Shared/Hooks
    ////////////////////////////////////////////////////////////////////
    if (mqmin60) {
      appearDisappearMenus(
        etatsMenu[2].toggled,
        sousMenuCompte,
        opened,
        setOpened
      );
    }
    // MOBILE -- AU CLIC TOGGLE
    ////////////////////////////////////////////////////////////////////
    if (mqmax60) {
      bodyHTML = document.querySelector("html");
      if (bodyHTML) {
        etatsMenu[2].toggled
          ? bodyHTML.classList.add("u-scroll-blocked")
          : bodyHTML.classList.remove("u-scroll-blocked");
      }
    }
    ////////////////////////////////////////////////////////////////////
  }, [etatsMenu[2].toggled]);

  return (
    <div
      className={`${cssHeader["c-nav-main__has-sous-menu"]} ${cssHeader["c-nav-main__has-sous-menu--ico"]}`}
      onMouseEnter={() => {
        containerOnMouseEnter();
      }}
      onMouseLeave={() => {
        containerOnMouseLeave(sousMenuCompte);
      }}
    >
      <Btn
        type={mqmax60 ? "text" : "routeLink"}
        path={mqmax60 ? undefined : pathHome}
        svg='ico-user'
        className={`js__trigrpusr ${uiBtn["u-btn-icousr--logue"]}`}
        onClick={() => btnOnClick(sousMenuCompte)}
      />

      <div
        className={`${cssHeader["c-nav-main__sous-menu"]} ${
          cssHeader["c-nav-main__sous-menu--profil"]
        } u-box-white u-box-shadow 
      ${mqmax60 ? cssHeader["c-nav-main__sous-menu--mobile"] : ""} 
      ${
        mqmax60 && menuRef.toggled
          ? cssHeader["c-nav-main__sous-menu--mobile--on"]
          : ""
      }`}
        ref={el => (sousMenuCompte = el)}
      >
        {context === "particulier" ? (
          <SousMenuParticulier onClick={() => btnOnClick(sousMenuCompte)} />
        ) : (
          <SousMenuPro onClick={() => btnOnClick(sousMenuCompte)} />
        )}

        <BtnDisconnectUser onClick={() => btnOnClick(sousMenuCompte)} />
      </div>
    </div>
  );
};

SousMenuProfil.propTypes = {
  context: PropTypes.string,
};

export default SousMenuProfil;
