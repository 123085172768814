import { useSession } from "next-auth/react";
import { ONE_MINUTE } from "@/Constants/time";
import { useContext, useEffect } from "react";
import { getTimeLeftBeforeTokenExpiry } from "@/Utils/index";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { REFRESH_TOKEN_ERROR, SESSION_EXPIRED } from "@/Constants/errors";

const TokensRefresher = ({ setInterval = () => {}, interval = 0 }) => {
  const session = useSession();
  const { setGlobalError } = useContext(GlobalContext);

  useEffect(() => {
    let errorMessage = null;
    if (session?.data?.error?.length) {
      const { error } = session.data;
      errorMessage = error?.message || error;
      console.warn(errorMessage);

      if (errorMessage === REFRESH_TOKEN_ERROR && interval > 0) {
        setGlobalError(errorMessage);
      }
      if (errorMessage === REFRESH_TOKEN_ERROR && interval < 1) {
        setGlobalError(SESSION_EXPIRED);
      }
    }

    if (session?.data?.user?.accessTokenExpiry && !errorMessage) {
      const { accessTokenExpiry } = session.data.user;

      // interval must be in seconds.
      // 10 min before the token expires, we start asking to refresh the token. replace 10 minutes by 59 for testing
      // useful resources : https://next-auth.js.org/tutorials/refresh-token-rotation,
      // https://dev.to/mabaranowski/nextjs-authentication-jwt-refresh-token-rotation-with-nextauthjs-5696
      const timeRemaining =
        getTimeLeftBeforeTokenExpiry(accessTokenExpiry - 10 * ONE_MINUTE) /
        1000;
      const interval = timeRemaining > 0 ? timeRemaining : 0;
      setInterval(interval);
    }
  }, [session]);

  return null;
};

export default TokensRefresher;
