/**
 * @namespace Constants
 * @module Constantes InputsValues
 * @description Fichier de constantes de valeurs d'inputs.
 * Utilisés pour remplir de valeurs dures les champs de formulaires.
 * @author David et Florent
 */

import { orderBy, sortBy } from "lodash";

const generateYears = (startYear, key) => {
  let currentYear = new Date().getFullYear();
  const years = [];

  if (key === "millesime") currentYear++;
  startYear = startYear || currentYear;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

/**
 * @constant
 * @type Array<object>
 */
export const DEPARTEMENTSFRANCE = [
  { key: "01", value: "01 - Ain" },
  { key: "02", value: "02 - Aisne" },
  { key: "03", value: "03 - Allier" },
  { key: "04", value: "04 - Alpes-de-Haute-Provence" },
  { key: "05", value: "05 - Hautes-Alpes" },
  { key: "06", value: "06 - Alpes-Maritimes" },
  { key: "07", value: "07 - Ardèche" },
  { key: "08", value: "08 - Ardennes" },
  { key: "09", value: "09 - Ariège" },
  { key: "10", value: "10 - Aube" },
  { key: "11", value: "11 - Aude" },
  { key: "12", value: "12 - Aveyron" },
  { key: "13", value: "13 - Bouches-du-Rhône" },
  { key: "14", value: "14 - Calvados" },
  { key: "15", value: "15 - Cantal" },
  { key: "16", value: "16 - Charente" },
  { key: "17", value: "17 - Charente-Maritime" },
  { key: "18", value: "18 - Cher" },
  { key: "19", value: "19 - Corrèze" },
  { key: "21", value: "21 - Côte-d'Or" },
  { key: "22", value: "22 - Côtes d'Armor" },
  { key: "23", value: "23 - Creuse" },
  { key: "24", value: "24 - Dordogne" },
  { key: "25", value: "25 - Doubs" },
  { key: "26", value: "26 - Drôme" },
  { key: "27", value: "27 - Eure" },
  { key: "28", value: "28 - Eure-et-Loir" },
  { key: "29", value: "29 - Finistère" },
  { key: "2A", value: "2A - Corse-du-Sud" },
  { key: "2B", value: "2B - Haute-Corse" },
  { key: "30", value: "30 - Gard" },
  { key: "31", value: "31 - Haute-Garonne" },
  { key: "32", value: "32 - Gers" },
  { key: "33", value: "33 - Gironde" },
  { key: "34", value: "34 - Hérault" },
  { key: "35", value: "35 - Ille-et-Vilaine" },
  { key: "36", value: "36 - Indre" },
  { key: "37", value: "37 - Indre-et-Loire" },
  { key: "38", value: "38 - Isère" },
  { key: "39", value: "39 - Jura" },
  { key: "40", value: "40 - Landes" },
  { key: "41", value: "41 - Loir-et-Cher" },
  { key: "42", value: "42 - Loire" },
  { key: "43", value: "43 - Haute-Loire" },
  { key: "44", value: "44 - Loire-Atlantique" },
  { key: "45", value: "45 - Loiret" },
  { key: "46", value: "46 - Lot" },
  { key: "47", value: "47 - Lot-et-Garonne" },
  { key: "48", value: "48 - Lozère" },
  { key: "49", value: "49 - Maine-et-Loire" },
  { key: "50", value: "50 - Manche" },
  { key: "51", value: "51 - Marne" },
  { key: "52", value: "52 - Haute-Marne" },
  { key: "53", value: "53 - Mayenne" },
  { key: "54", value: "54 - Meurthe-et-Moselle" },
  { key: "55", value: "55 - Meuse" },
  { key: "56", value: "56 - Morbihan" },
  { key: "57", value: "57 - Moselle" },
  { key: "58", value: "58 - Nièvre" },
  { key: "59", value: "59 - Nord" },
  { key: "60", value: "60 - Oise" },
  { key: "61", value: "61 - Orne" },
  { key: "62", value: "62 - Pas-de-Calais" },
  { key: "63", value: "63 - Puy-de-Dôme" },
  { key: "64", value: "64 - Pyrénées-Atlantiques" },
  { key: "65", value: "65 - Hautes-Pyrénées" },
  { key: "66", value: "66 - Pyrénées-Orientales" },
  { key: "67", value: "67 - Bas-Rhin" },
  { key: "68", value: "68 - Haut-Rhin" },
  { key: "69", value: "69 - Rhône" },
  { key: "70", value: "70 - Haute-Saône" },
  { key: "71", value: "71 - Saône-et-Loire" },
  { key: "72", value: "72 - Sarthe" },
  { key: "73", value: "73 - Savoie" },
  { key: "74", value: "74 - Haute-Savoie" },
  { key: "75", value: "75 - Paris" },
  { key: "76", value: "76 - Seine-Maritime" },
  { key: "77", value: "77 - Seine-et-Marne" },
  { key: "78", value: "78 - Yvelines" },
  { key: "79", value: "79 - Deux-Sèvres" },
  { key: "80", value: "80 - Somme" },
  { key: "81", value: "81 - Tarn" },
  { key: "82", value: "82 - Tarn-et-Garonne" },
  { key: "83", value: "83 - Var" },
  { key: "84", value: "84 - Vaucluse" },
  { key: "85", value: "85 - Vendée" },
  { key: "86", value: "86 - Vienne" },
  { key: "87", value: "87 - Haute-Vienne" },
  { key: "88", value: "88 - Vosges" },
  { key: "89", value: "89 - Yonne" },
  { key: "90", value: "90 - Territoire de Belfort" },
  { key: "91", value: "91 - Essonne" },
  { key: "92", value: "92 - Hauts-de-Seine" },
  { key: "93", value: "93 - Seine-St-Denis" },
  { key: "94", value: "94 - Val-de-Marne" },
  { key: "95", value: "95 - Val-D'Oise" },
];

/**
 * @constant
 * @type Array<object>
 */
export const METIERSPARTICULIERS = [
  { label: "Dirigeant", key: "dirigeant", value: "dirigeant" },
  { label: "Employé", key: "employé", value: "employé" },
];

// "Van",
// "Fourgon",
// "Caravane",
// "Profile",
// "Intégral",
// "Capucine"
/**
 * @constant
 * @type Array<object>
 */
export const CARROSSERIES = [
  {
    key: "van",
    label: "Van, Combi",
    name: "Van, Combi",
    value: "carrosserie-van",
    moreValues: ["Van", "van"],
  },
  {
    key: "fourgon",
    label: "Fourgon",
    name: "Fourgon",
    value: "carrosserie-fourgon",
    moreValues: ["Fourgon", "fourgon"],
  },
  {
    key: "caravane",
    label: "Caravane",
    name: "Caravane",
    value: "carrosserie-caravane",
    moreValues: ["Caravane", "caravane"],
  },
  {
    key: "profile",
    label: "Profilé",
    name: "Profilé",
    value: "carrosserie-profile",
    moreValues: ["Profile", "profile"],
  },
  {
    key: "integral",
    label: "Intégral",
    name: "Intégral",
    value: "carrosserie-integral",
    moreValues: ["Intégral", "Integral", "integral"],
  },
  {
    key: "capucine",
    label: "Capucine",
    name: "Capucine",
    value: "carrosserie-capucine",
    moreValues: ["capucine", "Capucine"],
  },
];

// export const LITS = [
//   { key: 'lit francaise', value:'Lit à la française'},
//   { key: 'lit central', value:'Lit central'},
//   { key: 'lit pavillon', value:'Lit pavillon, cabine'},
//   { key: 'lit toit relevable', value:'Lit toit relevable'},
//   { key: 'lit transversal', value:'Lit transversal'},
//   { key: 'lits jumeaux', value:'Lits jumeaux'},
//   { key: 'lits superposés', value:'Lits superposés'},
//   { key: 'lit dinette', value:'Lit dinette'},
//   { key: 'capucines', value:'Capucines'},
// ]

// export const MARQUES = [
//   { key: "Adria", value: "Adria" },
//   { key: "Airstream", value: "Airstream" },
//   { key: "Alcar", value: "Alcar" },
//   { key: "Arca", value: "Arca" },
//   { key: "Austin", value: "Austin" },
//   { key: "Auto roller", value: "Auto roller" },
//   { key: "Autosleeper", value: "Autosleeper" },
//   { key: "Autostar", value: "Autostar" },
//   { key: "Bavaria", value: "Bavaria" },
//   { key: "Bedford", value: "Bedford" },
//   { key: "Benimar", value: "Benimar" },
//   { key: "Blucamp", value: "Blucamp" },
//   { key: "Bolloré Motorhome", value: "Bolloré Motorhome" },
//   { key: "Burstner", value: "Burstner" },
//   { key: "Campereve", value: "Campereve" },
//   { key: "Camperway", value: "Camperway" },
//   { key: "Campster", value: "Campster" },
//   { key: "Capron", value: "Capron" },
//   { key: "Carado", value: "Carado" },
//   { key: "Carep", value: "Carep" },
//   { key: "Carthago", value: "Carthago" },
//   { key: "Cartier", value: "Cartier" },
//   { key: "Dethleffs", value: "Dethleffs" },
//   { key: "Dreamer", value: "Dreamer" },
//   { key: "Elios", value: "Elios" },
//   { key: "Elnagh", value: "Elnagh" },
//   { key: "Eriba", value: "Eriba" },
//   { key: "Esterel", value: "Esterel" },
//   { key: "Etrusco", value: "Etrusco" },
//   { key: "Euro Mobil", value: "Euro Mobil" },
//   { key: "Evasion", value: "Evasion" },
//   { key: "Fendt", value: "Fendt" },
//   { key: "Fiat", value: "Fiat" },
//   { key: "Fleetwood", value: "Fleetwood" },
//   { key: "Fleurette", value: "Fleurette" },
//   { key: "Florium", value: "Florium" },
//   { key: "Font Vendome", value: "Font Vendome" },
//   { key: "Ford", value: "Ford" },
//   { key: "Frankia", value: "Frankia" },
//   { key: "Giotti", value: "Giotti" },
//   { key: "Globecar", value: "Globecar" },
//   { key: "Hanroad", value: "Hanroad" },
//   { key: "Hobby", value: "Hobby" },
//   { key: "Home Car", value: "Home Car" },
//   { key: "Hymer", value: "Hymer" }
// ]

/**
 * @constant
 * @type Array<object>
 */
export const HEATING_SOURCES = [
  {
    label: "Sans chauffage",
    key: "Sans chauffage",
    name: "Sans chauffage",
    value: "Sans chauffage",
  },
  {
    label: "Carburant",
    key: "Carburant",
    name: "Carburant",
    value: "Carburant",
  },
  {
    label: "Gaz",
    key: "Gaz",
    name: "Gaz",
    queryValue: "Gaz",
    value: "Gaz",
  },
  {
    label: "Carburant + électrique",
    key: "Carburant + électrique",
    name: "Carburant + électrique",
    value: "Carburant + électrique",
  },
  {
    label: "Gaz + électrique",
    key: "Gaz + électrique",
    name: "Gaz + électrique",
    value: "Gaz + électrique",
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const HEATING_TYPES = [
  { key: "Air pulsé", value: "Air pulsé" },
  { key: "Fluide caloporteur", value: "Fluide caloporteur" },
];

/**
 * @constant
 * @type Array<object>
 */
export const MONTH = [
  { key: "Janvier", value: "Janvier" },
  { key: "Février", value: "Février" },
  { key: "Mars", value: "Mars" },
  { key: "Avril", value: "Avril" },
  { key: "Mai", value: "Mai" },
  { key: "Juin", value: "Juin" },
  { key: "Juillet", value: "Juillet" },
  { key: "Aout", value: "Aout" },
  { key: "Septembre", value: "Septembre" },
  { key: "Octobre", value: "Octobre" },
  { key: "Novembre", value: "Novembre" },
  { key: "Décembre", value: "Décembre" },
];

/**
 * @constant
 * @type Array<object>
 */
export const YEARS = [
  { key: "2021", value: "2021" },
  { key: "2022", value: "2022" },
  { key: "2023", value: "2023" },
  { key: "2024", value: "2024" },
  { key: "2025", value: "2025" },
  { key: "2026", value: "2026" },
  { key: "2027", value: "2027" },
  { key: "2028", value: "2028" },
  { key: "2029", value: "2029" },
  { key: "2030", value: "2030" },
];

/**
 * @constant
 * @type Array<object>
 */
export const CONCESSIONS = [
  { key: "CLC Alençon", value: "CLC Alençon" },
  { key: "CLC Le Mans", value: "CLC Le Mans" },
  { key: "CLC Nantes", value: "CLC Nantes" },
  { key: "CLC Rennes", value: "CLC Rennes" },
];

/**
 * @constant
 * @type Array<object>
 */
export const PERIODES = [
  { key: "Dernière semaine", value: "Dernière semaine" },
  { key: "15 jours", value: "15 jours" },
  { key: "Un mois", value: "Un mois" },
  { key: "6 mois", value: "6 mois" },
  { key: "1 an", value: "1 an" },
  { key: "Depuis le début", value: "Depuis le début" },
];

/**
 * @constant
 * @type Array<object>
 */
export const FILTRESFACTURESPRO = [
  { key: "Boost", value: "Boost" },
  { key: "Abonnements", value: "Abonnements" },
];

/**
 * @constant
 * @type Array<object>
 * @todo key de 0 et key de 1 ne correspondent pas à leurs valeurs
 */
export const FLUXPROTRI = [
  {
    label: "Date de publication décroissante",
    key: "publication-decrease",
    value: "date-decrease",
  },
  {
    label: "Date de publication croissante",
    key: "publication-increase",
    value: "date-increase",
  },
  {
    label: "Nom d'annonce Z-A",
    key: "offer-name-decrease",
    value: "offer-name-decrease",
  },
  {
    label: "Nom d'annonce A-Z",
    key: "offer-name-increase",
    value: "offer-name-increase",
  },
  { label: "Prix décroissant", key: "price-decrease", value: "price-decrease" },
  { label: "Prix croissant", key: "price-increase", value: "price-increase" },
  {
    label: "Nombre de leads décroissant",
    key: "lead-decrease",
    value: "lead-decrease",
  },
  {
    label: "Nombre de leads croissant",
    key: "lead-increase",
    value: "lead-increase",
  },
  {
    label: "Nombre de vues décroissant",
    key: "view-decrease",
    value: "view-decrease",
  },
  {
    label: "Nombre de vues croissant",
    key: "view-increase",
    value: "view-increase",
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const DUREEBOOST = [
  { key: "7 jours", value: "7 jours" },
  { key: "14 jours", value: "14 jours" },
  { key: "21 Jours", value: "21 jours" },
];

/**
 * @constant
 * @type Array<object>
 */
export const CAUSESIGNALEMENT = [
  {
    key: "1",
    label: "Fraude",
    placeholder: "Fraude",
    value: "Fraude",
  },
  {
    key: "2",
    label: "Non respect de la charte (vulgarité etc...)",
    placeholder: "Non respect de la charte (vulgarité etc...)",
    value: "Non respect",
  },
  {
    key: "3",
    label: "Autre",
    placeholder: "Autre",
    value: "Autre",
  },
];

// export const PORTEURS = [
//   { key: 'Fiat', value:'Fiat'},
//   { key: 'Mercedes', value:'Mercedes'},
//   { key: 'Renault', value:'Renault'},
// ]

/**
 * @constant
 * @type Array<object>
 */
export const BOITEVITESSES = [
  {
    label: "Automatique",
    key: "Automatique",
    value: "automatique",
    name: "automatique",
  },
  {
    label: "Séquentielle",
    key: "Séquentielle",
    value: "sequentielle",
    name: "sequentielle",
  },
  { label: "Manuelle", key: "Manuelle", value: "manuelle", name: "manuelle" },
];

/**
 * @constant
 * @type Array<object>
 */
export const CARBURANT = [
  { key: "Essence", value: "essence" },
  { key: "Diesel", value: "diesel" },
  { key: "Gaz", value: "gaz" },
  { key: "Électrique", value: "electrique" },
];

/**
 * @constant
 * @type Array<object>
 */
export const BEDTYPES = [
  {
    key: "Lit à la française",
    label: "Lit à la française",
    name: "Lit à la française",
    svg: "lit-francaise",
    value: "lit-a-la-francaise",
  },
  {
    key: "Lit central",
    label: "Lit central",
    name: "Lit central",
    svg: "lit-central",
    value: "lit-central",
  },
  {
    key: "Lit pavillon cabine",
    label: "Lit pavillon, cabine",
    name: "Lit pavillon cabine",
    svg: "lit-pavillon",
    value: "lit-pavillon-cabine",
  },
  {
    key: "Lit toit relevable",
    label: "Lit toit relevable",
    name: "Lit toit relevable",
    moreValues: ["Lit de toit"],
    svg: "lit-toit-relevable",
    value: "lit-toit-relevable",
  },
  {
    key: "Lit transversal",
    label: "Lit transversal",
    name: "Lit transversal",
    svg: "lit-transversal",
    value: "lit-transversal",
  },
  {
    key: "Lits jumeaux",
    label: "Lits jumeaux",
    name: "Lits jumeaux",
    svg: "lit-jumeaux",
    value: "lits-jumeaux",
  },
  {
    key: "Lits superposés",
    label: "Lits superposés",
    name: "Lits superposés",
    svg: "lit-superposes",
    value: "lits-superposes",
  },
  {
    key: "Couchage appoint",
    label: "Lit d'appoint",
    name: "Couchage appoint",
    svg: "lit-appoint",
    value: "lit-appoint",
    moreValues: ["couchage-appoint"],
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const MILLESIME = orderBy(
  generateYears(1980, "millesime").map(year => ({ key: year, value: year })),
  ["value"],
  ["desc"]
);

/**
 * @constant
 * @type Array<object>
 */
export const millesimes = MILLESIME;

/**
 * @constant
 * @type Array<object>
 */
export const bedTypes = sortBy(BEDTYPES, ["name"]);

/**
 * @constant
 * @type Array<object>
 */
export const yesOrNo = [
  {
    corps: "Oui",
    value: "yes",
    key: "yes",
  },
  {
    corps: "Non",
    value: "no",
    key: "no",
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const yesOrNoBoolean = [
  {
    corps: "Oui",
    value: true,
    key: "yes",
  },
  {
    corps: "Non",
    value: false,
    key: "no",
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const nbComparateurMobile = [
  {
    corps: "2",
    value: 2,
    key: "2",
    responsive: [45, 60, 80, 90, 110],
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const nbComparateurMq60 = [
  {
    corps: "3",
    value: 3,
    key: "3",
    responsive: [60, 80, 90, 110],
  },
  ...nbComparateurMobile,
];

/**
 * @constant
 * @type Array<object>
 */
export const nbComparateurMq80 = [
  {
    corps: "4",
    value: 4,
    key: "4",
    responsive: [80, 90, 110],
  },
  ...nbComparateurMq60,
];

/**
 * @constant
 * @type Array<object>
 */
export const nbComparateurMq110 = [
  {
    corps: "5",
    value: 5,
    key: "5",
    responsive: [90, 110],
  },
  ...nbComparateurMq80,
];

/**
 * @constant
 * @type Array<object>
 */
export const firstHand = [
  { corps: "neuf", value: "neuf" },
  { corps: "d'occasion", value: "occasion" },
];

/**
 * @constant
 * @type Array<object>
 */
export const energies = sortBy(CARBURANT, ["value"]);

/**
 * @constant
 * @type Array<object>
 */
export const GEARBOXES = sortBy(BOITEVITESSES, ["value"]);

/**
 * @constant
 * @type Array<object>
 */
export const heaterTypes = sortBy(HEATING_TYPES, ["value"]);

/**
 * @constant
 * @type Array<object>
 */
export const heaterEnergies = sortBy(HEATING_SOURCES, ["value"]);

/**
 * @constant
 * @type Array<object>
 */
export const soldFrom = [
  { corps: "J'ai vendu mon véhicule via WeekandGO", value: "WeekAndGo" },
  { corps: "J'ai vendu mon véhicule par un autre moyen", value: "Autre moyen" },
  { corps: "Je ne souhaite plus vendre mon véhicule", value: "Ne plus vendre" },
];

/**
 * @constant
 * @type {string}
 */
export const soldFromVehicle = "Véhicule";

/**
 * @constant
 * @type Array<object>
 */
export const ORDER = [
  {
    id: 0,
    label: "Date de parution décroissante",
    order: { published: "desc" },
    value: "publish-date-decrease",
  },
  {
    id: 1,
    label: "Date de parution croissante",
    order: { published: "asc" },
    value: "publish-date-increase",
  },
  {
    id: 2,
    label: "Prix décroissant",
    order: { inclVatPrice: "desc" },
    value: "price-decrease",
  },
  {
    id: 3,
    label: "Prix croissant",
    order: { inclVatPrice: "asc" },
    value: "price-increase",
  },
  {
    id: 4,
    label: "Kilométrage décroissant",
    order: { mileage: "desc" },
    value: "kilometers-decrease",
  },
  {
    id: 5,
    label: "Kilométrage croissant",
    order: { mileage: "asc" },
    value: "kilometers-increase",
  },
  {
    id: 6,
    label: "Année décroissante",
    order: { millesime: "desc" },
    value: "year-decrease",
  },
  {
    id: 7,
    label: "Année croissante",
    order: { millesime: "asc" },
    value: "year-increase",
  },
];

/**
 * @constant
 * @type Array<object>
 */
export const radiosNewsletter = [
  { corps: "Je suis inscrit", value: "yes" },
  { corps: "Je ne souhaite pas la recevoir", value: "no" },
];

/**
 * @constant
 * @type Array<object>
 */
export const radiosCivility = [
  { corps: "Mr", value: "M" },
  { corps: "Mme", value: "Mme" },
];

/**
 * @constant
 * @type Array<object>
 */
export const radiosAgreements = [
  { corps: "J'accepte", value: "accept" },
  { corps: "Je refuse", value: "refuse" },
];
