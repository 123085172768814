/**
 * @namespace Constants
 * @module Constantes Dates
 * @description Fichier de constantes de dates
 */

import { ENTRYPOINT } from "@/Constants/restApiRoutes";

/**
 * @constant
 * @type {{start: Date, end: Date}}
 */
export const DATES_GAME_FREE_FUEL_2022_MARCH = {
  start:
    ENTRYPOINT === "https://dev.api.weekandgo.com/public"
      ? new Date("2022-03-10T00:00")
      : new Date("2022-03-10T00:00"),
  end: new Date("2022-06-01T00:00"),
};
