import PropTypes from "prop-types";
import cssThemeEspace from "@/Theme/EspaceTheme.module.css";
import Svg from "@/Shared/Svg";
import cssMessage from "./Message.module.css";

const Message = ({
  context = "default",
  svg = null,
  children,
  toggleShow = null,
  ...rest
}) => {
  let classContext;

  switch (context) {
    case "primary":
      classContext = "c-message--primary";
      break;
    case "secondary":
      classContext = "c-message--secondary";
      break;
    case "error":
      classContext = "c-message--error";
      break;
    case "error-input":
      classContext = "c-message--error-input";
      break;
    case "success":
      classContext = "c-message--success";
      break;
    case "blanc":
      classContext = "c-message--bgblanc";
      break;
    default:
      classContext = "c-message--discret";
      break;
  }

  return (
    <>
      <div
        className={`${cssThemeEspace["c-message"]} ${classContext}${
          rest.className ? ` ${rest.className}` : ""
        }`}
      >
        {toggleShow !== null && (
          <i onClick={toggleShow} className={cssMessage["btn-close"]}>
            <Svg selection='ico-close' />
          </i>
        )}
        <div className={`${cssThemeEspace["c-message__inside"]}`}>
          {svg && <Svg selection={svg} />}
          <div className={`${cssThemeEspace["c-message__text"]}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

Message.propTypes = {
  context: PropTypes.string,
  svg: PropTypes.string,
};

export default Message;
