import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import cssComponent from "./ReCaptcha.module.css";
import Btn from "@/Shared/Ui/Btn";

// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
// https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
// https://brizzo.net/tips/hide-recaptcha-v3-badge/
const ReCaptcha = ({ refReCaptchaToken, mentions = true }) => {
  const updateReCaptchaToken = token => {
    if (refReCaptchaToken) refReCaptchaToken.current.reCaptchaToken = token;
  };

  return (
    <>
      <GoogleReCaptcha onVerify={updateReCaptchaToken} />
      {mentions && (
        <section className={cssComponent["section-google-recaptcha"]}>
          Ce site est protégé par reCAPTCHA et{" "}
          <Btn type='externalLink' href='https://policies.google.com/privacy'>
            les règles de confidentialité
          </Btn>
          &nbsp;et{" "}
          <Btn type='externalLink' href='https://policies.google.com/terms'>
            les conditions d&apos;utilisation de Google
          </Btn>{" "}
          s&apos;appliquent.
        </section>
      )}
    </>
  );
};

export default ReCaptcha;
