import { useContext, useEffect, useRef, useState } from "react";
import GlobalSuccessHandler from "@/Shared/GlobalSuccessHandler";
import Header from "@/Shared/Header";
import Spinner from "@/Shared/Spinner";
import Footer from "@/Shared/Footer";
import GlobalErrorHandler from "@/Shared/GlobalErrorHandler";
import useSWR from "swr";
import {
  LOADING,
  PROFESSIONAL_PROFILE_SIEGE,
  PROFESSIONAL_PROFILE_SITE,
} from "@/Constants/global";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeAdsActionFrom,
  changeAdsFilter,
  changeAdsOrder,
  changeInitialAdsFilter,
  loadAds,
} from "@/Actions/ads";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { getFromLocalStorage, setInLocalStorage } from "@/Utils/index";
import { deserialize } from "@/Utils/http/httpRequests";
import {
  ERROR_SAVED_RESEARCH_VALUES_FETCH,
  GENERIC_ERROR,
} from "@/Constants/errors";
import GlobalModalHandler from "@/Shared/GlobalModalHandler";
import { getInitialAdsFilter } from "@/Utils/adsFilter/getInitialAdsFilter";
import { ADS_FILTERS } from "@/Constants/filters";
import {
  PAGE_ANNONCE,
  PAGE_CONNEXION_INSCRIPTION,
  PAGE_INDEX,
  PAGE_PARTICULIER_RECHERCHES_SAUVEGARDEES,
  PAGE_RECHERCHE_VEHICULE,
} from "@/Constants/pageRoutes";
import {
  NEXT_CACHE_SLUG_CATEGORIES,
  NEXT_CACHE_SLUG_GLOBAL_SEO,
} from "@/Constants/nextCacheSlugs";
import {
  strapiGetBlogCategories,
  strapiGetGlobalBlogSeo,
} from "@/Utils/http/strapiApiRequests";
import {
  ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS,
  ACTION_FROM_INITIAL_FILTER_GENERATION,
} from "@/Constants/actions";
import { getAdsFilterFromResearch } from "@/Utils/adsFilter/getAdsFilterFromResearch";
import { removeValuesAdsFilter } from "@/Utils/adsFilter/removeValuesAdsFilter";

const arrayPathNamesNoAdsFilterReset = [
  PAGE_RECHERCHE_VEHICULE,
  PAGE_ANNONCE,
  PAGE_CONNEXION_INSCRIPTION,
];

const GlobalLayout = ({
  ads = {},
  children,
  error,
  changeAdsActionFrom,
  changeAdsOrder,
  changeAdsFilter,
  loadAds,
  changeInitialAdsFilter,
}) => {
  const router = useRouter();
  const session = useSession();

  const { data: blogCategories } = useSWR(
    NEXT_CACHE_SLUG_CATEGORIES,
    async () => await strapiGetBlogCategories()
  );
  const { data: globalBlogSeo } = useSWR(
    NEXT_CACHE_SLUG_GLOBAL_SEO,
    async () => await strapiGetGlobalBlogSeo()
  );

  const {
    state: {
      history,
      adsFilterData,
      filteredBrands,
      filteredModels,
      filteredRanges,
      globalSavedAdsResearch,
      my,
    },
    setCategoriesInfos,
    setCategoryAnnexes,
    setGlobalBlogSeo,
    setGlobalError,
    setGlobalSavedAdsResearch,
    setHistory,
    updateData,
    updateOptionsDealership,
  } = useContext(GlobalContext);

  const [isHeaderUp, setIsHeaderUp] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isToUseAdsInitialFilter, setIsToUseAdsInitialFilter] = useState(false);

  let boxFooter = useRef(null);

  const stringAdsFiltersInStorage = getFromLocalStorage("filters") || null;
  const stringAdsOrderInStorage = getFromLocalStorage("order") || null;

  const globalSavedAdsResearchFromStorage =
    getFromLocalStorage("savedAdsResearch") || null;

  function onRouterUpdate(router) {
    let newHistory = history;

    const previousPage = history[history.length - 1] || "";

    if (history.length > 10) {
      const lastPageVisited = history[9];
      newHistory = [lastPageVisited];
    }

    setHistory([...newHistory, router.pathname]);

    const isOnHomePage = router?.pathname === PAGE_INDEX;

    const isAdsFilterToResetDueToCurrentPage =
      !arrayPathNamesNoAdsFilterReset.includes(router?.pathname);

    // in order to prevent to reset the filters for nothing if user arrives from blog pages
    const isAdsFilterToResetDueToPreviousPage = Boolean(
      previousPage.match(PAGE_RECHERCHE_VEHICULE) ||
        previousPage.match(/^\/annonce/) ||
        previousPage.match(PAGE_CONNEXION_INSCRIPTION)
    );

    setIsToUseAdsInitialFilter(
      (isAdsFilterToResetDueToCurrentPage &&
        isAdsFilterToResetDueToPreviousPage) ||
        router?.query?.reinitialiseAdsFilter === "true" ||
        isOnHomePage
    );

    router?.query?.reinitialiseAdsFilter === "true" &&
      window.history.replaceState(null, "", window.location.pathname);
  }

  function updateInitialAdsFilter(
    adsFilterData,
    filteredBrands,
    filteredModels,
    filteredRanges
  ) {
    const initialAdsFilter = getInitialAdsFilter({
      filterData: adsFilterData,
      arrayFilteredBrands: filteredBrands,
      arrayFilteredRanges: filteredRanges,
      arrayFilteredModels: filteredModels,
      adsFilter: { ...ADS_FILTERS },
    });

    changeInitialAdsFilter({ ...initialAdsFilter });
  }

  function beforeUnloadEvent(ads, router) {
    if (arrayPathNamesNoAdsFilterReset.includes(router?.pathname)) {
      window.onbeforeunload = function (event) {
        // const realEvent = event || window.event
        // realEvent.preventDefault()
        setInLocalStorage("filters", ads.filters);
        setInLocalStorage("order", ads.order);
        setInLocalStorage("savedAdsResearch", globalSavedAdsResearch);
      };
    }
  }

  if (typeof window !== "undefined" && router) {
    beforeUnloadEvent(ads, router);
  }

  useEffect(() => {
    if (globalBlogSeo) setGlobalBlogSeo(globalBlogSeo);
  }, [globalBlogSeo]);

  useEffect(() => {
    if (blogCategories?.categoryAnnexes)
      setCategoryAnnexes(blogCategories.categoryAnnexes);

    if (blogCategories?.categoriesInfos) {
      blogCategories.categoriesInfos.articles = [];
      setCategoriesInfos(blogCategories.categoriesInfos);
    }
  }, [blogCategories]);

  useEffect(async () => {
    if (router) onRouterUpdate(router);
  }, [router]);

  useEffect(async () => {
    try {
      if (
        filteredBrands &&
        filteredModels &&
        filteredRanges &&
        adsFilterData &&
        !ads?.initialFilter
      ) {
        updateInitialAdsFilter(
          adsFilterData,
          filteredBrands,
          filteredModels,
          filteredRanges
        );
        loadAds();
      }
    } catch (error) {
      setGlobalError({ isToShow: false, error });
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
    }
  }, [filteredBrands, filteredModels, filteredRanges, adsFilterData]);

  useEffect(async () => {
    if (ads?.initialFilter) {
      if (isToUseAdsInitialFilter) {
        const adsFilter = removeValuesAdsFilter({ ...ads.initialFilter });

        setGlobalSavedAdsResearch(null);

        stringAdsOrderInStorage && setInLocalStorage("order", "");
        stringAdsFiltersInStorage && setInLocalStorage("filters", "");
        globalSavedAdsResearchFromStorage &&
          setInLocalStorage("savedAdsResearch", "");

        changeAdsFilter({ ...adsFilter });
        changeAdsOrder();
        changeAdsActionFrom(
          ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS
        );
        loadAds();

        setIsToUseAdsInitialFilter(false);
      }
    }

    if (!isToUseAdsInitialFilter) {
      if (stringAdsFiltersInStorage) {
        const adsFilter = deserialize(stringAdsFiltersInStorage);

        changeAdsFilter({ ...adsFilter });
        setInLocalStorage("filters", "");
      }

      if (stringAdsOrderInStorage) {
        const adsOrder = deserialize(stringAdsOrderInStorage);

        changeAdsOrder({ ...adsOrder });
        setInLocalStorage("order", "");
      }

      if (stringAdsOrderInStorage || stringAdsFiltersInStorage) {
        changeAdsActionFrom(ACTION_FROM_INITIAL_FILTER_GENERATION);
        loadAds();
      }
    }
  }, [ads?.initialFilter, isToUseAdsInitialFilter]);

  useEffect(() => {
    try {
      if (
        my?.seat?.concessions?.length &&
        my?.profil === PROFESSIONAL_PROFILE_SIEGE
      ) {
        updateOptionsDealership(my.seat.concessions);
      }

      if (my?.site && my?.profil === PROFESSIONAL_PROFILE_SITE) {
        updateOptionsDealership([my.site]);
      }
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);

      setGlobalError(GENERIC_ERROR);
    }
  }, [my]);

  useEffect(async () => {
    if (session?.status !== LOADING) {
      await updateData(session);
    }
  }, [session?.status]);

  useEffect(() => {
    const isUserConnected = Boolean(session?.data?.user?.accessToken);

    if (globalSavedAdsResearchFromStorage && isUserConnected) {
      const savedAdsResearch = deserialize(globalSavedAdsResearchFromStorage);
      setGlobalSavedAdsResearch(savedAdsResearch);
      setInLocalStorage("savedAdsResearch", "");
    }
  }, [session?.data?.user?.accessToken]);

  useEffect(async () => {
    try {
      if (
        (ads?.initialFilter && router?.pathname === PAGE_RECHERCHE_VEHICULE) ||
        router?.pathname === PAGE_PARTICULIER_RECHERCHES_SAUVEGARDEES
      ) {
        let adsFilter = removeValuesAdsFilter({ ...ads.initialFilter });

        if (globalSavedAdsResearch?.id && session?.data?.user?.accessToken) {
          adsFilter = await getAdsFilterFromResearch({
            adsFilter: { ...adsFilter },
            research: { ...globalSavedAdsResearch },
            accessToken: session.data.user.accessToken,
          });
        }

        changeAdsFilter({ ...adsFilter });
        changeAdsOrder();
        changeAdsActionFrom(
          ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS
        );
        loadAds();
      }
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);
      setGlobalError(ERROR_SAVED_RESEARCH_VALUES_FETCH);
    }
  }, [globalSavedAdsResearch]);

  /**
   * TODO merge these components GlobalErrorHandler, GlobalSuccessHandler and GlobalModalHandler  into a single one GlobalModal having as a prop an object globalModalProp with
   * something like errorCode, successCode, infoCode as fields.
   * GlobalLayout should set globalModalProp.error = error when error prop is truthy
   */
  return (
    <>
      <GlobalErrorHandler error={error} />
      <GlobalSuccessHandler />
      <GlobalModalHandler />
      <div
        className={`
        s-homepage global__app ${isSticky && "global__sticky-header"} ${
          isHeaderUp && "global__sticky-header--up"
        }
      `}
      >
        <Header fnSticky={setIsSticky} fnHeaderUp={setIsHeaderUp} />
        <Spinner />
        <main>{children}</main>
        <div ref={el => (boxFooter = el)}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default connect(
  store => ({ ads: store.ads }),
  dispatch => ({
    changeAdsActionFrom: bindActionCreators(changeAdsActionFrom, dispatch),
    changeAdsOrder: bindActionCreators(changeAdsOrder, dispatch),
    changeAdsFilter: bindActionCreators(changeAdsFilter, dispatch),
    loadAds: bindActionCreators(loadAds, dispatch),
    changeInitialAdsFilter: bindActionCreators(
      changeInitialAdsFilter,
      dispatch
    ),
  })
)(GlobalLayout);
