import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import thunkMiddleware from "redux-thunk";
import reducers from "@/Reducers/index";

const bindMiddleware = middlewares => {
  let output = applyMiddleware(...middlewares);

  if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) {
    output = composeWithDevTools(applyMiddleware(...middlewares));
  }

  return output;
};

// Merging states coming from server and client
const reducer = (state, action) => {
  let data = reducers(state, action);

  if (action.type === HYDRATE)
    data = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
      ads: state.ads,
    };

  return data;
};

const initStore = () => {
  let store = createStore(reducer, bindMiddleware([thunkMiddleware]));

  if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) {
    store = createStore(reducer, compose(bindMiddleware([thunkMiddleware])));
  }

  return store;
};

export const wrapper = createWrapper(initStore);
