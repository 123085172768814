/**
 * @namespace Constants
 * @module FEATURES
 * @description Fichier de constantes de features
 * @author Sébastien NOBOUR
 */

/**
 * @constant
 * @type {object}
 */
export const FEATURES = {
  googleTagManager: "google-tag-manager",
};
