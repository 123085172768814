/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes RestApiRoutes
 * @description Fichier de constantes de routes pour l'API métier. Les routes sont à apeller dans la fonction businessApi.
 * En fonction de la route, la fonction est capable de déterminer sous quelle forme
 * d'objet on veut recevoir et envoyer les données.
 */

/**
 * @constant
 * @type {string}
 * @todo Is ENTRYPOINT useless ?
 */
export const ENTRYPOINT = process.env.NEXT_PUBLIC_ENTRYPOINT;

// let apiUri = ENTRYPOINT
// if (ENTRYPOINT === 'https://caddy' || ENTRYPOINT === 'https://localhost') {
//   apiUri = `${ENTRYPOINT}/api`
// }
// if (
//   ENTRYPOINT === 'https://dev.api.weekandgo.com/public' ||
//   ENTRYPOINT === 'https://api.weekandgo.com/public'
// ) {
//   apiUri = `${ENTRYPOINT}/api`
// }

/**
 * @constant
 * @type {string}
 */
export const API_URI = `${ENTRYPOINT}/api`;

/**
 * @description ReCaptcha v2
 * @constant
 * @todo move in constants/global or delete with old recapctha
 * @type {string}
 */
export const RECAPTCHA_SITE_KEY = "6LfJVv8ZAAAAAELpLDaPR8Um0x92yy1G6ZllP58Z";

/**
 * @constant
 * @type {string}
 */
export const FILTERS_ROUTE = `${API_URI}/filters`;

/**
 * @constant
 * @type {string}
 */
export const FILTERS_BRANDS_ROUTE = `${FILTERS_ROUTE}/brands`;

/**
 * @constant
 * @type {string}
 */
export const FILTERS_FAMILIES_ROUTE = `${FILTERS_ROUTE}/families`;

/**
 * @constant
 * @type {string}
 */
export const FILTERS_MODELS_ROUTE = `${FILTERS_ROUTE}/models`;

/**
 * @constant
 * @type {string}
 */
export const FILTERS_RANGES_ROUTE = `${FILTERS_ROUTE}/ranges`;

/**
 * @constant
 * @type {string}
 */
export const FILTERS_CARRIERS_ROUTE = `${FILTERS_ROUTE}/carriers`;

/**
 * @constant
 * @type {string}
 */
export const OFFERS_ROUTE = `${API_URI}/offers`;

/**
 * @constant
 * @type {string}
 */
export const COUNTRIES_ROUTE = `${API_URI}/countries`;

/**
 * @constant
 * @type {string}
 */
export const RANGES_ROUTE = `${API_URI}/ranges`;

/**
 * @constant
 * @type {string}
 */
export const CARRIERS_ROUTE = `${API_URI}/carriers`;

/**
 * @constant
 * @type {string}
 */
export const FAMILIES_ROUTE = `${API_URI}/families`;

/**
 * @constant
 * @type {string}
 */
export const MODELS_ROUTE = `${API_URI}/models`;

/**
 * @constant
 * @type {string}
 */
export const USERS_ROUTE = `${API_URI}/users`;

/**
 * @constant
 * @type {string}
 */
export const USERS_MORE_ROUTE = `${API_URI}/usermore`;

/**
 * @constant
 * @type {string}
 */
export const DEALERS_ROUTE = `${API_URI}/dealers`;

/**
 * @constant
 * @type {string}
 */
export const BRANDS_ROUTE = `${API_URI}/brands`;

/**
 * @constant
 * @type {string}
 */
export const BRANDS_FAVORITES_ROUTE = `${BRANDS_ROUTE}/favorites`;

/**
 * @constant
 * @type {string}
 */
export const LOGIN_ROUTE = `${API_URI}/login`;

/**
 * @constant
 * @type {string}
 */
export const LOGOUT_ROUTE = `${API_URI}/logout`;

/**
 * @constant
 * @type {string}
 */
export const NEWSLETTER_SUBSCRIBE_ROUTE = `${API_URI}/newsletter/subscribe`;

/**
 * @constant
 * @type {string}
 */
export const MY_ROUTE = `${API_URI}/my`;

/**
 * @constant
 * @type {string}
 */
export const MY_IMAGES_ROUTE = `${API_URI}/my/images`;

/**
 * @constant
 * @type {string}
 */
export const MY_CONCESSIONS_ROUTE = `${MY_ROUTE}/concessions`;

/**
 * @constant
 * @type {string}
 */
export const MY_CONCESSIONS_NEW_ROUTE = `${MY_ROUTE}/concessions/new`;

/**
 * @constant
 * @type {string}
 */
export const MY_CONCESSION_ROUTE = `${MY_ROUTE}/concession`;

/**
 * @constant
 * @type {string}
 */
export const MY_CONCESSION_UPDATE_ROUTE = `${MY_ROUTE}/concession/update`;

/**
 * @constant
 * @type {string}
 */
export const MY_CONCESSION_REMOVE_ROUTE = `${MY_ROUTE}/concession/remove`;

/**
 * @constant
 * @type {string}
 */
export const MY_CONTACT_ROUTE = `${API_URI}/my/contact`;

/**
 * @constant
 * @type {string}
 */
export const MY_BANK_DATA_UPDATE_ROUTE = `${MY_ROUTE}/bankdata/update`;

/**
 * @constant
 * @type {string}
 */
export const MY_BANK_DATA_REQUEST_CURRENT_ROUTE = `${MY_ROUTE}/bankdatarequest/current`;

/**
 * @constant
 * @type {string}
 */
export const MY_DETAILS_UPDATE_ROUTE = `${MY_ROUTE}/details/update`;

/**
 * @constant
 * @type {string}
 */
export const MY_HOME_ROUTE = `${MY_ROUTE}/home`;

/**
 * @constant
 * @type {string}
 */
export const MY_PASSWORD_UPDATE_ROUTE = `${MY_ROUTE}/password/update`;

/**
 * @constant
 * @type {string}
 */
export const MY_PREFERENCES_ROUTE = `${MY_ROUTE}/preferences`;

/**
 * @constant
 * @type {string}
 */
export const MY_MORE_ROUTE = `${MY_ROUTE}/more`;

/**
 * @constant
 * @type {string}
 */
export const MY_VEHICLES_ROUTE = `${MY_ROUTE}/vehicles`;

/**
 * @constant
 * @type {string}
 */
export const MY_OFFERS_ROUTE = `${MY_ROUTE}/offers`;

/**
 * @constant
 * @type {string}
 */
export const MY_OFFERS_TEMP_ROUTE = `${MY_ROUTE}/offerstemp`;

/**
 * @constant
 * @type {string}
 */
export const MY_FAVORITES_ROUTE = `${MY_ROUTE}/favorites`;

/**
 * @constant
 * @type {string}
 */
export const MY_OFFER_IMAGES_ROUTE = `${MY_ROUTE}/offerimages`;

/**
 * @constant
 * @type {string}
 */
export const MY_VEHICLES_NEW_ROUTE = `${MY_VEHICLES_ROUTE}/new`;

/**
 * @constant
 * @type {string}
 */
export const MY_USER_ROUTE = `${MY_ROUTE}/user`;

/**
 * @constant
 * @type {string}
 */
export const MY_USER_DETAILS_ROUTE = `${MY_USER_ROUTE}/details`;

/**
 * @constant
 * @type {string}
 */
export const MY_USER_DASHBOARD_ROUTE = `${MY_USER_ROUTE}/dashboard`;

/**
 * @constant
 * @type {string}
 */
export const MY_SAVED_RESEARCHES_ROUTE = `${MY_ROUTE}/saved-researches`;

/**
 * @constant
 * @type {string}
 */
export const USER_DMS_ROUTE = `${API_URI}/userdms`;

/**
 * @constant
 * @type {string}
 */
export const USER_DMS_NEW_ROUTE = `${USER_DMS_ROUTE}/new`;

/**
 * @constant
 * @type {string}
 */
export const TOKEN_REFRESH_ROUTE = `${API_URI}/token/refresh`;

/**
 * @constant
 * @type {string}
 */
export const USER_ROUTE = `${API_URI}/user`;

/**
 * @constant
 * @type {string}
 */
export const USER_PASSWORD_TOKEN_ROUTE = `${USER_ROUTE}/password/token`;

/**
 * @constant
 * @type {string}
 */
export const USER_PASSWORD_RESET_ROUTE = `${USER_ROUTE}/password/reset`;
