import React from "react";
import css from "./BackToTop.module.css";
import Svg from "@/Shared/Svg";

const BackToTop = ({ visible }) => {
  const handleClickBTT = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`${css["backToTop"]} ${
        visible ? css["backToTop--on"] : css["backToTop--off"]
      }`}
      onClick={handleClickBTT}
    >
      <Svg selection='ico-arrow-down' />
    </div>
  );
};

BackToTop.propTypes = {
  //
};

export default BackToTop;
