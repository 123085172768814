/**
 * @namespace Constants
 * @module Constantes Filters
 * @description constantes contenant les matrices de filtres notamment utilisés
 * par les moteurs de recherche de véhicules et de concessions.
 * @author Sébastien NOBOUR
 */

import {
  BEDTYPES,
  FLUXPROTRI,
  // BEDTYPES,
  GEARBOXES,
  HEATING_SOURCES,
  millesimes,
} from "@/Constants/InputsValues";

/**
 * @constant
 * @description Filtre contenant les critères pour la recherche d'annonces
 * @type object
 * @todo // TODO all fields should be handled in query params ASAP
 */
export const ADS_FILTERS = {
  type: {
    name: "type",
    label: null,
    type: "button",
    visible: true,
    values: [
      { label: "Neuf", name: "neuf", checked: false, disabled: false },
      { label: "Occasion", name: "occasion", checked: false, disabled: false },
    ],
  },
  "user.userType": {
    name: "user.userType",
    label: "Vendeur",
    type: "checkbox",
    visible: true,
    values: [
      {
        label: "Professionnel",
        name: "professionnel",
        checked: false,
        disabled: false,
      },
      {
        label: "Particulier",
        name: "particulier",
        checked: false,
        disabled: false,
      },
    ],
  },
  "model.rangeVehicle": {
    name: "model.rangeVehicle",
    label: "Type de carrosserie",
    type: "svg",
    visible: true,
    values: [
      {
        label: "Capucine",
        svg: "carrosserie-capucine",
        name: "capucine",
        id: 6,
        checked: false,
        disabled: false,
      },
      {
        label: "Caravane",
        svg: "carrosserie-caravane",
        name: "caravane",
        id: 5,
        checked: false,
        disabled: false,
      },
      {
        label: "Fourgon",
        svg: "carrosserie-fourgon",
        name: "fourgon",
        id: 4,
        checked: false,
        disabled: false,
      },
      {
        label: "Intégral",
        svg: "carrosserie-integral",
        name: "integral",
        id: 1,
        checked: false,
        disabled: false,
      },
      {
        label: "Profilé",
        svg: "carrosserie-profile",
        name: "profile",
        id: 2,
        checked: false,
        disabled: false,
      },
      {
        label: "Van/Combi",
        svg: "carrosserie-van",
        name: "van",
        id: 3,
        checked: false,
        disabled: false,
      },
    ],
  },
  bedType: {
    name: "bedType",
    label: "Couchage principal",
    type: "svg",
    visible: true,
    values: [...BEDTYPES],
  },
  size: {
    name: "size",
    label: "Dimensions en m",
    type: "multi-slider",
    visible: true,
    values: [
      {
        name: "length",
        label: "Longueur",
        type: "slider",
        min: 3.0,
        max: 10.0,
        step: 0.1,
        values: [],
      },
      {
        name: "height",
        label: "Hauteur",
        type: "slider",
        min: 0.0,
        max: 3.2,
        step: 0.1,
        values: [],
      },
      {
        name: "width",
        label: "Largeur",
        type: "slider",
        min: 0.0,
        max: 3.0,
        step: 0.1,
        values: [],
      },
    ],
  },
  inclVatPrice: {
    name: "inclVatPrice",
    label: "Prix en euros",
    type: "slider",
    min: 0,
    max: 120000,
    step: 5000,
    visible: true,
    values: [],
  },
  localisation: {
    name: "localisation",
    label: "Périmètre de recherche",
    type: "multi-text",
    visible: true,
    values: [
      {
        name: "postcode",
        label: "Code Postal",
        type: "number",
        layout: "wide",
        value: "",
      },
      {
        name: "radius",
        label: "100",
        type: "number",
        layout: "inline",
        before: "élargir à",
        after: "km",
        value: "100",
      },
    ],
  },
  registeredSeats: {
    name: "registeredSeats",
    label: "Places carte grise",
    type: "slider",
    min: 3,
    max: 7,
    step: 1,
    visible: true,
    values: [],
  },
  sleepingSeats: {
    name: "sleepingSeats",
    label: "Places de couchage",
    type: "slider",
    min: 2,
    max: 7,
    step: 1,
    visible: true,
    values: [],
  },
  mileage: {
    name: "mileage",
    label: "Kilométrage",
    type: "slider",
    min: 0,
    max: 310000,
    step: 10000,
    visible: true,
    values: [],
  },
  millesime: {
    name: "millesime",
    label: "Millésime",
    type: "slider",
    min: 1980,
    max: Number(millesimes?.[0]?.value),
    step: 1,
    visible: true,
    values: [],
  },
  "model.brand": {
    name: "model.brand",
    label: "Marques",
    type: "checkbox",
    visible: true,
    values: [],
    fixHeight: true,
    fixHeight100: false,
  },
  model: {
    name: "model",
    label: "Modèles",
    type: "checkbox",
    visible: true,
    values: [],
    fixHeight: true,
    fixHeight100: false,
  },
  gearbox: {
    name: "gearbox",
    label: "Boîte de vitesses",
    type: "checkbox",
    visible: true,
    values: GEARBOXES,
  },
  // carrier: {
  //    name: 'carriers',
  //   label: 'Marques porteur',
  //   type: 'checkbox',
  //   values: []
  // },
  grossWeight: {
    name: "grossWeight",
    label: "PTAC",
    type: "checkbox",
    visible: true,
    values: [
      {
        label: "Inférieur ou égal à 3,5T",
        paramQueryString: "grossWeight[lte]=3500",
        checked: false,
        disabled: false,
      },
      {
        label: "Supérieur à 3,5T (poids lourd)",
        paramQueryString: "grossWeight[gt]=3500",
        checked: false,
        disabled: false,
      },
    ],
  },
  // chassis: {
  //   label: 'Châssis',
  //   type: 'checkbox',
  //   visible: false,
  //   values: [
  //     { label: 'Châssis AL-KO', name: '' }
  //   ]
  // },
  heating: {
    name: "heating",
    label: "Chauffage",
    type: "checkbox",
    visible: true,
    values: [...HEATING_SOURCES],
  },
  // TODO miss heatingType
  // heatingType (fluid) ? heating source (gas etc),
  dealer: {
    name: "dealer",
    label: "Concessionnaire",
    type: "button",
    visible: false,
    disabled: true,
    values: [
      { checked: false, disabled: true, id: "", label: "", key: "blank" },
    ],
  },
};

/**
 * @constant
 * @description Filtre contenant les critères pour le filtrage de données gérées d'une concession
 * @type object
 */
export const FILTERS_PROFESSIONAL_PROFILE_SEAT = {
  dealership: null,
  isFilteringArchived: false,
  // #SLEEPING isMatchingBoosterFilter: false,
  isFilteringFirstHand: false,
  isFilteringSecondHand: false,
  isFilteringUnpublished: false,
  sorting: FLUXPROTRI[0].value,
  year: null,
};

/**
 * @constant
 * @description Filtre contenant les critères pour la recherche de concessionnaires
 * @type object
 */
export const FILTER_DEALERSHIPS = {
  localisation: {
    name: "localisation",
    label: "Périmètre de recherche",
    type: "multi-text",
    visible: true,
    values: [
      {
        name: "postcode",
        label: "Code Postal",
        type: "number",
        layout: "wide",
        value: "",
        min: 0,
        max: 99999999,
      },
      {
        name: "radius",
        label: "100",
        type: "number",
        layout: "inline",
        before: "élargir à",
        after: "km",
        value: 100,
        min: 0,
        max: 99999,
      },
    ],
  },
  brand: {
    name: "brand",
    label: "Marques distribuées",
    type: "checkbox",
    visible: true,
    values: [],
    fixHeight: true,
    fixHeight100: false,
  },
};
