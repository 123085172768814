/**
 * @namespace Constants
 * @module Constantes Actions
 * @description constantes d'actions utilisées avec redux
 */

/**
 * @constant
 * @type {string}
 */
export const ACTION_FILTER_CHANGED_FROM_PASSERELLES =
  "ADS/FILTER_CHANGED_FROM_PASSERELLES";

/**
 * @constant
 * @type {string}
 */
export const ACTION_FILTER_CHANGED_FROM_SEARCH_FORM =
  "ADS/FILTER_CHANGED_FROM_SEARCH_FORM";

/**
 * @constant
 * @type {string}
 */
export const ACTION_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS =
  "ADS/LIST_CHANGED_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS";

/**
 * @constant
 * @type {string}
 */
export const ACTION_FROM_INITIAL_FILTER_GENERATION =
  "ADS/LIST_CHANGED_FROM_FORM_SUBMIT_OR_CHANGE_ORDER_OR_LOAD_MORE_ADS";
