/**
 * @namespace Constants
 * @module Constantes Google Events
 * @author Sébastien NOBOUR
 * @description Fichier de constantes contenants des objets représentant des évènements
 * à enclencher pour être captés par Google Universal Analytics, Google Analytics 4
 * ou Google Tag Manager
 */

export const GTAG_EVENTS = {
  jeu_ok: {
    action: "submit",
    eventCategory: "jeu_concours",
    eventLabel: "jeu_ok",
    value: 1,
  },
  inscription_particulier: {
    action: "clic",
    eventCategory: "inscription",
    eventLabel: "inscription_particulier",
    value: 1,
  },
  publication_annonce: {
    action: "clic",
    eventCategory: "publication",
    eventLabel: "publication_annonce",
    value: 1,
  },
  inscription_newsletter: {
    action: "clic",
    eventCategory: "Inscription",
    eventLabel: "inscription_newsletter",
    value: 1,
  },
  inscription_professionnel: {
    action: "clic",
    eventCategory: "inscription",
    eventLabel: "inscription_professionnel",
    value: 1,
  },
  test_dev: {
    action: "load_test",
    eventCategory: "test_dev",
    eventLabel: "test_dev",
    value: 0,
  },
};
