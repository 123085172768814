import React from "react";
import { Box } from "@mui/system";
import { isBoolean, isEmpty, isNumber, orderBy } from "lodash";
import cssThemeEspace from "@/Theme/EspaceTheme.module.css";
import { getSession } from "next-auth/react";
import { stringify } from "query-string";
import moment from "moment";
import {
  EMPTY_DATA,
  IS_NOT_OFFER,
  IS_OFFER,
  NOT_FOUND,
  NOT_FOUND_NO_CAPITAL_F,
  NOT_FOUND_SNAKE_CASE,
  RELEASE_DATE_ERROR,
  SESSION_EXPIRED,
  VEHICLE_WRONG_HEIGHT,
  VEHICLE_WRONG_INCL_VAT_PRICE,
  VEHICLE_WRONG_LENGTH,
  VEHICLE_WRONG_WIDTH,
  WRONG_IMAGE_FORMAT,
} from "@/Constants/errors";
import {
  BEDTYPES,
  CARROSSERIES,
  GEARBOXES,
  bedTypes,
  energies,
  millesimes,
} from "@/Constants/InputsValues";
import {
  formatNumberToLocale,
  normalize,
} from "@/Utils/normalizers/businessApiNormalizers";
import placeholderCapucine from "@/Images/annonces/placeholder-capucine.jpg";
import placeholderCaravane from "@/Images/annonces/placeholder-caravane.jpg";
import placeholderFourgon from "@/Images/annonces/placeholder-fourgon.jpg";
import placeholderProfle from "@/Images/annonces/placeholder-profile.jpg";
import placeholderVan from "@/Images/annonces/placeholder-van.jpg";
import {
  DEALERSHIP_COVER_IMAGE_DEFAULT_DIMENSIONS,
  DEALERSHIP_COVER_IMAGE_TYPE,
  DEALERSHIP_LOGO_IMAGE_DEFAULT_DIMENSIONS,
  DEALERSHIP_LOGO_IMAGE_TYPE,
  NEXT_PHASES,
  PROFESSIONAL_PROFILE_SIEGE,
  PROFESSIONAL_PROFILE_SITE,
  missingCoverImageData,
  missingLogoImageData,
} from "@/Constants/global";
import { serialize } from "@/Utils/http/httpRequests";
import { ONE_HOUR, ONE_MINUTE } from "@/Constants/time";
import { DATES_GAME_FREE_FUEL_2022_MARCH } from "@/Constants/dates";
import * as PAGES_ROUTES from "@/Constants/pageRoutes";
import {
  DYNAMIC_PAGE_ROUTES,
  PAGE_404,
  PAGE_INDEX,
  PAGE_PRO_FLUX,
  PAGE_PRO_HOME,
  PAGE_PRO_MA_CONCESSION,
  PAGE_PRO_MES_CONCESSIONS,
} from "@/Constants/pageRoutes";
import Critere from "@/Shared/SearchEngine/Critere";
import css from "@/Shared/SearchEngine/BlockCritereNew/BlockCritereNew.module.css";
import { ADS_FILTERS, FILTER_DEALERSHIPS } from "@/Constants/filters";
import {
  FORM_CONTEXT_DEALERSHIPS_FILTER,
  FORM_CONTEXT_OFFERS_FILTER,
} from "@/Constants/formContexts";
import {
  MAX_WIDTH_COVER_DEALERHIP,
  MAX_WIDTH_LOGO_DEALERHIP,
  ROUND_LIMITS_FUNCTIONS,
} from "@/Constants/limits";
import {
  restApiApiGetMyUserDetails,
  restApiGetOffers,
} from "@/Utils/http/businessApiRequests";

/**
 * @file utils/index.js
 * @description contient des fonctions générales
 * @todo a besoin d'être redécoupé plus finement
 * @module Utils index
 * @namespace Utils
 */

/**
 * @function
 * @description formate un paramètre de type string en url, en remplaçant les espaces par des '-' et mettant tout en
 * lower case. Utile pour générer un slug pour une catégorie d'articles de blogs
 * @param args
 * @returns {string}
 */
export const formatURLSlug = (...args) => {
  return encodeURI(
    args.reduce((slug, arg) => {
      let string;
      if (arg?.toString()) {
        string = `${slug}-${arg
          .toString()
          .replace(/\s/, "-")
          .trim()
          .toLowerCase()}`;
      }
      return string;
    })
  );
};

/**
 * @function
 * @description transforme les propriétés et valeurs d'un objet en un string pour une requête http.
 * Utilisé avec restApiGetOffers.
 * @todo Il y a un package 'query-string' qui existe pour ce besoin.
 * @param object
 * @returns {string}
 * @author David
 */
export const objectToQueryString = object => {
  const queryString = [];
  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        Object.keys(value).length > 0
      ) {
        const obj = Object.entries(value)[0];
        queryString.push(`${key}[${obj[0]}]=${obj[1]}`);
      } else if (typeof value !== "object" || Array.isArray(value)) {
        if (Array.isArray(value)) {
          value.forEach(e => queryString.push(`${key}[]=${e}`));
        } else {
          queryString.push(`${key}=${value}`);
        }
      }
    }
  });
  return encodeURI(`?${queryString.join("&")}`);
};

// Given an object with props, creates the corresponding QueryString
/**
 * @function
 * @description Retourne un string de query à partir des propriétés et valeurs d'un filtre ADS_FILTER ou DEALERS_FILTER.
 * @namespace Code Central
 * @param filters
 * @param params
 * @returns {string}
\ */
export const filtersToQueryString = (
  filters,
  params = { context: FORM_CONTEXT_OFFERS_FILTER }
) => {
  let arrayQueries = [];

  for (const [key, filter] of Object.entries(filters)) {
    switch (filter?.type) {
      case "button":
      case "svg":
      case "checkbox":
        // TODO the choice of value to use in query should be on one and only field (paramQueryString ?)
        if (params.context === FORM_CONTEXT_DEALERSHIPS_FILTER) {
          const keyParams = filter.values?.length
            ? {
                [key]: [],
              }
            : null;

          filter.values?.forEach(value => keyParams?.[key]?.push(value));

          const stringKeyQuery = keyParams ? stringify(keyParams) : null;

          if (stringKeyQuery) arrayQueries.push(stringKeyQuery);
        }

        if (params.context === FORM_CONTEXT_OFFERS_FILTER) {
          filter.values
            ?.filter(value => value.checked)
            ?.forEach(value =>
              arrayQueries?.push(
                value.paramQueryString ??
                  `${key}[]=${
                    value.queryValue ?? value.id ?? value.name ?? value.label
                  }`
              )
            );
        }
        break;
      case "slider":
        if (filter.values[0]) {
          arrayQueries.push(`${key}[gte]=${filter.values[0].toString()}`);
        }
        if (filter.values[1]) {
          arrayQueries.push(`${key}[lte]=${filter.values[1].toString()}`);
        }
        break;
      case "multi-slider":
        filter.values.forEach(({ name, values }) => {
          if (values[0]) {
            arrayQueries.push(`${name}[gte]=${values[0].toString()}`);
          }
          if (values[1]) {
            arrayQueries.push(`${name}[lte]=${values[1].toString()}`);
          }
        });
        break;
      case "multi-checkbox":
        filter.values.forEach(
          // todo refactor: no v.id or v.name... subFilter.value for all maybe
          v =>
            v.values
              .filter(v => v.checked)
              .forEach(v =>
                arrayQueries.push(`${key}[]=${v.id || v.name || v.label}`)
              )
        );
        break;
      case "multi-text":
        filter.values.forEach(filterValue => {
          if (filterValue.value) {
            const queryNameRegex = new RegExp("^(" + filterValue.name + "=).*");
            arrayQueries = arrayQueries.filter(
              query => !queryNameRegex.test(query)
            );
            arrayQueries.push(`${filterValue.name}=${filterValue.value}`);
          }
        });
        break;
      default:
    }
  }

  let queryString = "";

  if (arrayQueries?.length) {
    if (params.context === FORM_CONTEXT_OFFERS_FILTER)
      queryString = "&" + arrayQueries.join("&");
    if (params.context === FORM_CONTEXT_DEALERSHIPS_FILTER)
      queryString = "?" + arrayQueries.join("&");
  }
  queryString = queryString.replaceAll("dealer[]", "dealer");
  queryString = encodeURI(queryString);

  return queryString;
};

/**
 * @function
 * @description Permet d'extraire l'id d'une annonce depuis son slug. Pas sûr que ce soit une bonne idée de garder
 * cette fonction.
 * @param slug
 * @returns {number}
 */
export const getIdFromSlug = slug => {
  if (!slug.length) {
    throw new Error(EMPTY_DATA);
  }
  return ~~slug?.substring(slug?.lastIndexOf("-") + 1);
};

/**
 * @function
 * @description Utilisé par normalizeOffers. Permet de récupérer les informations pour gérer la pagination sur la
 * requête récupérant les offres de l'API métier
 * @param string
 * @param char
 * @returns {string | undefined}
 */
export const getParamAfterLastChar = (string, char) => {
  return string?.substring(string?.lastIndexOf(char) + 1);
};

/**
 * @function
 * @description Utilisée par le composant SVG pour transformer un array de classes en modules css en un string
 * @param classes
 * @param css
 * @returns {*}
 */
export const listClassesTostring = (classes, css) =>
  classes
    .split(" ")
    .map(classe => css[classe])
    .join(" ");

/**
 * @function
 * @description Transforme un type number en type string pour l'affichage sur l'UI
 * @param x {number | null}
 * @returns {string}
 */
export const numberToString = (x = 0) => {
  let output;
  try {
    if (x === null) x = 0;
    output = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } catch (error) {
    if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) {
      console.warn("numberToString execution error: ", error);
      console.warn(x);
    }
  }
  return output;
};

/**
 * @function
 * @description retourne un string devant correspondre à une valeur contenue dans une key de l'un des objets dans
 * l'array svgList afin de permettre au composant Svg d'afficher un élément svg existant listé dans svgList.
 * @param dbselect
 * @returns {string|*}
 */
export const svgSelectionFromDB = dbselect => {
  const referenceOptions = [...CARROSSERIES, ...BEDTYPES];
  let output;

  let option = referenceOptions?.find(
    referenceValue => referenceValue.name === dbselect
  );

  if (!option) {
    option = referenceOptions?.find(
      referenceValue => referenceValue.key === dbselect
    );
  }

  if (!option) {
    option = referenceOptions?.find(
      referenceValue => referenceValue.value === dbselect
    );
  }

  if (!option) {
    referenceOptions?.forEach(referenceValue => {
      if (referenceValue?.moreValues) {
        const value = referenceValue?.moreValues.find(
          value => value === dbselect
        );
        if (value) {
          option = referenceValue;
        }
      }
    });
  }

  if (option?.value) {
    output = option.value;
  }

  return output;
};

/**
 * @function
 * @description applique un style (séparé ?) avec une Box Material UI sur un input de formulaire.
 * @param input
 * @param className
 * @param key
 * @todo make a react component of it. Should not be here
 * @returns {JSX.Element}
 */
export const splitInput = (input, className = null, key) => {
  return (
    <Box
      sx={{
        width: {
          mobile: "100%",
          b60: "48%",
        },
      }}
      className={className && cssThemeEspace[className]}
      key={key}
    >
      {input}
    </Box>
  );
};

/**
 * @function
 * @description applique un style (large ?) avec une Box Material UI sur un input de formulaire.
 * @param input
 * @param className
 * @param key
 * @todo make a react component of it. Should not be here
 * @returns {JSX.Element}
 */
export const wideInput = (input, noMarge = false) => {
  return (
    <Box
      sx={{
        width: {
          mobile: "100%",
          b60: "100%",
        },
      }}
    >
      {input}
    </Box>
  );
};

/**
 * @function
 * @description vérifie qu'une personne est majeure sur la base de la date de naissance fournie.
 * @param birthday
 * @returns {Boolean}
 */
export const validateIsAdult = birthday => {
  let isAdult = false;
  const currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
  // calculate age comparing current date and birthday
  const age = ~~((Date.now(currentDate) - birthday) / 31557600000);

  if (age > 18) {
    isAdult = true;
  }
  return isAdult;
};

/**
 * @function
 * @description vérifie qu'un objet est bien fourni et qu'il n'est pas vide
 * @param object
 * @returns {Boolean}
 */
export const isObjectTruthy = object =>
  !(isEmpty(object) && object === undefined);

/**
 * @function
 * @description vérifie qu'un token est bien fourni et qu'il est bien un string avec une longueur.
 * @param token
 * @returns {false|*}
 */
export const isTokenTruthy = token =>
  typeof token === "string" && token?.length;

/**
 * @function
 * @description Récupère l'id sous un type number depuis un IRI. Utilisé pour générer les champs id de ressources
 * renvoyées par l'API métier
 * @param entryField
 * @returns {number}
 */
export const getIdFromIri = (entryField = undefined) => {
  let output;
  if (entryField) {
    output = ~~entryField.substring(entryField.lastIndexOf("/") + 1);
  }
  return output;
};

/**
 * @function
 * @deprecated
 * @description permet de générer un array contenant toutes les années entre l'année actuelle et celle fournie pour le
 * départ.
 * @param startYear
 * @returns Array<number | null>
 */
export const generateYears = startYear => {
  const currentYear = new Date().getFullYear(),
    years = [];

  startYear = startYear || currentYear;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

/**
 * @function
 * @description retourne sous forme de tableau, une liste des critères permettant de générer le slug d'une annonce.
 * @param offer
 * @returns Array<*,*,*,string>
 */
export const generateOfferSlugArray = offer => {
  const range = offer?.model?.rangeVehicle?.slugFr || null;
  const brand = offer?.model?.brand?.slug || null;
  const model = offer?.model?.slug || null;
  const id =
    offer?.id?.toString() || getIdFromIri(offer?.["@id"])?.toString() || null;

  return [range, brand, model, id];
};

/**
 * @function
 * @description retourne un slug à partir d'une offre fournie
 * @param offer
 * @returns {string}
 */
export const generateOfferSlug = offer => {
  let slug = "";
  const slugArray = generateOfferSlugArray(offer);

  slugArray.forEach((param, index) => {
    if (param?.length) {
      let output = `-${param}`;
      if (index === 0) {
        output = param;
      }
      slug += output;
    }
  });
  return slug;
};

/**
 * @function
 * @description retourne une url correspondant à une image et la dimension (pas format...) demandé en paramètre
 * pour récupérer l'image sur l'API métier
 * @param entryField
 * @param format
 * @returns {*}
 */
export const withImageFormat = (entryField = undefined, format = undefined) => {
  let output;
  if (entryField && format) {
    if (
      !(
        format === "large" ||
        format === "medium" ||
        format === "small" ||
        format === "thumbnails"
      )
    ) {
      throw new Error(WRONG_IMAGE_FORMAT);
    }
    output = entryField?.replace("/images/offers", `/images/offers/${format}`);
  }
  return output;
};

/**
 * @function
 * @description formate un objet de données de véhicules en un objet au format accepté par un formulaire associé
 * @param data
 * @param type
 * @returns object
 */
export const formatVehicleOfferDataToForm = (
  data,
  type = { offer: true, vehicle: false }
) => {
  if (type?.vehicle) type.offer = false;

  data.length = parseFloat(data.length).toFixed(2);
  data.height = parseFloat(data.height).toFixed(2);
  data.width = parseFloat(data.width).toFixed(2);

  data.length = formatNumberToLocale(data.length);
  data.height = formatNumberToLocale(data.height);
  data.width = formatNumberToLocale(data.width);

  let output = {
    id: data?.id || "",
    range: data?.model?.rangeVehicle?.slugFr || "",
    brand: data?.model?.brand?.id || "",
    millesime: data?.millesime || "",
    name: data?.model?.name || "",
    bedType:
      bedTypes.find(bedType => bedType?.name === data?.bedType)?.value || "",
    registeredSeats: data?.registeredSeats || "",
    sleepingSeats: data.sleepingSeats,
    length: data?.length || "",
    width: data?.width || "",
    height: data?.height || "",
    popUpRoof: data?.popUpRoof === true ? "yes" : "no",
    mileage: data?.mileage || "",
    carrier: getIdFromIri(data?.carrier?.["@id"]) || "",
    gearbox:
      GEARBOXES.find(gearbox => gearbox.value === data?.gearbox)?.value || "",
    energy: energies.find(energy => energy.value === data?.energy)?.value || "",
    dinPower: data?.dinPower || "",
    fiscalPower: data?.fiscalPower || "",
    chassisNumber: data?.chassisNumber || "",
    grossWeight: data?.grossWeight || "",
    firstHand: data?.firstHand === true ? "neuf" : "occasion",
    unladenWeight: data?.unladenWeight || "",
    releaseDate: data?.releaseDate || "",
  };

  if (type?.offer) {
    let inclVatPrice = formatNumberToLocale(data?.inclVatPrice);
    if (inclVatPrice === "0") inclVatPrice = "";

    output = {
      ...output,
      city: data?.city || "",
      country: data?.country || "",
      heaterEnergy: data?.heaterEnergy || "",
      heaterType: data?.heaterType || "",
      images: data?.images || [],
      inclVatPrice: inclVatPrice ?? "",
      introduction: data?.introduction || "",
      shownName: data?.shownName || "",
      phoneHidden: data?.phoneHidden === true ? "yes" : "no",
      zipCode: data?.zipCode || "",
      game: isBoolean(data?.game) || false,
    };
  }

  return output;
};

/**
 * @function
 * @async
 * @description Permet d'éviter les erreurs lorsqu'un objet session n'est pas fourni dans les phases de build de Next.js
 * @param phase
 * @param context
 * @returns {Promise<{}>}
 */
export const handleSessionFetchingByPhase = async (phase, context) => {
  let session = {};
  if (phase && phase !== NEXT_PHASES.build) {
    session = await getSession(context);
  }
  return session;
};

/**
 * @function
 * @async
 * @description Cette fonction executée uniquement sur le serveur gère les données à retourner à une page de
 * l'application frontend.
 *
 * Le paramètre session correspond à un objet retourné par la fonction
 * await getSession(context) de 'next-auth/react'; le paramètre context est un objet passé en paramètre
 * à getServerSideProps ou getStaticProps; l'objet option contient dans arrayRequestCallbacks un array de requêtes à
 * effectuer, un champ arrayPropNames contenant array de string pour les noms de props à associer à une réponse
 * récupérée dans à arrayRequestCallbacks dans l'ordre respectif des requêtes pour retourner un objet de props à la
 * sortie de cette fonction, getErrors contenant un boolean pour retourner ou pas l'erreur dans les props en sortie
 * de fonction, getParams contenant un boolean pour retourner ou pas les params (comme une redirection) en sortie de
 * la fonction, isDynamicRouteToValidate contenant un boolean pour executer des fonctions propres à la gestion de
 * route de page dynamiques (comme pages/espace-pro/concession/[[...params..]].js; isWithProfileRestrictions contenant
 * un boolean pour générer des redirections si une page est restreinte à un professionnel de profil siège ou site;
 * userType contenant soit USER_TYPE_PROFESSIONNEL ou USER_TYPE_PARTICULIER pour générer des redirections si une page
 * est restreinte à session.userType professionnel ou particulier; redirect contenant un string correspondant à une
 * url sur laquelle on veut rediriger en cas d'invalidation avec options.userType ou  options.isWithProfileRestrictions;
 * props étant un objet contenant les props récupérées avant l'execution de la fonction prepareServerProps mais qu'elle
 * doit tout de même retourner.
 *
 * @todo pas mal de refactor pour rendre la logique plus simple, options.arrayRequestCallbacks et options.arrayPropNames pourraient peut-être être fusionnés. ParamsByError devrait plutôt être paramsByStatus (http code), mais pas pour le moment puisque ce n'est pas systématiquement retourné par les endpoints.
 *
 * @param session
 * @param context
 * @param options {object | null}
 * @returns {Promise<any>}
 */
export const prepareServerProps = async (
  session = null,
  context = null,
  options = {}
) => {
  let output = null;

  // todo refactor: options isWithProfileRestrictions should be options.restrictions.isWithProfile
  try {
    options = {
      arrayPropNames: options?.arrayPropNames ?? [],
      arrayRequestCallbacks: options?.arrayRequestCallbacks ?? [],
      getErrors: !!options?.getErrors,
      getParams: !!options?.getParams,
      isDynamicRouteToValidate: !!options?.isDynamicRouteToValidate,
      isWithProfileRestrictions: !!options?.isWithProfileRestrictions,
      props: options?.props ?? {},
      redirect: options?.redirect ?? "/",
      userType: options?.userType ?? "",
      paramsByError: options?.paramsByError ?? null,
    };

    let params = null;
    let isRouteValid = true;

    let { props } = options;

    const resolvedRoute = getResolvedRoute(context, options);

    if (options.isDynamicRouteToValidate) {
      const redirectionTo404Page = {
        redirect: {
          destination: PAGE_404,
        },
      };

      isRouteValid = !!resolvedRoute;

      if (!isRouteValid) params = { ...redirectionTo404Page };
    }

    try {
      // TODO refactor: this function should only be called once we checked access to page is granted after exec of
      //  checkUserTypeAccess and checkProfessionalProfileAccess
      const arePreparePropsParamsTruthy =
        (options?.arrayPropNames?.length &&
          options?.arrayRequestCallbacks?.length) ||
        resolvedRoute;

      if (arePreparePropsParamsTruthy && isRouteValid) {
        props = await prepareProps(resolvedRoute, props, context, options);
      }
    } catch (error) {
      let { getErrors, getParams } = options;

      getErrors = isBoolean(getParams) ? getErrors : true;
      getParams = isBoolean(getParams) ? getParams : true;

      const result = handleErrorPropOnServer(error, options.paramsByError);

      if (getErrors) props.error = result.error;
      if (getParams) params = { ...result.params };
    }

    output = { props, ...params };

    if (options?.userType && isRouteValid) {
      const { userType } = options;
      output = await checkUserTypeAccess(context, {
        session,
        props,
        params,
        userType,
      });
    }

    if (options?.isWithProfileRestrictions && isRouteValid) {
      const { professionalProfile } = options;
      output = await checkProfessionalProfileAccess(context, {
        session,
        data: output,
        professionalProfile,
        resolvedRoute,
      });
    }
  } catch (error) {
    console.error(`prepareServerProps error: ${error}`);
  }

  return normalize(output);
};

/**
 * @function
 * @description vérifie qu'une route dynamique fournie existe véritablement dans les routes l'application contenues dans
 * constantes/pagesRoutes.js > DYNAMIC_PAGE_ROUTES
 * @param context
 * @param options
 * @returns {null}
 */
const getResolvedRoute = (context, options) => {
  let resolvedRoute = null;

  const staticRoutes = {};
  const startQueryParams = "?";
  let arrayRoutes = [];
  let route = context?.resolvedUrl;

  if (context?.resolvedUrl) {
    // only keeps pages routes that are not defined as dynamic
    Object.assign(staticRoutes, { ...PAGES_ROUTES });
    delete staticRoutes?.DYNAMIC_PAGE_ROUTES;
    arrayRoutes = Object.values(staticRoutes);

    if (options?.isDynamicRouteToValidate)
      arrayRoutes = Object.values(DYNAMIC_PAGE_ROUTES);

    const indexStartQuery = String(context.resolvedUrl).indexOf(
      startQueryParams
    );

    if (indexStartQuery > 0) {
      route = String(context.resolvedUrl).substring(0, indexStartQuery);
    }

    resolvedRoute = arrayRoutes?.find(validRoute => validRoute === route);
  }

  return resolvedRoute;
};

/*

*/
/**
 * @function
 * @async
 * @description Prepare props on server for getServerSideProps or getStaticProps.
 *   On any components inside of pages/, it's possible to give props by filling options.props,
 *   by filling arrayPropNames (array containing the name of all the props) and arrayRequestCallbacks (an array of http
 *   requests for each prop name where arrayRequestCallbacks[Nindex] corresponds to arrayPropNames[Nindex]).
 *   A third possibility is to delegate the generation of the props to getPropsFromResolvedRoute according to
 *   the resolved route.
 * @todo traduire en fr
 * @param resolvedRoute
 * @param props
 * @param context
 * @param options
 * @returns {Promise<{}>}
 */
const prepareProps = async (
  resolvedRoute = null,
  props = {},
  context = null,
  options = {}
) => {
  options = {
    ...options,
    arrayPropNames: options?.arrayPropNames ?? [""],
    arrayRequestCallbacks: options?.arrayRequestCallbacks ?? [() => {}],
  };

  const { arrayPropNames, arrayRequestCallbacks } = options;

  const arrayResponses = await Promise.all(
    arrayRequestCallbacks.map(callback => callback())
  );

  for (const propName of arrayPropNames) {
    const index = arrayPropNames?.indexOf(propName);
    props[propName] = arrayResponses[index];
  }

  if (resolvedRoute)
    props = getPropsFromResolvedRoute(resolvedRoute, props, context, options);

  return props;
};

/**
 * @function
 * @description retourne un objet de props à retourner à getServerSideProps ou getStaticProps
 * en fonction de la route dynamique demandée
 * @param resolvedRoute
 * @param props
 * @returns Promise<* | object>
 */
export const getPropsFromResolvedRoute = async (
  resolvedRoute = null,
  props = {}
) => {
  let addedProps;

  switch (resolvedRoute) {
    case DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_INFO_ADD:
      addedProps = { layout: DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_INFO_ADD };
      break;
    case DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_DETAILS_EDITION:
      addedProps = {
        layout: DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_DETAILS_EDITION,
      };
      break;
    case DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_SUPPRESSION:
      addedProps = {
        layout: DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_SUPPRESSION,
      };
      break;
    default:
      addedProps = null;
  }
  props = { ...props, ...addedProps };

  return props;
};

/**
 * @function
 * @description retourne un paramètre à getServerSideProps ou getStaticProps en fonction de l'erreur détectée.
 * @param error
 * @returns {null}
 */
export const getParamsFromError = (error, paramsByError) => {
  let output = null;

  if (error?.message?.length || error?.length) {
    let message = error?.message || error;
    message = message?.replace(".", "").trim();

    let snakeCaseError;

    switch (message) {
      case NOT_FOUND:
      case NOT_FOUND_NO_CAPITAL_F:
        snakeCaseError = NOT_FOUND_SNAKE_CASE;
        break;
    }

    const params = snakeCaseError ? paramsByError[snakeCaseError] : null;

    switch (message) {
      case NOT_FOUND:
      case NOT_FOUND_NO_CAPITAL_F:
        output = params ? { ...params } : { notFound: true };
        break;

      case IS_OFFER:
      case IS_NOT_OFFER:
        output = { notFound: true };
        break;

      case SESSION_EXPIRED:
        output = {};
        break;
      default:
        output = {};
    }
  }

  return output;
};

/**
 * @function
 * @description définit comment gérer une erreur dans getServerSideProps ou getStaticProps
 * @param error
 * @param paramsByError
 * @returns {{error: null, params: null}}
 */
export const handleErrorPropOnServer = (error, paramsByError) => {
  let output = { error: null, params: null };

  if (error?.message?.length || error?.length) {
    const message = error?.message || error;

    if (message?.length) {
      console.error(`Error trapped in handleErrorPropOnServer: ${message}`);
      output = { ...output, error: message };

      const params = getParamsFromError(message, paramsByError);

      if (isObjectTruthy(params)) {
        output = { ...output, params };
      }
    }
  }

  return output;
};

/**
 * @function
 * @description Définit quelles props ou params retourner à getServerSideProps ou getStaticProps en fonction d'une
 * invalidation du userType fourni.
 * @param context
 * @param options
 * @returns {Promise<any>}
 */
export const checkUserTypeAccess = async (context, options = {}) => {
  options = {
    session: options?.session ?? null,
    props: options?.props ?? {},
    params: options?.params ?? null,
    userType: options?.userType ?? "",
  };

  const { props, userType, params } = options;
  let { session } = options;

  let output = { props, ...params };

  session = session ? session : await getSession(context);

  if (
    !(session?.user?.userType === userType || session?.user?.type === userType)
  ) {
    output = {
      props: {
        error: props?.error || "",
      },
      redirect: {
        destination: PAGE_INDEX,
      },
    };
  }

  return normalize(output);
};

/**
 * @function
 * @description Définit quelles données ou redirection à retourner à getServerSideProps ou getStaticProps en
 * fonction d'une invalidation du profil professionnel fourni.
 * @param context
 * @param options
 * @returns {Promise<any>}
 */
export const checkProfessionalProfileAccess = async (
  context = null,
  options = {}
) => {
  options = {
    session: options?.session ?? null,
    data: options?.data ?? {},
    professionalProfile: options?.professionalProfile ?? null,
    resolvedRoute: options?.resolvedRoute ?? null,
  };

  const { data, resolvedRoute } = options;
  let { session } = options;

  let output = data;

  session = session ? session : await getSession(context);
  const profile = session?.user?.profil ?? null;
  const isSubscriber = !!session?.user?.subscribed;

  const arrayRoutesRestrictedToSubscribed = [PAGE_PRO_FLUX, PAGE_PRO_HOME];
  const arrayProfileRestrictions = getArrayProfileRestrictions(resolvedRoute);

  const isRouteRestrictedToSubscribed =
    !!arrayRoutesRestrictedToSubscribed.find(route => route === resolvedRoute);

  const isProfileAccessGranted = !!arrayProfileRestrictions.find(
    profileRestriction => profileRestriction === profile
  );
  const isSubscriptionAccessGranted = isRouteRestrictedToSubscribed
    ? isSubscriber
    : true;

  if (!isProfileAccessGranted || !isSubscriptionAccessGranted) {
    let destination = "/";

    if (profile === PROFESSIONAL_PROFILE_SIEGE)
      destination = PAGE_PRO_MES_CONCESSIONS;
    if (profile === PROFESSIONAL_PROFILE_SITE)
      destination = PAGE_PRO_MA_CONCESSION;

    output = {
      ...output,
      redirect: {
        destination,
      },
    };
  }

  return normalize(output);
};

/**
 * @function
 * @description retourne un array de profils pros correspondant informant quel profil pro à accès à la route fournie.
 * Utilisé par la fonction checkProfessionalProfileAccess.
 * @param resolvedRoute
 * @returns Array<string>
 */
export const getArrayProfileRestrictions = resolvedRoute => {
  let arrayProfileRestrictions = [];

  switch (resolvedRoute) {
    case DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_DETAILS_EDITION:
      arrayProfileRestrictions = [
        PROFESSIONAL_PROFILE_SIEGE,
        PROFESSIONAL_PROFILE_SITE,
      ];
      break;
    case DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_INFO_ADD:
      arrayProfileRestrictions = [PROFESSIONAL_PROFILE_SIEGE];
      break;
    case DYNAMIC_PAGE_ROUTES.PAGE_PRO_CONCESSION_SUPPRESSION:
      arrayProfileRestrictions = [
        PROFESSIONAL_PROFILE_SIEGE,
        PROFESSIONAL_PROFILE_SITE,
      ];
      break;
    case PAGE_PRO_MA_CONCESSION:
      arrayProfileRestrictions = [PROFESSIONAL_PROFILE_SITE];
      break;
    case PAGE_PRO_MES_CONCESSIONS:
      arrayProfileRestrictions = [PROFESSIONAL_PROFILE_SIEGE];
      break;
    default:
      arrayProfileRestrictions = [
        PROFESSIONAL_PROFILE_SIEGE,
        PROFESSIONAL_PROFILE_SITE,
      ];
  }

  return arrayProfileRestrictions;
};

/**
 * @function
 * @async
 * @description retourne les données des détails d'un utilisateur. Probablement inutile
 * (appelé pour la gestion de RIB alors que le composant de gestion de RIB n'a plus lieu d'être).
 * @param session
 * @returns Promise<null | object>
 */
export const prepareMyUserDetailsProp = async session => {
  let output = null;
  if (session?.user?.id && session?.user?.accessToken) {
    output = await restApiApiGetMyUserDetails({
      accessToken: session.user.accessToken,
    });
  }
  return output;
};

/**
 * @function
 * @description retourne une image de véhicule à afficher en fonction d'un slug de type de carrosserie.
 * @param rangeSlugFr
 * @returns {*}
 */
export const findDefaultCover = rangeSlugFr => {
  let coverImage;
  switch (rangeSlugFr) {
    case "capucine":
      coverImage = placeholderCapucine;
      break;

    case "caravane":
      coverImage = placeholderCaravane;
      break;

    case "fourgon":
      coverImage = placeholderFourgon;
      break;

    case "profile":
      coverImage = placeholderProfle;
      break;

    case "van":
      coverImage = placeholderVan;
      break;

    default:
      coverImage = placeholderCapucine;
  }
  return coverImage;
};

/**
 * @function
 * @description transforme un objet en string json et le stocke dans le session storage
 * @param name
 * @param data
 */
export const setInSessionStorage = (name, data) => {
  if (typeof window !== "undefined" && data) {
    sessionStorage.setItem(name, serialize(data));
  }
};

/**
 * @function
 * @description récupère un string json du session storage en fonction du nom de string fourni
 * @param name
 * @returns {string | null}
 */
export const getFromSessionStorage = name => {
  let output = "";
  if (typeof window !== "undefined") {
    output = sessionStorage.getItem(name);
  }
  return output;
};

/**
 * @function
 * @description transforme un objet en string json et le stocke dans le local storage
 * @param name
 * @param data
 */
export const setInLocalStorage = (name, data = "") => {
  if (typeof window !== "undefined") {
    if (data) {
      data = serialize(data);
    }
    localStorage.setItem(name, data);
  }
};

/**
 * @function
 * @description récupère un string json du local storage en fonction du nom de string fourni
 * @param name
 * @returns {string}
 */
export const getFromLocalStorage = name => {
  let output = "";
  if (typeof window !== "undefined") {
    output = localStorage.getItem(name);
  }
  return output;
};

/**
 * @function
 * @description transforme un objet en string json et le stocke dans les cookies,
 * https://www.pierre-giraud.com/javascript-apprendre-coder-cours/cookies/
 * @param cookieName
 * @param data
 * @param path
 * @param milliSecondsBeforeExpiration
 * @param samesite
 */
export const setDataInCookie = (
  cookieName = null,
  data = null,
  path = "/",
  milliSecondsBeforeExpiration = ONE_HOUR * 24,
  samesite = "strict"
) => {
  if (
    typeof cookieName === "string" &&
    typeof data === "object" &&
    typeof document !== "undefined" &&
    typeof milliSecondsBeforeExpiration === "number" &&
    typeof samesite === "string"
  ) {
    let expirationDate = new Date(Date.now() + milliSecondsBeforeExpiration);
    expirationDate = expirationDate.toUTCString();

    document.cookie = `${cookieName}=${serialize(
      data
    )}; path=${path}; expires=${expirationDate}; samesite=${samesite}; secure;`;
  }
};

/**
 * @function
 * @description récupère un string json des cookies en fonction du nom de string fourni
 * @param cookieName
 * @returns {string}
 */
export const getDataFromCookie = (cookieName = null) => {
  let output;
  if (typeof document !== "undefined" && typeof cookieName === "string") {
    output = document.cookie[cookieName];
  }
  return output;
};

/**
 * @function
 * @description retourne un boolean définissant si une date est comprise entre deux autres dates
 * @param date
 * @param min
 * @param max
 * @todo replacer moment par luxon
 * @returns {null|boolean}
 */
export const checkDateIsBetween = (date, min, max) => {
  let output = null;

  if (date) {
    date = moment(date).format("YYYY-MM-DD HH:MM");
    min = moment(min).format("YYYY-MM-DD HH:MM");
    max = moment(max).format("YYYY-MM-DD HH:MM");

    date = Date.parse(date);
    min = Date.parse(min);
    max = Date.parse(max);

    output = date < max && date > min;
  }

  return output;
};

/**
 * @function
 * @description Vérifie qu'une date de mise en circulation d'un véhicule n'est pas incohérent avec
 * le millésime du même véhicule. Utilisé pour la validation de formulaire.
 * @param releaseDate
 * @param millesime
 */
export const validateReleaseDateIsValid = (releaseDate, millesime) => {
  const min = `${millesimes[millesimes.length - 1]?.value}-01-01`;
  const max = Date.now();

  // a season starts one year before a new millesime gets released
  millesime--;

  if (moment(releaseDate).year() < millesime) {
    throw new Error(RELEASE_DATE_ERROR);
  }

  const isDateValid = checkDateIsBetween(releaseDate, min, max);

  if (!isDateValid) {
    throw new Error(RELEASE_DATE_ERROR);
  }
};

/**
 * @function
 * @description Transforme une date donnée en une date de type string au format YYYY-MM-DD et la retourne.
 * @todo replacer moment par luxon
 * @param releaseDate
 * @returns {*}
 */
export const formatDateForRequest = releaseDate =>
  moment(releaseDate).format("YYYY-MM-DD");

/**
 * @function
 * @description retourne un boolean pour informer si une date donnée situe bien dans la période d'activation du jeu
 * concours "1 an de carburant gratuit" de 2022
 * @param currentDate
 * @returns {boolean|null}
 */
export const isGameFreeFuel2022MarchActive = currentDate =>
  checkDateIsBetween(
    currentDate,
    DATES_GAME_FREE_FUEL_2022_MARCH.start,
    DATES_GAME_FREE_FUEL_2022_MARCH.end
  );

/**
 * @function
 * @description Transforme une date donnée en une date de type string au format DD/MM/YYYY et la retourne.
 * Utilisée pour l'UI française.
 * @todo replacer moment par luxon
 * @param date
 * @returns {*}
 */
export const formatDateToFr = date =>
  date ? moment(date).format("DD/MM/YYYY") : null;

/**
 * @function
 * @description Retourne un boolean informant si le champ contenant le moment avant
 * expiration d'un token donné est écoulé.
 * @param accessTokenExpiry
 * @returns {Boolean}
 */
export const getIsTokenToRefresh = (accessTokenExpiry = 0) =>
  getTimeLeftBeforeTokenExpiry(accessTokenExpiry - 15 * ONE_MINUTE) < 0;

/**
 * @function
 * @description Prend en champ contenant le moment d'expiration d'un token et retourne un number représentant
 * le temps restant avant expiration.
 * @param accessTokenExpiry
 * @returns {number}
 */
export const getTimeLeftBeforeTokenExpiry = (accessTokenExpiry = 0) =>
  Math.round(accessTokenExpiry - Date.now());

/**
 * @function
 * @description prend en paramètre un objet contenant les champs height, length et width représentant les dimensions
 * d'un véhicule et retourne une erreur si l'une des dimensions dépasse un maximum. Utilisé lors de création d'une
 * annonce par un particulier
 * @todo Les valeurs limites devraient être stockées dans des constants et la fonction être placée dans un dossier
 * du type formValidation/offersValidation.js
 * @throws VEHICLE_WRONG_HEIGHT | VEHICLE_WRONG_LENGTH | VEHICLE_WRONG_WIDTH
 * @param height
 * @param length
 * @param width
 */
export const validateDimensions = ({ height, length, width }) => {
  if (height > 5.0) throw new Error(VEHICLE_WRONG_HEIGHT);
  if (length > 20.0) throw new Error(VEHICLE_WRONG_LENGTH);
  if (width > 5.0) throw new Error(VEHICLE_WRONG_WIDTH);
};

/**
 * @function
 * @description prend en paramètre un objet contenant le champs inclVatPrice représentant le prix d'une annonce et
 * retourne une erreur si le prix dépasse un maximum. Utilisé lors de création d'une annonce par un particulier.
 * @todo Les valeurs limites devraient être stockées dans des constants et la fonction être placée dans un dossier
 * du type formValidation/offersValidation.js
 * @throws VEHICLE_WRONG_INCL_VAT_PRICE
 * @param inclVatPrice
 */
export const validatePrice = ({ inclVatPrice }) => {
  if (inclVatPrice < 0 || inclVatPrice > 1000000000)
    throw new Error(VEHICLE_WRONG_INCL_VAT_PRICE);
};

/**
 * @function
 * @async
 * @description Récupère toutes les annonces existantes sur l'API métier et retourne un tableau de celles-ci.
 * La fonction a été créée afin de relancer une requête si une page suivante est fournie dans la pagination.
 * Cette fonction est nécessaire pour créer des valeurs de défaut pour le filtre d'annonces.
 * @todo Exposer cet endpoint sans pagination et récupérer toutes les offres, puis mettre le résultat en cache sur le
 * serveur de l'application frontend, puis l'exposer au client.
 * @returns Promise<Array>
 */
export const getArrayAllAvailableOffers = async () => {
  let arrayAllOffers = [];
  let pageToQuery = 1;

  // TODO optimize with Promise.all: find all page numbers to make objectToQueryString from page 1 to lastPage for
  //  all queryString, create array of restApiGetOffers. Promise.all[arrayRestApiGetOffers]
  while (pageToQuery) {
    const queryString = objectToQueryString({ page: pageToQuery });
    const { offers, nextPageId, lastPageId } = await restApiGetOffers(
      queryString
    );

    if (nextPageId <= lastPageId) pageToQuery = nextPageId;
    if (!nextPageId) pageToQuery = null;

    if (offers.length) {
      arrayAllOffers = [...arrayAllOffers, ...offers];
    }
  }

  return arrayAllOffers;
};

/**
 * @function
 * @description Retourne un number représentant le taux de remplissage de détails d'un profil particulier en lisant
 * le contenu du champ moreUserInfoFields de l'objet myUserDetails et moreUserInfoFillingRate fournis en paramètres.
 * Utilisé pour afficher la banner correspondante au taux de remplissage sur la page espace-client/mon-compte
 * @param myUserDetails
 * @param moreUserInfoFillingRate
 * @returns {number}
 */
export const calculateMoreUserInfoFillingRate = (
  myUserDetails = {},
  moreUserInfoFillingRate = 0
) => {
  const moreUserInfoFields = { ...myUserDetails.userMore };
  const moreUserInfoFieldsLength =
    Object.entries(moreUserInfoFields)?.length ?? 0;
  let moreUserInfoFiledFields = 0;

  delete moreUserInfoFields?.["@id"];
  delete moreUserInfoFields?.["@type"];

  Object.entries(moreUserInfoFields)?.map(([key, value]) => {
    if (key === "userMorePeople" && value?.length) moreUserInfoFiledFields++;
    if (key !== "userMorePeople" && value !== null) moreUserInfoFiledFields++;
  });

  if (moreUserInfoFieldsLength > 0) {
    moreUserInfoFillingRate =
      (moreUserInfoFiledFields / Object.entries(moreUserInfoFields)?.length) *
      100;
    moreUserInfoFillingRate = Math.floor(moreUserInfoFillingRate);
  }
  return moreUserInfoFillingRate;
};

/**
 * @function
 * @description Retourne un number représentant le taux de remplissage d'un profil particulier en comparant
 * les valeurs de globalFillingRate et moreUserInfoFillingRate donnés en paramètres.
 * Utilisé pour afficher la banner correspondante au taux de remplissage sur la page espace-client/mon-compte
 * @param globalFillingRate
 * @param moreUserInfoFillingRate
 * @returns {number}
 */
export const calculateProfileFillingRate = (
  globalFillingRate,
  moreUserInfoFillingRate
) => {
  if (moreUserInfoFillingRate === 100) globalFillingRate = 100;
  return globalFillingRate;
};

/**
 * @function
 * @description Trouve les espaces dans un string donné en paramètre et les remplace par '' avant de le retourner.
 * @param string
 * @returns {string}
 */
export const removeSpaceFromString = string =>
  String(string).replace(/\s+/g, "");

/**
 * @function
 * @description transforme un nombre donné sous type de string en type number une fois que les espaces
 * ont été enlevés et le retourne.
 * @param string
 * @returns {number}
 */
export const turnStringIntoNumber = string => {
  const nbrValue = Number(removeSpaceFromString(string));
  return isNaN(nbrValue) ? 0 : nbrValue;
};

/**
 * @function
 * @description Aide à trier les objets checkboxes de marques distribuées par un concessionnaire de celles simplement
 * existantes. Retourne un objet avec deux champs contenant arrays :
 * arrayBrandsPropsRecipient et arrayBrandsPropsSource. Utilisé dans FormDetailsConcession.
 * @param arrayBrandsPropsRecipient
 * @param arrayBrandsPropsSource
 * @param pickedBrandProps
 * @param indexPickedBrandProps
 * @todo cette fonction peut-elle être simplifiée en prenant moins de paramètres ?
 * @returns {object}
 */
export const updateArrayBrandsProps = ({
  arrayBrandsPropsRecipient = [],
  arrayBrandsPropsSource = [],
  pickedBrandProps = [],
  indexPickedBrandProps = null,
}) => {
  // const start = performance.now()
  const isBrandToAddInRecipient = arrayBrandsPropsRecipient.every(
    brandProps => brandProps.value !== pickedBrandProps.value
  );
  // const duration = performance.now() - start
  // console.log('duration : ', duration)

  if (isBrandToAddInRecipient) {
    arrayBrandsPropsRecipient.push(pickedBrandProps);
  }

  if (isNumber(indexPickedBrandProps)) {
    arrayBrandsPropsSource.splice(indexPickedBrandProps, 1);
  }

  arrayBrandsPropsRecipient = orderBy(
    arrayBrandsPropsRecipient,
    ["label"],
    ["asc"]
  );

  return { arrayBrandsPropsRecipient, arrayBrandsPropsSource };
};

/**
 * @function
 * @description Trouve et retourne une concession au sein des concessions contenues dans les données
 * d'un compte professionnel en donnant my (motte) et id (aiguille) en paramètres.
 * @param my
 * @param id
 * @returns {object | null}
 */
export const findDealershipInSeatById = (my, id) => {
  let arrayDealerships = [];

  if (my.profil === PROFESSIONAL_PROFILE_SITE) {
    arrayDealerships = [my.site] ?? [];
  }

  if (my.profil === PROFESSIONAL_PROFILE_SIEGE) {
    arrayDealerships = my.seat.concessions ?? [];
  }

  return arrayDealerships?.find(dealership => String(dealership.id) === id);
};

/**
 * @function
 * @description Retourne un array contenant des objets pour les options de selections de concessions. Auparavant cette
 * fonction ne retournait que les concessions dont l'abonnement est actif.
 * @param concessions
 * @returns Array<object>
 */
export const getArrayActiveConcessions = concessions => {
  const options = [];

  concessions?.forEach(dealership =>
    options.push({ label: dealership.denomination, value: dealership.id })
  );

  return options;
};

/**
 * @function
 * @description Retourne un array d'éléments JSX checkbox utilisés dans les critères de filtres, sur la base du champ
 * values donné en paramètre appartenant au critère et contenant un array de valeurs. On appelle cette fonction
 * dès qu'un critère de filtre dont le type est 'checkbox' et qu'on veut afficher des checkboxes.
 * @param layer1
 * @returns Array<JSX.Element>
 */
export const getArrayCheckboxesLayers = layer1 => {
  const arrayCheckboxesLayers1 = [];
  const layer2 = {};
  let count = 0;

  for (const keyLayer1 in layer1) {
    if (layer1.hasOwnProperty(keyLayer1)) {
      let element = getCheckboxLayer1(layer1, keyLayer1, count);

      if (layer1[keyLayer1]?.finitions) {
        const arrayInputsLayer2 = layer1[keyLayer1].finitions;

        layer2[arrayInputsLayer2] =
          getElementCheckboxesLayer2(arrayInputsLayer2);
        element = <>{layer2[arrayInputsLayer2]}</>;
      }

      if (element) arrayCheckboxesLayers1.push(element);
    }
    count++;
  }

  return arrayCheckboxesLayers1;
};

/**
 * @function
 * @description Retourne un élément JSX checkbox, sur la base d'un objet de checkbox fourni en paramètre.
 * @param layer1
 * @param keyLayer1
 * @param index
 * @param options
 * @returns {JSX.Element}
 */
const getCheckboxLayer1 = (
  layer1,
  keyLayer1,
  index,
  options = { register: null }
) => {
  const type = layer1[keyLayer1]?.finitions ? "icoHasNiv2" : "checkbox";

  return (
    <Critere
      type={type}
      label={layer1[keyLayer1].label}
      key={index}
      register={options?.register}
    />
  );
};

/**
 * @function
 * @description Retourne un array d'éléments JSX checkbox utilisés dans les critères de filtres, sur la base du champ
 * values donné en paramètre. Cette fonction a une utilité similaire à la fonction getCheckboxLayer1 mais on l'appelle
 * lorsqu'un objet checkbox de niveau 1 contient le mot clé finitions.
 * @todo Le jour où l'on se servira de cette fonction, elle ne devrait pas être appelée juste lorsqu'on trouve le champ
 * finition dans une checkbox et pas juste sur le pour le niveau deux d'un objet checkbox.
 * @param layer2
 * @returns {JSX.Element}
 */
const getElementCheckboxesLayer2 = layer2 => {
  const arrayCheckboxesLayers2 = [];

  layer2?.forEach((keyLayer2, index) => {
    if (layer2.hasOwnProperty(keyLayer2)) {
      const checkboxLayer2 = getCheckboxLayer2(layer2, keyLayer2, index);
      arrayCheckboxesLayers2.push(checkboxLayer2);
    }
  });

  return (
    <div className={css["u-sousniveau"]}>
      {arrayCheckboxesLayers2?.map(checkboxLayer2 => checkboxLayer2)}
    </div>
  );
};

/**
 * @function
 * @description Formate et retourne un array d'objets checkboxes sur la base d'un objet checkbox, keyLayer2 le nom de
 * clé du critère et l'index de l'objet checkbox contenu dans son array  donnés en paramètres.
 * Cette fonction fait parti des éléments permettetant de créer des éléments JSX checkboxes utilisés dans les critères
 * de filtres.
 * @param layer2
 * @param keyLayer2
 * @param index
 * @returns {JSX.Element}
 */
const getCheckboxLayer2 = (layer2, keyLayer2, index) => (
  <Critere type='checkbox' label={layer2[keyLayer2].label} key={index} />
);

/**
 * @function
 * @description Cette fonction permet de réinitialiser l'axe y du scroll d'un élément JSX et une valeur
 * d'axe y, sur la base d'element un identifiant d'élément JSX et yValue la valeur de l'axe y  donnés en paramètres.
 * @param element
 * @param yValue
 * @returns {number}
 */
export const setElementScrollToTop = (element, yValue = 0) =>
  (document.querySelector(element).scrollTop = yValue);

/**
 * @function
 * @todo à décrire
 * @param availableBrands
 * @param brandsSearchValue
 * @returns {*}
 */
export const getFilterArrayBrandsMatching = (
  availableBrands,
  brandsSearchValue = ""
) =>
  availableBrands?.filter(brand =>
    brand.name.toLowerCase().includes(brandsSearchValue.toLowerCase())
  );

/**
 * @function
 * @todo à décrire
 * @param objectProps
 * @returns {{}}
 */
export const getInputProps = objectProps => {
  const MAX = objectProps?.max ?? 999999;
  const MIN = objectProps?.min ?? 0;

  let inputProps = {};

  if (
    objectProps.name === FILTER_DEALERSHIPS.localisation.values[0].name ||
    objectProps.name === FILTER_DEALERSHIPS.localisation.values[1].name
  ) {
    inputProps = {
      inputMode: "numeric",
      pattern: "[0-9]*",
      min: MIN,
      max: MAX,
    };
  }

  return inputProps;
};

/**
 * @function
 * @description Cette fonction retourne un boolean permettant de savoir si le contexte où est exécuté cette fonction
 * contient le mot clé window. Cela permet d'indiquer si le contexte d'exécution de la fonction correspond à un
 * navigateur web ou à un serveur. Utilisé pour vérifier que l'on est bien en contexte navigateur avant d'exécuter des
 * fonctions seulement utilisables en contexte navigateur.
 * @param window
 * @returns {Boolean}
 */
export const getIsWindowDefined = window => typeof window !== "undefined";

/**
 * @function
 * @description Répare les dimensions des images de concessions lorsqu'elles sont trop grandes ou nulles.
 * @param imageData
 * @param imageType
 * @returns {{width: *, height: *}}
 */
export const getDealershipImagesDimensions = (imageData, imageType) => {
  let dimensions = {
    height: imageData?.height,
    width: imageData?.width,
  };

  let rateResize;

  const maxWidth =
    imageType === DEALERSHIP_LOGO_IMAGE_TYPE
      ? MAX_WIDTH_LOGO_DEALERHIP
      : MAX_WIDTH_COVER_DEALERHIP;

  if (dimensions?.width > maxWidth) {
    rateResize = 1 - (dimensions.width - maxWidth) / dimensions.width;
  }

  if (rateResize) {
    dimensions.width = dimensions?.width * rateResize;
    const mathRound = getMathRoundFunction(dimensions?.width, maxWidth);

    dimensions.width = mathRound(imageData?.width * rateResize);
    dimensions.height = mathRound(dimensions?.height * rateResize);
  }

  if (!dimensions?.width || !dimensions?.height) {
    if (imageType === DEALERSHIP_LOGO_IMAGE_TYPE) {
      dimensions = DEALERSHIP_LOGO_IMAGE_DEFAULT_DIMENSIONS;
    }

    if (imageType === DEALERSHIP_COVER_IMAGE_TYPE) {
      dimensions = DEALERSHIP_COVER_IMAGE_DEFAULT_DIMENSIONS;
    }
  }

  return dimensions;
};

/**
 * @function
 * @description retourne une callback Math.ceil ou Math.floor sur la base de width une largeur d'image et maxWidth
 * une valeur maximum de largeur. Corrige getDealershipImagesDimensions pour lui éviter de retourner une largeur à
 * 501px alors que l'on souhaite avoir cette valeur à 500px.
 * @param width
 * @param maxWidth
 * @returns {function(*): number}
 */
const getMathRoundFunction = (width, maxWidth) => {
  let mathFunction = calculation => Math.ceil(calculation);

  if (width > maxWidth) {
    mathFunction = calculation => Math.floor(calculation);
  }

  return mathFunction;
};

/**
 * @function
 * @description Retourne un objet pour une image de logo de concession redimensionnée si un objet valide est fourni
 * ou bien une fallback image.
 * @param data
 * @returns {* | object}
 */
export const getLogoImage = data => {
  const dimensions = getDealershipImagesDimensions(
    data,
    DEALERSHIP_LOGO_IMAGE_TYPE
  );

  return data
    ? {
        ...data,
        src: data?.fileUrl ?? data?.src,
        alt: data?.name ?? data?.src,
        ...dimensions,
      }
    : missingLogoImageData;
};

/**
 * @function
 * @description Retourne un objet pour une image de couverture de concession redimensionnée si un objet valide est
 * fourni ou bien un objet pour une fallback image.
 * @param data
 * @returns {* | object}
 */
export const getCoverImage = data => {
  const dimensions = getDealershipImagesDimensions(
    data,
    DEALERSHIP_COVER_IMAGE_TYPE
  );

  return data
    ? {
        ...data,
        src: data?.fileUrl ?? data?.src,
        alt: data?.name ?? data?.src,
        ...dimensions,
      }
    : missingCoverImageData;
};

/**
 * @function
 * @description retourne un nombre de type string en type number. Si le type number vaut NaN, alors 0 est retourné.
 * @param strValue {string}
 * @param round  {object}
 * @returns {number}
 */
export const getIntegerFromString = (
  strValue,
  round = { key: null, limit: "min" }
) => {
  const { key } = round;
  let { limit } = round;

  limit = limit ?? "min";

  const roundFunction = ROUND_LIMITS_FUNCTIONS[limit];

  const keys = {
    length: value => roundFunction(value),
    height: value => roundFunction(value),
    width: value => roundFunction(value),
    inclVatPrice: value => roundFunction(value, limit),
    registeredSeats: value => roundFunction(value),
    sleepingSeats: value => roundFunction(value),
    mileage: value => roundFunction(value, limit),
    // 'millesime',
  };
  const keyFunction = keys[key];

  const indexComa = strValue.indexOf(",");
  if (indexComa > -1) strValue = strValue.substring(0, indexComa);

  let value = turnStringIntoNumber(strValue);

  if (keyFunction) {
    value = keyFunction(value);
  }

  return value;
};

/**
 * @function
 * @description Prend un paramètre value de type number et le retourne arrondi à l'inferieur pour une limite 'min' ou au supérieur pour une limite 'max'
 * @param value {number}
 * @param limitName {string}
 * @param criteriaName {string}
 * @param step {null | number}
 * @returns {number}
 */
export const round = (
  value,
  limitName = "min",
  criteriaName = "",
  step = null
) => {
  const roundFunction = ROUND_LIMITS_FUNCTIONS[limitName];

  let callback = (value, divider) => roundFunction(value / divider) * divider;

  let divider = 1000;

  if (value > 100000) divider = 10000;

  if (criteriaName === ADS_FILTERS.inclVatPrice.name) {
    step = step ?? ADS_FILTERS.inclVatPrice.step;
    if (value < step) callback = () => 0;
    if (value > step && value < 10000) callback = () => step;
    if (value > 10000) divider = 10000;
  }

  value = callback(value, divider);

  return isNaN(value) ? 0 : value;
};

/**
 * @function
 * @description creates formatted file to transform its initial name to lower case before uploading it
 * @param file
 * @return {File}
 */
export const formatFile = file =>
  new File([file], file.name.toLowerCase(), { type: file.type });

/**
 * @function
 * @description Return a boolean. True if the component name is included in arrayReferenceComponentsNames, false otherwise
 * @param arrayReferenceComponentsNames
 * @param component
 * @return {arrayComponentsIncluded, arrayComponentsExcluded}
 */
export const getIsComponentNameIncludedInArrayComponentNames = (
  arrayReferenceComponentsNames,
  component
) =>
  component.type !== undefined
    ? arrayReferenceComponentsNames.includes(component.type.name)
    : false;

export const filterArrayComponentsByName = (
  arrayReferenceComponentsNames,
  arrayComponents
) => {
  const arrayComponentsIncluded = [];
  const arrayComponentsExcluded = [];

  arrayComponents.forEach(component => {
    getIsComponentNameIncludedInArrayComponentNames(
      arrayReferenceComponentsNames,
      component
    )
      ? arrayComponentsIncluded.push(component)
      : arrayComponentsExcluded.push(component);
  });

  return { arrayComponentsIncluded, arrayComponentsExcluded };
};
