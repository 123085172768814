import { ADS_FILTERS } from "@/Constants/filters";
import { isArray } from "lodash";

export const updateAdsFilter = ({
  adsFilter,
  filterValues,
  arrayFilteredRanges,
  arrayFilteredBrands,
  arrayFilteredModels,
}) => {
  const arrayKeys = [
    "size",
    "inclVatPrice",
    "registeredSeats",
    "sleepingSeats",
    "mileage",
    "millesime",
  ];

  for (const key of arrayKeys) {
    const isSize = key === "size";

    if (isSize) {
      adsFilter.size.values = adsFilter.size.values.map(subCriteria => ({
        ...subCriteria,
        min: filterValues[subCriteria.name][0],
        max: filterValues[subCriteria.name][1],
        values: [],
      }));
    }

    if (!isSize) {
      adsFilter[key].min = Number(filterValues[key][0]);
      adsFilter[key].max = Number(filterValues[key][1]);
      adsFilter[key].values = [];
    }
  }

  adsFilter["model.rangeVehicle"].values = getArrayCriteriaRangeValues({
    arrayFilterRangeValues: adsFilter["model.rangeVehicle"]?.values,
    arrayRanges: arrayFilteredRanges,
  });
  adsFilter["model.brand"].values = getArrayCriteriaBrandValues({
    arrayBrands: arrayFilteredBrands,
  });
  adsFilter.model.values = getArrayCriteriaModelValues(arrayFilteredModels);

  adsFilter.type.values = adsFilter.type.values.map(refValue => ({
    ...refValue,
    checked: false,
    disabled: !filterValues.type.find(value => value === refValue?.name),
  }));
  adsFilter["user.userType"].values = adsFilter["user.userType"].values.map(
    refValue => ({
      ...refValue,
      disabled: !filterValues["user.userType"]?.find(
        value => value === refValue?.name
      ),
    })
  );

  // Not possible at the moment
  // arrayKeys = ['bedType', 'gearbox', 'grossWeight', 'heating']
  adsFilter.bedType.values = adsFilter.bedType?.values.map(refValue => ({
    ...refValue,
    disabled: !filterValues.bedType.find(value => value === refValue?.key),
  }));
  adsFilter.gearbox.values = adsFilter.gearbox.values.map(refValue => ({
    ...refValue,
    disabled: !filterValues.gearbox?.find(value => value === refValue?.value),
  }));
  adsFilter.grossWeight.values = adsFilter.grossWeight.values.map(refValue => ({
    ...refValue,
    disabled: !filterValues.grossWeight.find(value => value === refValue.label),
  }));
  adsFilter.heating.values = adsFilter.heating?.values?.map(refValue => ({
    ...refValue,
    disabled: !filterValues.heating?.find(value => value === refValue?.value),
  }));
  adsFilter.localisation.values = [
    {
      ...ADS_FILTERS.localisation.values[0],
      // value: filterValues.postcode[0],
    },
    {
      ...ADS_FILTERS.localisation.values[1],
      // value: filterValues.radius[0],
    },
  ];

  // adsFilter.dealer.values = ...ADS_FILTERS.dealer.values.map(value => ({...value, checked: false})),
  adsFilter.dealer.values = [...ADS_FILTERS.dealer.values];

  // TO KEEP but disable since we do not create values for this criteria at initialization
  // adsFilter.dealer.values = adsFilter.dealer?.values.map(refValue => ({
  //   ...refValue,
  //   checked: false,
  //   disabled: true,
  // }))

  return { ...adsFilter };
};

/**
 * @function
 * @description Retourne un array d'objets checkboxes de profils de véhicules dont le champ disabled est réinitialisé
 * lorsqu'un objet checkbox de profil dans l'array arrayFilterRangeValues possède un name ayant la même valeur que le
 * champ slug d'un objet profil contenu dans l'array filteredRanges
 * @param arrayFilterRangeValues {array}
 * @param arrayRanges {array}
 * @returns {array}
 */
const getArrayCriteriaRangeValues = ({
  arrayFilterRangeValues = [],
  arrayRanges = [],
}) => {
  let output = [];

  if (isArray(arrayFilterRangeValues)) {
    output = arrayFilterRangeValues.map(filterRangeValue => {
      filterRangeValue.disabled = false;

      const availableFilterRange = arrayRanges?.find((filteredRange, index) => {
        return filterRangeValue?.name === filteredRange?.slugFr;
      });

      if (!availableFilterRange) {
        filterRangeValue.disabled = true;
      }

      return filterRangeValue;
    });
  }

  return output;
};

/**
 * @function
 * @description Formate l'objet marque (brand) donné en paramètre en un objet values contenu dans le champ values de
 * type array du critère brands d'un filtre d'annonces et le retourne.
 * @todo Redondant d'avec getInitialOffersFilterFromOffers
 * @param brands
 * @returns {object}
 */
export const getArrayCriteriaBrandValues = ({ arrayBrands = [] }) =>
  arrayBrands?.map(brand => ({
    label: brand.name,
    name: brand.name,
    id: brand.id,
    key: brand.id,
    checked: false,
    disabled: false,
  }));

/**
 * @function
 * @description Retourne un array de brands parmi filteredBrands marques utilisées dans les offres en premier paramètre,
 * dont les id correspondent aux id des objets checkboxes de l'array checkedValuesBrand en second paramètre.
 * @param filteredBrands
 * @param checkedValuesBrand
 * @returns {Array}
 */
export const getFilterBrandActiveValues = (
  filteredBrands,
  checkedValuesBrand
) => {
  const arrayActiveBrands = [];

  checkedValuesBrand?.forEach(checkedValueBrand => {
    const activeBrand = filteredBrands?.find(
      filteredBrand => checkedValueBrand.id === filteredBrand.id
    );

    if (activeBrand) arrayActiveBrands.push(activeBrand);
  });

  return arrayActiveBrands;
};

/**
 * @function
 * @description Filtre un array d'objets checkboxes de marques contenues dans un filtre -> brands -> values et retourne
 * un tableau ne contenant que des objets objets checkboxes de marques où le champ checked vaut true.
 * @todo pourrait être plus généraliste et renommé getArrayCriteriaCheckedValues
 * @param valuesBrands
 * @returns {Array | null}
 */
export const getFilterBrandCheckedValues = valuesBrands =>
  valuesBrands?.filter(brandValue => brandValue.checked);

/**
 * @function
 * @description Retourne un array de models de véhicules contenus dans brands array de marques fourni en paramètres.
 * @param brands
 * @returns {Array}
 */
export const getModelsInActiveBrands = brands => {
  const arrayModelsInActiveBrands = [];

  brands?.forEach(
    brand => brand.models && arrayModelsInActiveBrands.push(...brand.models)
  );
  return arrayModelsInActiveBrands;
};

/**
 * @function
 * @description Retourne un objet checkbox model sur la base d'un model de véhicule fourni. Utilisé pour générer les
 * objets checkboxes models contenu dans un filtre, champ model, champs values de type array. Cette fonction a pour but
 * de réinitialiser les valeurs du critère model dans un filtre lorsqu'on remet à 0 ce dernier.
 * @param arrayModels {array}
 * @returns {object}
 */
export const getArrayCriteriaModelValues = (arrayModels = []) =>
  arrayModels.map(model => ({
    id: model?.id,
    label: model?.name,
    name: model?.slug,
    checked: !!model?.checked,
    finitions: model?.finitions || [],
    key: model.id,
    disabled: false,
  }));

/**
 * @function
 * @description retourne arrayFilterValuesModels un array de valeurs du critère model d'un filtre généré sur la base
 * d'ads un objet de state globale redux contenant le filtre d'annonces, filteredBrands un tableau
 * de marques séléctionné dans le filtre et filteredModels un tableau de models de véhicules actuellement
 * utilisés dans les annonces existantes. Le but de cette fonction est de ne retourner que les models de
 * véhicules appartenant aux marques sélectionnées.
 * @param ads
 * @param filteredBrands
 * @param filteredModels
 * @param options
 * @returns {*}
 */
export const getArrayCriteriaModelAvailableValues = ({
  arrayFilteredBrands,
  arrayFilteredModels,
  arrayCriteriaBrandValues = [],
  arrayCriteriaModelValues = [],
}) => {
  // const arrayFilterValuesBrand = ads?.filters['model.brand']?.values
  // const arrayFilterValuesModels = ads?.filters?.['model']?.values

  const arrayCheckedValuesBrand = getFilterBrandCheckedValues(
    arrayCriteriaBrandValues
  );
  const arrayActiveBrands = getFilterBrandActiveValues(
    arrayFilteredBrands,
    arrayCheckedValuesBrand
  );
  const arrayModelsInActiveBrands = getModelsInActiveBrands(arrayActiveBrands);
  const arrayAvailableModels = getAvailableModels(
    arrayFilteredModels,
    arrayModelsInActiveBrands
  );

  if (!arrayCriteriaModelValues?.length) {
    arrayCriteriaModelValues =
      getArrayCriteriaModelValues(arrayAvailableModels);
  }

  arrayCriteriaModelValues = changeArrayFilterModelValues(
    arrayCriteriaModelValues,
    arrayAvailableModels
  );

  return arrayCriteriaModelValues;
};

/**
 * @function
 * @description Réinitialise le champ disabled d'un des objets d'arrayFilterValuesModels fourni en paramètres,
 * étant un array d'objets checkboxes de models de véhicules lorsque le champ id d'un de ses objet correspond au champ
 * id d'un objet model contenu dans le paramètre arrayAvailableModels de type array. Cette fonction a pour but de
 * réinitialiser les valeurs du critère model.rangeVehicle dans un filtre lorsqu'on remet à 0 ce dernier.
 * @param arrayFilterValuesModels
 * @param arrayAvailableModels
 * @returns {object}
 */
export const changeArrayFilterModelValues = (
  arrayFilterValuesModels,
  arrayAvailableModels
) =>
  arrayFilterValuesModels?.map(modelValue => {
    let output = modelValue;
    output.disabled = false;

    const availableModel = arrayAvailableModels?.find(
      availableModel => availableModel?.id === modelValue?.id
    );

    if (!availableModel) {
      output = {
        ...modelValue,
        disabled: true,
        checked: false,
      };
    }

    return output;
  });

/**
 * @function
 * @description Retourne un array de models de véhicules au sein de filteredModels un array de models de véhicules
 * utilisés dans les annonces lorsque l'id d'un model correspond à celui d'un model contenu dans
 * arrayModelsInActiveBrands array de models de véhicules dans des marques sélectionnées.
 * @param filteredModels
 * @param arrayModelsInActiveBrands
 * @returns {Array}
 */
export const getAvailableModels = (
  filteredModels,
  arrayModelsInActiveBrands
) => {
  let output = filteredModels;

  if (arrayModelsInActiveBrands?.length) {
    const arrayAvailableModels = [];

    filteredModels?.forEach(filteredModel => {
      const availableModel = arrayModelsInActiveBrands?.find(
        modelInActiveBrand => modelInActiveBrand.id === filteredModel.id
      );

      if (availableModel) {
        arrayAvailableModels.push(availableModel);
      }
    });

    if (arrayAvailableModels?.length) {
      output = arrayAvailableModels;
    }
  }

  return output;
};
