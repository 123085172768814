/**
 * @namespace Constants
 * @module Constantes Errors
 * @author Sébastien NOBOUR
 * @description Fichier de constantes d'erreurs globales
 */

/**
 * @constant
 * @type {string}
 */
export const EMPTY_HTTP_OPTIONS = "EMPTY HTTP OPTIONS";

/**
 * @constant
 * @type {string}
 */
export const EMPTY_DATA = "EMPTY DATA";

/**
 * @constant
 * @type {string}
 */
export const MISSING_DATA = "MISSING DATA";

/**
 * @constant
 * @type {string}
 */
export const MISSING_ID = "MISSING ID";

/**
 * @constant
 * @type {string}
 */
export const MISSING_ID_USER_TO = "MISSING ID USER TO";

/**
 * @constant
 * @type {string}
 */
export const MISSING_OFFER_ID = "MISSING_OFFER_ID";

/**
 * @constant
 * @type {string}
 */
export const NO_HYDRA_MEMBER = "NO HYDRA MEMBER";

/**
 * @constant
 * @type {string}
 */
export const NO_CONTEXT = "NO CONTEXT";

/**
 * @constant
 * @type {string}
 */
export const NO_RESPONSE_FROM_REQUEST = "NO RESPONSE FROM REQUEST";

/**
 * @constant
 * @type {string}
 */
export const NO_ACCESS_TOKEN_PROVIDED = "NO_ACCESS_TOKEN_PROVIDED";

/**
 * @constant
 * @type {string}
 */
export const NO_RECAPTCHA_TOKEN = "NO RECAPTCHA TOKEN PROVIDED";

/**
 * @constant
 * @type {string}
 */
export const NO_REFRESH_TOKEN_PROVIDED = "NO_REFRESH_TOKEN_PROVIDED";

/**
 * @constant
 * @type {string}
 */
export const MISSING_CREDENTIALS = "MISSING CREDENTIALS";

/**
 * @constant
 * @type {string}
 */
export const NO_CREDENTIALS_RETURN = "NO_CREDENTIALS_RECEIVED";

/**
 * @constant
 * @type {string}
 */
export const SESSION_EXPIRED = "SESSION HAS EXPIRED";

/**
 * @constant
 * @type {string}
 */
export const WRONG_CREDENTIALS = "WRONG CREDENTIALS";

/**
 * @constant
 * @type {string}
 */
export const NOT_FOUND = "Not Found";

/**
 * @constant
 * @type {string}
 */
export const NOT_FOUND_SNAKE_CASE = "NOT_FOUND";

/**
 * @constant
 * @type {string}
 */
export const NOT_FOUND_NO_CAPITAL_F = "Not found";

/**
 * @constant
 * @type {string}
 */
export const FAILED_TO_FETCH = "Failed to fetch";

/**
 * @constant
 * @type {string}
 */
export const IS_OFFER = "IS_ALREADY_OFFER";

/**
 * @constant
 * @type {string}
 */
export const IS_NOT_OFFER = "IS_NOT_OFFER";

/**
 * @constant
 * @type {string}
 */
export const IS_NOT_CONNECTED = "IS NOT CONNECTED";

/**
 * @constant
 * @type {string}
 */
export const UNKNOWN_DEALER = "UNKNOWN DEALER";

/**
 * @constant
 * @type {string}
 */
export const INVALID_MESSAGE = "Invalid message";

/**
 * @constant
 * @type {string}
 */
export const RECIPIENT_NOT_FOUND = "Recipient not found or disabled";

/**
 * @constant
 * @type {string}
 */
export const OFFER_NOT_FOUND = "Offer not found";

/**
 * @constant
 * @type {string}
 */
export const OFFER_NOT_TO_USER = "The offer does not belong to the recipient";

/**
 * @constant
 * @type {string}
 */
export const SENDER_IS_RECIPIENT = "The sender cannot be the same as recipient";

/**
 * @constant
 * @type {string}
 */
export const ON_GOING_BANK_DATA_UPDATE = "ON GOING BANK DATA UPDATE";

/**
 * @constant
 * @type {string}
 */
export const NO_USER_TYPE = "NO USER TYPE PROVIDED";

/**
 * @constant
 * @type {string}
 */
export const WRONG_PASSWORD = "The current password is incorrect";

/**
 * @constant
 * @type {string}
 */
export const EXPIRED_JWT_TOKEN = "Expired JWT Token";

/**
 * @constant
 * @type {string}
 */
export const CODE_403 = "403 UNGRANTED";

/**
 * @constant
 * @type {string}
 */
export const NO_USERTO = "NO USERTO";

/**
 * @constant
 * @type {string}
 */
export const RELEASE_DATE_ERROR = "RELEASE_DATE_ERROR";

/**
 * @constant
 * @type {string}
 */
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

/**
 * @constant
 * @type {string}
 */
export const LOGIN_ERROR = "LOGIN_ERROR";

/**
 * @constant
 * @type {string}
 */
export const AUTHORIZE_ERROR = "AUTHORIZE_ERROR";

/**
 * @constant
 * @type {string}
 */
export const NO_ACCESSTOKEN_RECEIVED = "NO_TOKEN_RECEIVED";

/**
 * @constant
 * @type {string}
 */
export const NO_TOKENS_RECEIVED = "NO_TOKENS_RECEIVED";

/**
 * @constant
 * @type {string}
 */
export const NO_REFRESH_TOKEN_RECEIVED = "NO_REFRESH_TOKEN_RECEIVED";

/**
 * @constant
 * @type {string}
 */
export const TOKEN_DECODE_ERROR = "TOKEN_DECODE_ERROR";

/**
 * @constant
 * @type {string}
 */
export const INVALID_DATE_ERROR = "Invalid date";

/**
 * @constant
 * @type {string}
 */
export const GENERIC_ERROR = "GENERIC_ERROR";

/**
 * @constant
 * @type {string}
 */
export const OFFER_ALREADY_REPORTED_BY_USER =
  "Offer already reported by this user";

/**
 * @constant
 * @type {string}
 */
export const INCOMPLETE_RECAPTCHA = "INCOMPLETE_RECAPTCHA";

/**
 * @constant
 * @type {string}
 */
export const ALREADY_SUBSCRIBED_NEWSLETTER = "ALREADY_SUBSCRIBED_NEWSLETTER";

/**
 * @constant
 * @type {string}
 */
export const OFFER_ID_MISSING_ADD_IMAGE = "OFFER_ID_MISSING_ADD_IMAGE";

/**
 * @constant
 * @type {string}
 */
export const IS_UNDER_LEGAL_AGE_ACCOUNT_CREATE =
  "IS_UNDER_LEGAL_AGE_ACCOUNT_CREATE";

/**
 * @constant
 * @type {string}
 */
export const MISSING_TOKEN_PASSWORD_REINIT = "MISSING_TOKEN_PASSWORD_REINIT";

/**
 * @constant
 * @type {string}
 */
export const WRONG_IMAGE_FORMAT = "WRONG_IMAGE_FORMAT";

/**
 * @constant
 * @type {string}
 */
export const MAX_50_IMAGES_FOR_OFFER = "MAX_50_IMAGES_FOR_OFFER";

/**
 * @constant
 * @type {string}
 */
export const VEHICLE_WRONG_HEIGHT = "VEHICLE_WRONG_HEIGHT";

/**
 * @constant
 * @type {string}
 */
export const VEHICLE_WRONG_LENGTH = "VEHICLE_WRONG_LENGTH";

/**
 * @constant
 * @type {string}
 */
export const VEHICLE_WRONG_WIDTH = "VEHICLE_WRONG_WIDTH";

/**
 * @constant
 * @type {string}
 */
export const VEHICLE_WRONG_INCL_VAT_PRICE = "VEHICLE_WRONG_INCL_VAT_PRICE";

/**
 * @constant
 * @type {string}
 */
export const NO_URN = "NO_URN";

/**
 * @constant
 * @type {string}
 */
export const DEALERSHIP_SUPPRESSION_ALREADY_REQUESTED =
  "DEALERSHIP_SUPPRESSION_ALREADY_REQUESTED";

/**
 * @constant
 * @type {string}
 */
export const ERROR_HTTP_REQUEST_ABORT = "AbortError";

/**
 * @constant
 * @type {string}
 */
export const ERROR_SERVER = "ERROR SERVER";

/**
 * @constant
 * @type {string}
 */
export const ERROR_DISTANT_SERVER = "ERROR_DISTANT_SERVER";

/**
 * @constant
 * @type {string}
 */
export const ERROR_OFF_SUBSCRIPTION = "ERROR_OFF_SUBSCRIPTION";

/**
 * @constant
 * @type {string}
 */
export const ERROR_NO_OFFER_FOR_BRAND = "ERROR_NO_OFFER_FOR_BRAND";

/**
 * @constant
 * @type {string}
 */
export const ERROR_SAVED_RESEARCH_VALUES_FETCH =
  "ERROR_SAVED_RESEARCH_VALUES_FETCH";

/**
 * @constant
 * @type {string}
 */
export const ERROR_UNKNOWN_GTAG_EVENT = "ERROR_UNKNOWN_GTAG_EVENT";

/**
 * @constant
 * @type {string}
 */
export const ERROR_MISSING_OFFER_FROM_COMPARATOR =
  "ERROR_MISSING_OFFER_FROM_COMPARATOR";

/**
 *
 * @type {string}
 */
export const ERROR_TO_PROVIDE_TO_ERROR_HANDLER =
  "ERROR_TO_PROVIDE_TO_ERROR_HANDLER";
