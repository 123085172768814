/**
 * @namespace Constants
 * @module Constantes Data Attributes HTML
 * @description Fichier de constantes de data-attribute html. Sert à personnaliser les balises et les retrouver dans le
 * document html via document.querSelector('[data-<attribute>="<value>"]). Utile pour vérifier qu'un élément est bien
 * présent sur la page.
 * @author Sébastien NOBOUR
 */

/**
 * @constant
 * @type {{individual: string, visitor: string, pro: string}}
 */
export const HEADERS_ATTRIBUTE = {
  visitor: "VISITOR",
  pro: "PRO",
  individual: "INDIVIDUEL",
};
