import { ADS_FILTERS } from "@/Constants/filters";
import { getAdsFilterValues } from "@/Utils/adsFilter/getAdsFilterValues";
import { updateAdsFilter } from "@/Utils/adsFilter/updateAdsFilter";
import { getRoundedAdsFilterValues } from "@/Utils/adsFilter/getRoundedAdsFilterValues";
// import {getValidAdsFilterValues} from '@/Utils/adsFilter/getValidAdsFilterValues'

export const getInitialAdsFilter = ({
  filterData = null,
  arrayFilteredBrands = [],
  arrayFilteredRanges = [],
  arrayFilteredModels = [],
  adsFilter = { ...ADS_FILTERS },
}) => {
  let filterValues = getAdsFilterValues({
    filterData,
  });

  filterValues = getRoundedAdsFilterValues(filterValues);

  return updateAdsFilter({
    adsFilter,
    filterValues,
    arrayFilteredRanges,
    arrayFilteredBrands,
    arrayFilteredModels,
  });
};
