import {
  BRANDS_FAVORITES_ROUTE,
  BRANDS_ROUTE,
  COUNTRIES_ROUTE,
  DEALERS_ROUTE,
  FILTERS_BRANDS_ROUTE,
  FILTERS_MODELS_ROUTE,
  FILTERS_RANGES_ROUTE,
  FILTERS_ROUTE,
  MODELS_ROUTE,
  MY_CONCESSIONS_ROUTE,
  MY_CONCESSION_ROUTE,
  MY_FAVORITES_ROUTE,
  MY_ROUTE,
  MY_SAVED_RESEARCHES_ROUTE,
  OFFERS_ROUTE,
  RANGES_ROUTE,
} from "@/Constants/restApiRoutes";
import {
  normalize,
  normalizeBrands,
  normalizeCountries,
  normalizeDealers,
  normalizeFilters,
  normalizeModels,
  normalizeMy,
  normalizeMyDealership,
  normalizeMyDealerships,
  normalizeMyFavorites,
  normalizeMySavedResearches,
  normalizeOfferById,
  normalizeOffers,
  normalizeRanges,
} from "@/Utils/normalizers/businessApiNormalizers";

/**
 * @function
 * @description retourne les données normalisées d'une réponse à une requête http traitée à l'intérieur de
 * la fonction businessApiRequest. Cette fonction doit être suffisamment intelligente pour sélectionner et executer la
 * fonction de normalization correspondant à l'urn et paramètres donnés à la fonction businessApiRequest.
 * @param urn
 * @param data
 * @param params
 * @returns {Promise<object>|object}
 */
export const normalizeBusinessData = async (urn, data, params) => {
  let normalizeCallback = data => normalize(data);

  const isValidId = params.id !== null && params.id !== 0 && params.id !== "0";

  switch (urn) {
    case MY_CONCESSION_ROUTE:
      if (params.httpMethod === "GET")
        normalizeCallback = data => normalizeMyDealership(data);
      break;

    case MY_CONCESSIONS_ROUTE:
      if (params.httpMethod === "GET")
        normalizeCallback = data => normalizeMyDealerships(data);
      break;

    case MY_ROUTE:
      if (params.httpMethod === "GET")
        normalizeCallback = (data, params) =>
          normalizeMy(data, params?.accessToken);
      break;

    case FILTERS_BRANDS_ROUTE:
    case BRANDS_FAVORITES_ROUTE:
      normalizeCallback = data =>
        normalizeBrands(data, {
          isAlphabeticSort: urn === FILTERS_BRANDS_ROUTE,
        });
      break;

    case DEALERS_ROUTE:
      if (params.httpMethod === "GET")
        normalizeCallback = data => normalizeDealers(data);
      break;

    case MY_SAVED_RESEARCHES_ROUTE:
      normalizeCallback = data => normalizeMySavedResearches(data);
      break;

    case MY_FAVORITES_ROUTE:
      normalizeCallback = data => normalizeMyFavorites(data);
      break;

    case MODELS_ROUTE:
    case FILTERS_MODELS_ROUTE:
      normalizeCallback = data => normalizeModels(data);
      break;

    case RANGES_ROUTE:
      normalizeCallback = data => normalizeRanges(data);
      break;

    case BRANDS_ROUTE:
      normalizeCallback = data => normalizeBrands(data);
      break;

    case COUNTRIES_ROUTE:
      normalizeCallback = data => normalizeCountries(data);
      break;

    case OFFERS_ROUTE:
      if (isValidId) {
        normalizeCallback = data => normalizeOfferById(data);
      } else {
        normalizeCallback = data => normalizeOffers(data);
      }
      break;

    case FILTERS_RANGES_ROUTE:
      normalizeCallback = data => normalizeRanges(data);
      break;

    case FILTERS_ROUTE:
      normalizeCallback = data => normalizeFilters(data);
  }

  return normalizeCallback(data, params);
};
