import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSession } from "next-auth/react";
import css from "./Header.module.css";
import Logo from "@/Shared/Logo";
import HeaderUser from "@/Shared/HeaderUser";
import HeaderParticulier from "@/Shared/HeaderParticulier";
import HeaderPro from "@/Shared/HeaderPro";
import MenuMobile from "@/Shared/MenuMobile";
import { isDesktop, isMobile } from "react-device-detect";
import Btn from "@/Shared/Ui/Btn";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import UseMediaQueries from "@/Shared/Hooks/UseMediaQueries";
import throttle from "lodash/throttle";
import UseMenus from "@/Shared/Hooks/UseMenus";
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  USER_TYPE_INVITE,
  USER_TYPE_PARTICULIER,
  USER_TYPE_PROFESSIONNEL,
} from "@/Constants/global";
import MenuCommun from "@/Shared/MenuCommun";
import BackToTop from "@/Shared/Ui/BackToTop";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";

const Header = ({ fnSticky = () => {}, fnHeaderUp = () => {} }) => {
  const mq = UseMediaQueries();

  const {
    state: { my },
  } = useContext(GlobalContext);
  const { status } = useSession();

  const [profilHeader, setProfil] = useState();
  const [dirScroll, setDirScroll] = useState("down");
  const [backTopVisible, setBackTopVisible] = useState(false);

  const htmlDOM = useRef(
    typeof document !== "undefined" ? document.querySelector("html") : null
  );

  // construction des menus DESK ou MOBILE
  const isMobileCmp = useRef(isMobile);
  const isDesktopCmp = useRef(isDesktop);

  const mqmin60 = mq.min60;
  const mqmax60 = mq.max60;

  /* -- */
  const { toggleMenu, menuRef } = UseMenus("global");
  /* -- */

  const onScrollHandler = () => {
    // console.log('scroll',window.pageYOffset,limiteToStick)
    /* si on a scrollé suffisamment, lorsqu'on scroll vers le haut, le menu réapparait */
    window.pageYOffset > 400
      ? setBackTopVisible(true)
      : setBackTopVisible(false);

    if (window.pageYOffset > limiteToStick) {
      fnSticky(true);
      if (previousOffset > window.pageYOffset) {
        setDirScroll("up");
        fnHeaderUp(true);
      } else {
        setDirScroll("down");
        fnHeaderUp(false);
      }
    }
    previousOffset = window.pageYOffset;
  };
  const throttledScrolledEvent = useMemo(
    () => throttle(onScrollHandler, 10),
    [throttle]
  );

  let previousOffset, watcherScrollY;
  const limiteToStick = 400; /* valeur de scroll pour que le header passe en sticky scroll up */

  const handleClick = e => {
    setProfil(e.target.innerHTML);
  };

  const toggleoffMenuMobile = () => {
    if (mqmax60) toggleMenu("global", false);
  };

  useEffect(() => {
    if (menuRef?.toggled) {
      htmlDOM.current.classList.add("js__menu-open");
    } else {
      htmlDOM.current.classList.remove("js__menu-open");
    }
  }, [menuRef?.toggled]);

  useEffect(() => {
    if (status === UNAUTHENTICATED) {
      setProfil(USER_TYPE_INVITE);
    }
    if (my?.userType && status === AUTHENTICATED) {
      setProfil(my.userType);
    }
  }, [status, my?.userType]);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        fnSticky(false);
        fnHeaderUp(false);
        document.querySelector("body")?.classList?.add("js__scrollOnTop");
      } else {
        document.querySelector("body")?.classList?.remove("js__scrollOnTop");
      }
      throttledScrolledEvent();
    };
  }, []);

  return (
    <>
      <div className={`${css["l-recepMobile"]}`}>
        {isMobileCmp.current || mqmax60 ? (
          <MenuCommun
            htmlDOM={htmlDOM}
            toggleMenu={toggleMenu}
            menuRef={menuRef}
          />
        ) : (
          ""
        )}

        {profilHeader !== USER_TYPE_PROFESSIONNEL ? (
          <Btn
            type='routeLink'
            path={
              profilHeader === "invite"
                ? "/connexion-inscription"
                : "/espace-client/mes-vehicules/ajout"
            }
            className={`f-primary ${uiBtn["u-btn-primary"]}`}
            label='Publier une annonce'
            onClick={toggleoffMenuMobile}
          />
        ) : (
          ""
        )}
      </div>

      {/* BOUTON POUR RETOUR EN HAUT AU SCROLL */}
      <BackToTop visible={backTopVisible} />

      <header
        className={`l-wide ${css["l-header-main"]} sticky-header ${
          dirScroll === "down" ? "sticky-header--down" : "sticky-header--up"
        }`}
      >
        <MenuMobile
          htmlDOM={htmlDOM}
          toggleMenu={toggleMenu}
          menuRef={menuRef}
        />
        <Logo situation='header' onClick={toggleoffMenuMobile} />
        <div className={`${css["l-recepDesktop"]}`}>
          {isDesktopCmp.current && mqmin60 ? (
            <MenuCommun
              htmlDOM={htmlDOM}
              toggleMenu={toggleMenu}
              menuRef={menuRef}
            />
          ) : (
            ""
          )}
        </div>
        {profilHeader === USER_TYPE_INVITE || !profilHeader ? (
          <HeaderUser toggleoffMenuMobile={toggleoffMenuMobile} />
        ) : (
          ""
        )}
        {profilHeader === USER_TYPE_PARTICULIER ? (
          <HeaderParticulier toggleoffMenuMobile={toggleoffMenuMobile} />
        ) : (
          ""
        )}
        {profilHeader === USER_TYPE_PROFESSIONNEL ? (
          <HeaderPro toggleoffMenuMobile={toggleoffMenuMobile} />
        ) : (
          ""
        )}
      </header>
    </>
  );
};

Header.propTypes = {
  fnSticky: PropTypes.func,
  fnHeaderUp: PropTypes.func,
};

Header.defaultProps = {
  fnSticky: () => {},
  fnHeaderUp: () => {},
};

export default Header;
