import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
// TODO TEST @MUI with jest
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Btn from "@/Shared/Ui/Btn";
import cssThemeEspace from "@/Theme/EspaceTheme.module.css";

const DialogInfo = ({
  handleClose,
  open,
  objet,
  message,
  submit,
  textButtonClose,
  children,
}) => {
  textButtonClose = submit ?? textButtonClose ?? "Retour";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={"b60"}
      className={`${cssThemeEspace["dialog-box"]}`}
    >
      <DialogTitle id='alert-dialog-title'>
        <span
          className={`f-primary f-primary--700 ${cssThemeEspace["dialog-box__title"]}`}
        >
          {objet}
        </span>
      </DialogTitle>
      <DialogContent>
        {message && !children && (
          <DialogContentText id='alert-dialog-description'>
            {message}
          </DialogContentText>
        )}
        {children ?? null}
      </DialogContent>
      <DialogActions>
        <Btn
          type='text'
          className={`${cssThemeEspace["containerFormulaire__submit__btn"]} 
          ${cssThemeEspace["containerFormulaire__submit__btn--condensed"]} 
          f-primary f-primary--700
        `}
          label={textButtonClose}
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

DialogInfo.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  objet: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submit: PropTypes.string,
};

export default DialogInfo;
