// // Cette syntaxe d'import permet de "transformer" un svg en composant React
// // On exporte ensuite l'ensemble de ces composants en export par défaut


import BadgeCarburant from './BadgeCarburant.svg'
import CarrosserieCapucine from './CarrosserieCapucine.svg'
import CarrosserieCaravane from './CarrosserieCaravane.svg'
import CarrosserieFourgon from './CarrosserieFourgon.svg'
import CarrosserieIntegral from './CarrosserieIntegral.svg'
import CarrosserieProfile from './CarrosserieProfile.svg'
import CarrosserieVan from './CarrosserieVan.svg'
import FlagFR from './FlagFR.svg'
import FlagGB from './FlagGB.svg'
import FlagDE from './FlagDE.svg'
import FlagES from './FlagES.svg'
import FlagIT from './FlagIT.svg'
import GfxDomeCenteredTop from './GfxDomeCenteredTop.svg'
import GfxWaveBottom from './GfxWaveBottom.svg'
import IcoAffichageGrille from './IcoAffichageGrille.svg'
import IcoAffichageLignes from './IcoAffichageLignes.svg'
import IcoAjoutComparateur from './IcoAjoutComparateur.svg'
import IcoComparateurAjoutV2 from './IcoComparateurAjoutV2.svg'
import IcoAmpoule from './IcoAmpoule.svg'
import IcoArobase from './IcoArobase.svg'
import IcoArticle from './IcoArticle.svg'
import IcoArrow from './IcoArrow.svg'
import IcoArrowDown from './IcoArrowDown.svg'
import IcoBaissePrix from './IcoBaissePrix.svg'
import IcoBulle from './IcoBulle.svg'
import IcoCalAbo from './IcoCalAbo.svg'
import IcoChat from './IcoChat.svg'
import IcoChecklist from './IcoChecklist.svg'
import IcoCircleCare from './IcoCircleCare.svg'
import IcoCircleError from './IcoCircleError.svg'
import IcoCircleInfo from './IcoCircleInfo.svg'
import IcoCircleSuccess from './IcoCircleSuccess.svg'
import IcoCles from './IcoCles.svg'
import IcoCloche from './IcoCloche.svg'
import IcoClose from './IcoClose.svg'
import IcoComparateur from './IcoComparateur.svg'
import IcoComparateurAjout from './IcoComparateurAjout.svg'
import IcoCompateurSwitch from './IcoCompateurSwitch.svg'
import IcoCrayonMetre from './IcoCrayonMetre.svg'
import IcoCroixSupp from './IcoCroixSupp.svg'
import IcoDeconnexion from './IcoDeconnexion.svg'
import IcoDistributeur from './IcoDistributeur.svg'
import IcoEnveloppe from './IcoEnveloppe.svg'
import IcoEnveloppe2 from './IcoEnveloppe2.svg'
import IcoEnveloppeSpace from './IcoEnveloppeSpace.svg'
import IcoEtoile from './IcoEtoile.svg'
import IcoFb from './IcoFb.svg'
import IcoFile from './IcoFile.svg'
import IcoFusee from './IcoFusee.svg'
import IcoFuseeSmall from './IcoFuseeSmall.svg'
import IcoIg from './IcoIg.svg'
import IcoImprimer from './IcoImprimer.svg'
import IcoInfos from './IcoInfos.svg'
import IcoKg from './IcoKg.svg'
import IcoLeadMail from './IcoLeadMail.svg'
import IcoLeadTel from './IcoLeadTel.svg'
import IcoLoupe from './IcoLoupe.svg'
import IcoModification from './IcoModification.svg'
import IcoMoins from './IcoMoins.svg'
import IcoNotifComment from './IcoNotifComment.svg'
import IcoNotifFusee from './IcoNotifFusee.svg'
import IcoNotifRecherche from './IcoNotifRecherche.svg'
import IcoNiv2Moins from './IcoNiv2Moins.svg'
import IcoNiv2Plus from './IcoNiv2Plus.svg'
import IcoOeil from './IcoOeil.svg'
import IcoOeilInput from './IcoOeilInput.svg'
import IcoOeilInputBarre from './IcoOeilInputBarre.svg'
import IcoPartager from './IcoPartager.svg'
import IcoPlay from './IcoPlay.svg'
import IcoPlus from './IcoPlus.svg'
import IcoPlusSolo from './IcoPlusSolo.svg'
import IcoPointer from './IcoPointer.svg'
import IcoPrixMarche from './IcoPrixMarche.svg'
import IcoProTools from './IcoProTools.svg'
import IcoRecadrage from './IcoRecadrage.svg'
import IcoRefresh from './IcoRefresh.svg'
import IcoRemplissageProfil from './IcoRemplissageProfil.svg'
import IcoRemplissageProfil100 from './IcoRemplissageProfil100.svg'
import IcoRoueLoupe from './IcoRoueLoupe.svg'
import IcoSelectionMultiple from './IcoSelectionMultiple.svg'
import IcoSignaler from './IcoSignaler.svg'
import IcoSyncPro from './IcoSyncPro.svg'
import IcoToggle from './IcoToggle.svg'
import IcoToggleSliced from './IcoToggleSliced.svg'
import IcoUser from './IcoUser.svg'
import IcoVideo from './IcoVideo.svg'
import IcoYt from './IcoYt.svg'
import LitAppoint from './LitAppoint.svg'
import LitCentral from './LitCentral.svg'
import LitFrancaise from './LitFrancaise.svg'
import LitJumeaux from './LitJumeaux.svg'
import LitPavillon from './LitPavillon.svg'
import LitSuperposes from './LitSuperposes.svg'
import IcoTDB from './IcoTDB.svg'
import LitToitRelevable from './LitToitRelevable.svg'
import LitTransversal from './LitTransversal.svg'
import LogoInline from './LogoInline.svg'
import SubmitGo from './SubmitGo.svg'
import Spinner from './Spinner.svg'
import Trouvez from './Trouvez.svg'

const object = {
  BadgeCarburant,
  CarrosserieCapucine,
  CarrosserieCaravane,
  CarrosserieFourgon,
  CarrosserieIntegral,
  CarrosserieProfile,
  CarrosserieVan,
  FlagFR,
  FlagGB,
  FlagDE,
  FlagES,
  FlagIT,
  GfxDomeCenteredTop,
  GfxWaveBottom,
  IcoAffichageGrille,
  IcoAffichageLignes,
  IcoAjoutComparateur,
  IcoComparateurAjoutV2,
  IcoAmpoule,
  IcoArobase,
  IcoArrow,
  IcoArrowDown,
  IcoArticle,
  IcoBaissePrix,
  IcoBulle,
  IcoCalAbo,
  IcoChat,
  IcoChecklist,
  IcoCircleCare,
  IcoCircleError,
  IcoCircleInfo,
  IcoCircleSuccess,
  IcoCles,
  IcoCloche,
  IcoClose,
  IcoComparateur,
  IcoComparateurAjout,
  IcoCompateurSwitch,
  IcoCrayonMetre,
  IcoCroixSupp,
  IcoDeconnexion,
  IcoDistributeur,
  IcoEnveloppe,
  IcoEnveloppe2,
  IcoEnveloppeSpace,
  IcoEtoile,
  IcoFb,
  IcoFile,
  IcoFusee,
  IcoFuseeSmall,
  IcoIg,
  IcoImprimer,
  IcoInfos,
  IcoKg,
  IcoLeadMail,
  IcoLeadTel,
  IcoLoupe,
  IcoModification,
  IcoMoins,
  IcoNotifComment,
  IcoNotifFusee,
  IcoNotifRecherche,
  IcoNiv2Moins,
  IcoNiv2Plus,
  IcoOeil,
  IcoOeilInput,
  IcoOeilInputBarre,
  IcoPartager,
  IcoPlay,
  IcoPlus,
  IcoPlusSolo,
  IcoPointer,
  IcoPrixMarche,
  IcoProTools,
  IcoRecadrage,
  IcoRefresh,
  IcoRemplissageProfil,
  IcoRemplissageProfil100,
  IcoRoueLoupe,
  IcoSelectionMultiple,
  IcoSignaler,
  IcoSyncPro,
  IcoTDB,
  IcoToggle,
  IcoToggleSliced,
  IcoUser,
  IcoVideo,
  IcoYt,
  LitAppoint,
  LitCentral,
  LitFrancaise,
  LitJumeaux,
  LitPavillon,
  LitSuperposes,
  LitToitRelevable,
  LitTransversal,
  LogoInline,
  SubmitGo,
  Spinner,
  Trouvez,
}

export default object
